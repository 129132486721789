const template = require(`html-loader!./votePage.html`).default;

class votePageComponent {

  constructor(pageData, $window, $scope, $state, $stateParams) {
    'ngInject';
    this.busy = true;
    this.data = {};

    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;

    this.onResize = this.onResize.bind(this);
  }

  $onInit() {
    if(!this.$stateParams.challengeUrl) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return;
    }
    let challenge = {};
    challenge.url = this.$stateParams.challengeUrl;
    this.data.challenge = challenge;
    this.data.mission = this.$stateParams.mission;

    this.init();
  }

  $onDestroy() {
    $(window).off('resize', this.onResize);
  }

  init() {
    this.pageData.get('vote');
    // fix for iOs bottom bar
    this.availHeight = window.innerHeight;
    $(window).on('resize', this.onResize);
  }

  onResize() {
    console.log('availHeight', window.innerHeight);
    this.availHeight = window.innerHeight;
  }
}
votePageComponent.$inject = [
  'pageData', '$window', '$scope', '$state', '$stateParams'
];
export default {
  template: template,
  controller: votePageComponent
};
