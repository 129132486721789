const template = require(`html-loader!./teamsMatch.html`).default;

import MatchModel from "../../../api/models/team/match.model";
import TeamStateModel from '../../../api/models/team/teamState.model';

import teamEnum from '../../../api/enums/team.enum';
import {autoUpdateTypeEnum} from "../../../services/autoUpdate.service.data";

class teamsMatchCtrl {

  constructor(
    $scope,
    $rootScope,
    RestManager,
    Modals,
    $stateParams,
    stateService,
    $filter,
    socketService,
    $interval,
    notificationAlert,
    teamsService,
    autoUpdateService,
    tagEventService
  ) {
    'ngInject';

    this.matchHistoryMode = false;
    this.busy = false;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.teamEnum = teamEnum;
    this.Modals = Modals;
    this.$stateParams = $stateParams;
    this.stateService = stateService;
    this.$filter = $filter;
    this.$interval = $interval;
    this.notificationAlert = notificationAlert;
    this.teamsService = teamsService;
    this.autoUpdateService = autoUpdateService;
    this.tagEventService = tagEventService;
  }

  $onInit() {
    this.isTeamAdmin = this.team.current_member.isAdmin();
    this.matchId = this.$stateParams.matchId;
    this.busy = true;
    this.getMatch();
    this.offOnMatchChanged = this.$rootScope.$on('onMatchChanged', this.onMatchChanged.bind(this));
    this.tagEventService.sendPageViewEvent({title: 'Teams - my team - match page'});
  }

  onMatchChanged(){
    if(!this.matchId){
      this.getMatch();
      this.setAutoUpdate();
    }
  }

  $onDestroy() {
    this.removeAutoUpdate();
    this.offOnMatchChanged();
  }

  matchHelp() {
    this.notificationAlert.info({
      message: this.configData.match.tutorial,
      buttonText: 'Got it',
      title: 'Match'
    });
  }

  removeAutoUpdate(){
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.MATCH);
  }

  setAutoUpdate(){
    let autoUpdateObject = {
      type : autoUpdateTypeEnum.MATCH,
      callback : this.getMatch.bind(this),
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
  }

  async getMatch(){
    // const res = await get_match_waiting_for_opponent;
    const res = await this.RestManager.getMatch({team_id: this.team.id, match_id: this.matchId});
    this.busy = false;

    if(!res.success) {
      return;
    }

    this.match = new MatchModel(res.data);
    this.matchState = new TeamStateModel(res.state);
    //set teams
    if (this.match.teams && this.match.teams.length) {
      this.myTeam = this.match.teams[0] || null;
      this.opponentTeam = this.match.teams[1] || null;
    }
    if (this.match.challenges.length) {
      // parse votes
      this.match.challenges.map( (challenge) =>{
        challenge.votes = this.$filter('abbreviateNumber')(challenge.votes);
        challenge.matchSubtype = this.isTeamAdmin ? 'start' : 'all';

        if (this.matchState.status === teamEnum.MATCH_STATUS.NO_ACTIVE_MATCH) {
          challenge.showMembers = true;
        }
      });
    }

    switch (this.matchState.status) {
      case teamEnum.MATCH_STATUS.NO_ACTIVE_MATCH:
        this.joinTitle = !this.isTeamAdmin ?
          'Team admin’s can choose a match challenge' : 'Select a challenge to start a match with your team';
        if (this.match.challenges.length) {
          await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.AVAILABLE_MATCH);
        }
        break;
      case teamEnum.MATCH_STATUS.WAITING_FOR_MINIMUM:
        this.setAutoUpdate();
        this.setDefaultMembers();
        break;
      case teamEnum.MATCH_STATUS.WAITING_FOR_OPPONENT:
        this.myTeam.activeMembers = this.myTeam.members;
        break;
      case teamEnum.MATCH_STATUS.FINALIZING:
      case teamEnum.MATCH_STATUS.RUNNING:
        this.setAutoUpdate();
        this.setMinTeamMembers();
        await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.ACTIVE_MATCH_1);
        break;

      case teamEnum.MATCH_STATUS.CLOSED:
        this.setMinTeamMembers();
        this.myTeamWon = this.myTeam.total_votes >=  this.opponentTeam.total_votes;
        this.winningTeam = this.myTeamWon ? this.myTeam : this.opponentTeam;
        this.losingTeam = this.myTeamWon ? this.opponentTeam : this.myTeam;
        this.setDetailItems();
        break;
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setDetailItems() {
    this.teamDetailTypes = [
      teamEnum.DETAILS.TYPES.SCORE_WHITE,
      teamEnum.DETAILS.TYPES.VOTE
    ];
  }

  setMinTeamMembers() {
    this.minTeamMembers = Math.min(this.myTeam.members.length, this.opponentTeam.members.length);
    this.minTeamMembers = this.minTeamMembers < this.configData.minimum_number_of_members_in_challenge ?
      this.configData.minimum_number_of_members_in_challenge : this.minTeamMembers;
    this.match.teams.forEach( (team)=> {
      team.activeMembers = [];
      team.stendByMembers = [];

      team.members.forEach( (member, index)=> {
        if (index < this.minTeamMembers) {
          team.activeMembers.push(member);
        } else {
          team.stendByMembers.push(member);
        }
      });
    });
    this.maxStandbyMembers = Math.max(this.match.teams[0].stendByMembers.length, this.match.teams[1].stendByMembers.length);
  }

  setDefaultMembers(){
    this.myTeam.numOfMembers = this.myTeam.members.length;
    let members = [];
    const memberLength = Math.max(this.configData.minimum_number_of_members_in_challenge, this.myTeam.numOfMembers);

    for (let i = 0; i < memberLength; i++) {
      members.push(this.myTeam.members[i] || null);
    }

    this.myTeam.activeMembers = members;
  }

  async matchHistory() {
    this.matchHistoryMode = !this.matchHistoryMode;

    if(!this.matchHistoryMode) {
      this.matchId = null;
      this.busy = true;
      this.getMatch();
      this.setAutoUpdate();
    }else {
      this.removeAutoUpdate();
    }
  }

  isTeamSuggestedInviteEnabled() {
    return this.teamsService.isTeamSuggestedInviteEnabled(this.team);
  }
}

teamsMatchCtrl.$inject = [
  '$scope',
  '$rootScope',
  'RestManager',
  'Modals',
  '$stateParams',
  'stateService',
  '$filter',
  'socketService',
  '$interval',
  'notificationAlert',
  'teamsService',
  'autoUpdateService',
  'tagEventService'
];
export default {
  template: template,
  controller: teamsMatchCtrl,
  bindings: {
    team: '<',
    configData: '<'
  }
};
