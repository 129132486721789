const template = require(`html-loader!./editVirtualExhibition.html`).default;
import {isFieldValidNew} from "../../../../../../helpers/validate.helper";

class editVirtualExhibitionCtrl {

  /**
   * @param {RestManager} RestManager
   * @param {ModalsService} Modals
   * */

  constructor(RestManager, $stateParams, $state, Toasts, $element, notificationAlert, Modals, PhotosManager, MemberService) {
    'ngInject';
    this.RestManager = RestManager;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.Toasts = Toasts;
    this.$element = $element;
    this.notificationAlert = notificationAlert;
    this.Modals = Modals;
    this.PhotosManager = PhotosManager;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    if(!this.$stateParams.virtualExhibition && this.$state.current.url === '/virtual-edit'){
      this.returnToList();
      return;
    }
    this.virtualExhibition = this.$stateParams.virtualExhibition || {};
  }

  returnToList(){
    this.$state.go('gs.gsAdmin.contentManager.exhibitions.virtual', {}, {reload: true});
  }

  async save() {
    if (this.validate()) {
      let resData = {
        title : this.virtualExhibition.title,
        link : this.virtualExhibition.link,
        image_id: this.virtualExhibition.image_id,
        order:this.virtualExhibition.order,
        exhibition_id: this.virtualExhibition.id || null
      };

      let res;
      if(resData.exhibition_id){
        res = await this.RestManager.updateVirtualExhibition(resData);
      }else{
        res = await this.RestManager.createVirtualExhibition(resData);
      }

      if (!res.success) {
        if(res.message){
          this.notificationAlert.info({message:res.message});
        }
        return;
      }
      await this.PhotosManager.setImageData({
        id: this.virtualExhibition.image_id,
        labels: ['virtual'],
      });
      this.returnToList();
    }
  }

  validate(){
    let required_fields = [
      {field:'title', title:'Title'},
      {field:'image_id', title:'Image id'},
      {field:'order', title:'Order', isNumber:true},
    ];

    for (let required_field of required_fields) {
      let validRes = isFieldValidNew(this.virtualExhibition, required_field);
      if (!validRes.valid) {
        this.notificationAlert.info({message:validRes.message});
        return false;
      }
    }
    return true;
  }

  selectImage(event) {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: {
        id: this.member.id,
      },
      callback: images => {
        this.virtualExhibition.image = images[0];
        this.virtualExhibition.image_id = images[0].id;
      },
    });
  }
}
editVirtualExhibitionCtrl.$inject = [
  'RestManager', '$stateParams', '$state', 'Toasts', '$element', 'notificationAlert', 'Modals', 'PhotosManager', 'MemberService'
];
export default {
  template: template,
  controller: editVirtualExhibitionCtrl,
  bindings: {},
};
