import {Component, Input, OnInit} from '@angular/core';
import {StoreService} from "../../../store/services/store.service";
import {IGetPaymentConfig, IPaymentFormData, PaymentService, PaymentState} from "../../payment.service";
import {IStoreItem} from "../../../store/services/store.service.types";
import {AbstractControl, FormBuilder, Validators} from "@angular/forms";
import {NgLocalStorageEnum} from "../../../../core/models/enums";
import {NgLocalStorageService} from "../../../../core/services/ng-local-storage.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @Input() storeItem?: IStoreItem;
  isUnknownPayment: boolean = true;
  paymentConfig?: IGetPaymentConfig;
  PaymentState = PaymentState;
  busy: boolean = true;
  error: string = '';
  state: PaymentState | undefined;

  pfFirstNameValidationLength: number = 2;
  pfLastNameValidationLength: number = 2;
  pfCardNumberMinValidationLength: number = 15;
  pfCardNumberMaxValidationLength: number = 16;
  pfCardCvvCValidationLength: number = 3;
  pfFirstNameC: AbstractControl | null = null; // C - control
  pfLastNameC: AbstractControl | null = null; // C - control
  pfEmailC: AbstractControl | null = null; // C - control
  pfCardNumberC: AbstractControl | null = null; // C - control
  pfCardExpiredC: AbstractControl | null = null; // C - control
  pfCardCvvC: AbstractControl | null = null; // C - control
  pf = this.fb.group({
    firstName: [
      '',
      [
        Validators.required,
        Validators.minLength(this.pfFirstNameValidationLength)
      ]
    ],
    lastName: [
      '',
      [
        Validators.required,
        Validators.minLength(this.pfLastNameValidationLength)
      ]
    ],
    email: [
      '',
      [
        Validators.required,
        Validators.email
        // Validators.pattern(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gm)
      ]
    ],
    card: this.fb.group({
      number: [
        '',
        [
          Validators.required,
          Validators.minLength(this.pfCardNumberMinValidationLength),
          Validators.maxLength(this.pfCardNumberMaxValidationLength),
          Validators.pattern("^[0-9]*$")
        ]
      ],
      expired: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(0[1-9]|1[0-2])\/([0-9][0-9])$/gm)
          // this.customValidator({pattern: /^(0[1-9]|1[0-2])\/([0-9][0-9])$/gm, errorName: 'expired'})
        ]
      ],
      cvv: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3),
          Validators.pattern(/^\d{3}$/gm)
          // this.customValidator({pattern: /^\d{3}$/gm, errorName: 'threedigits'})
        ]
      ]
    })
  }); // PF - payment form

  constructor(
    public storeService: StoreService,
    public paymentService: PaymentService,
    public localStorageService: NgLocalStorageService,
    private fb: FormBuilder
  ) {

    this.paymentService.paymentState$.pipe(
      takeUntilDestroyed()
    ).subscribe((state) => this.state = state);

    this.paymentService.loading$.pipe(
      takeUntilDestroyed()
    ).subscribe((loading) => this.busy = loading);

    this.paymentService.error$.pipe(
      takeUntilDestroyed()
    ).subscribe((error) => {
        this.busy = false;
        this.error = error;
    });
  }

  async ngOnInit(): Promise<void> {
    //region Shortcut for template
    this.pfFirstNameC = this.pf.get('firstName');
    this.pfLastNameC = this.pf.get('lastName');
    this.pfEmailC = this.pf.get('email');
    this.pfCardNumberC = this.pf.get('card')!.get('number');
    this.pfCardExpiredC = this.pf.get('card')!.get('expired');
    this.pfCardCvvC = this.pf.get('card')!.get('cvv');
    //endregion
    await this.init();
  }

  async init(){
    this.paymentConfig = await this.paymentService.getPaymentConfig();
    this.busy = false;
    this.setInitialValues();
  }

  setInitialValues(): void {
    this.pfFirstNameC?.setValue(this.paymentConfig?.config.first_name);
    this.pfLastNameC?.setValue(this.paymentConfig?.config.last_name);
    this.pfEmailC?.setValue(this.paymentConfig?.config.email);
  }

  backToStore(): void {
    this.storeService.setStoreActive();
  }

  isDisabled(): boolean {
    return !(this.storeItem && (this.pf.valid || this.state === PaymentState.SAVED_CARD));
  }

  async onSubmit() {
    let formData:IPaymentFormData | undefined = undefined;
    if (this.state === PaymentState.NEW_CARD) {
      formData = {
        firstName: this.pfFirstNameC?.value,
        lastName: this.pfLastNameC?.value,
        email: this.pfEmailC?.value,
        card: {
          number: this.pfCardNumberC?.value,
          expired: this.pfCardExpiredC?.value,
          cvv: this.pfCardCvvC?.value,
        }
      };
    }
    this.busy = true;
    this.paymentService.pay(this.storeItem!, this.state, formData).then(() => {
      this.localStorageService.saveMemberDataByKeyValue(NgLocalStorageEnum.LAST_PAYED_WITH_PAYPAL, false);
      this.paymentService.onPaymentSuccess(this.storeItem!, this.storeService.itemSectionType!);
    }).catch((e) => {
      this.busy = false;
      this.error = e;
    });
  }

  onUseYourCreditCard(){
    if (this.paymentService.isSavedCardAvailable()) {
      this.state = PaymentState.SAVED_CARD;
    } else {
      this.state = PaymentState.NEW_CARD;
    }
  }
}
