/* globals angular */
const template = require(`html-loader!./mobileRankingMenu.html`).default;

class mobileRankingMenuCtrl {
  /** @param {rankFilters} rankFilters */

  constructor($element,
              $state,
              Modals,
              rankFilters,
              challengeService
  ) {
    "ngInject";
    this.active = false;
    this.myPosition = false;

    this.$element = $element;
    this.$state = $state;
    this.Modals = Modals;
    this.rankFilters = rankFilters;
    this.challengeService = challengeService;
  }

  $onInit() {
    this.filter = this.rankFilters.getFilter();
    this.filters = this.rankFilters.getList();
  }

  preSubmit(event) {
    this.challengeService.openPreSubmit(this.challenge);
  }

  goToMyPos() {
    $(document).trigger('go-to-my-position');
  }

  toggleActive() {
    this.active = !this.active;
    $(this.$element[0]).toggleClass('active');
  }
}
mobileRankingMenuCtrl.$inject = [
  '$element', '$state', 'Modals', 'rankFilters', 'challengeService'
];
export default {
  template: template,
  controller: mobileRankingMenuCtrl,
  bindings: {
    challenge: '<',
    member: '<',
    tools: '='
  }
};
