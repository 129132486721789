/* globals angular */
const template = require(`html-loader!./rules.html`).default;

class challengeRulesCtrl {

  constructor($location, tagEventService) {
    "ngInject";

    this.$location = $location;
    this.tagEventService = tagEventService;
  }

  $onInit() {
    // vPageView
    this.tagEventService.sendPageViewEvent({title: 'Challenge Rules'});
    this.rules = this.challenge.getRules();
  }
}
challengeRulesCtrl.$inject = [
  '$location', 'tagEventService'
];
export default {
  template: template,
  controller: challengeRulesCtrl,
  bindings: {
    challenge: '<'
  }
};
