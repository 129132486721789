function config($stateProvider) {
  "ngInject";
  $stateProvider
    .state('gs.partners', {
      url: '/partners',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'partnersPage'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
