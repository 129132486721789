import {
  AfterContentInit,
  Component,
  DestroyRef,
  Inject,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {RankingService} from "../../services/ranking.service";
import {debounceTime, delay, iif, of, skip, Subject, switchMap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {RankingInterface, RankingItemInterface, RankingResponseInterface} from "../../interfaces/ranking.interface";
import {CdkVirtualScrollViewport, ScrollDispatcher} from "@angular/cdk/scrolling";
import {filter, tap} from "rxjs/operators";
import {isNumber} from "lodash";
import {autoUpdateTypeEnum} from "../../../../gsApp/app/services/autoUpdate.service.data";
import GsTimerService from "../../../../core/services/gs-timer.service";
import {GsTimerEnum} from "../../../../shared/pipes/gs-timer/gs-timer.enum";
import {MediatorMessageKey, MediatorService} from "../../../../core/services/mediator.service";
import {MemberService} from "../../../../core/services/member.service";
import {BankrollService} from "../../../bankroll/bankroll.service";
import {ResourceType} from "../../../../core/models/enums";
import {SectionType} from "../../../store/models/enums";
import {fadeInFadeOut} from "../../../../core/animations/animations";
import {ISocketStatusUpdateEventResult, SocketService} from "../../../../core/services/socket.service";
import challengeEnum from "../../../../gsApp/app/api/enums/challenge.enum";
import {SocketsEnum} from "../../../../core/enum/sockets.enum";
import mixpanelEventsEnum from "../../../../gsApp/app/services/mixpanel/mixpanel.enum";

const itemsLimit = 20;

@Component({
  selector: 'app-photographer-ranking',
  templateUrl: './photographer-ranking.component.html',
  styleUrl: './photographer-ranking.component.scss',
  animations: [fadeInFadeOut(500)]
})
export class PhotographerRankingComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;

  @Input() public challenge!: any;
  @Input() public member!: any;

  public cachedRanking!: RankingResponseInterface;
  public rankingItems: RankingItemInterface[] = [];
  public itemHeight = 120;
  public isLockedMode = true;
  public nextInterval = 0;
  public firstPrizes = [];
  public countDownTime = 0;
  public gsTimerEnum = GsTimerEnum;
  public exposure = 0;
  public memberId = '';
  public isChallengeFinished = false;
  public isAutoUpdateOn = false;
  public isJoinedToChallenge = false;
  public isNotificationShown = false;
  public isFillButtonEnabled = false;
  public challengeEnum = challengeEnum;
  public showNotification$ = new Subject<void>();
  public timeLastRankingReceived = 0;
  public isTargetRequestActive = false;
  public isLoading = true;
  private getRanking$ = new Subject<RankingInterface>();
  private goToTarget$ = new Subject<RankingInterface>();
  private setLockMode$ = new Subject<boolean>();
  private destroyRef = inject(DestroyRef);
  private viewPortSize = 0;
  private isTopReached = false;
  private onStatusUpdateRef:any;

  constructor(
    private rankingService: RankingService,
    private scrollDispatcher: ScrollDispatcher,
    private gsTimerService: GsTimerService,
    private mediatorService: MediatorService,
    private memberService: MemberService,
    private bankrollService: BankrollService,
    private socketService: SocketService,
    @Inject("autoUpdateService") private autoUpdateService: any,
    @Inject('mixpanelService')  private mixpanelService: any,
    @Inject('brazeService') private brazeService: any
  ) {

    this.getRanking$.pipe(
      tap(({isTopReached}) => {
        this.isTopReached = isTopReached as boolean;
      }),
      filter(({index}) => isNumber(index) && index >= 0),
      filter(() => !this.isTargetRequestActive),
      switchMap(
        ({c_id, index}) =>
          this.rankingService.getRanking(c_id, false, index, itemsLimit, itemsLimit)
      ),
      takeUntilDestroyed()
    ).subscribe(ranking => {
      this.rankingItems = ranking.items;
      const index = this.getIndex(ranking?.start_index);
      setTimeout(() => {
        const offset = this.isTopReached ? index * this.itemHeight : (index + 2.5) * this.itemHeight - this.viewPortSize;
        this.viewPort.scrollToOffset(
          offset,
          "instant"
        );
      });
    });

    this.goToTarget$.pipe(
      switchMap(
        ({c_id, init}) => {
          this.isTargetRequestActive = true;
          this.isJoinedToChallenge = this.challenge.member?.is_joined ?? false;
          return iif(() =>
              (!this.cachedRanking || (Date.now() > (this.timeLastRankingReceived + (this.nextInterval * 1000)))),
            this.rankingService.getRanking(
              c_id, init, this.isJoinedToChallenge ? null : 0, itemsLimit, itemsLimit
            ), of(this.cachedRanking))
        }
      ),
      takeUntilDestroyed()
    ).subscribe(ranking => {
      this.timeLastRankingReceived = Date.now();
      this.cachedRanking = ranking;
      this.rankingItems = ranking.items;
      const index = this.getIndex(ranking?.start_index);
      const next_interval = ranking.next_interval;
      this.isLoading = false;

      if (ranking.challenge) {
        // @ts-ignore
        this.firstPrizes = ranking.challenge.first_prizes;
        // @ts-ignore
        this.isFillButtonEnabled = ranking.challenge.fill_enable && !ranking.challenge.fill_locked
        // @ts-ignore
        const closeTime = ranking.challenge.close_time * 1000 as number;
        this.gsTimerService.start({
          expireTime: closeTime,
          timesUpHandler: this.timesUpHandler.bind(this),
          timeTickingHandler: this.timeTickingHandler.bind(this)
        });
      }

      if (next_interval && this.isJoinedToChallenge && !this.isChallengeFinished) {
        this.nextInterval = next_interval;
        this.initAutoUpdate();
      }

      if (ranking.exposure) {
        this.exposure = ranking.exposure.exposure_factor;
      }

      this.scrollToSelfElement(index);
      this.setLockMode$.next(true);
      setTimeout(() => {
        this.isTargetRequestActive = false;
      }, 500);
    });

    this.setLockMode$.pipe(
      delay(200),
      takeUntilDestroyed()
    ).subscribe(isLockedMode => {
      this.isLockedMode = isLockedMode;
      if (this.isLockedMode && this.nextInterval && !this.isChallengeFinished) {
        this.initAutoUpdate();
      }
    });

    this.showNotification$.pipe(
      filter(() => !this.isChallengeFinished),
      tap(() => this.isNotificationShown = true),
      delay(2500),
      takeUntilDestroyed()
    ).subscribe(() => this.isNotificationShown = false);

    this.mediatorService.on(MediatorMessageKey.UPDATE_EXPOSURE).pipe(
      takeUntilDestroyed()
    ).subscribe((data: any) => {
      this.exposure = data.data;
    });

    this.mediatorService.on(MediatorMessageKey.FILL).pipe(
      takeUntilDestroyed()
    ).subscribe(() => this.exposure = 100);

    this.onStatusUpdateRef = this.onStatusUpdate.bind(this);
    this.socketService.socket.on(SocketsEnum.StatusUpdate, this.onStatusUpdateRef);
  }

  onStatusUpdate(result:ISocketStatusUpdateEventResult){
    const isCurrentChallenge = result.challenge_id === this.challenge.id;
    if (isCurrentChallenge) {
      this.challenge.status = result.status;
    }
  }

  ngOnInit(): void {
    this.memberId = this.memberService.getCurrentMember()?.id;
    window.scrollTo({top: 550, behavior: 'smooth'});
    this.goTarget(true);
  }

  ngAfterContentInit(): void {
    this.scrollDispatcher.scrolled().pipe(
      skip(1),
      debounceTime(250),
      filter(() => this.viewPort?.measureScrollOffset('bottom') === 0),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      const lastIndex = this.rankingItems[this.rankingItems.length - 1]?.index;
      this.getRanking$.next({
        c_id: this.challenge.id,
        init: false,
        index: lastIndex,
        isTopReached: false
      });
    });

    this.scrollDispatcher.scrolled().pipe(
      skip(1),
      debounceTime(250),
      filter(() => this.viewPort?.measureScrollOffset('top') === 0),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      const firstIndex = this.rankingItems[0]?.index;
      this.getRanking$.next({
        c_id: this.challenge.id,
        init: false,
        index: firstIndex,
        isTopReached: true
      });
    });

    this.scrollDispatcher.scrolled().pipe(
      skip(2),
      debounceTime(50),
      filter((event) => !!event),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.isLockedMode = false;
      this.stopAutoUpdate();
      window.scrollTo({top: 550, behavior: 'smooth'});
    });
  }

  getIndex(start_index: number): number {
    return (this.rankingItems ?? []).findIndex(item => item.index === start_index) ?? 0;
  }

  goTarget(init = false): void {
    this.goToTarget$.next({
      c_id: this.challenge.id,
      init
    });
  }

  scrollToSelfElement(index: number): void {
    setTimeout(() => {
      if (!this.viewPortSize && this.viewPort) {
        this.viewPortSize = this.viewPort.getViewportSize();
      }
      this.viewPort.scrollToOffset(
        index * this.itemHeight - (this.viewPortSize / 2 - this.itemHeight),
        "instant"
      );
    });
  }

  goVote(): void {
    this.mediatorService.broadcast(MediatorMessageKey.OPEN_VOTE_MODAL, this.challenge);
    this.eventMixPanelAction({
      ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
      ui_name: mixpanelEventsEnum.UI_NAME.FLASH_RANK_VOTE
    });
  }

  async fill(): Promise<void> {
    if (this.bankrollService.isChallengeResourceEmpty(ResourceType.FILLS)) {
      this.isFillButtonEnabled = false;
      const outFillsEventParams = {outOfResourceId: 'out_fills' + Date.now()};
      this.brazeService.logCustomEvent('out_key', outFillsEventParams);
      await this.brazeService.waitForBrazeMessage(this.openStoreFillsSection.bind(this), outFillsEventParams.outOfResourceId);
      this.isFillButtonEnabled = true;
      return;
    }
    this.mediatorService.broadcast(MediatorMessageKey.OPEN_FILL_MODAL, {
      challengeIds: [this.challenge.id],
      challengeId: this.challenge.id,
      availableFills: this.bankrollService.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.FILLS),
      showSingleFill: true,
      showAllOption: false,
      exposure: this.exposure
    });

    this.eventMixPanelAction({
      ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
      ui_name: mixpanelEventsEnum.UI_NAME.FLASH_RANK_FILL
    });
  }

  openStoreFillsSection(): void {
    this.bankrollService.openStoreByResourceType(ResourceType.FILLS);
  }

  initAutoUpdate(): void {
    let autoUpdateObject = {
      type: autoUpdateTypeEnum.RANKING,
      callback: this.goTarget.bind(this),
      initCallbackOnStart: false,
      delay: this.nextInterval * 1000
    };
    // @ts-ignore
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
    if (!this.isAutoUpdateOn) {
      this.isAutoUpdateOn = true;
      if (!this.isChallengeFinished) {
        this.showNotification$.next();
      }
    }
  }

  stopAutoUpdate(): void {
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.RANKING);
    if (this.isAutoUpdateOn && !this.isChallengeFinished) {
      this.showNotification$.next();
    }
    this.isAutoUpdateOn = false;
  }

  timesUpHandler(): void {
    this.isChallengeFinished = true;
    this.challenge.status = challengeEnum.STATUSES.CLOSED.VALUE;
    this.stopAutoUpdate();
  }

  timeTickingHandler(countDownTimestamp: number): void {
    this.countDownTime = countDownTimestamp;
  }

  ngOnDestroy(): void {
    this.stopAutoUpdate();
    this.gsTimerService.stop();
    this.socketService.removeListener(SocketsEnum.StatusUpdate, this.onStatusUpdateRef);
  }

  onHeaderPrizesClick() {
    this.eventMixPanelAction({
      ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
      ui_name: mixpanelEventsEnum.UI_NAME.FLASH_RANK_PRIZE
    });
  }

  eventMixPanelAction({ui_name, ui_action ,data = {}}:
                        {ui_name:any, ui_action:any, data?:any}) {
    this.mixpanelService.track(
      {
        event: mixpanelEventsEnum.EVENT_NAME.FLASH_RANK,
        data: {
          ui_action,
          ui_name,
          challenge_id: this.challenge.id,
          ...data,
        }
      }
    );
  }
}
