const template = require(`html-loader!./myChallengesManageItem.html`).default;
import badgeV2Mode from "../../../../api/enums/badgeV2.enum";

class myChallengesManageItemController {
  /** @param {GsModalsService} gsModals **/

  constructor(
    $element,
    Modals,
    $location,
    $timeout,
    $interval,
    photoSwipe,
    gsModals,
    $cookies,
    $state,
    MemberService,
    $rootScope
  ) {
    'ngInject';
    this.$element = $element;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.$location = $location;
    this.$interval = $interval;
    this.photoSwipe = photoSwipe;
    this.gsModals = gsModals;
    this.$cookies = $cookies;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.badgeV2Mode = badgeV2Mode;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/challenge/${this.challenge.url}/details`;
  }

  vote(mode) {
    this.gsModals.open('vote', { challenge: this.challenge, mode });
  }

  duplicate(){
    this.Modals.open('createChallenge', null, {cUrl: this.challenge.url});
  }
}
myChallengesManageItemController.$inject = [
  '$element',
  'Modals',
  '$location',
  '$timeout',
  '$interval',
  'photoSwipe',
  'gsModals',
  '$cookies',
  '$state',
  'MemberService',
  '$rootScope'
];
export default {
  template: template,
  controller: myChallengesManageItemController,
  bindings: {
    challenge: '<',
    settings: '<'
  },
};
