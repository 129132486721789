class dlCtrl {
  constructor($state,
              $stateParams,
              pageData,
              $cookies,
              deepLinkService,
              $rootScope,
              $timeout,
              MemberService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.pageData = pageData;
    this.$cookies = $cookies;
    this.deepLinkService = deepLinkService;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.MemberService = MemberService;
  }

  $onInit() {
    this.init();
  }

  /** @return void **/
  async init() {
    // //check if teams deep link
    // if(this.$state.current.name === 'gs.dl.teams' && this.$stateParams.teamId){
    //   const isMobile = $(document.documentElement).hasClass('mobile');
    //   if(!isMobile){
    //     this.$state.go('gs.teams.info', {teamId:this.$stateParams.teamId}, {reload:true});
    //     return;
    //   }
    //   this.$state.go('gs.challenges.myChallenges.joined');
    //   return;
    // }

    this.member = this.MemberService.getCurrentMember();
    const dlState = this.deepLinkService.dlStates[this.$state.current.name];
    if (!dlState) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return;
    }

    let dl = {params:{}};
    Object.assign(dl, dlState);
    Object.assign(dl.params, this.$stateParams);
    if(dl.type === 'profileUpload'){
      dl.params.cUserName =  this.member.user_name;
    }

    let expired = new Date();
    expired.setDate(expired.getDate() + 1);
    this.$cookies.putObject('dl', dl, {expires: expired});

    if (this.member.logged_in === false) {
      this.$state.go('gs.homePage');
      return;
    }

    this.$state.go(dl.redirectState, dl.params);
  }
}
dlCtrl.$inject = [
  '$state',
  '$stateParams',
  'pageData',
  '$cookies',
  'deepLinkService',
  '$rootScope',
  '$timeout',
  'MemberService',
];
export default dlCtrl;
