const challengeTemplate = require(`html-loader!./challenge.page.html`).default;
const challengeWinnersTemplate = require(`html-loader!./winners/winners.html`).default;

function config($stateProvider) {
  'ngInject';

  //Routes
  $stateProvider
    .state('gs.challenge', {
      url: '/challenge/:cUrl',
      redirectTo: 'gs.challenge.details',
      params: {
        cUrl: null
      },
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {
          template: challengeTemplate,
          controller: 'challengePageCtrl as cp'
        },
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.challenge.winners', {
      url: '/winners',
      redirectTo: 'gs.challenge.winners.all',
      template: challengeWinnersTemplate,
      params: {
        force: false
      }
    })
    .state('gs.challenge.winners.all', {
      url: '/all',
      views: {
        'winners@gs.challenge.winners': {
          template: `<challenge-winners-all challenge="cp.challenge"
                                            member="cp.member"></challenge-winners-all>`
        }
      }
    })
    .state('gs.challenge.winners.exhibitPrint', {
      url: '/exhibit-print',
      views: {
        'winners@gs.challenge.winners': {
          template: `<challenge-winners-exhibition
                                     type="print"
                                     challenge="cp.challenge"
                                     member="cp.member"></challenge-winners-exhibition>`
        }
      }
    })
    .state('gs.challenge.winners.exhibitDigital', {
      url: '/exhibit-digital',
      views: {
        'winners@gs.challenge.winners': {
          template: `<challenge-winners-exhibition
                                    type="digital"
                                    challenge="cp.challenge"
                                    member="cp.member"></challenge-winners-exhibition>`
        }
      }
    })
    .state('gs.challenge.details', {
      url: '/details',
      template: `<challenge-details challenge="cp.challenge" ng-if="cp.done"></challenge-details>`
    })
    .state('gs.challenge.prizes', {
      url: '/prizes',
      template: `<challenge-prizes challenge="cp.challenge" ng-if="cp.done"></challenge-prizes>`
    })
    .state('gs.challenge.rules', {
      url: '/rules',
      template: `<challenge-rules challenge="cp.challenge" ng-if="cp.done"></challenge-rules>`
    })
    .state('gs.challenge.rank', {
      url: '/rank',
      redirectTo: 'gs.challenge.rank.topPhotographer',
      template: `<challenge-rank tools="cp.tools"
                                 member="cp.member"
                                 challenge="cp.challenge"
                                 ng-if="cp.done"></challenge-rank>`,
      reloadOnSearch: false,
      params: {
        force: false
      }
    })
    .state('gs.challenge.rank.topPhotographer', {
      url: '/top-photographer/:targetMember/:random',
      params: {
        targetMember: {
          value: null,
          squash: true
        },
        random: {
          value: null,
          squash: true
        },
        filter: {
          value: null
        }
      },
      views: {
        'rank@gs.challenge.rank': {
          template: `<rank-top-photographer challenge="$ctrl.challenge"
                                            tools="cp.tools"
                                            member="$ctrl.member" ng-if="$ctrl.done"></rank-top-photographer>`
        },
        'footer@gs': {template: ''}
      }
    })
    .state('gs.challenge.rank.topPhoto', {
      url: '/top-photo',
      params: {
        filter: {
          value: null
        }
      },
      views: {
        'rank@gs.challenge.rank': {
          template: `<rank-top-photo challenge="$ctrl.challenge"
                                     member="$ctrl.member"></rank-top-photo>`
        }
      }
    })
    .state('gs.challenge.rank.gurusTopPick', {
      url: '/gurus-top-pick',
      views: {
        'rank@gs.challenge.rank': {
          template: `<rank-gurus-top-pick challenge="$ctrl.challenge"
                                          member="$ctrl.member"></rank-gurus-top-pick>`
        }
      }
    })
  ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
