function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state('gs.mobileMembersSearch', {
      url: '/mobile_members_search',
      views: {
        'header@gs': null,
        'page@gs': {component: 'mobileMembersSearch'},
        'footer@gs': null
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
