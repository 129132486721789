const template = require(`html-loader!./teamItem.html`).default;
import {teamItemMode} from "./teamItem.data";

class teamItemCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.teamItemMode = teamItemMode;
  }

  $onChanges(){
    if(!this.mode || !this.team){return}
    switch (this.mode) {
      case teamItemMode.lb_leagues:
        this.rank = this.team.league.rank.item_rank;
        break;
      case teamItemMode.lb_global:
        this.rank = this.team.rank.item_rank;
        this.leagueInternalName = this.team.league.internal_name;
        break;
      case teamItemMode.join:
        this.leagueInternalName = this.team.league.internal_name;
        break;
    }
  }
}

teamItemCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: teamItemCtrl,
  bindings: {
    team: '<',
    mode: '@',
    leagueInternalName: '@',
  }
};
