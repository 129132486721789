function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state('gs.exhibitions', {
      url: '/exhibitions',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {template: '<exhibitions lazy-img-container></exhibitions>'},
        'footer@gs': null
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
