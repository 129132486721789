const template = require(`html-loader!./achievementsBanner.html`).default;
import achievementEnum from "../../../../../api/enums/achievement.enum";
import mixpanelEventsEnum from "../../../../../services/mixpanel/mixpanel.enum";

class achievementsBannerCtrl {

  constructor($rootScope, Modals, mixpanelService, MemberService) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.mixpanelService = mixpanelService;
    this.MemberService = MemberService;
  }

  $onInit() {}

  help(){
    this.Modals.open('achievementsInfo', null , {type: achievementEnum.MODAl.TYPE.INFO});
    this.eventMixPanelAction();
  }

  eventMixPanelAction() {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.ACHIEVEMENT_LEVELS,
      data: {
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
        ui_name: mixpanelEventsEnum.UI_NAME.ACHIEVEMENTS_HELP_BUTTON,
      }
    });
  }
}
achievementsBannerCtrl.$inject = [
  '$rootScope', 'Modals', 'mixpanelService', 'MemberService'
];
export default {
  template: template,
  controller: achievementsBannerCtrl,
  bindings: {}
};
