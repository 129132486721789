const template = require(`html-loader!./createChallenge.html`).default;
import {assignModes} from './createChallenge.data';

class createChallengeCtrl {
  /**
   * @param {Toasts} Toasts
   * */

  constructor($mdDialog, stateService) {
    "ngInject";
    this.radioData = {
      group : assignModes.owner
    };

    this.$mdDialog = $mdDialog;
    this.stateService = stateService;
    this.assignModes = assignModes;
  }

  close() {
    this.$mdDialog.hide();
  }

  create() {
    const params = {
      assignMode: this.radioData.group,
      // unassigned:this.radioData.group === "unassigned",
      cUrl: this.data.cUrl,
      dup:true
    };
    this.stateService.goToState('gs.challenges.myChallenges.editor', params, true);
    this.close();
  }
}
createChallengeCtrl.$inject = [
  '$mdDialog', 'stateService'
];
export default {
  template: template,
  controller: createChallengeCtrl,
  bindings: {
    data: '=data'
  }
};
