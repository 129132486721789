const template = require(`html-loader!./gsTableEditable.html`).default;

class gsTableEditable {
  constructor(
    $state
  ) {
    'ngInject';

    this.state = $state;
    this.currentStart = null;
  }

  $onInit() {
    this.currentStart = this.config.pagination.start;
  }

  createNew() {
    this.state.go(this.config.states.new);
  }

  loadMore() {
    this.currentStart += this.config.pagination.limit;

    this.config.loadMore.loadMoreFn({
      start: this.currentStart
    });
  }
}

gsTableEditable.$inject = [
  '$state'
];

export default {
  template: template,
  controller: gsTableEditable,
  bindings: {
    'config': '<'
  }
};
