import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./exhibitionWidget.html`).default;
import ImageModel from "../../../api/models/image/image.model";

class exhibitionWidgetPageComponent {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {RankingManager} RankingManager
   * @param {photoSwipeService} photoSwipe
   * */

  constructor(pageData,
              $window,
              $scope,
              $timeout,
              $state,
              $stateParams,
              RankingManager,
              ChallengesManager,
              photoSwipe,
              MemberService,
  ) {
    'ngInject';
    this.busy = true;

    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.RankingManager = RankingManager;
    this.ChallengesManager = ChallengesManager;
    this.photoSwipe = photoSwipe;
    this.MemberService = MemberService;
  }

  $onInit() {
    this.cId = this.$stateParams.cId;
    this.initPage();
  }

  /** @return void **/
  async initPage() {
    if(!this.cId) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return true;
    }

    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('exhibitionWidget');
    const challengeRes = await this.ChallengesManager.getData({id: this.cId});

    if(!challengeRes.success) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return true;
    }

    this.challenge = new NgChallengeModel(challengeRes.challenge);
    const requestData = {
      c_id: this.cId,
      start: 0,
      limit: 10000,
      filter: `selected_expo_2`
    };
    const res = await this.RankingManager.getTopPhoto(requestData);

    if(!res.success) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return true;
    }

    this.images = res.items.map(image => new ImageModel(image));
    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  runSlideShow() {
    this.photoSwipe.open({images:this.images, index:0, slideShow:true})
  }
}
exhibitionWidgetPageComponent.$inject = [
  'pageData',
  '$window',
  '$scope',
  '$timeout',
  '$state',
  '$stateParams',
  'RankingManager',
  'ChallengesManager',
  'photoSwipe',
  'MemberService',
];
export default {
  template: template,
  controller: exhibitionWidgetPageComponent
};
