const template = require(`html-loader!./myChallengesManageDraft.html`).default;
import badgeV2Mode from "../../../../api/enums/badgeV2.enum";
import challengeEnum from '../../../../api/enums/challenge.enum';

class myChallengesManageDraftCtrl {
  /** @param {ChallengesManager} ChallengesManager **/

  constructor(gsModals, $state, $rootScope, ChallengesManager, Modals, MemberService) {
    'ngInject';
    this.challengeEnum = challengeEnum;

    this.gsModals = gsModals;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.ChallengesManager = ChallengesManager;
    this.Modals = Modals;
    this.badgeV2Mode = badgeV2Mode;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  vote() {
    this.gsModals.open('vote', {challenge: this.challenge});
  }

  async removeReject() {
    this.challenge.reject_title = '';
    this.challenge.reject_description = '';
    const data = {
      id: this.challenge.id,
      reject_title: '',
      reject_description: ''
    };
    await this.ChallengesManager.setChallenge(data);
  }

  duplicate(){
    this.Modals.open('createChallenge', null, {cUrl: this.challenge.url});
  }
}
myChallengesManageDraftCtrl.$inject = [
  'gsModals', '$state', '$rootScope', 'ChallengesManager', 'Modals', 'MemberService'
];
export default {
  template: template,
  controller: myChallengesManageDraftCtrl,
  bindings: {
    challenge: '<'
  }
};
