import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./devBattles.component.html`).default;

class devBattlesComponent {
  mockCompletedChallenges = {
    "is_new": false,
    "completed_challenges": [
      {
        "type": "battle",
        "id": 52161,
        "prizes_worth": 3000,
        "badge_right": "Challenge",
        "image": {
          "id": "8a682779bddddfe2b6c9705fb54e9bac",
          "member_id": "712477f0aa2e0896959401d4a7f8c58d",
          "labels": [
            "Person",
            "Human",
            "Road",
            "Nature",
            "Outdoors",
            "Smoke",
            "Freeway",
            "Building",
            "Street",
            "City",
            "Urban",
            "Town",
            "Water",
            "Architecture",
            "Steamer",
            "Pier",
            "Waterfront",
            "Port",
            "Dock",
            "Overpass"
          ],
          "title": "",
          "member": {
            "id": "712477f0aa2e0896959401d4a7f8c58d",
            "name": "Tom Bourdon",
            "user_name": "tombourdon"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 4,
        "players": 22232,
        "close_time": 1752990400,
        "tags": [
          "Exhibition",
          "Comm"
        ],
        "badge": "exhibition",
        "start_time": 1651134004,
        "entries": 88955,
        "image_id": "8a682779bddddfe2b6c9705fb54e9bac",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "Exhibition",
        "welcome_message": "<p><b>Show your perfect photos in Minneapolis, United States!</b></p><p><b><br></b></p><p>This challenge is your ticket to the <b><a href=\"https://www.praxisphotocenter.org/\" target=\"_blank\" rel=\"noopener noreferrer\">Praxis Gallery</a></b>, one of the most well known art forums in the US. The gallery is dedicated to photography and supports the development of new and emerging artists.</p><p>All you have to do is submit <b>“Your Perfect Shot”</b>, and you too can be part of this amazing opportunity. Don’t forget to complete your application to receive your very own FREE GS bundle!</p><p><br></p><p><b>Exhibition Dates:</b> 12-14 August, 2022</p><p><br></p><p><b>How many winners will be selected?</b></p><p>- 40 prints and more than 1,000 digital photos will be exhibited.</p><p><br></p><p>* All-Stars & Guru’s Picks applicants will have a guaranteed spot</p><p>* Winners are guaranteed to be printed</p><p><br></p><p><b>All </b>applicants will receive a FREE GuruShots Bundle!</p><p><br></p><p>Cover image by <a href=\"https://gurushots.com/fabecollage/photos\" target=\"_blank\" rel=\"noopener noreferrer\">Fabe Collage</a></p>",
        "media": [],
        "title": "Your Perfect Shot",
        "boost_enable": true,
        "member_id": "712477f0aa2e0896959401d4a7f8c58d",
        "apply": {
          "title": "USA, here we come!",
          "text": "<p>This is your chance to become a staple of photography and achieve the American dream.</p> Finish your application now and get your ticket to the Praxis Gallery, Minneapolis, United States and also get a FREE GS bundle.",
          "package_id": 605,
          "small_image": {
            "id": "a850bf1667aee2afc055c23311fc9650",
            "member_id": "eec411c6ab7e36c00bd78109714c7447",
            "labels": [
              "Person",
              "Human",
              "Clothing",
              "Apparel",
              "Face",
              "Text",
              "Coat",
              "People",
              "Overcoat",
              "Female"
            ],
            "title": "",
            "member": {
              "id": "eec411c6ab7e36c00bd78109714c7447",
              "name": "administration",
              "user_name": "administration"
            }
          },
          "big_image": {
            "id": "3f20fe10af281f801920a387216633b2",
            "member_id": "eec411c6ab7e36c00bd78109714c7447",
            "labels": [
              "Clothing",
              "Apparel",
              "Person",
              "Human",
              "Poster",
              "Advertisement",
              "Flyer",
              "Brochure",
              "Paper",
              "Text",
              "Jacket",
              "Coat",
              "Word",
              "Magazine",
              "Sleeve",
              "People"
            ],
            "title": "",
            "member": {
              "id": "eec411c6ab7e36c00bd78109714c7447",
              "name": "administration",
              "user_name": "administration"
            }
          }
        },
        "url": "your-perfect-shot3",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 50,
          "level_2": 250,
          "level_3": 900,
          "level_4": 1900,
          "level_5": 4900
        },
        "guru_info": {
          "id": "712477f0aa2e0896959401d4a7f8c58d",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/712477f0aa2e0896959401d4a7f8c58d/3_b02dfc10be03464e586a778a471e7f8c.jpg",
          "first_name": "Tom",
          "last_name": "Bourdon",
          "user_name": "tombourdon",
          "name": "Tom Bourdon",
          "about": "Tom is a multi award-winning documentary travel photographer specialising in recording cultural festivals & celebrations across the globe. He has won the Wanderlust/Nikon Travel Photo of the Year Competition, the PDN World in Focus competition, selected as the Editor choice by the Travel Photographers Society and has been a finalist in the Travel Photographer of the Year competition 5 times.<br><br>\n\nTom's work has appeared in numerous international magazines, newspapers, guide books and publications such as the National Geographic Traveller, The Sunday Times, The BBC, The Independent, Wanderlust, Hello magazine, P&O, Insight Guides and Microsoft to name a few.<br><br>\n\nTom relishes taking his camera to some of the world's craziest events which can be seen on his website where you can also find out about his authentic off the beaten track photo tours.",
          "website": "http://tombourdon.co.uk/",
          "facebook": "https://www.facebook.com/TomBourdonPhotographer",
          "twitter": "https://twitter.com/tombourdon/",
          "instagram": "https://www.instagram.com/tom.bourdon/",
          "youtube": "",
          "member_status": 9,
          "member_status_name": "GURU PRO",
          "member_group_id": 2000,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 89268251,
        "time_left": {
          "seconds": 0,
          "minutes": 0,
          "hours": 0,
          "days": 0
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 1604,
              "rank": 4881,
              "level": 3,
              "level_name": "PREMIER",
              "level_rank": 286,
              "next": 296,
              "percent": 84.465508162191,
              "benchmark": [
                {
                  "benchmark": "1",
                  "score": "7642"
                },
                {
                  "benchmark": "10",
                  "score": "7264"
                },
                {
                  "benchmark": "100",
                  "score": "6731"
                }
              ],
              "exposure": 0,
              "points": 100,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "8415e1873f4569aa57e5305ffbd256c9",
                "votes": 458,
                "rank": 1722,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "b13510a7375a26d59c2b6b466bd154d4",
                "votes": 449,
                "rank": 1731,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495286,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "95f42daf0aca600010c14495b0fc6846",
                "votes": 394,
                "rank": 1786,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332819161,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "8ebe6c41d39c0cf46491116b9149c0aa",
                "votes": 303,
                "rank": 1877,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332866288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 20598,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": [
              {
                "id": "a13d957623c02986f9282759d6854010",
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "votes": 318,
                "guru_pick": false
              }
            ]
          },
          "achievements": [],
          "rewards": [],
          "points": 100,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/your-perfect-shot3/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/your-perfect-shot3/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      },
      {
        "type": "battle",
        "id": 52328,
        "prizes_worth": 160,
        "badge_right": "",
        "image": {
          "id": "4f5813fc5f570cebc36110dad8358778",
          "member_id": "1e0ca10917f3ccbf1a8845eb2dbcf44c",
          "labels": [
            "Person",
            "Human",
            "Bicycle",
            "Bike",
            "Vehicle",
            "Transportation",
            "Mountain Bike",
            "Wheel",
            "Machine",
            "Cyclist",
            "Sport",
            "Sports",
            "Watercraft",
            "Vessel"
          ],
          "title": "",
          "member": {
            "id": "1e0ca10917f3ccbf1a8845eb2dbcf44c",
            "name": "Antonio Manidi",
            "user_name": "Antonio1949"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 2,
        "players": 3042,
        "close_time": 1652528700,
        "tags": [
          "special 1 pic",
          "Comm"
        ],
        "badge": "fourphoto",
        "start_time": 1652269501,
        "entries": 6111,
        "image_id": "4f5813fc5f570cebc36110dad8358778",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "",
        "welcome_message": "<p>Are you ready to show the GuruShots community what you’ve got? </p><p><br></p><p>This challenge is designed specifically for fresh GuruShots members, so oil up the creative machine and share your best shots!  </p><p><br></p><p>Pull out your favorite pieces of work and compete head to head, against users in this \"Experts and Below\" challenge!</p><p><br></p><p>Let’s see those photo skills! </p>",
        "media": [],
        "title": "Experts and Below",
        "boost_enable": true,
        "member_id": "1e0ca10917f3ccbf1a8845eb2dbcf44c",
        "apply": {
          "title": "",
          "text": "",
          "package_id": 0
        },
        "url": "experts-and-below1",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 5,
          "level_2": 15,
          "level_3": 45,
          "level_4": 135,
          "level_5": 300
        },
        "guru_info": {
          "id": "1e0ca10917f3ccbf1a8845eb2dbcf44c",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/1e0ca10917f3ccbf1a8845eb2dbcf44c/3_0fbbacfa8cc41fd4aeef9ba4ed1cd5ad.jpg",
          "first_name": "Antonio",
          "last_name": "Manidi",
          "user_name": "Antonio1949",
          "name": "Antonio Manidi",
          "about": "",
          "website": "",
          "facebook": "",
          "twitter": "",
          "instagram": "",
          "youtube": "",
          "member_status": 10,
          "member_status_name": "GURU I",
          "member_group_id": 1003,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 1126127,
        "time_left": {
          "seconds": 0,
          "minutes": 0,
          "hours": 0,
          "days": 0
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 110,
              "rank": 759,
              "level": 3,
              "level_name": "PREMIER",
              "level_rank": 25,
              "next": 25,
              "percent": 82.089552238806,
              "exposure": 0,
              "points": 100,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "95f42daf0aca600010c14495b0fc6846",
                "votes": 65,
                "rank": 566,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332819161,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "a13d957623c02986f9282759d6854010",
                "votes": 45,
                "rank": 586,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495289,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 2784,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": [
              {
                "id": "8ebe6c41d39c0cf46491116b9149c0aa",
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "votes": 26,
                "guru_pick": false
              }
            ]
          },
          "achievements": [],
          "rewards": [
            {
              "type": "PHOTO_SWAP",
              "value": 1,
              "title": "Swap"
            }
          ],
          "points": 100,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/experts-and-below1/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/experts-and-below1/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      },
      {
        "type": "speed",
        "id": 52348,
        "prizes_worth": 100,
        "badge_right": "",
        "image": {
          "id": "f35c6cb3bb359c57b29d0a90fe7eebcd",
          "member_id": "eba904c9e0181fd7ca69396c2432679a",
          "labels": [
            "Water",
            "Dome",
            "Building",
            "Architecture",
            "Outdoors",
            "Watercraft",
            "Vessel",
            "Vehicle",
            "Transportation",
            "Metropolis",
            "City",
            "Urban",
            "Town",
            "Waterfront",
            "Pier",
            "Dock",
            "Port",
            "Spire",
            "Tower",
            "Steeple",
            "Nature",
            "Canal",
            "Marina",
            "Boat"
          ],
          "title": "The lock down effect",
          "member": {
            "id": "eba904c9e0181fd7ca69396c2432679a",
            "name": "Marco Relaxed Vampire",
            "user_name": "megacips"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 1,
        "players": 2714,
        "close_time": 1652448000,
        "tags": [
          "onboarding",
          "No comm"
        ],
        "badge": "",
        "start_time": 1652357400,
        "entries": 2775,
        "image_id": "f35c6cb3bb359c57b29d0a90fe7eebcd",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "",
        "welcome_message": "<p>Do you think you have a postcard worthy photo in your portfolio? This is the challenge for you!</p><p><span xss=removed>I want to see the greatest, the best, your one and only photo. The one that is so good, it might as well be a postcard.</span><br></p><p>This challenge is only for Experts or below.</p><p><br></p><p>Good luck and have fun! </p>",
        "media": [],
        "title": "Postcard Worthy",
        "boost_enable": false,
        "member_id": "eba904c9e0181fd7ca69396c2432679a",
        "apply": {
          "title": "",
          "text": "",
          "package_id": 0
        },
        "url": "postcard-worthy28",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 4,
          "level_2": 10,
          "level_3": 35,
          "level_4": 100,
          "level_5": 200
        },
        "guru_info": {
          "id": "eba904c9e0181fd7ca69396c2432679a",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/eba904c9e0181fd7ca69396c2432679a/3_94d84914ba4075f38902b3200a28d611.jpg",
          "first_name": "Marco",
          "last_name": "Relaxed Vampire",
          "user_name": "megacips",
          "name": "Marco Relaxed Vampire",
          "about": "",
          "website": "",
          "facebook": "",
          "twitter": "",
          "instagram": "",
          "youtube": "",
          "member_status": 12,
          "member_status_name": "GURU III",
          "member_group_id": 1003,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 357096,
        "time_left": {
          "seconds": 0,
          "minutes": 0,
          "hours": 0,
          "days": 0
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 34,
              "rank": 350,
              "level": 2,
              "level_name": "SKILLED",
              "level_rank": 1,
              "next": 1,
              "percent": 100,
              "exposure": 0,
              "points": 60,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "8ebe6c41d39c0cf46491116b9149c0aa",
                "votes": 34,
                "rank": 350,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332866288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 2364,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": []
          },
          "achievements": [],
          "rewards": [],
          "points": 60,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/postcard-worthy28/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/postcard-worthy28/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      },
      {
        "type": "default",
        "id": 52231,
        "prizes_worth": 240,
        "badge_right": "",
        "image": {
          "id": "f0b9d69f8389309cdff42a3d86e8e875",
          "member_id": "2fb4994cae4bd350dd178ec24f33bc14",
          "labels": [
            "Person",
            "Human",
            "Camera",
            "Electronics",
            "Photography",
            "Photo",
            "Photographer",
            "People",
            "Portrait",
            "Face"
          ],
          "title": "",
          "member": {
            "id": "2fb4994cae4bd350dd178ec24f33bc14",
            "name": "GuruShots Team",
            "user_name": "gurushots.team"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 3,
        "players": 8505,
        "close_time": 1652384100,
        "tags": [
          "Quick",
          "Comm",
          "3 photos"
        ],
        "badge": "fourphoto",
        "start_time": 1652355305,
        "entries": 25615,
        "image_id": "f0b9d69f8389309cdff42a3d86e8e875",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "",
        "welcome_message": "<div class=\"\"><b>Only 8 hours to win!</b></div><p>Let’s take on the next speed challenge. There’s no time to lose, push your creative limits - this is your chance to speed test new techniques and tricks you have learned. Get ready to add a WIN to your GuruShots portfolio in this 8-hour challenge!<br></p><p><br></p><div>See you in the challenge!</div>",
        "media": [],
        "title": "8 Hours",
        "boost_enable": true,
        "member_id": "2fb4994cae4bd350dd178ec24f33bc14",
        "apply": {
          "title": "",
          "text": "",
          "package_id": 0
        },
        "url": "8-hours15",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 50,
          "level_2": 250,
          "level_3": 450,
          "level_4": 900,
          "level_5": 1500
        },
        "guru_info": {
          "id": "2fb4994cae4bd350dd178ec24f33bc14",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/2fb4994cae4bd350dd178ec24f33bc14/3_21146a80f540340317db73dc567821d0.jpg",
          "first_name": "GuruShots",
          "last_name": "Team",
          "user_name": "gurushots.team",
          "name": "GuruShots Team",
          "about": "",
          "website": "https://www.highme-photos.de/",
          "facebook": "",
          "twitter": "",
          "instagram": "https://www.instagram.com/stepan_a_ermakov/",
          "youtube": "",
          "member_status": 9,
          "member_status_name": "GURU PRO",
          "member_group_id": 2000,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 13513946,
        "time_left": {
          "seconds": 0,
          "minutes": 0,
          "hours": 0,
          "days": 0
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 418,
              "rank": 1963,
              "level": 2,
              "level_name": "SKILLED",
              "level_rank": 17,
              "next": 32,
              "percent": 93.095768374165,
              "exposure": 0,
              "points": 60,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "a13d957623c02986f9282759d6854010",
                "votes": 182,
                "rank": 1071,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495289,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "8ebe6c41d39c0cf46491116b9149c0aa",
                "votes": 134,
                "rank": 1119,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332866288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "95f42daf0aca600010c14495b0fc6846",
                "votes": 102,
                "rank": 1151,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332819161,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 8340,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": []
          },
          "achievements": [],
          "rewards": [
            {
              "type": "EXPOSURE_AUTOFILL",
              "value": 1,
              "title": "Fill"
            }
          ],
          "points": 60,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/8-hours15/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/8-hours15/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      },
      {
        "type": "default",
        "id": 52338,
        "prizes_worth": 300,
        "badge_right": "",
        "image": {
          "id": "5504774ead8653ba57e234639568249f",
          "member_id": "b93d7b241b6e709721f2d26d03b2e8d7",
          "labels": [
            "Outer Space",
            "Space",
            "Universe",
            "Astronomy",
            "Planet",
            "Helmet",
            "Clothing",
            "Apparel",
            "Globe",
            "Sphere",
            "Glass"
          ],
          "title": "",
          "member": {
            "id": "b93d7b241b6e709721f2d26d03b2e8d7",
            "name": "Edyta Roman",
            "user_name": "Edytar"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 4,
        "players": 7295,
        "close_time": 1652375400,
        "tags": [
          "MV",
          "No comm"
        ],
        "badge": "fourphoto",
        "start_time": 1652289000,
        "entries": 29188,
        "image_id": "5504774ead8653ba57e234639568249f",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "",
        "welcome_message": "<div>Share those photos that you have made specifically for \"GuruShots\".</div><p><span xss=removed>Looking forward to seeing your work.</span><br></p><p><span xss=\"removed\"><br></span></p><p><span xss=\"removed\">Good luck!</span><br></p>",
        "media": [],
        "title": "Captured for GuruShots",
        "boost_enable": true,
        "member_id": "b93d7b241b6e709721f2d26d03b2e8d7",
        "apply": {
          "title": "",
          "text": "",
          "package_id": 0
        },
        "url": "captured-for-gurushots",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 40,
          "level_2": 160,
          "level_3": 450,
          "level_4": 900,
          "level_5": 1800
        },
        "guru_info": {
          "id": "b93d7b241b6e709721f2d26d03b2e8d7",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/b93d7b241b6e709721f2d26d03b2e8d7/3_03844a429ade83b6032a2f6c4c328b7c.jpg",
          "first_name": "Edyta",
          "last_name": "Roman",
          "user_name": "Edytar",
          "name": "Edyta Roman",
          "about": "",
          "website": "",
          "facebook": "",
          "twitter": "",
          "instagram": "",
          "youtube": "",
          "member_status": 12,
          "member_status_name": "GURU III",
          "member_group_id": 1003,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 11825621,
        "time_left": {
          "seconds": 0,
          "minutes": 0,
          "hours": 0,
          "days": 0
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 428,
              "rank": 2043,
              "level": 2,
              "level_name": "SKILLED",
              "level_rank": 13,
              "next": 22,
              "percent": 95.32293986637,
              "exposure": 0,
              "points": 60,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "8415e1873f4569aa57e5305ffbd256c9",
                "votes": 147,
                "rank": 998,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "b13510a7375a26d59c2b6b466bd154d4",
                "votes": 125,
                "rank": 1020,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495286,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "8ebe6c41d39c0cf46491116b9149c0aa",
                "votes": 88,
                "rank": 1057,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332866288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "a13d957623c02986f9282759d6854010",
                "votes": 68,
                "rank": 1077,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495289,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 7178,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": []
          },
          "achievements": [],
          "rewards": [],
          "points": 60,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/captured-for-gurushots/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/captured-for-gurushots/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      },
      {
        "type": "default",
        "id": 52332,
        "prizes_worth": 240,
        "badge_right": "",
        "image": {
          "id": "023df39153aec37465fd641174b09412",
          "member_id": "379dabfb394b028cee12819fb1b667b7",
          "labels": [
            "Landscape",
            "Nature",
            "Outdoors",
            "Scenery",
            "Water",
            "Waterfront",
            "Aerial View",
            "Marina",
            "Urban",
            "City",
            "Town",
            "Building",
            "Pier",
            "Dock",
            "Port",
            "Metropolis",
            "Road",
            "Panoramic",
            "Watercraft",
            "Vessel",
            "Vehicle",
            "Transportation",
            "Condo",
            "Housing",
            "Plant",
            "Harbor",
            "Downtown",
            "Promontory"
          ],
          "title": "",
          "member": {
            "id": "379dabfb394b028cee12819fb1b667b7",
            "name": "Bruno Minkia the Vampire",
            "user_name": "bruno.davi"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 4,
        "players": 7191,
        "close_time": 1652364600,
        "tags": [
          "Strong",
          "No comm",
          "1 day"
        ],
        "badge": "fourphoto",
        "start_time": 1652278200,
        "entries": 28799,
        "image_id": "023df39153aec37465fd641174b09412",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "",
        "welcome_message": "<p>Use filters on your camera or photo editing software to create an atmospheric shot.</p><p><br></p><p>Be creative and show us your filtered view of the world!</p>",
        "media": [],
        "title": "Filtered Images",
        "boost_enable": true,
        "member_id": "379dabfb394b028cee12819fb1b667b7",
        "apply": {
          "title": "",
          "text": "",
          "package_id": 0
        },
        "url": "filtered14",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 20,
          "level_2": 100,
          "level_3": 450,
          "level_4": 950,
          "level_5": 1700
        },
        "guru_info": {
          "id": "379dabfb394b028cee12819fb1b667b7",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/379dabfb394b028cee12819fb1b667b7/3_b967b22056c1885b56e3c701eb6e4956.jpg",
          "first_name": "Bruno",
          "last_name": "Minkia the Vampire",
          "user_name": "bruno.davi",
          "name": "Bruno Minkia the Vampire",
          "about": "",
          "website": "",
          "facebook": "",
          "twitter": "",
          "instagram": "",
          "youtube": "",
          "member_status": 12,
          "member_status_name": "GURU III",
          "member_group_id": 1003,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 11781875,
        "time_left": {
          "seconds": 0,
          "minutes": 0,
          "hours": 0,
          "days": 0
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 752,
              "rank": 1778,
              "level": 3,
              "level_name": "PREMIER",
              "level_rank": 196,
              "next": 198,
              "percent": 79.241306638567,
              "exposure": 0,
              "points": 100,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "a13d957623c02986f9282759d6854010",
                "votes": 369,
                "rank": 746,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495289,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": 1652328492,
                "boosting": false,
                "boosted": true
              },
              {
                "id": "95f42daf0aca600010c14495b0fc6846",
                "votes": 145,
                "rank": 970,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332819161,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "8415e1873f4569aa57e5305ffbd256c9",
                "votes": 124,
                "rank": 991,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495288,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              },
              {
                "id": "b13510a7375a26d59c2b6b466bd154d4",
                "votes": 114,
                "rank": 1001,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495286,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 6446,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": []
          },
          "achievements": [],
          "rewards": [],
          "points": 100,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/filtered14/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/filtered14/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      },
      {
        "type": "speed",
        "id": 52297,
        "prizes_worth": 100,
        "badge_right": "",
        "image": {
          "id": "2584fd918004dcc9f4e44201e0495764",
          "member_id": "a03b58a807b47b96751ef1888c1c3fbb",
          "labels": [
            "Animal",
            "Mammal",
            "Wildlife",
            "Lion"
          ],
          "title": "Busch Gardens, Tampa, FL",
          "member": {
            "id": "a03b58a807b47b96751ef1888c1c3fbb",
            "name": "Joe Bike",
            "user_name": "joe.bike"
          }
        },
        "assets_id": 0,
        "max_photo_submits": 1,
        "players": 2842,
        "close_time": 1652275200,
        "tags": [
          "onboarding",
          "No comm"
        ],
        "badge": "",
        "start_time": 1652184601,
        "entries": 2986,
        "image_id": "2584fd918004dcc9f4e44201e0495764",
        "status": "closed",
        "permissions_group_id": 8,
        "badge_left": "",
        "welcome_message": "<p class=\"\">Give us your <b>best</b> shot!</p><p class=\"\">A lot of fellow photographers like yourself will be reading this description, and looking to win the challenge, so don't hold back from putting out your very best!</p><p class=\"\">Good Luck!</p><p class=\"\"><br></p><p>This challenge is only for Experts or below.</p>",
        "media": [],
        "title": "Show Us What You Got",
        "boost_enable": false,
        "member_id": "a03b58a807b47b96751ef1888c1c3fbb",
        "apply": {
          "title": "",
          "text": "",
          "package_id": 0
        },
        "url": "show-us-what-you-got28",
        "ranking_levels": {
          "level_0": 0,
          "level_1": 4,
          "level_2": 10,
          "level_3": 35,
          "level_4": 100,
          "level_5": 200
        },
        "guru_info": {
          "id": "a03b58a807b47b96751ef1888c1c3fbb",
          "avatar": "https://photos.gurushots.com/unsafe/500x500/a03b58a807b47b96751ef1888c1c3fbb/3_7dbdd1e25bdabef0b1613c6288c073a4.jpg",
          "first_name": "Joe",
          "last_name": "Bike",
          "user_name": "joe.bike",
          "name": "Joe Bike",
          "about": "",
          "website": "",
          "facebook": "https://www.facebook.com/app_scoped_user_id/10210139175064843/",
          "twitter": "",
          "instagram": "",
          "youtube": "",
          "member_status": 12,
          "member_status_name": "GURU III",
          "member_group_id": 1003,
          "moderator": false,
          "moderator_badge": false
        },
        "votes": 429514,
        "time_left": {
          "seconds": 10,
          "minutes": 10,
          "hours": 10,
          "days": 10
        },
        "member": {
          "ranking": {
            "total": {
              "amit": "new ranking member without cache",
              "votes": 60,
              "rank": 350,
              "level": 3,
              "level_name": "PREMIER",
              "level_rank": 40,
              "next": 40,
              "percent": 60.606060606061,
              "exposure": 0,
              "points": 100,
              "guru_picks": 0
            },
            "entries": [
              {
                "id": "b13510a7375a26d59c2b6b466bd154d4",
                "votes": 60,
                "rank": 350,
                "member_id": "7a40f6baeac79b09ca3d48b71173d9fe",
                "adult": false,
                "event_id": 4000332495286,
                "amit": "new ranking image without cache",
                "guru_pick": false,
                "boost": -1,
                "boosting": false,
                "boosted": false
              }
            ],
            "member": {
              "id": "7a40f6baeac79b09ca3d48b71173d9fe",
              "avatar": "https://photos.gurushots.com/unsafe/500x500/7a40f6baeac79b09ca3d48b71173d9fe/3_805b64d445b75977cc72535ff8862914.jpg",
              "country": "Ukraine",
              "member_status": 1,
              "member_status_name": "NEWBIE",
              "member_group_id": 1004,
              "name": "antoni.lunenko",
              "user_name": "antoni.lunenko",
              "moderator": false,
              "moderator_badge": false,
              "member_league_id": null
            },
            "index": 2171,
            "exposure": {
              "exposure_factor": 0,
              "vote_exposure_factor": 0,
              "vote_ratio": 3
            },
            "swaps": []
          },
          "achievements": [],
          "rewards": [],
          "points": 100,
          "is_new": false,
          "chest": null,
          "share": {
            "public_url": "https://webv2.gurushots.com/achievements/show-us-what-you-got28/antoni.lunenko",
            "screenshot_url": "https://gsms-render.gurushots.com/image/1200x630/https://html-svc.gurushots.com/achievements/show-us-what-you-got28/antoni.lunenko"
          },
          "exhibition": {
            "state": "APPLY_NOW"
          }
        }
      }
    ],
    "showing_last_days": 14,
    "success": true,
    "valueText": 'test test'
  };

  constructor() {
    'ngInject';
  }

  $onInit() {
    this.mockCompletedChallenges.completed_challenges = this.mockCompletedChallenges.completed_challenges.map(challenge => new NgChallengeModel(challenge));
  }
}

devBattlesComponent.$inject = [

];
export default {
  template: template,
  controller: devBattlesComponent
};
