function config($stateProvider, $urlRouterProvider) {
  'ngInject';

  // **********
  // Routes
  // **********
  $stateProvider
    .state('gs.profile', {
      url: '/:cUserName',
      params: {
        cUserName: {
          value: null,
          squash: true,
        },
      },
      views: {
        'header@gs': { component: 'gsHeader' },
        'page@gs': { component: 'profilePage' },
        'footer@gs': { component: 'gsFooter' },
      },
    })
    .state('gs.profile.photos', {
      url: '/photos/:status/:status2',
      template: '<gs-gallery type="photos"></gs-gallery>',
      params: {
        status: {
          value: null,
          squash: true,
        },
        status2: {
          value: null,
          squash: true,
        },
      },
    })
    .state('gs.profile.likes', {
      url: '/likes',
      template: '<gs-gallery type="likes"></gs-gallery>',
    })
    .state('gs.profile.achievements', {
      url: '/achievements',
      template: '<profile-achievements></profile-achievements>',
      redirectTo: 'gs.profile.achievements.achievements',
    })
    .state('gs.profile.achievements.achievements', {
      url: '/achievements/:achievementUniqueKey',
      template: '<profile-achievements-achievements class="gs-flex-column-all-space"></profile-achievements-achievements>',
      params: {
        achievementUniqueKey: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.profile.achievements.challenges', {
      url: '/challenges',
      template: '<profile-achievements-challenges class="gs-flex-column-all-space"></profile-achievements-challenges>',
    })
    .state('gs.profile.followers', {
      url: '/followers',
      template: '<profile-followers method="getFollowers"></profile-followers>',
    })
    .state('gs.profile.following', {
      url: '/following',
      template: '<profile-followers  method="getFollowing"></profile-followers>',
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
