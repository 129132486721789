import {EventEmitter, Injectable} from '@angular/core';
import {ResourceType} from "../../core/models/enums";
import {BehaviorSubject, filter, Observable, switchMap} from "rxjs";
import RequestService from "../../core/services/request/requestService";
import RequestServiceModel from "../../core/services/request/requestService.model";
import NgRequestEnum from "../../core/services/request/request.enum";
import {IGsResponse} from "../../core/models/gsResponse.types";
import {map} from "rxjs/operators";
import {SectionType} from "../store/models/enums";
import {NgModalsService} from "../angular-material/services/ng-modals.service";
import {StoreEventsService} from "../store/services/store-events.service";
import {MemberService} from "../../core/services/member.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {INgGsIconModel} from "../challenges/interfaces/challenges.interface";
import {PrizeType} from "../challenges/enums/challenges.enum";
export interface BankrollItem {
  type: ResourceType,
  title: string ,
  amount: number,
  icon: INgGsIconModel
}
export interface IUpdateBankrollItemAmountParams {
  resourceType: ResourceType;
  amountToAddOrReduce: number;
}
export interface IGetBankroll extends IGsResponse {
  bankroll:{
    challenges:BankrollItem[],
    battles:BankrollItem[],
  }
}
@Injectable({providedIn: 'root'})
export class BankrollService {
  itemClick$ = new EventEmitter<ResourceType>();
  bankrollData$ = new BehaviorSubject<Map<SectionType, Map<ResourceType, BankrollItem>>
    | undefined>(undefined);

  prizeTypeResourceTypeMap = new Map<PrizeType,ResourceType>(
    [
      [PrizeType.COINS,ResourceType.COINS],
      [PrizeType.KEY,ResourceType.KEYS],
      [PrizeType.EXPOSURE_AUTOFILL,ResourceType.FILLS],
      [PrizeType.PHOTO_SWAP,ResourceType.SWAPS],
    ]
  )

  constructor(
    private requestService: RequestService,
    private ngModalsService: NgModalsService,
    private storeEventsService: StoreEventsService,
    private memberService: MemberService,
  ) {
    this.memberService.memberChanged$.pipe(
      filter((member) => member !== undefined),
      switchMap(() => {
        return this.getBankroll();
      }),
      takeUntilDestroyed()
    ).subscribe(() => {});
  }

  //region bankrollAPI
  getBankroll(): Observable<IGetBankroll> {
    /* Usage: over project. Bankroll API call. */
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_BANKROLL.NAME,
      // getMockData: true,
      // mockDataState: 1
    });
    return this.requestService.request<IGetBankroll>(conf).pipe(
      map((res) => {
        if (res.success) {
          let map = new Map<SectionType,Map<ResourceType, BankrollItem>>();
          if (res.bankroll.challenges) {
            map.set(SectionType.CHALLENGES, this.getParseSectionData(res.bankroll.challenges));
          }
          if (res.bankroll.battles) {
            map.set(SectionType.BATTLES, this.getParseSectionData(res.bankroll.battles));
          }
          this.bankrollData$.next(map);
        }
        return res;
      })
    );
  }

  private getParseSectionData(bankrollItems: BankrollItem[]){
    /* Usage: related to getBankroll API call. */
    let map  = new Map<ResourceType, BankrollItem>();
    for (const item of bankrollItems){
      map.set(item.type,item);
    }
    return map;
  }

  updateBankrollItemAmount(resourcesToUpdate: IUpdateBankrollItemAmountParams[]){
    const bankrollData = this.bankrollData$.value;
    const sectionData = bankrollData?.get(SectionType.CHALLENGES);
    resourcesToUpdate.forEach((resourceToUpdate)=>{
      let bankrollItem:BankrollItem | undefined = sectionData?.get(resourceToUpdate.resourceType);
      if(bankrollData && sectionData && bankrollItem){
        bankrollItem.amount = bankrollItem.amount ?
          (bankrollItem.amount + resourceToUpdate.amountToAddOrReduce) : resourceToUpdate.amountToAddOrReduce;
      }
    });
    this.bankrollData$.next(bankrollData);
  }
  //endregion

  //region Bridge to Angularjs
  openStoreByResourceType(type:ResourceType){
    this.ngModalsService.materialDialogOpenStore(type);
  }

  openStorePayment(storeItemId:number){
    this.ngModalsService.materialDialogOpenStore(undefined, storeItemId);
  }

  onBankrollItemClick(bankrollItem:BankrollItem){
    this.openStoreByResourceType(bankrollItem.type);
    this.storeEventsService.storeOpenFromBankrollEvent(bankrollItem)
  }
  //endregion

  //region getBankrollItemAmount
  getBankrollItemAmount(sectionType: SectionType, resourceType: ResourceType):number {
    /* Generic fn */
    const bankrollData = this.bankrollData$.value;
    const sectionData = bankrollData?.get(sectionType);
    const bankrollItem = sectionData?.get(resourceType);
    /* If we get a "false"/null/undefined bankrollItem?.amount, mb make sense to call this.getBankroll()? */
    return bankrollItem?.amount || 0;
  }

  isChallengeResourceEmpty(resourceType: ResourceType){
    return this.getBankrollItemAmount(SectionType.CHALLENGES, resourceType) === 0;
  }

  getBankrollItemAmountCoins(){
    return this.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.COINS);
  }
  //endregion

  getResourceTypeByPrizeType(prizeType:PrizeType){
    return this.prizeTypeResourceTypeMap.get(prizeType)!;
  }
}
