const template = require(`html-loader!./lp1.html`).default;
import {lpConsts} from '../landingPages.data';

class lp1Ctrl {
  /** @param {ModalsService} Modals */

  constructor(
    Modals,
    $timeout,
    $scope,
    $state,
    gsScrollTopService,
    tagEventService,
    MemberService
  ) {
    'ngInject';
    this.busy = true;

    this.$scope = $scope;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.$state = $state;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    const numOfPrizes = lpConsts.numOfPrizes;
    const monthlyVotes = lpConsts.monthlyVotes;

    this.scopes = {
      'gs.lp1': {
        coverUrl: '/assets/images/pages/lp1/cover.jpg',
        numOfPrizes,
        monthlyVotes
      },
      'gs.lp2': {
        coverUrl: '/assets/images/pages/lp1/cover2.jpg',
        numOfPrizes,
        monthlyVotes
      },
      'gs.lp3': {
        coverUrl: '/assets/images/pages/lp1/cover3.jpg',
        numOfPrizes,
        monthlyVotes
      },
      'gs.lp4': {
        coverUrl: '/assets/images/pages/lp1/cover4.jpg',
        video: '/assets/videos/gurushots-cover-video.mp4',
        numOfPrizes,
        monthlyVotes
      }
    };

    this.scope = _.clone(this.scopes[this.$state.current.name]);
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.tagEventService.sendPageViewEvent({title: 'Landing page - lp1'});
    this.busy = false;

    if (this.member.logged_in) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return;
    }

    this.gsScrollTopService.scrollTop();

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  login(event) {
    this.Modals.open('login', event, {signUp: true});
  }

}
lp1Ctrl.$inject = [
  'Modals',
  '$timeout',
  '$scope',
  '$state',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
];
export default {
  template: template,
  controller: lp1Ctrl
};
