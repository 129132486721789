import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";
import {SessionConfigService} from "../../../../core/services/session-config.service";

const templateStep3 = require(`html-loader!./tpl/onBoarding_step-3.html`).default;
const templateStep4 = require(`html-loader!./tpl/onBoarding_step-4.html`).default;
const templateStep5 = require(`html-loader!./tpl/onBoarding_step-5.html`).default;
const templateStep5speed = require(`html-loader!./tpl/onBoarding_step-5-speed.html`).default;
const templateStep71 = require(`html-loader!./tpl/onBoarding_step-7.1.html`).default;
const templateStep6 = require(`html-loader!./tpl/onBoarding_step-6.html`).default;
const templateStep8 = require(`html-loader!./tpl/onBoarding_step-8.html`).default;
const templateStepTeams1 = require(`html-loader!./tpl/onBoarding_step-teams-1.html`).default;
const templateStep2_1 = require(`html-loader!./tpl/onBoarding_step-2.1.html`).default;
const templateStep2_2 = require(`html-loader!./tpl/onBoarding_step-2.2.html`).default;
const templateMobileStep2 = require(`html-loader!./tpl/onBoarding_mobile_step-2.html`).default;
const templateMobileStep3 = require(`html-loader!./tpl/onBoarding_mobile_step-3.html`).default;
const templateMobileStep4 = require(`html-loader!./tpl/onBoarding_mobile_step-4.html`).default;
const templateMobileStep5 = require(`html-loader!./tpl/onBoarding_mobile_step-5.html`).default;
const templateMobileStep7 = require(`html-loader!./tpl/onBoarding_mobile_step-7.html`).default;

class onBoarding {
  /**
   * @param {ModalsService} Modals
   * @param {RestManager} RestManager
   * */

  constructor(
    $cookies,
    $location,
    Modals,
    $mdDialog,
    $timeout,
    $compile,
    $state,
    $rootScope,
    gsModals,
    RestManager,
    tagEventService,
    SessionConfigService
  ) {
    'ngInject';
    this.$cookies = $cookies;
    this.$location = $location;
    this.Modals = Modals;
    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    this.$compile = $compile;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.gsModals = gsModals;
    this.RestManager = RestManager;
    this.tagEventService = tagEventService;
    this.SessionConfigService = SessionConfigService;

    this.next = this.next.bind(this);
    this.nextClick = this.nextClick.bind(this);
    this.voteClick = this.voteClick.bind(this);
    this.closeClick = this.closeClick.bind(this);
  }

  load(data = {}) {
    // set data
    this.data = data;
    // set mobile
    this.isMobile = screen.availWidth < 1100;
    // events
    $(document)
      .off('intro_next', this.next)
      .on('intro_next', this.next);
    $(document)
      .off('click', '.js-intro-next-click', this.nextClick)
      .on('click', '.js-intro-next-click', this.nextClick);
    $(document)
      .off('click', '.js-intro-vote-click', this.voteClick)
      .on('click', '.js-intro-vote-click', this.voteClick);
    $(document)
      .off('click', '.js-intro-close-click', this.closeClick)
      .on('click', '.js-intro-close-click', this.closeClick);
  }

  // Next step (Depends of device)
  next(...args) {
    if (this.ended) return;
    if (this.isMobile) {
      this.mobile(...args);
      return;
    }
    this.desktop(...args);
  }

  nextClick(event) {
    let step = $(event.target).data('step');
    if (step) {
      this.next(undefined, step);
    }
  }

  voteClick() {
    const url = $('.gs-highlight').data('url');
    const challenge = new NgChallengeModel({url});
    this.gsModals.open('vote', {
      challenge: challenge,
      callback: () => {
        this.$state.go('gs.challenges.myChallenges.joined', { force: true }, { reload: this.$state.current });
      },
    });
    if (!this.$rootScope.$$phase) {
      this.$rootScope.$digest();
    }
  }

  closeTeamIntro() {
    this.cleanStep();
  }

  closeClick() {
    this.cleanStep();
  }

  setIntroCookie(value) {
    //Update cookies
    let expires = new Date();
    expires.setTime(expires.getTime() + 60 * 60 * 24 * 7);
    this.$cookies.put('intro_step', value, { expires });
  }

  // Desktop flow
  desktop(event, step) {
    let el;
    let elChallengeDashboard = $('section.challenges.dashboard')[0];
    let elChallengeFlexWrap = $('.collection.dashboard.active > li .flex-wrap')[0];

    // clear CSS
    this.cleanStep();

    // find step
    step = step || (event && event.step) || this.$cookies.get('intro_step') || '1';

    // step lost
    if (!step) {
      console.log('onBoarding error: "Step lost!"');
      return false;
    }
    step = step.toString();

    console.log('onBoarding (step: ' + step + ')');

    //Steps flow
    switch (step) {
      // skip boarding
      case 'skip':
        $(document).trigger('skipIntro');
        this.skipIntro();
        break;
      // Uploader
      case '1':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 1a design: ' + this.data.reg_flow});

        this.Modals.open('gsUploader', event, {
          target: 'onBoarding',
          challenge: this.data.challenge,
          sign_up_source: this.$cookies.get('sign_up_source'),
          reg_flow: this.data.reg_flow
        });

        this.setIntroCookie(1);
        break;
      case '1.2':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 1a design: ' + this.data.reg_flow});

        this.Modals.open('intro', event, {
          value: 12,
          onBoarding: this.data
        });
        this.$timeout(() => {
          $('.c-top-tabs-menu__item.active').addClass('gs-highlight');
          $('md-backdrop').css('background-color', 'transparent');
        }, 250);
        this.setIntroCookie('1.2');
        break;
      case '1.3':
        //Trigger FB pixel
        this.$state.go('gs.challenges.myChallenges.joined', {force: true}, {reload: this.$state.current});
        this.setIntroCookie(2);
        break;
      case '2':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 2'});

        this.Modals.open('intro', event, { value: 2 });
        this.$timeout(function() {
          $('.c-top-tabs-menu__item.active').addClass('gs-highlight');
          $('md-backdrop').css('background-color', 'transparent');
        }, 250);
        this.setIntroCookie(2);
        break;
      //Highlight ACHIEVEMENT
      case '3':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 3'});

        el = $('.c-challenges-item__achievement')[0];
        $(el).addClass('gs-highlight');
        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        $('#page > .challenges').css('overflow', 'visible');
        //Load block
        $(el).append(this.$compile(templateStep3)(this));
        this.setIntroCookie(3);
        break;
      //Highlight VOTES
      case '4':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 4'});

        el = $('.c-challenges-item__votes')[0];

        if(!el) {
          this.desktop(null, '5');
          return;
        }

        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        $($('.collection.dashboard.active > li')[0]).css('overflow', 'visible');
        $('#page > .challenges').css('overflow', 'visible');
        $(el).addClass('gs-highlight');
        //Load block
        $(el).append(this.$compile(templateStep4)(this));
        this.setIntroCookie(4);
        break;
      //Highlight Photos
      case '5':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 5'});

        el = $('.c-challenges-item__photos')[0];

        // speed challenge
        let template;
        if(!el) {
          el = $('.c-challenges-speed-item__photo');
          template = templateStep5speed;
        } else {
          template = templateStep5;
        }

        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        $('#page > .challenges').css('overflow', 'visible');
        $(el).addClass('gs-highlight');
        // load block
        $(el).append(this.$compile(template)(this));
        this.setIntroCookie(5);
        break;
      //Highlight EXPOSURE
      case '6':
        this.setIntroCookie(7);
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 6'});

        el = $('.c-challenges-item__exposure')[0];
        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        $('.c-challenges-item__exposure__auto-fill').css('display', 'none');
        $('.c-challenges-item__exposure__auto-fill__btn').css('display', 'none');
        $(el).addClass('gs-highlight');
        $('#page > .challenges').css('overflow', 'visible');
        //Load block
        let newScope = this.$rootScope.$new(true);
        newScope.data = this.data;
        $(el).append(this.$compile(templateStep6)(newScope));

        newScope.$digest();

        if (this.data.challenge.time_to_vote > 0) {
          this.$timeout(() => {
            $('.gs-intro-step-6__timeout .flip-clock-wrapper').FlipClock(this.data.challenge.time_to_vote, {
              clockFace: 'DailyCounter',
              countdown: true,
            });
          });
        }
        break;
      case '7':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 7'});

        this.Modals.open('intro', event, { value: 7 });
        this.setIntroCookie(7);
        break;
      case '7.1':
        this.setIntroCookie('7.1');

        el = $('.member-path__item__lock')[0];
        $(el).addClass('active').addClass('gs-highlight');
        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        // load block
        $(el).append(this.$compile(templateStep71)(this));
        break;
      case '8':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 8'});

        el = $('.c-top-tabs-menu__item')[1];
        $(el).addClass('gs-highlight');
        //Load block
        $(el).append(this.$compile(templateStep8)(this));
        this.setIntroCookie('9');
        break;
      case '8.1':
        this.setIntroCookie('9');
        break;
      case '9':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 9'});
        this.skipIntro();
        break;
      //If "Finish later" new flow
      case '2.1':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 2.1'});

        el = $('.c-top-tabs-menu__item')[1];
        $(el).addClass('gs-highlight');
        //Load block
        $(el).append(this.$compile(templateStep2_1)(this));
        break;
      case '2.2':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step 2.2'});

        el = $('.challenge-block')[0];
        $(el).addClass('gs-highlight');
        //Load block
        $(el).append(this.$compile(templateStep2_2)(this));

        this.setIntroCookie(9);
        break;
      case 'team_1':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'Intro Step team_1'});

        el = $('.gs-header__main-menu__teams')[0];
        $(el).addClass('gs-highlight');
        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        $('#page > .challenges').css('overflow', 'visible');
        let ns = this.$rootScope.$new(true);
        ns.close = this.closeTeamIntro;
        //Load block
        $(el).append(this.$compile(templateStepTeams1)(ns));
        break;
    }
  }









  // Mobile flow
  mobile(event, step) {
    let el;
    let elChallengeDashboard = $('section.challenges.dashboard')[0];
    let elChallengeFlexWrap = $('.collection.dashboard.active > li .flex-wrap')[0];

    //Clear CSS
    this.cleanStepMobile();

    //Find step
    step = step || (event && event.step) || this.$cookies.get('intro_step') || '1';

    //Step lost
    if (!step) {
      console.log('onBoarding mobile error: "Step lost!"');
      return false;
    }
    step = step.toString();

    console.log('onBoarding mobile (step: ' + step + ')');

    //Mobile steps flow
    switch (step) {
      //Intro (Skip)
      case 'skip':
        $(document).trigger('skipIntro');
        this.skipIntro();
        break;
      case '1':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 1'});

        this.Modals.open('introMobile', event, {
          value: 1,
          onBoarding: this.data
        });

        break;
      case '1.1':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 1.1'});

        this.Modals.open('gsUploader', event, {
          target: 'onBoarding',
          challenge: this.data.challenge,
          sign_up_source: this.$cookies.get('sign_up_source'),
          reg_flow: this.data.reg_flow
        });
        this.setIntroCookie('1.1');
        break;
      case '1.2':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 1.2'});

        this.Modals.open('intro', event, {
          value: 12,
          onBoarding: this.data
        });
        break;
      case '1.3':
        this.$state.go('gs.challenges.myChallenges.joined', {force: true}, {reload: this.$state.current});
        this.setIntroCookie('1.4');
        break;
      case '1.4':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 1.4'});

        this.Modals.open('introMobile', event, {
          value: 13,
          reg_flow: this.data.reg_flow
        });
        this.$timeout(() => {
          $('.c-top-tabs-menu__item.active')
            .addClass('gs-highlight')
            .css({
              'min-width': '110px',
              overflow: 'visible'
            });
          $('md-backdrop').css('background-color', 'transparent');
        }, 250);
        this.setIntroCookie(13);
        break;
      //Highlight Badge
      //challenges-item-mobile
      case '2':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 2'});
        //Set step
        this.setIntroCookie(2);
        //Find element
        el = $('.c-challenges-item-mobile__info-line__item')[1];
        if (!el) {
          el = $('.c-challenges-speed-item-mobile__line')[0];
          // highlight element
          $(el).addClass('gs-highlight');
        } else {
          // highlight element
          $(el)
            .addClass('gs-highlight')
            .css({ padding: '10px', width: '1.2%' });
        }
        //Scroll to element
        $('html, body').animate(
          {
            scrollTop: parseInt($(el).offset().top - 130),
          },
          300,
        );
        //Load popup
        $(el).append(this.$compile(templateMobileStep2)(this));
        break;
      case '3':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 3'});
        //Set step
        this.setIntroCookie(3);
        //Find element
        el = $('.c-challenges-item-mobile__info-line2__item')[0];
        if(!el) {
          el = $('.c-challenges-speed-item-mobile__line--2')[0];
        }
        //Scroll to element
        $('html, body').animate(
          {
            scrollTop: parseInt($(el).offset().top - 130),
          },
          300,
        );
        //Highlight element
        $(el)
          .addClass('gs-highlight')
          .css({ padding: '10px' });
        //Load popup
        $(el).append(this.$compile(templateMobileStep3)(this));
        break;
      case '4':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 4'});

        el = $('.c-challenges-item-mobile__photos')[0];
        if(!el) {
          el = $('.c-challenges-speed-item-mobile__line--2')[0];
        }
        $('html, body').animate(
          {
            scrollTop: parseInt($(el).offset().top - 130),
          },
          300,
        );
        $(el)
          .addClass('gs-highlight')
          .css({ padding: '10px' });
        $('#page > .challenges').css('overflow', 'visible');
        $('.c-actions-block--mobile').css('overflow', 'hidden');
        $('#footer').css('display', 'none');
        //Load block
        $(el).append(this.$compile(templateMobileStep4)(this));
        this.setIntroCookie(4);
        break;
      case '5':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 4'});

        el = $('.c-challenges-item-mobile__exposure')[0];
        $('html, body').animate(
          {
            scrollTop: parseInt($(el).offset().top - 450),
          },
          300,
        );
        $(elChallengeDashboard).css('z-index', 200);
        $(elChallengeFlexWrap).css('overflow', 'visible');
        $('#page > .challenges').css('overflow', 'visible');
        $($('.collection.dashboard.active > li')[0]).css('overflow', 'visible');
        $('#footer').css('display', 'none');
        $('body').css({
          'margin-top': -350,
        });
        $(el)
          .addClass('gs-highlight')
          .css({ padding: '14px 20px', margin: '0' });

        //Load block
        let newScope = this.$rootScope.$new(true);
        newScope.data = this.data;
        $(el).append(this.$compile(templateMobileStep5)(newScope));

        // countdown
        if (this.data.challenge.time_to_vote && this.data.challenge.time_to_vote > 0) {
          this.$timeout(() => {
            $('.gs-intro-step-mobile-5__timeout .flip-clock-wrapper').FlipClock(this.data.challenge.time_to_vote, {
              clockFace: 'DailyCounter',
              countdown: true,
            });
          });
        }

        this.setIntroCookie(6);
        break;
      case '6':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 6'});

        this.Modals.open('introMobile', event, { value: 6 });
        this.setIntroCookie(6);
        break;
      case '7':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 7'});

        this.$timeout(() => {
          $($('.c-top-tabs-menu__item')[1])
            .addClass('gs-highlight')
            .css('overflow', 'visible');
          $('md-backdrop').css('background-color', 'transparent');
        }, 250);

        el = $('.c-top-tabs-menu__item')[1];
        $(el).addClass('gs-highlight');

        //Load block
        $(el).append(this.$compile(templateMobileStep7)(this));

        this.setIntroCookie(8);
        break;
      case '8':
        // Google vPageView
        this.tagEventService.sendPageViewEvent({title: 'intro Mobile Step 8'});

        this.skipIntro();

        break;
    }
  }

  async skipIntro() {
    this.SessionConfigService.deleteOnBoarding();
    this.$cookies.remove('intro_step');
    await this.RestManager.setMemberSettings('show_on_boarding', 0);
    this.ended = true;
  }

  cleanStep() {
    //Fix CSS
    $('.gs-highlight').removeAttr('style');
    $('*').removeClass('gs-highlight');
    $($('.collection.dashboard.active > li .flex-wrap')[0]).css('overflow', 'hidden');
    $($('section.challenges.dashboard')[0]).css('z-index', 1);
    $('.c-challenges-item__exposure__auto-fill').css('display', 'block');
    $('.c-challenges-item__exposure__auto-fill__btn').css('display', 'block');
    $('.member-path__item__lock').removeClass('active');
    $('.gs-intro-step').remove();
    //Close modals
    this.$mdDialog.hide();
  }

  cleanStepMobile() {
    //Fix CSS
    $('.gs-highlight').removeAttr('style');
    $('*').removeClass('gs-highlight');
    $('.gs-intro-step, .gs-intro-step--top-right, .gs-intro-step--top-center, .gs-intro-step--bottom').remove();
    $($('.collection.dashboard.active > li .flex-wrap')[0]).css('overflow', 'hidden');
    $($('.collection.dashboard.active > li')[0]).css('overflow', 'visible');
    $('.c-actions-block--mobile').css('overflow', 'visible');
    $('.c-top-tabs-menu__item.active').css({
      'min-width': '0',
      overflow: 'hidden',
    });
    $($('section.challenges.dashboard')[0]).css('z-index', 1);
    $('body').css({
      position: 'relative',
      'margin-top': 0,
    });
    $('#footer').css('display', 'block');
    //Close modals
    this.$mdDialog.hide();
  }

  destroy() {
    $(document).off('intro_next', this.next);
    $(document).off('click', '.js-intro-next-click', this.nextClick);
    $(document).off('click', '.js-intro-vote-click', this.voteClick);
    $(document).off('click', '.js-intro-close-click', this.closeClick);
  }
}
onBoarding.$inject = [
  '$cookies',
  '$location',
  'Modals',
  '$mdDialog',
  '$timeout',
  '$compile',
  '$state',
  '$rootScope',
  'gsModals',
  'RestManager',
  'tagEventService',
  'SessionConfigService',
];
export default onBoarding;
