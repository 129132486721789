import MessageModel from './message.model';
import ChatMessageModel from './chatMessage.model';
import ChatMemberModel from './chatMember.model';
import ChatMediaModel from './chatMedia.model';

class ChatReplyModel {
  constructor({id, dateTime, members, message, media} = {}) {
    this.id = id;
    this.origDateTime = dateTime;
    this.dateTime = dateTime;
    this.members = members ? members.map( member => new ChatMemberModel(member)) : members;
    this.message = new MessageModel(message);
    this.media = new ChatMediaModel(media);
  }

  get member() {
    return this.members && this.members[0] ? this.members[0] : null;
  }

  get color() {
    return this.member ? ChatMessageModel.getChatMemberColor(this.member.id) : null;
  }

  isMyMessage(myMemberId){
    return this.member.id === myMemberId;
  }
}

export default ChatReplyModel;
