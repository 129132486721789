import {WinnersLayout} from "../../../../../modules/challenges/enums/challenges.enum";

const template = require(`html-loader!./winners.all.html`).default;
import ImageModel from '../../../api/models/image/image.model';
import challengeEnum from '../../../api/enums/challenge.enum';
import MemberModel from "../../../api/models/member.model";
import {NgChallengesService} from "../../../../../modules/challenges/services/ng-challenges.service";
import {AdultFilterMode} from "../../../../../shared/components/ng-adult-filter/ng-adult-filter.component";

class challengeWinnersAllCtrl {

  constructor(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $location,
    $rootScope,
    NgChallengesService,
    tagEventService,
    MemberService,
  ) {
    'ngInject';
    this.busy = true;

    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.NgChallengesService = NgChallengesService;
    this.tagEventService = tagEventService;
    this.winnersLayout = WinnersLayout;
    this.AdultFilterMode = AdultFilterMode;
    this.MemberService = MemberService;
    this.loggedinMember = MemberService.getCurrentMember();
  }

  $onInit() {
    // challenge not closed
    if (this.challenge.status !== challengeEnum.STATUSES.CLOSED.VALUE) {
      this.$state.go('gs.challenge.details');
    }
    // Google vPageView
    this.tagEventService.sendPageViewEvent({title: 'Challenge Winners All'});
    this.c_id = this.challenge.id;
    this.init();
  }

  /** @return void **/
  async init() {
    const res = await this.NgChallengesService.getWinnersAchievementsAndPrizes(this.c_id);
    this.busy = false;

    if (!res.success) {
      this.noData = true;
      return;
    }

    res.winners.forEach(winner => {
      winner.items = winner.items.map(item => {
        return {
          ...item,
          activeImageIndex: 0,
          member: new MemberModel(item.member),
          images: item.images.map(image => new ImageModel(image))
        };
      })
    });

    this.data = res.winners;

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setMainImg(item, index) {
    item.activeImageIndex = index;
  }

  isAdultFilterEnabled(image){
    return !this.loggedinMember?.adult && image.adult;
  }
}
challengeWinnersAllCtrl.$inject = [
  '$scope', '$state', '$stateParams', '$timeout', '$location', '$rootScope', 'NgChallengesService', 'tagEventService', 'MemberService'
];
export default {
  template: template,
  controller: challengeWinnersAllCtrl,
  bindings: {
    challenge: '<',
    member: '<'
  }
};
