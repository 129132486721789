import {MediatorMessageKey} from "../../../../../core/services/mediator.service";
import {Subject} from "rxjs";

const template = require(`html-loader!./rank.html`).default;

class rankCtrl {
  /** @param {rankFilters} rankFilters */

  constructor(
    $scope,
    Toasts,
    $mdToast,
    rankFilters,
    $rootScope,
    challengePageService,
    stateService,
    MediatorService,
    gsModals,
    Modals
  ) {
    'ngInject';
    this.$scope = $scope;
    this.Toasts = Toasts;
    this.$mdToast = $mdToast;
    this.rankFilters = rankFilters;
    this.$rootScope = $rootScope;
    this.challengePageService = challengePageService;
    this.stateService = stateService;
    this.rankTabs = [];
    this.subject = new Subject();
    this.mediatorService = MediatorService;
    this.gsModals = gsModals;
    this.Modals = Modals;
    this.isNewRankingEnabled = false;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onChangedRankPage = this.onChangedRankPage.bind(this);

    this.mediatorService.on(MediatorMessageKey.OPEN_VOTE_MODAL).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(()=>{
      this.gsModals.open('vote', { challenge: this.challenge });
    });

    this.mediatorService.on(MediatorMessageKey.OPEN_FILL_MODAL).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((message)=>{
      this.Modals.open('autoFill', null, message.data);
    });
  }

  $onInit() {
    this.isNewRankingEnabled = this.challenge.new_ranking_enable;
    this.rankTabs = this.challengePageService.getRankTabs();
    this.offFilterChanged = this.$rootScope.$on('onFilterChanged', this.onFilterChanged);
    this.offChangedRankPage = this.$rootScope.$on('onChangedRankPage', this.onChangedRankPage);

    this.filter = this.rankFilters.filter;
    this.filters = this.rankFilters.getList();
    this.done = true;
  }

  $onDestroy() {
    this.offFilterChanged();
    this.offChangedRankPage();
    this.$mdToast.hide();
    this.subject.next();
    this.subject.complete();
  }

  onFilterChanged(event, data) {
    this.filter = data.filter;
  }

  onChangedRankPage(event, data) {
    this.filter = this.rankFilters.filter;
    this.filters = this.rankFilters.getList();
  }
}
rankCtrl.$inject = [
  '$scope',
  'Toasts',
  '$mdToast',
  'rankFilters',
  '$rootScope',
  'challengePageService',
  'stateService',
  'MediatorService',
  'gsModals',
  'Modals'
];
export default {
  template: template,
  controller: rankCtrl,
  bindings: {
    member: '<',
    challenge: '<',
    tools: '='
  }
};
