import {ChangeDetectionStrategy, Component, signal, WritableSignal} from '@angular/core';
import {BankrollItem, BankrollService} from "../../bankroll.service";
import {SectionType} from "../../../store/models/enums";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter} from "rxjs";
import {ResourceType} from "../../../../core/models/enums";

@Component({
  selector: 'app-global-bankroll',
  templateUrl: './global-bankroll.component.html',
  styleUrl: './global-bankroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalBankrollComponent {
  items: WritableSignal<BankrollItem[]> = signal([]);
  resourceStyleMap = new Map<ResourceType,string>()
  resourceStyleMapSignal:WritableSignal<Map<ResourceType,string>> = signal(this.resourceStyleMap);


  constructor(
    private bankrollService: BankrollService,
    ) {
    this.bankrollService.bankrollData$.pipe(
      filter( data =>  data!== undefined),
      takeUntilDestroyed()
    ).subscribe((bankrollData) => {
      this.items.set(Array.from(bankrollData?.get(SectionType.CHALLENGES) as Map<ResourceType, BankrollItem>, entry => entry[1]));
      this.resourceStyleMapSignal.update( (map)=>{
        map.set(ResourceType.KEYS,'0 14px 0 6px');
        map.set(ResourceType.SWAPS,'0 14px 0 9px');
        map.set(ResourceType.FILLS,'0 14px 0 2px');
        map.set(ResourceType.COINS,'0 14px 0 9px');
        return map;
      })
    });
  }

  openStore(bankrollItem:BankrollItem) {
    this.bankrollService.onBankrollItemClick(bankrollItem!);
  }

}
