const template = require(`html-loader!./teamHome.html`).default;

import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';
import {autoUpdateTypeEnum} from "../../../services/autoUpdate.service.data";

class teamHomeCtrl {
  constructor(
    $scope,
    RestManager,
    $timeout,
    stateService,
    $stateParams,
    $location,
    Modals,
    Toasts,
    $element,
    $rootScope,
    $state,
    teamsService,
    autoUpdateService,
    // AdsService
  ) {
    'ngInject';

    this.team;
    this.refreshTime = 60000 * 10;

    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.stateService = stateService;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.Modals = Modals;
    this.Toasts = Toasts;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.teamsService = teamsService;
    this.autoUpdateService = autoUpdateService;
    // this.AdsService = AdsService;
  }

  $onInit() {
    this.teamChangedOff = this.$scope.$on('teamChanged', (evt, data)=>{
      this.getTeam(data.teamId);
    });
    this.setAutoUpdate();

    this.init();
  }

  async init(){
    await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.MY_TEAM);

    if (this.team.current_member.isAdmin()) {
      await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.TEAM_LEADER);
    }
  }

  setAutoUpdate(){
    let autoUpdateObject = {
      type : autoUpdateTypeEnum.TEAM,
      callback : this.getTeam.bind(this),
      callbackParams : {teamId: this.team.id},
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
  }

  removeAutoRefresh(){
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.TEAM);
  }

  $onDestroy(){
    this.teamChangedOff();
    this.removeAutoRefresh();
  }

  /** @return void**/
  async getTeam({teamId}){
    const res = await this.RestManager.getTeam(teamId);

    if (!res.success || !res.data || !res.data.teams || !res.data.teams[0]) {
      return;
    }

    this.team = new TeamModel(res.data.teams[0]);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
teamHomeCtrl.$inject = [
  '$scope',
  'RestManager',
  '$timeout',
  'stateService',
  '$stateParams',
  '$location',
  'Modals',
  'Toasts',
  '$element',
  '$rootScope',
  '$state',
  'teamsService',
  'autoUpdateService',
  // 'AdsService'
];
export default {
  template: template,
  controller: teamHomeCtrl,
  bindings: {
    team: '<',
    configData: '<'
  }
};
