const template = require(`html-loader!./challengeTemplates.html`).default;
import tableEnum from "../../../../../api/enums/table.enum";
import {ChallengeTemplate} from "../../../../../api/models/ChallengeTemplate";

class challengeTemplatesCtrl {

  constructor(RestManager, $scope, $state) {
    'ngInject';
    this.RestManager = RestManager;
    this.$scope = $scope;
    this.$state = $state;
    this.tableEnum = tableEnum;

    this.busy = false;
  }

  $onInit() {
    this.getChallengeTemplates();
  }

  async getChallengeTemplates() {
    this.busy = true;
    const res  = await this.RestManager.getChallengeTemplates();
    this.busy = false;
    if(!res.success){
      return;
    }
    this.templates = res.templates.map((template)=> new ChallengeTemplate(template));
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
challengeTemplatesCtrl.$inject = [
  'RestManager', '$scope', '$state'
];
export default {
  template: template,
  controller: challengeTemplatesCtrl,
  bindings: {},
};
