import { scopes } from './gsUploader.data';
const template = require(`html-loader!./gsUploader.html`).default;
import ImageModel from '../../../api/models/image/image.model';
import {MediatorMessageKey} from "../../../../../core/services/mediator.service";
import {JoinedChallengesPageService} from "../../../../../modules/challenges/services/joined-challenges-page.service";


class gsUploaderComponent {
  /**
   * @param {RestManager} RestManager
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * @param {PhotosManager} PhotosManager
   * @param {tagEventService} tagEventService
   * @param {challengeService} challengeService
   * @param {brazeService} brazeService
   * @param {stateService} stateService
   * */

  constructor($mdDialog,
              RestManager,
              ChallengesManager,
              Modals,
              $rootScope,
              PhotosManager,
              $state,
              $cookies,
              tagEventService,
              challengeService
              , brazeService,
              stateService,
              MediatorService,
              NgLocalStorageService,
              MemberService,
              JoinedChallengesPageService
  ) {
    'ngInject';
    this.busy = true;
    this.images = [];
    this.settings = {
      state: 'buttons',
      images: [],
    };
    this.scopes = scopes;

    this.$mdDialog = $mdDialog;
    this.RestManager = RestManager;
    this.ChallengesManager = ChallengesManager;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.PhotosManager = PhotosManager;
    this.$state = $state;
    this.$cookies = $cookies;
    this.tagEventService = tagEventService;
    this.challengeService = challengeService;
    this.brazeService = brazeService;
    this.stateService = stateService;
    this.mediatorService = MediatorService;
    this.NgLocalStorageService = NgLocalStorageService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.member = MemberService.getCurrentMember();
    this.JoinedChallengesPageService = JoinedChallengesPageService;

    // shared
    this.tools = {
      back: this.back.bind(this),
      close: this.close.bind(this),
      submit: this.submit.bind(this),
    };
  }

  $onInit() {
    // If no scope settings
    if (!this.scopes[this.data.target]) {
      window.console.log('%c Uploader scope%c not exist!', 'color:tomato', 'color:#ccc');
      this.$mdDialog.hide();
      return;
    }

    // merge settings
    this.settings = angular.extend({}, this.scopes[this.data.target], this.settings, this.data);

    // Swap
    if (this.settings.target === 'swap' && !this.settings.currentImage) {
      this.settings.state = 'swap';
    }

    this.init();
  }

  // Init uploader
  init() {
    // Get restrictions
    this.RestManager.getUploadRestrictions(this.settings.scope, this.settings.getScopeId()).then(res => {
      res.items_limit =
        this.settings.items_limit && res.items_limit >= this.settings.items_limit
          ? this.settings.items_limit
          : res.items_limit;
      // extend settings
      this.settings = angular.extend({}, this.settings, res);
      this.busy = false;
    });
  }

  // Submit manager
  submit(images) {
    this.busy = true;
    // Save for swap
    this.images = images;
    // Prepare data and preSubmit actions
    switch (this.settings.source) {
      case 'device':
      case 'profile':
        let imageIds = [];
        for (const image of images) {
          imageIds.push(image.id);
          this.settings.images.push(image);
        }
        this.submitActions(imageIds);
        break;
      case 'google':
        let socialImages = [];
        for (const image of images) {
          socialImages.push({
            id: image.id,
            url: image.url,
            source: this.settings.source,
          });
          this.settings.images.push(image);
        }
        this.remoteUpload(socialImages);
        break;
      default:
        this.$mdDialog.hide();
    }
  }

  // remote upload (images with url)
  async remoteUpload(images) {
    const res = await this.PhotosManager.remoteUpload({
      upload_domain: this.settings.upload_domain,
      token: this.settings.token,
      images: images,
    });
    if (!res.success) {
      this.$mdDialog.hide();
      if (!this.$rootScope.$$phase) this.$rootScope.$digest();
      return true;
    }
    // set images
    this.settings.images = res.items;
    // prepare image ids
    let imageIds = [];
    for (const image of res.items) {
      imageIds.push(image.id);
    }
    // Submit actions
    this.submitActions(imageIds);

    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }



  // Actions for submit
  async submitActions(imageIds) {
    // Final action depends of target
    switch (this.settings.target) {
      case 'profile':
        // Create relation to profile
        await this.addProfileImage(imageIds);
        this.$mdDialog.hide();
        // reload page
        if (this.$state.current.name === 'gs.profile.photos') {
          this.$cookies.put('profile_filter', 'Newest');
          this.$state.go(this.$state.current, { filter: '' }, { reload: this.$state.current });
        }
        break;
      case 'challenge':
      case 'onBoarding':
        this.submitToChallenge(imageIds);
        // Add images to profile
        if (this.settings.source !== 'profile') {
          this.addProfileImage(imageIds);
        }
        break;
      case 'theme':
        const res = await this.joinTheme(imageIds);
        if(res.success){
          if (this.settings.source !== 'profile') {
            await this.addProfileImage(imageIds);
          }
          this.settings.callback(res);
        }
        this.Modals.close();
        break;
      case 'swap':
        // To profile
        this.addProfileImage(imageIds);
        // Challenge swap
        const resSwap = await this.challengeService.swap(
          this.settings.challenge.id,
          this.settings.currentImage.id,
          imageIds[0]
        );
        if (!resSwap.success) {
          this.$mdDialog.hide();
          return;
        }
        // Update data
        this.challengeService.updateSwapsAmount(-1);
        this.mediatorService.broadcast(MediatorMessageKey.GET_ACTIVE_CHALLENGE, {
          challengeId: this.settings.challenge.id
        });
        this.$mdDialog.hide();

        break;
      case 'avatar':
      case 'cover':
        const resPhotoRelation = await this.PhotosManager.setPhotoRelation(
          'set',
          this.settings.scope,
          this.settings.member.id,
          imageIds,
          'challenges',
          true
        );
        if (!resPhotoRelation.success) {
          this.$mdDialog.hide();
          return true;
        }
        // generate url
        const url = `${this.$rootScope.photoDomain}/unsafe/0x0/${this.settings.member.id}/3_${imageIds[0]}.jpg`;
        // trigger change cover
        switch (this.settings.target) {
          case 'avatar':
            $(document).trigger('avatarChange', url);
            break;
          case 'cover':
            $(document).trigger('coverChange', url);
            break;
        }
        // close modal
        this.$mdDialog.hide();
        break;
      case 'chat':
      case 'suggestedChallenge':
      case 'getImages':
        if (this.settings.saveToProfile) this.addProfileImage(imageIds);
        if (!this.settings.callback) {
          this.$mdDialog.hide();
          break;
        }
        this.settings.images = this.settings.images.map(image => {
          if (!image.member_id) image.member_id = this.member.id;
          image = new ImageModel(image);
          return image;
        });
        this.settings.callback(this.settings.images);
        this.$mdDialog.hide();
        break;
      default:
        this.$mdDialog.hide();
    }
  }

  async addProfileImage(imageIds){
    const res = await this.PhotosManager.addProfileImage(imageIds);
    if(res.success && imageIds && this.settings.source !== 'profile'){
      for (let imageId of imageIds) {
        this.brazeService.sendUploadsPhotoEvent(imageId, this.settings.source === 'google' ? 'google_photos' : this.settings.source);
      }
    }
    return res;
  }

  async submitToChallenge(imageIds) {
    this.busy = true;
    // Submit
    const canSubmitToChallenge = await this.challengeService.unlockChallengeBeforeJoin(
        this.settings.challenge,
        this.close.bind(this)
      );
    let res = null
    if (canSubmitToChallenge) {
      res = await this.challengeService.submitToChallenge(
        imageIds,
        this.settings.challenge.id,
        this.settings.challenge.type
      );
      if (res.challenge_id) {
        this.settings.challenge.id = res.challenge_id;
      }
      if (!res.success) {
        this.$mdDialog.hide();
        return true;
      }

      if (res.challenge_id) {
        this.settings.challenge.id = res.challenge_id
      }
    } else {
      this.$mdDialog.hide();
      return true;
    }

    // onBoarding
    if (this.settings.target === 'onBoarding') {
      this.$mdDialog.hide();
      $(document).trigger({ type: 'intro_next', step: '1.2' });
      return true;
    }

    if (this.$state.current.name === 'gs.teams.home.match') {
      this.JoinedChallengesPageService.getMyActiveChallenge(res.challenge_id);
      this.$state.reload();
      this.$mdDialog.hide();
      return true;
    }

    if (window.location.pathname === '/challenges/my-challenges/current') {
      this.mediatorService.broadcast(MediatorMessageKey.GET_ACTIVE_CHALLENGE, {
        challengeId: this.settings.challenge.id
      });
      this.$mdDialog.hide();
      return true;
    }

    // Open broadcast
    if (this.settings.challenge.isExhibition()) {
      const openExhibitionShop = await this.Modals.open('challengeIntro', null, {
        challenge: this.settings.challenge,
        closeOnApply: true
      });
      console.log(openExhibitionShop, 'openExhibitionShop');
      if (openExhibitionShop) {
        await this.challengeService.openExhibitionShop(this.settings.challenge);
      }
    }

    // redirect to my challenges
    let data = {activeChallengeIdToAdd: this.settings.challenge.id};
    if (res.join && res.show_join_message) {
      data.challenge =  this.settings.challenge;
    }
    this.stateService.goToDefaultState(data);
    this.$mdDialog.hide();
  }

  back() {
    this.settings.selectedImagesUrl = [];
    this.settings.state = 'buttons';
  }

  close() {
    this.$mdDialog.hide();

    // After close actions
    switch (this.settings.target) {
      case 'onBoarding':
        $(document).trigger({ type: 'intro_next', step: 'skip' });
        break;
    }
  }
}
gsUploaderComponent.$inject = [
  '$mdDialog', 'RestManager', 'ChallengesManager', 'Modals', '$rootScope', 'PhotosManager',
  '$state', '$cookies', 'tagEventService', 'challengeService', 'brazeService', 'stateService',
  'MediatorService','NgLocalStorageService', 'MemberService', 'JoinedChallengesPageService'
];
export default {
  template: template,
  controller: gsUploaderComponent,
  bindings: {
    data: '=',
  },
};
