export const filters = [
  {
    name: 'Newest',
    value: '-start_time',
  },
  {
    name: 'Oldest',
    value: 'start_time',
  },
  {
    name: 'Ending soon',
    value: 'close_time',
  },
];
