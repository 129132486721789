import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UpgradeModule} from '@angular/upgrade/static';
import {HttpClientModule} from "@angular/common/http";
import {
  ModalsServiceBridgeProvider,
  rootScopeServiceProvider,
  autoUpdateProvider,
  BrazeProvider,
  MixpanelProvider,
  StateParamsProvider,
  stateProvider,
  gsModalsProvider,
  voteManagerProvider, TagEventProvider, photoSwipeProvider, ModalsProvider, challengeServiceProvider
} from "./ajs-upgraded-providers";
import { AppComponent } from './app.component';
import {MaintenanceComponent} from "./components/maintenance/maintenance.component";
import {LottieModule} from "ngx-lottie";
import { AiTermsComponent } from './components/ai-terms/ai-terms.component';
import {CoreModule} from "./core/core.module";
import {ChallengesModule} from "./modules/challenges/challenges.module";
import {SocketIoModule} from 'ngx-socket-io';
import {StoreModule} from "./modules/store/store.module";

export function playerFactory(): any {
  // Note we need a separate function as it's required
  // by the AOT compiler.
  //
  // return player;

  // The lottie-web library can be loaded on demand using dynamic import.
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    declarations: [
      AppComponent,
      MaintenanceComponent,
      AiTermsComponent
    ],
    imports: [
      CoreModule,
      StoreModule,
      BrowserAnimationsModule,
      BrowserModule,
      UpgradeModule,
      HttpClientModule,
      ChallengesModule,
      SocketIoModule,
      LottieModule.forRoot({ player: playerFactory })
    ],
    providers: [
      stateProvider,
      rootScopeServiceProvider,
      autoUpdateProvider,
      ModalsServiceBridgeProvider,
      BrazeProvider,
      TagEventProvider,
      MixpanelProvider,
      StateParamsProvider,
      gsModalsProvider,
      voteManagerProvider,
      photoSwipeProvider,
      ModalsProvider,
      challengeServiceProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
