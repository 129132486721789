import {
  IAchievementPrize,
  IAchievementPrizeAchievement,
  IAchievementPrizePrize,
  IAchievementPrizeRank,
} from "../interfaces/challenges.interface";
import {PrizesLayout, WinnersLayout} from "../enums/challenges.enum";
import {ImageInterface} from "../../../core/models/models";

export class NgAchievementPrize implements IAchievementPrize {
  prizes_layout?: PrizesLayout | null = null;
  winners_layout?: WinnersLayout;
  title?: string;
  more_info_url?: string | null = null;
  image?: ImageInterface | null = null;
  rank?: IAchievementPrizeRank | null = null;
  achievement?: IAchievementPrizeAchievement | null = null;
  // @ts-ignore
  prizes?:IAchievementPrizePrize[] | null = null;
  constructor(props: IAchievementPrize = {}) {
    Object.assign(this, props);
  }

  parseBeforeSave(){
    // @ts-ignore
    this.prizes = (this.prizes && this.prizes.length) ? this.prizes : null;
    this.image = (this.image && this.image.id && this.image.member_id) ? this.image : null;
    this.achievement = this.achievement || null;
    this.prizes_layout = this.prizes_layout || null;
    this.more_info_url = this.more_info_url || null;
    this.rank = (this.rank && this.rank.type && this.rank.max_rank && this.rank.min_rank) ? this.rank : null;
  }
}
