import RequestService from "../../core/services/request/requestService";
import RequestServiceModel from "../../core/services/request/requestService.model";
import NgRequestEnum from "../../core/services/request/request.enum";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {EventEmitter, Inject, Injectable} from "@angular/core";
import {ScriptService} from "../../core/services/script.service";
import {map} from "rxjs/operators";
import {IStoreItem} from "../store/services/store.service.types";
import {IGsResponse} from "../../core/models/gsResponse.types";
import {environment} from "../../../environments/environment";
import {StoreService} from "../store/services/store.service";
import {SectionType} from "../store/models/enums";
import {NgLocalStorageService} from "../../core/services/ng-local-storage.service";
import {NgLocalStorageEnum} from "../../core/models/enums";
import {BankrollService, IUpdateBankrollItemAmountParams} from "../bankroll/bankroll.service";

declare var $: any;
enum PurchaseMethod {
  CREDIT_CARD='credit_card',
  PAYPAL='paypal',
}
export enum PaymentState {
  NEW_CARD,
  SAVED_CARD,
  PAYPAL,
}

interface ICreatePaypalResponse extends IGsResponse {
  items:{
    payment_id:string,
  };
}

interface IPurchaseResponse extends IGsResponse {
  first_time:boolean;
}
export interface IGetPaymentConfig  extends IGsResponse{
  config:IPaymentConfig
}

interface IPaymentConfig {
  email: string;
  first_name: string;
  last_name: string;
  public_key: string;
  bluesnap_3ds_token: string;
  test_mode: boolean;
  payment_card:IPaymentCard
}

interface IPaymentCard {
  type: string,
  last_4: string,
  exp_month: string,
  exp_year: string,
  email: string,
  token: string,
}
interface IPaypalCreateData {
  package_id: number;
  param:string;
}
interface IPaypalExecuteData {
  payment_id: string;
  payer_id?: string | null;
  package_id: number;
}
interface IPayWithNewCardRequestData {
  first_name: string;
  last_name: string;
  email: string;
  encrypted_credit_card: string;
  encrypted_cvv: string;
  exp_month: number;
  exp_year: number;
  package_id: number;
  param: string;
  bs_3d_secure_reference_id: string;
}
interface IPayWithSavedCardRequestData {
  package_id: number;
  param: string;
  bs_3d_secure_reference_id: string;
}

export interface IBluesnapCard{
  ccNumber: number,
  cvv: number,
  expDate: string,
}

export interface IPaymentFormData{
  firstName: string,
  lastName: string,
  email: string,
  card:{
    number: number,
    expired: string,
    cvv: number,
  }
}

@Injectable({providedIn: 'root'})
export class PaymentService {
  paymentState$ = new BehaviorSubject<PaymentState | undefined>(undefined);
  loading$ = new EventEmitter<boolean>();
  error$ = new EventEmitter<string>();
  bluesnapInitialized: Boolean = false;
  bluesnap2: any;
  paymentConfig?: IGetPaymentConfig;
  // paypal:any;
  formSelectorName:string = 'payment-form'
  constructor(
    private requestService:RequestService,
    private scriptService:ScriptService,
    private storeService:StoreService,
    private bankrollService:BankrollService,
    private localStorageService:NgLocalStorageService,
    @Inject('brazeService') private brazeService: any,
    @Inject('tagEventService') private tagEventService: any,
    // @Inject('mixpanelService') private mixpanelService: any,
  ) {
    this.loadPaypal();
  }
  async loadPaypal() {
    await this.scriptService.load(
      'https://www.paypalobjects.com/api/checkout.js'
    )
  }

  async initPaypal(storeItem:IStoreItem, selector:string) {
    //@ts-ignore
    if(paypal) {
      try {
        //@ts-ignore
        paypal.Button.render(
          {
            env: environment.env === 'production' ? 'production' : 'sandbox',
            style: {
              label: 'checkout', // checkout || credit
              size: 'small', // tiny | small | medium
              shape: 'rect', // pill | rect
              color: 'gold', // gold | blue | silver
            },
            payment: (resolve:any, reject:any) => {
              // this.eventMixPanelAction({
              //   uiName: mixpanelEventsEnum.UI_NAME.PAYPAL_CHECKOUT,
              //   packageId: data.packId,
              // });
              this.loading$.emit(true);
              return this.paypalCreate({package_id: storeItem.id, param: ''})
                .then((res:ICreatePaypalResponse) => {
                  if (!res.success) {
                    reject(res.message);
                    this.error$.emit(res.message || 'Something went wrong');
                    return '';
                  }
                  resolve(res.items.payment_id);
                  return res.items.payment_id;
                });
            },
            onAuthorize: (payData:any) => {
              return this.paypalExecute({
                payment_id: payData.paymentID,
                payer_id: payData.payerID,
                package_id: storeItem.id
              }).then((res:IPurchaseResponse) => {
                if (!res.success) {
                  this.error$.emit(res.message || 'Something went wrong');
                }else{
                  this.localStorageService.saveMemberDataByKeyValue(NgLocalStorageEnum.LAST_PAYED_WITH_PAYPAL, true);
                  this.onPaymentSuccess(storeItem,this.storeService.itemSectionType!);
                  this.sendPurchaseCompleteEvent(storeItem, PurchaseMethod.PAYPAL, res.first_time);
                }
              });
            },
            onCancel: (data:any, actions:any) => {
              this.loading$.emit(false);
              console.log('onCancel', data, actions);
            },
            onError: function (err:any) {
              this.error$.emit('Something went wrong');
              console.log('onError', err);
            },
          },
          `#${selector}`
        );
      } catch (error) {
        console.error("failed to render the PayPal Buttons", error);
      }
    }
  }

  getPaymentConfig(): Promise<IGetPaymentConfig> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_PAYMENT_CONFIG.NAME,
    });
    return firstValueFrom(this.requestService.request<IGetPaymentConfig>(conf)
      .pipe(
        map((res:IGetPaymentConfig) => {
          this.paymentConfig = res;
          const isPaypalLastPayment = this.localStorageService.getMemberDataByKey(NgLocalStorageEnum.LAST_PAYED_WITH_PAYPAL);
          if(isPaypalLastPayment) {
            this.paymentState$.next(PaymentState.PAYPAL);
          } else if (this.isSavedCardAvailable()) {
            this.paymentState$.next(PaymentState.SAVED_CARD);
          } else {
            this.paymentState$.next(PaymentState.NEW_CARD);
          }
          return res;
        }
      )
    ));
  }

  paypalCreate(data:IPaypalCreateData): Promise<ICreatePaypalResponse> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.PAYPAL_CREATE.NAME,
      body:data
    });
    return firstValueFrom(this.requestService.request<ICreatePaypalResponse>(conf));
  }

  paypalExecute(data:IPaypalExecuteData): Promise<IPurchaseResponse> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.PAYPAL_EXECUTE.NAME,
      body:data
    });
    return firstValueFrom(this.requestService.request<IPurchaseResponse>(conf));
  }

  payWithNewCard(data:IPayWithNewCardRequestData): Promise<IPurchaseResponse> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.PAY_CC.NAME,
      body:data
    });
    return firstValueFrom(this.requestService.request<IPurchaseResponse>(conf));
  }

  payWithSavedCard(data:IPayWithSavedCardRequestData): Promise<IPurchaseResponse> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.PAY.NAME,
      body:data
    });
    return firstValueFrom(this.requestService.request<IPurchaseResponse>(conf));
  }

  async pay(storeItem:IStoreItem, paymentType?:PaymentState, formData?:IPaymentFormData): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let domainPre = this.paymentConfig?.config.test_mode ? 'sandbox' : 'gateway';
      console.log('bluesnap domainPre', domainPre);
      try {
        await this.scriptService.load(
          `https://${domainPre}.bluesnap.com/js/cse/v1.0.4/bluesnap.js`,
          `https://${domainPre}.bluesnap.com/web-sdk/4.3.0/bluesnap.js`,
        )
        const bluesnap3dsPromise = new Promise((resolve) => {
          try {
            // @ts-ignore
            bluesnap.threeDsPaymentsSetup(this.paymentConfig?.config.bluesnap_3ds_token,  (sdkResponse) =>{
              resolve(sdkResponse);
            });
          }catch (e) {
            reject(e);
            return
          }
        });
        //submit bluesnap data
        if(paymentType === PaymentState.NEW_CARD){
          if(!formData) {
            reject('formData is required for new card payment');
            return;
          }
          this.bluesnapSubmitNewCardData(storeItem, {
            ccNumber: formData.card.number,
            expDate: formData.card.expired,
            cvv: formData.card.cvv,
          });
        } else if(paymentType === PaymentState.SAVED_CARD){
          this.bluesnapSubmitSavedCardData(storeItem);
        }
        //wait for bluesnap response
        const sdkResponse:any = await bluesnap3dsPromise;
        console.log('on threeDsPaymentsSetup - ' + paymentType + ' - pay sdkResponse',sdkResponse);
        // on Success
        if (sdkResponse.code === '1') {
          const threeDSecure = sdkResponse.threeDSecure;
          let requestData:IPayWithNewCardRequestData | IPayWithSavedCardRequestData;
          let serverResponse:IPurchaseResponse = { success: false, first_time: false};
          switch (paymentType) {
            case PaymentState.NEW_CARD:
              //bluesnap encrypting process on new card
              try {
                // @ts-ignore
                this.bluesnap2 = new BlueSnap(this.paymentConfig?.config.public_key, this.paymentConfig?.config.test_mode);
                this.bluesnap2.encrypt(this.formSelectorName);
              } catch (e) {
                reject(e);
                return;
              }

              let encryptedFormData = $(`#${this.formSelectorName}`)
              .serializeArray().reduce((obj:any, item:any) => {
                  obj[item.name] = item.value;
                  return obj;
                }, {});

              let expire = formData!.card.expired.split('/');

              requestData = {
                first_name: formData!.firstName,
                last_name: formData!.lastName,
                email: formData!.email,
                encrypted_credit_card: encryptedFormData.encryptedCreditCard,
                encrypted_cvv: encryptedFormData.encryptedCvv,
                exp_month: parseInt(expire[0]),
                exp_year: parseInt(expire[1]),
                package_id: storeItem.id,
                param: '',
                bs_3d_secure_reference_id: threeDSecure.threeDSecureReferenceId,
              };
              serverResponse =  await this.payWithNewCard(requestData)
              break;
            case PaymentState.SAVED_CARD:
              requestData = {
                package_id: storeItem.id,
                param: '',
                bs_3d_secure_reference_id: threeDSecure.threeDSecureReferenceId,
              };
              serverResponse =  await this.payWithSavedCard(requestData);
          }
          //server response handler
          if (!serverResponse.success) {
            reject(serverResponse.message || 'Error');
            return;
          } else {
            resolve('Success');
          }
          this.sendPurchaseCompleteEvent(storeItem, PurchaseMethod.CREDIT_CARD, serverResponse.first_time);
        } else {
          // on 3ds errors
          let message;
          if(sdkResponse.info){
            const errorsArray:string[] = sdkResponse.info.errors;
            const warningsArray:string[] = sdkResponse.info.warnings;

            if(errorsArray && errorsArray[0]){
              message = errorsArray[0];
            }else if(warningsArray && warningsArray[0]){
              message = warningsArray[0];
            }
          }
          switch(sdkResponse.code){
            case '15':{
              return;
            }
            case '14101':{
              message = 'Authentication failed, please try a different payment method';
              break;
            }
          }
          reject(message || 'Error');
        }
      } catch (e) {
        reject(e);
        return
      }

    });
  }

  bluesnapSubmitNewCardData(storeItem:IStoreItem, card:IBluesnapCard){
    const newCard = {
      ccNumber: card.ccNumber,
      cvv: card.cvv,
      expDate: card.expDate,
      amount: storeItem.cost_amount,
      currency: 'USD'
    };
    console.log("newCard", newCard);
    // @ts-ignore
    bluesnap.threeDsPaymentsSubmitData(newCard);
  }

  bluesnapSubmitSavedCardData(storeItem:IStoreItem){
    const savedCard = {
      last4Digits: this.paymentConfig?.config.payment_card.last_4,
      ccType: this.paymentConfig?.config.payment_card.type,
      amount: storeItem.cost_amount,
      currency: 'USD'
    };
    // @ts-ignore
    bluesnap.threeDsPaymentsSubmitData(savedCard);
  }

  sendPurchaseCompleteEvent(storeItem:IStoreItem, method:PurchaseMethod, firstTimePurchase:boolean){
    this.tagEventService.storeComplete({
      id : storeItem.id,
      name : storeItem.name,
      price : storeItem.cost_amount,
      category : storeItem.received[0].type
    },firstTimePurchase);
    this.brazeService.sendPurchaseEvent({
      price: storeItem.cost_amount,
      product_id: storeItem.id,
      method: method,
    });
  }

  onPaymentSuccess(item: IStoreItem, sectionType: SectionType): void {
    this.storeService.setStoreActive();
    const resourcesToUpdate:IUpdateBankrollItemAmountParams[] = item.received.map((r)=>{
      return {resourceType:r.type, amountToAddOrReduce:r.amount}
    })
    this.bankrollService.updateBankrollItemAmount(resourcesToUpdate)
    this.storeService.completePurchase(item, sectionType);
  }

  isSavedCardAvailable(){
    return this.paymentConfig?.config.payment_card;
  }
}
