const template = require(`html-loader!./adminExhibitions.html`).default;

class adminExhibitionsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Manager', route: 'gs.gsAdmin.contentManager.exhibitions.manager'},
      {name: 'Selected', route: 'gs.gsAdmin.contentManager.exhibitions.selected'},
      {name: 'Page', route: 'gs.gsAdmin.contentManager.exhibitions.page'},
      {name: 'virtual', route: 'gs.gsAdmin.contentManager.exhibitions.virtual'},
    ];
  }
}

export default {
  template: template,
  controller: adminExhibitionsCtrl,
  bindings: {}
};
