const mixpanelEventsEnum = Object.freeze({
  EVENT_NAME: {
    ACHIEVEMENT_LEVELS: 'achievement_levels',
    BATTLE_EVENTS: 'battle_events',
    INDIVIDUAL_LEAGUE: 'individual_league',
    WEB_CLIENT_EVENTS: 'web_client_events',
    COOLDOWN_PURCHASE: 'cooldown_purchase',
    VOTING: 'voting',
    STORE: 'store',
    BATTLES_FEATURE_FTUE: 'battles_feature_ftue',
    BATTLES_SCREEN: 'battle_screen',
    GET_VOTES_TOOLTIP: 'get_votes_tooltip',
    TURBO_FTUE_POPUP: 'turbo_ftue_popup',
    FREEZE_FTUE_POPUP: 'freeze_ftue_popup',
    SHIELD_FTUE_POPUP: 'shield_ftue_popup',
    JOINED_THEME: 'joined_theme',
    END_OF_BATTLE_SCREEN: 'end_of_battle_screen',
    DRAG_DROP_TOOLTIP: 'drag_drop_tooltip',
    YOUR_FIRST_SPACE_POPUP: 'your_first_space_popup',
    TOOLTIP_STATUS: 'tooltip_status',
    JOIN: 'join',
    JOIN_CHALLENGE: 'join_challenge',
    CHALLENGE_SUMMARY: 'challenge_summary',
    VOTING_1V1_SELECTED_PHOTO: 'voting_1v1_selected_photo',
    OOC_POPUP: 'ooc_popup',
    MAP: 'map',
    ALL_GALLERIES_COMPLETED: 'all_galleries_completed',
    DAILY_BONUS_SCREEN: 'daily_bonus_screen',
    VOTING_1V1: 'voting_1v1',
    TURBO_CHALLENGES: 'turbo_challenges',
    FLASH_ACTIVE: 'flash_active',
    VOTE_SESSION: 'vote_session',
    FLASH_RANK: 'flash_rank',
  },
  UI_ACTION: {
    APPEAR:'appear',
    NAVIGATION_BAR__BATTLES: 'navigation_bar__battles',
    NAVIGATION_BAR__GALLERIES: 'navigation_bar__galleries',
    NAVIGATION_BAR__Q: 'navigation_bar__q',
    PURCHASE: 'purchase',
    DISPLAY: 'display',
    AUTO_CLOSE: 'auto_close',
    CLOSE_APP: 'close_app',
    BUTTON_FILL: 'button_fill',
    BUTTON_SWAP: 'button_swap',
    BUTTON_VOTE: 'button_vote',
    CANCEL: 'cancel',
    CLICK: 'click',
    CLOSE: 'close',
    SHOWN: 'shown',
    CLICKED: 'clicked',
    CONTINUE: 'continue',
    DONE: 'done',
    DONE_VOTING: 'done_voting',
    HOVER: 'hover',
    IMPRESSION: 'impression',
    INIT: 'init',
    JOIN: 'join',
    JOIN_ANOTHER_BATTLE: 'join_another_battle',
    JOIN_CHALLENGE: 'join_challenge',
    KEEP_VOTING: 'keep_voting',
    LEAVE_BATTLE: 'leave_battle',
    PHOTO_SOURCE_DEVICE_PHOTOS: 'device_photos',
    PHOTO_SOURCE_GOOGLE_PHOTOS: 'google_photos',
    PHOTO_SOURCE_PROFILE: 'profile',
    PROCEED_TO_VOTING: 'proceed_to_voting',
    SEE_OPEN_BATTLES: 'see_open_battles',
    SWIPE_LEFT: 'swipe_left',
    SWIPE_RIGHT: 'swipe_right',
    TYPE: 'type',
    UNVOTE: 'unvote',
    VIEW_RANKING: 'view_ranking',
    VOTE_RIGHT: 'vote_right',
    VOTE_LEFT: 'vote_left',
    CHALLENGES_TAB: 'challenges_tab',
    BATTLES_TAB: 'battles_tab',
    PACKAGE_SELECT: 'package_select',
    WELCOME_BATTLE_POPUP_APPEAR: 'welcome_battles_popup_appear',
    WELCOME_BATTLE_POPUP_PRESSED: 'welcome_battles_popup_pressed',
    BATTLES_FIRST_COINS_APPEAR: 'battles_first_coins_appear',
    BATTLES_FIRST_COINS_CLOSED: 'battles_first_coins_closed',
    WAITING_ROOM_SCREEN_APPEAR: 'waiting_room_screen_appear',
    BATTLE_SCREEN_APPEAR: 'battle_screen_appear',
    BATTLE_SCREEN_LOADED: 'battle_screen_loaded',
    GET_VOTES_TOOLTIP_APPEAR: 'get_votes_tooltip_appear',
    GET_VOTES_TOOLTIP_CLOSED: 'get_votes_tooltip_closed',
    TURBO_FTUE_POPUP_APPEAR: 'turbo_ftue_popup_appear',
    TURBO_FTUE_POPUP_CLICKED: 'turbo_ftue_popup_clicked',
    TURBO_FTUE_POPUP_CLOSED: 'turbo_ftue_popup_closed',
    FREEZE_FTUE_POPUP_APPEAR: 'freeze_ftue_popup_appear',
    FREEZE_FTUE_POPUP_CLICKED: 'freeze_ftue_popup_clicked',
    FREEZE_FTUE_POPUP_CLOSED: 'freeze_ftue_popup_closed',
    SHIELD_FTUE_POPUP_APPEAR: 'shield_ftue_popup_appear',
    SHIELD_FTUE_POPUP_CLICKED: 'shield_ftue_popup_clicked',
    SHIELD_FTUE_POPUP_CLOSED: 'shield_ftue_popup_closed',
    JOINED_THEME_SUCCESS: 'joined_theme_success',
    JOINED_THEME_FAILED: 'joined_theme_failed',
    END_OF_BATTLE_SCREEN_APPEAR: 'end_of_battle_screen_appear',
    END_OF_BATTLE_SCREEN_CLOSED: 'end_of_battle__screen_closed',
    DRAG_DROP_TOOLTIP_APPEAR: 'drag_drop_tooltip_appear',
    DRAG_DROP_TOOLTIP_CLOSED: 'drag_drop_tooltip_closed',
    YOUR_FIRST_SPACE_POPUP_APPEAR: 'your_first_space_popup_appear',
    YOUR_FIRST_SPACE_POPUP_CLOSED: 'your_first_space_popup_closed',
    VIEW_ACHIEVEMENTS: 'view_achievements',
  },
  UI_CATEGORY: {
    ACTIVE_CHALLENGE: 'active_challenge',
    BANNER: 'banner',
    BUTTON: 'button',
    CHALLENGE_SUGGESTION: 'challenge_suggestion',
    CLOSED_CHALLENGES: 'closed_challenges',
    COMPLETED_CHALLENGE: 'completed_challenge',
    EVENT_WIDGET: 'event_widget',
    EXHIBITION_POP_UP: 'exhibition_pop_up',
    FILL_ALL_POPUP: 'fill_all_popup',
    JOINED_CHALLENGE_POPUP: 'joined_challenge_popup',
    JOIN_CHALLENGE_POPUP: 'join_challenge_popup',
    MISSED_BOOST: 'missed_boost',
    OPEN_CHALLENGES: 'open_challenges',
    PROFILE_PAGE_IMPRESSION: 'profile_page_impression',
    SCREEN: 'screen',
    STATUS_PROGRESSION_BAR: 'status_progression_bar',
    STORE_BUTTON: 'store_button',
    SWAP_SUGGESTION: 'swap_suggestion',
    TOP_NAV: 'top_nav',
    TOP_SECONDARY_NAV: 'top_secondary_nav',
    UPCOMING_CHALLENGES: 'upcoming_challenges',
  },
  UI_NAME: {
    CHALLENGE_SUMMARY_CLOSE:'challenge_summary_close',
    CHALLENGE_SUMMARY_SHOW: 'challenge_summary_show',
    JOIN_CONFIRMATION:'join_confirmation',
    FLASH_RANK_PRIZE:'flash_rank_prize',
    FLASH_RANK_VOTE:'flash_rank_vote',
    FLASH_RANK_FILL:'flash_rank_fill',
    MORE_OPTIONS: 'more_options',
    X_BUTTON: 'x_button',
    NAVIGATION_BAR: 'navigation_bar',
    VISITORS_BONUS_ARENA: 'visitors_bonus_arena',
    TURBO_CHALLENGE_CTA: 'turbo_challenge_cta',
    COOLDOWN: 'cooldown',
    ACHIEVEMENTS: 'achievements',
    ACHIEVEMENTS_HELP_BUTTON: 'achievements_help_button',
    ACHIEVEMENT_EXPAND_BUTTON: 'achievement_expand_button',
    ACHIEVEMENT_HELP_BUTTON: 'achievement_help_button',
    ACTIVE_BATTLE: 'active_battle',
    ACTIVITY: 'activity',
    APPLY_NOW: 'apply_now',
    APPLY_ORDER_BY: 'apply_order_by',
    ARTICLES: 'articles',
    BATTLES_THEME_SLOT: 'battles_theme_slot',
    BATTLE_COMPLETED: 'battle_completed',
    BOOST_LOCKED: 'boost_locked',
    BOOST_UNLOCKED: 'boost_unlocked',
    CHALLENGES: 'challenges',
    CHALLENGE_IMAGE: 'challenge_image',
    CHALLENGE_NAME_BUTTON: 'challenge_name_button',
    CHOOSE_PHOTO: 'choose_photo',
    CHOOSE_PHOTO_SOURCE: 'choose_photo_source',
    CLOSE: 'close',
    CLOSE_STORE: 'close_store',
    CONFIRM_PURCHASE: 'confirm_purchase',
    CONTINUE: 'continue',
    DISCOVER: 'discover',
    DONE_VOTING: 'done_voting',
    EMPTY_STATE: 'empty_state',
    EXHIBIT: 'exhibit',
    EXHIBITIONS: 'exhibitions',
    EXHIBITION_BANNER: 'exhibition_banner',
    FILL: 'fill',
    FILL_ALL: 'fill_all',
    FILL_ALL_APPROVE: 'fill_all_approve',
    FOLLOWERS: 'followers',
    FOLLOWING: 'following',
    FRIENDS: 'friends',
    GOT_IT: 'got_it',
    INFO: 'info',
    INFO_WHAT_IS_BOOST: 'info_what_is_boost',
    KEYS_STORE: 'keys_store',
    COINS_STORE: 'keys_store',
    FILLS_STORE: 'fills_store',
    SWAPS_STORE: 'swaps_store',
    LIKES: 'likes',
    MISSED_BOOST: 'missed_boost',
    MORE_BUTTON: 'more_button',
    NEW_THEME_FOR_VOTING: 'new_theme_for_voting',
    NOTIFICATIONS_BELL: 'notifications_bell',
    NO_THANKS: 'no_thanks',
    OPEN_WIDGET: 'open_widget',
    PAYMENT_FAILED: 'payment_failed',
    PAYMENT_SUCCESS: 'payment_success',
    PAYPAL_CHECKOUT: 'paypal_checkout',
    PHOTOS: 'photos',
    PROFILE_IMAGE: 'profile_image',
    PROFILE_MENU_OPEN: 'profile_menu_open',
    PROFILE_MENU_SELECT: 'profile_menu_select',
    PROGRESSION_INFO: 'progression_info',
    RANKING: 'ranking',
    FLASH_ACTIVE_RANK: 'flash_active_rank',
    VOTE_INTRO: 'vote_intro',
    VOTE_EXPOSURE: 'vote_exposure',
    VOTE_EXPOSURE_KEEP: 'vote_exposure_keep',
    VOTE_EXPOSURE_DONE: 'vote_exposure_done',
    VOTE_FULL_GREAT: 'vote_full_great',
    VOTE_FULL: 'vote_full',
    REMOVE_PHOTO: 'remove_photo',
    REPLACE_PHOTO: 'replace_photo',
    SEARCH: 'search',
    SEARCH_TYPING: 'search_typing',
    SELECT_PACKAGE: '_select_package',
    SHARE_BUTTON: 'share_button',
    SHARE_RANK: 'share_rank',
    SPOTLIGHT_BUTTON: 'spotlight_button',
    STORE_TOP_NAV: 'store_top_nav_',
    SUBMIT_CHALLENGE: 'submit_challenge',
    SUBMIT_PHOTO: 'submit_photo',
    SWAP: 'swap',
    SWAP_NOW: 'swap_now',
    SWIPE_TO: 'swipe_to_',
    TEAMS: 'teams',
    UNLOCK_BOOST: 'unlock_boost',
    UPLOAD: 'upload',
    VIEW_ACHIEVEMENTS_BUTTON: 'view_Achievements_button',
    VIEW_CHALLENGE_BUTTON: 'view_challenge_button',
    VIEW_DETAILS_BUTTON: 'view_details_button',
    VIEW_RULES: 'view_rules',
    VOTE: 'vote',
    VOTING_SCREEN: 'voting_screen',
    WAITING_ROOM: 'waiting_room',
    WAITING_ROOM_KICKOUT: 'waiting_room_kickout',
    ONE_VS_OVE_VOTING: '1v1_voting',
    VOTING_1V1_SESSION_ENDED: 'voting_1v1_session_ended',
    STORE_TABS: 'store_tabs',
    BANKROLL_TOP_NAV: 'bankroll_top_nav',
    PACKAGE: 'package',
    CONFIRM_PURCHASE_POPUP: 'confirm_purchase_popup',
    WELCOME_BATTLE_POPUP: 'welcome_battles_popup',
    BATTLES_FIRST_COINS: 'battles_first_coins',
    WAITING_ROOM_SCREEN: 'waiting_room_screen',
    BATTLES_SCREEN: 'battle_screen',
    GET_VOTES_TOOLTIP: 'get_votes_tooltip',
    TURBO_FTUE_POPUP: 'turbo_ftue_popup',
    FREEZE_FTUE_POPUP: 'freeze_ftue_popup',
    SHIELD_FTUE_POPUP: 'SHIELD_FTUE_POPUP',
    JOINED_THEME: 'joined_theme',
    END_OF_BATTLE_SCREEN: 'end_of_battle_screen',
    DRAG_DROP_TOOLTIP: 'drag_drop_tooltip',
    YOUR_FIRST_SPACE_POPUP: 'your_first_space_popup',
    MAP_GALLERY_ICON: 'map_gallery_icon',
    MAP_SCREEN: 'map_screen',
    CLOSE_MAP: 'close_map_x',
    MAP_SCREEN_ICON: 'map_screen_icon',
    GALLERY_SCREEN: 'gallery_screen',
    ALL_GALLERIES_COMPLETED_POPUP: 'all_galleries_completed_popup',
    ALL_GALLERIES_COMPLETED_ARENA: 'all_galleries_completed_arena',
    DAILY_BONUS_SCREEN: 'daily_bonus_screen',
  },
  MENU_SELECTION: {
    ACCOUNT_SETTINGS: 'account_settings',
    GURUSHOPS: 'gurushops',
    INVITE_FRIENDS: 'invite_friends',
    MY_PROFILE: 'my_profile',
    SIGN_OUT: 'sign_out',
  },
  ORDER_TYPE: {
    END_DATE: 'End Date',
    JOINED: 'Joined',
    START_DATE: 'Start Date',
    TYPE: 'Type',
    VOTES: 'Votes',
  },
  LEAGUES_STATE: {
    ACTIVE_IN_LEAGUE: 'active_in_league',
    INACTIVE_IN_LEAGUE: 'inactive_in_league',
    INACTIVE_NOT_IN_LEAGUE: 'inactive_not_in_league',
    PENDING: 'pending',
    RESULTS: 'results',
  },
  PAGE_NO_ROUTE: {
    STORE: 'store'
  }
});

export default mixpanelEventsEnum;
