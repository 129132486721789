import config from './leaderboard.config';
import leaderboardPage from './leaderboardPage/leaderboardPage';
import leagueTimer from './leagueTimer/leagueTimer';
import leaderboardHeader from './leaderboardHeader/leaderboardHeader';
import leaderboardInfo from './leaderboardInfo/leaderboardInfo';

let leaderboardPagemodule = angular
  .module('gsApp.leaderboard', [])
  .config(config)
  .component('leagueTimer', leagueTimer)
  .component('leaderboardPage', leaderboardPage)
  .component('leaderboardHeader', leaderboardHeader)
  .component('leaderboardInfo', leaderboardInfo)
;

export default leaderboardPagemodule.name;
