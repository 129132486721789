<div class="rewards gs-center-flex {{styleClass}}"
     *ngIf="prizes?.length">
  <div class="rewards_gift">
    <i class="icon-gift-2"></i>
    <div class="rewards_gift_text">1st Prize</div>
  </div>
  <div class="gs-center-flex gs-g-8" *ngFor="let prize of prizes;  last as isLast">
    <img [src]="'/assets/images/resources/' + prizesMap.get(prize.type!) + '.png'"
         alt="{{prize.type}}">
    <div class="rewards_amount gs-theme-color-gray_90">
      <span *ngIf="prize.type !== PrizeType.COINS">x</span>{{prize.amount | number}}
    </div>
    <div *ngIf="!isLast" class="divider"></div>
  </div>
</div>
