const template = require(`html-loader!./exhibitionsPage.html`).default;

class exhibitionsComponent {

  constructor(pageData,
              ExhibitionsManager,
              $scope,
              tagEventService,
              MemberService,
              NgMetaDataService,
  ) {
    'ngInject';
    this.ready = false;

    this.pageData = pageData;
    this.ExhibitionsManager = ExhibitionsManager;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.updateMetaTags({
      title:`Photography Exhibitions`,
      description:`Discover stunning photography exhibitions from around the world. Join GuruShots to have your work exhibited in prestigious galleries and museums.`,
      keywords:[ 'photography exhibitions', 'photo galleries', 'GuruShots exhibitions', 'exhibit your photos', 'photography events']
    });

    this.initPage();
    this.getExhibitions();
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('exhibitions');
    this.tagEventService.sendPageViewEvent({title: 'Exhibition page'});
    this.ready = true;
  }

  /** @return void **/
  async getExhibitions() {
    const res = await this.ExhibitionsManager.getExhibitionPageItems();
    this.exhibitions = res.items;
    let exhibitedMembers = 0;
    this.exhibitions.map(exhibition => (exhibitedMembers += parseInt(exhibition.summary.members)));
    this.exhibitedMembers = exhibitedMembers;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
exhibitionsComponent.$inject = [
  'pageData',
  'ExhibitionsManager',
  '$scope',
  'tagEventService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: exhibitionsComponent,
};
