const template = require(`html-loader!./adminDailyRewards.html`).default;

class adminDailyRewardsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Missions', route: 'gs.gsAdmin.contentManager.dailyRewards.missions'},
      {name: 'Prizes', route: 'gs.gsAdmin.contentManager.dailyRewards.prizes'},
    ];
  }
}

export default {
  template: template,
  controller: adminDailyRewardsCtrl,
};
