const template = require(`html-loader!./profilePage.html`).default;

import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';

class profilePageCtrl {
  profilePageMember;
  constructor(
    $state,
    $scope,
    pageData,
    $stateParams,
    $rootScope,
    Modals,
    $window,
    $timeout,
    $location,
    deepLinkService,
    RestManager,
    stateService,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.memberPath = null;
    this.bioFull = false;

    this.$state = $state;
    this.$scope = $scope;
    this.pageData = pageData;
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$location = $location;
    this.deepLinkService = deepLinkService;
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.teamEnum = teamEnum;

    this.isBlockUserButton = false;

    this.forceReload = this.forceReload.bind(this);
    this.onChangeCover = this.onChangeCover.bind(this);
    this.onChangeAvatar = this.onChangeAvatar.bind(this);

    this.$rootScope.$on('userBlockingSuccess', this.displayUserButton.bind(this));
    this.member = this.MemberService.getCurrentMember();
  }

  $onInit() {
    this.init();
  }

  $onDestroy() {
    $(document)
      .off('coverChange', this.onChangeCover)
      .off('avatarChange', this.onChangeAvatar);
    this.MemberService.resetMemberProfile();
  }

  /** @type void */
  async init() {
    const res = await this.MemberService.getProfileByUserName(this.$stateParams.cUserName);
    if(this.MemberService.profilePageMember) {
      this.NgMetaDataService.updateMetaTags({
        title: `${this.MemberService.profilePageMember.name}'s Photos`,
        description: `Explore the photography portfolio of ${this.MemberService.profilePageMember.name} on GuruShots. Discover their best shots and follow their journey in the photography community.`,
        keywords:[this.MemberService.profilePageMember.name, 'photography portfolio', 'GuruShots profile', 'user photos', 'photography journey']
      })
      this.counters = res.counters;
      this.profilePageMember = this.MemberService.profilePageMember;
      if(this.MemberService.isMyProfilePage){
        this.MemberService.getMemberPath().then((res)=>{
          this.member_path = res.member_path;
        });
      }

      if (this.$state.current.name === 'gs.profile') {
        const d = this.stateService.getProfileDefaultState(this.MemberService.isMyProfilePage);
        this.$state.go(d, this.$stateParams, {location: 'replace'});
      }

      $(document)
        .on('coverChange', this.onChangeCover)
        .on('avatarChange', this.onChangeAvatar);

      this.$window.scrollTo(0, 0);
      // share link
      this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/${this.member.user_name}`;
      // redirect if user deleted
      if (this.profilePageMember.isRestricted() && !this.member.is_admin) {
        window.location.href = '/error/page_404';
        return;
      }
      await this.getMemberTeamState();

      this.deepLinkService.check();
      await this.displayUserButton();

      this.done = true;

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

    } else {
      this.stateService.goToDefaultState();
    }
  }

  async displayUserButton() {
    if (this.MemberService.isMyProfilePage) {
      console.log('if');
      this.isBlockUserButton = false;
    } else {
      console.log('else');
      const isMemberBlocked =
        await this.RestManager.isMemberBlocked({member_id: this.profilePageMember.id});
      this.isBlockUserButton = !isMemberBlocked.blocked; /* true/false */
    }

    console.log('this.isBlockUserButton ', this.isBlockUserButton);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async getMemberTeamState(){
    const res = await this.RestManager.getMemberTeamState(this.profilePageMember.id);

    if(!res.success) {
      return;
    }

    this.memberTeamState = res.state;

    if(res.team) {
      this.team = new TeamModel(res.team);
    }
  }

  /** @return void **/
  async inviteMember(){
    if(!this.inviteMemberBusy){
      this.inviteMemberBusy = true;
      const res = await this.RestManager.inviteMember(this.profilePageMember.id);
      if(!res.success){
        this.inviteMemberBusy = false;
      } else {
        this.memberTeamState = teamEnum.MEMBER.STATE.INVITED;
      }
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  forceReload() {
    this.$state.go(this.$state.current, { force: true }, { reload: this.$state.current });
  }

  onChangeCover(e, url) {
    this.$timeout(() => {
      this.profilePageMember.profile_cover = url;
    });
  }

  onChangeAvatar(e, url) {
    this.$timeout(() => {
      this.profilePageMember.avatar = url;
    });
  }

  order(event) {
    this.Modals.open('shop.guruReview', event, {
      name: 'shop.guruReview',
      guruId: this.profilePageMember.id,
    });
  }

  bioMoreTrigger() {
    this.bioFull = !this.bioFull;
  }
}
profilePageCtrl.$inject = [
  '$state',
  '$scope',
  'pageData',
  '$stateParams',
  '$rootScope',
  'Modals',
  '$window',
  '$timeout',
  '$location',
  'deepLinkService',
  'RestManager',
  'stateService',
  'MemberService',
  'NgMetaDataService',
];

export default {
  template,
  controller: profilePageCtrl,
};
