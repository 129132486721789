const template = require(`html-loader!./myChallengesManageItemSuggested.html`).default;

class myChallengesManageItemSuggestedController {

  constructor(Modals, $cookies, stateService, notificationAlert, $timeout, $element, $scope, MemberService) {
    'ngInject';

    this.showText = false;
    this.textLines;

    this.Modals = Modals;
    this.$cookies = $cookies;
    this.stateService = stateService;
    this.notificationAlert = notificationAlert;
    this.$timeout = $timeout;
    this.$element = $element;
    this.$scope = $scope;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.onInit();
    // this.$timeout(()=>{
    //   let el = $(".my-challenges-manage-item-suggested__info div")[0];
    //   let divHeight = el.offsetHeight
    //   console.log(Math.ceil(divHeight / 21));
    // },500);
  }

  async onInit(){
    //show link by line number
    await this.$timeout(()=>{},100);
    let el = angular.element(this.$element[0].querySelector(".my-challenges-manage-item-suggested__info div"))[0];
    let divHeight = el.offsetHeight;
    this.textLines = Math.ceil(divHeight / 21);
    console.log(this.textLines);
    this.showText = true;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  uploadCoverPhoto(event) {
    this.Modals.open('gsUploader', event, {
      target: 'suggestedChallenge',
      items_limit: 1,
      member: {
        id: this.member.id,
      },
      callback: images => {
        if(images[0] && images[0].id){
          console.log(images[0].id);
          const params = {
            cUrl: this.challenge.url,
            imageId:images[0].id
          };
          this.stateService.goToState('gs.challenges.myChallenges.editor', params, true);
        }
      },
    });
  }

  info(){
    this.notificationAlert.open(`
    The 'Suggestion' section offers challenges for you to run.
    In order to apply and run a challenge, you must first submit your cover photo.
    Once the GS team approves the cover photo, the challenge will move to 'Upcoming' and will be ready for you to run.
    <br><br>
    More information available in our <a href="https://gurushots.freshdesk.com/a/solutions/articles/13000066853-being-a-guru" target="_blank">FAQs</a>
    `);
  }

  showDescription(){
    this.notificationAlert.open(`
    <div class="gs-notification-alert__text_desc">
    <b class="gs-challenge-title">${this.challenge.title}</b>
    ${this.challenge.welcome_message}
    </div>`, false);
    // this.Modals.open();
  }
}
myChallengesManageItemSuggestedController.$inject = [
  'Modals', '$cookies', 'stateService', 'notificationAlert', '$timeout', '$element', '$scope', 'MemberService'
];
export default {
  template: template,
  controller: myChallengesManageItemSuggestedController,
  bindings: {
    challenge: '<',
    settings: '<'
  },
};
