export const topTabMenuData = {
  TYPE: {
    CHALLENGES: {
      NAME:'CHALLENGES',
      TABS: [
        {
          TITLE: 'MY CHALLENGES',
          IMAGE: 'my-challenges.png',
          PARENT: true,
          state: 'gs.challenges.myChallenges.joined'
        },
        {
          TITLE: 'JOINED',
          state: 'gs.challenges.myChallenges.joined'
        },
        {
          TITLE: 'MANAGE',
          state: 'gs.challenges.myChallenges.manage',
          permissions: ['CREATE_CHALLENGE', 'CHALLENGE_BID']
        },
        {
          TITLE: 'COMPLETED',
          ADD_SPACER: true,
          state: 'gs.challenges.myChallenges.completed'
        },
        {
          TITLE: 'DISCOVER',
          IMAGE: 'discover.png',
          PARENT: true,
          state: 'gs.challenges.open'
        },
        {
          TITLE: 'OPEN',
          state: 'gs.challenges.open'
        },
        {
          TITLE: 'UPCOMING',
          state: 'gs.challenges.upcoming'
        },
        {
          TITLE: 'CLOSED',
          state: 'gs.challenges.closed'
        },
      ]
    },
    CHALLENGES_MOBILE_MY_CHALLENGES: {
      NAME:'CHALLENGES_MOBILE_MY_CHALLENGES',
      TABS: [
        {
          TITLE: 'JOINED',
          state: 'gs.challenges.myChallenges.joined'
        },
        {
          TITLE: 'COMPLETED',
          state: 'gs.challenges.myChallenges.completed'
        }
      ]
    },
    CHALLENGES_MOBILE_DISCOVER: {
      NAME:'CHALLENGES_MOBILE_DISCOVER',
      TABS: [
        {
          TITLE: 'OPEN',
          state: 'gs.challenges.open'
        },
        {
          TITLE: 'UPCOMING',
          state: 'gs.challenges.upcoming'
        },
      ]
    },
    INDIVIDUAL_LEADERBOARD_INFO: {
      NAME:'INDIVIDUAL_LEADERBOARD_INFO',
      TABS: [
        {
          TITLE: 'INFO',
          SELECTED: true
        },
        {
          TITLE: 'RULES',
        },
      ]
    },
    ACHIEVEMENTS: {
      NAME:'ACHIEVEMENTS',
      TABS: [
        {
          IMAGE: 'achievements.svg',
          TITLE: 'ACHIEVEMENTS',
          state: 'gs.profile.achievements.achievements'
        },
        {
          IMAGE: 'challenges.svg',
          TITLE: 'CHALLENGES',
          state: 'gs.profile.achievements.challenges'
        }
      ]
    },
  }
};
