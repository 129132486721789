import {MediatorMessageKey} from "../../../../../core/services/mediator.service";

const template = require(`html-loader!./autoFill.html`).default;
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";
import {PromiseHelper} from "../../../../../core/helpers/PromiseHelper";
import {SessionConfigService} from "../../../../../core/services/session-config.service";

class autoFill {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * @param {challengeService} challengeService
   * **/

  constructor(
    $mdDialog,
    $rootScope,
    notificationAlert,
    ChallengesManager,
    Modals,
    challengeService,
    mixpanelService,
    MediatorService,
    MemberService,
    JoinedChallengesPageService,
    brazeService,
    SessionConfigService,
  ) {
    'ngInject';
    this.$ctrl = this;
    this.allPrice = 1;
    this.fillDiscountMin = 2;
    this.data = {
      availableFills: null,
      challengeId: null,
      challengeIds: [],
      showAllOption: false,
      showSingleFill: true,
      exposure: null
    };

    this.$mdDialog = $mdDialog;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.notificationAlert = notificationAlert;
    this.ChallengesManager = ChallengesManager;
    this.challengeService = challengeService;
    this.mixpanelService = mixpanelService;
    this.mediatorService = MediatorService;
    this.SessionConfigService = SessionConfigService;
    this.joinedChallengesPageService = JoinedChallengesPageService;
    this.member = MemberService.getCurrentMember();
    this.joinedChallengesPageService = JoinedChallengesPageService;
    this.brazeService = brazeService;
  }

  $onInit() {
    this.data.showAllOption = this.data.challengeIds.length > 1
    if(this.data.showAllOption){
      this.fillDiscountMin  = this.SessionConfigService.fillDiscountMin;
      this.allPrice =
        this.isFillDiscount()
          ? this.data.challengeIds.length - 1
          : this.data.challengeIds.length;
    }
    this.exposure = this.data.exposure;
  }

  isFillDiscount(){
    return  this.data.challengeIds.length >= this.fillDiscountMin;
  }

  async fill(all = false) {
    const price = all ? this.allPrice : 1;
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.FILL_ALL_APPROVE, price);
    const c_ids = all  ? this.data.challengeIds : [this.data.challengeId];
    // open shop
    if (this.data.availableFills < price) {
      this.$mdDialog.hide();
      const fillsStoreEventParams = {outOfResourceId: 'out_fills' + Date.now()};
      this.brazeService.logCustomEvent('out_fills', fillsStoreEventParams);
      await this.brazeService.waitForBrazeMessage(this.openStoreFillsSection.bind(this), fillsStoreEventParams.outOfResourceId);
      return;
    }
    const res = await this.challengeService.exposureAutofill({
      el: 'my_challenges',
      el_id: this.member.id,
      challenge_ids: c_ids,
      numOfFills: price
    });
    if (!res.success) {
      this.close();
      return;
    } else {
      this.mediatorService.broadcast(MediatorMessageKey.FILL, {});
      this.data.exposure = 100;
      await PromiseHelper.wait(1200);
      (c_ids || []).forEach(id => {
        const challenge  = this.joinedChallengesPageService.activeChallenges.find(
          challenge => challenge.id === id
        );

        if (challenge) {
          challenge.member.ranking.total.exposure = 100;
        }
      });
    }

    this.$mdDialog.hide();
    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }

  help() {
    this.notificationAlert.open(`Fill your challenge "Exposure Bonus" meter without voting!
        <a href="https://gurushots.freshdesk.com/support/solutions/articles/13000010866-what-is-the-autofill-feature-"
        target="_blank">Read More</a>`);
  }

  close() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.CLOSE);
    this.$mdDialog.hide();
  }

  eventMixPanelAction(uiName, price=1) {
    let data = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.FILL_ALL_POPUP,
      ui_name: uiName,
      fills_amount: price
    };

    if (uiName === mixpanelEventsEnum.UI_NAME.FILL_ALL_APPROVE) {
      data.number_of_challenges_to_fill = this.data.challengeIds.length;
    }

    this.mixpanelService.track({data});
  }

  openStoreFillsSection() {
    this.challengeService.openStoreFillsSection();
  }
}
autoFill.$inject = [
  '$mdDialog',
  '$rootScope',
  'notificationAlert',
  'ChallengesManager',
  'Modals',
  'challengeService',
  'mixpanelService',
  'MediatorService',
  'MemberService',
  'JoinedChallengesPageService',
  'brazeService',
  'SessionConfigService',
];
export default {
  template: template,
  controller: autoFill,
  bindings: {
    data: '=data',
  },
};
