const template = require(`html-loader!./sponsorsPage.html`).default;

class sponsorsPageComponent {

  constructor(pageData, $window, $scope, $stateParams, $sce) {
    'ngInject';
    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$sce = $sce;
  }

  $onInit() {
    // to top
    this.$window.scrollTo(0, 0);
    // init
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.pageData.get('sponsors');

    if(!this.$stateParams.formId) {
      return;
    }

    this.url = this.$sce.trustAsResourceUrl('https://gurushots.wufoo.eu/forms/'+this.$stateParams.formId);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
sponsorsPageComponent.$inject = [
  'pageData', '$window', '$scope', '$stateParams', '$sce'
];
export default {
  template: template,
  controller: sponsorsPageComponent
};
