import {NgAchievementPrize} from "../../../../../../modules/challenges/models/ng-achievement-prize.model";
import {
  AchievementPrizeAchievementType,
  AchievementType,
  PrizesLayout, PrizeType,
  WinnersLayout
} from "../../../../../../modules/challenges/enums/challenges.enum";
import {NgAchievementPrizePrize} from "../../../../../../modules/challenges/models/ng-achievement-prize-prize.model";

const template = require(`html-loader!./myChallengesAchievementPrize.html`).default;

class myChallengesAchievementPrizeCtrl{
  challenge;
  prizesLayouts = Object.values(PrizesLayout);
  winnersLayouts = Object.values(WinnersLayout);
  rankTypes = Object.values(AchievementType);
  prizeTypes = Object.values(PrizeType);
  achievementOptions = [
    `${AchievementPrizeAchievementType.CHALLENGE_TOP_PHOTOGRAPHER},win,1`,
    `${AchievementPrizeAchievementType.CHALLENGE_TOP_PHOTO},win,2`,
    `${AchievementPrizeAchievementType.GURU_TOP_PICK},win,3`,
    `${AchievementPrizeAchievementType.TOP_FLASH_WINNER},win,1`,
    `${AchievementPrizeAchievementType.TOP_FLASH_ACHIEVEMENT},rank,3`,
    `${AchievementPrizeAchievementType.TOP_FLASH_ACHIEVEMENT},rank,10`,
    `${AchievementPrizeAchievementType.TOP_FLASH_ACHIEVEMENT},rank,50`,
    `${AchievementPrizeAchievementType.TOP_PHOTOGRAPHER_ACHIEVEMENT},rank,50`,
    `${AchievementPrizeAchievementType.TOP_PHOTO_ACHIEVEMENT},rank,50`,
  ];
  selectedAchievement = '';
  constructor() {
    'ngInject';
  }

  $onChanges() {
    if(this.challenge.achievements_and_prizes){
      this.challenge.achievements_and_prizes = this.challenge.achievements_and_prizes.map((achievementsPrize) => {
        if(achievementsPrize.achievement){
          achievementsPrize.selectedAchievement =
            `${achievementsPrize.achievement.type},${achievementsPrize.achievement.param},${achievementsPrize.achievement.value}`
        }
        return new NgAchievementPrize(achievementsPrize)
      });
    } else {
      this.challenge.achievements_and_prizes = [];
    }
  }

  addItem(){
    this.challenge.achievements_and_prizes.push(new NgAchievementPrize())
  }

  print(){
    console.log('achievements_and_prizes', this.challenge.achievements_and_prizes);
  }

  addPrize(achievementsPrize){
    if(!achievementsPrize.prizes){
      achievementsPrize.prizes = [];
    }
    achievementsPrize.prizes.push(new NgAchievementPrizePrize())
  }

  onAchievementChange(achievementsPrize){
    const arr = achievementsPrize.selectedAchievement.split(',');
    achievementsPrize.achievement = achievementsPrize.achievement || {};
    achievementsPrize.achievement.type = arr[0];
    achievementsPrize.achievement.param = arr[1];
    achievementsPrize.achievement.value = +arr[2];
  }

  save(){
    console.log('achievements_and_prizes', this.challenge.achievements_and_prizes);
  }
}
myChallengesAchievementPrizeCtrl.$inject = [];
export default {
  template: template,
  controller: myChallengesAchievementPrizeCtrl,
  bindings: {
    challenge: '<'
  }
};
