import {extractContentFromHtml} from "../../../helpers/string.helper";

const template = require(`html-loader!./article.html`).default;
import * as _ from 'lodash';
import { shareList } from '../articles.share.data';

class articleCtrl {
  /**
   * @param {ArticlesManager} ArticlesManager
   * */

  constructor(
    ArticlesManager,
    $timeout,
    $stateParams,
    $state,
    $window,
    $element,
    $scope,
    $location,
    photoSwipe,
    Modals,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.rand = 1;
    this.shareList = shareList;
    this.ready = false;

    this.ArticlesManager = ArticlesManager;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$window = $window;
    this.$element = $element;
    this.$scope = $scope;
    this.$location = $location;
    this.photoSwipe = photoSwipe;
    this.Modals = Modals;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;

    this.tools = {
      openPhoto: this.openPhoto.bind(this),
    };
  }

  $onInit() {
    this.member = this.MemberService.getCurrentMember();
    this.$window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.loggedIn = this.member.logged_in;
    this.isAdmin = !!this.member.is_admin;
    this.currentUrl = this.$location.absUrl();
    this.load();
  }

  /** @type void */
  async load() {
    if (this.ready) return;
    // get article
    const res = await this.ArticlesManager.getArticles({
      url: this.$stateParams.url,
    });
    if (!res.success) {
      this.$state.go('gs.articles', {}, { reload: true });
      return;
    }

    this.article = res.articles.splice(0, 1)[0];
    this.recommended = res.articles.splice(0, 6);
    this.ready = true;

    // prepare images for photoBox
    this.images = [];
    for (const item of this.article.items) {
      if (item.type !== 'image') continue;
      this.images.push(item.image);
    }

    let keywords = [];
    if(this.article.keywords?.length){
      keywords = this.article.keywords.split(' ');
    }
    this.NgMetaDataService.updateMetaTags({
      title: this.article.title,
      description: extractContentFromHtml(this.article.text, true),
      keywords:[ ...keywords, 'photography articles', 'GuruShots' ]
    });

    if (!this.$scope.$$phase) this.$scope.$digest();

    this.$timeout(() => {
      // facebook plugins
      if (this.article.published) FB.XFBML.parse();
    });
  }

  getIndex(image) {
    return this.images.indexOf(image);
  }

  initRand() {
    return _.random(1, 21);
  }

  join(event) {
    this.Modals.open('login', event, { signUp: true });
  }

  openPhoto(index) {
    this.photoSwipe.open({images:this.images, index});
  }
}
articleCtrl.$inject = [
  'ArticlesManager',
  '$timeout',
  '$stateParams',
  '$state',
  '$window',
  '$element',
  '$scope',
  '$location',
  'photoSwipe',
  'Modals',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: articleCtrl,
};
