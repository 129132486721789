const template = require(`html-loader!./page404.html`).default;

class page404Ctrl {

  constructor(
    $scope
  ) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.initPage
    ();
  }
}

page404Ctrl.$inject = [
  '$scope'
];

export default {
  template: template,
  controller: page404Ctrl
};
