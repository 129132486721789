import { Pipe, PipeTransform } from '@angular/core';
import {NgDateHelper} from "../../../core/helpers/ngDateHelper";
import {GsTimerEnum} from "./gs-timer.enum";

enum TimeType {
  DAY = 'Day',
  HOUR = 'Hour',
  MINUTE = 'Minute',
  SECOND = 'Second'
}
@Pipe({name: 'gsTimer'})
export class GsTimerPipe implements PipeTransform {
  millisecondsInMinute = NgDateHelper.millisecondsInSecond *
    NgDateHelper.secondsInMinute;
  millisecondsInHour = NgDateHelper.millisecondsInSecond *
    NgDateHelper.secondsInMinute *
    NgDateHelper.minutesInHour;
  millisecondsInDay = NgDateHelper.millisecondsInSecond *
    NgDateHelper.secondsInMinute *
    NgDateHelper.minutesInHour * NgDateHelper.hoursInDay;

  transform(deltaTimestamp: number | null | undefined, type: string): string {
    let result;

    if(!deltaTimestamp){
      return '';
    }

    if (deltaTimestamp > 0) {

      switch(type) {
        case GsTimerEnum.BATTLE:
          result = `${this.getMinutes(deltaTimestamp)}M ${this.getSecondes(deltaTimestamp)}S`;
          break;
        case GsTimerEnum.COOLDOWN:
          result = `${this.getHours(deltaTimestamp)}H ${this.getMinutes(deltaTimestamp)}M ${this.getSecondes(deltaTimestamp)}S`;
          break;
        case GsTimerEnum.SHIELD:
        case GsTimerEnum.FREEZE:
          result = `${this.getSecondes(deltaTimestamp)} s`;
          break;
        case GsTimerEnum.VOTE_INFO:
          result = `${this.getSecondes(deltaTimestamp, false)}s`;
          break;
        case GsTimerEnum.RANKING:
          result = `${this.getHours(deltaTimestamp)}:${this.getMinutes(deltaTimestamp)}:${this.getSecondes(deltaTimestamp)}`;
          break;
        case GsTimerEnum.GALLERY_VISITOR_BONUS:
          if(deltaTimestamp >= this.millisecondsInDay){
            result = `${this.getDays(deltaTimestamp)}d ${this.getHours(deltaTimestamp)}h`;
          } else if (deltaTimestamp >= this.millisecondsInHour) {
            result = `${this.getHours(deltaTimestamp)}:${this.getMinutes(deltaTimestamp)}:${this.getSecondes(deltaTimestamp)}`;
          } else {
            result = `${this.getMinutes(deltaTimestamp)}:${this.getSecondes(deltaTimestamp)}`;
          }
          break;
        case GsTimerEnum.CHALLENGE_TURBO_BUTTON:
          if(deltaTimestamp >= this.millisecondsInDay){
            result = `${this.getDays(deltaTimestamp,false)}D`;
          } else if (deltaTimestamp >= this.millisecondsInHour) {
            result = `${this.getHours(deltaTimestamp, false)}H`;
          } else if (deltaTimestamp >= this.millisecondsInMinute){
            result = `${this.getMinutes(deltaTimestamp, false)}M`;
          }else {
            result = `${this.getSecondes(deltaTimestamp, false)}S`;
          }
          break;
        default:
          result = `The default timer type in not defined`;
          break;
      }
    } else {
      result = `Ended`;
    }

    return result;
  }

  getDays(deltaTimestamp:number, leadZero:boolean = true){
    return this.getTimeByType(deltaTimestamp, TimeType.DAY, leadZero);
  }

  getHours(deltaTimestamp:number, leadZero:boolean = true){
    return this.getTimeByType(deltaTimestamp, TimeType.HOUR, leadZero);
  }
  getMinutes(deltaTimestamp:number, leadZero:boolean = true){
    return this.getTimeByType(deltaTimestamp, TimeType.MINUTE, leadZero);
  }
  getSecondes(deltaTimestamp:number, leadZero:boolean = true){
    return this.getTimeByType(deltaTimestamp, TimeType.SECOND, leadZero);
  }

  getTimeByType(deltaTimestamp:number, type: TimeType, addLeadZero:boolean = true){
    let value: string | number = NgDateHelper[`get${type}sByDelta`](deltaTimestamp);
    if(addLeadZero){
      value = NgDateHelper.addLeadZero(value);
    }
    return value;
  }
}
