import config from './virtualExhibitions.page.config';
import virtualExhibitions from './virtualExhibitions';

let moduleVirtualExhibitions = angular
  .module('gsApp.virtualExhibitions', [])
  .config(config)
  .component('virtualExhibitions', virtualExhibitions)
;

export default moduleVirtualExhibitions.name;
