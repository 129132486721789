const template = require(`html-loader!./challengesOpen.html`).default;
import { filters } from './challengesOpen.data';
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";
import {SocketsEnum} from "../../../../../core/enum/sockets.enum";
import {Subject} from "rxjs";

const IS_MOBILE = 768;

class challengesOpenCtrl {
  firstTimeDataLoaded = true;
  subject = new Subject();

  constructor(
    $cookies,
    $location,
    $rootScope,
    $scope,
    $stateParams,
    $timeout,
    $window,
    ChallengesManager,
    gsModals,
    Modals,
    onBoarding,
    tagEventService,
    mixpanelService,
    SocketService,
    JoinedChallengesPageService,
    SessionConfigService,
    MediatorService,
  ) {
    'ngInject';
    this.busy = true;
    this.items = [];
    this.filters = filters;

    this.$cookies = $cookies;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$window = $window;
    this.ChallengesManager = ChallengesManager;
    this.gsModals = gsModals;
    this.Modals = Modals;
    this.onBoarding = onBoarding;
    this.tagEventService = tagEventService;
    this.mixpanelService = mixpanelService;
    this.SocketService = SocketService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.SessionConfigService = SessionConfigService;
    this.mediatorService = MediatorService;

    this.onResize = _.debounce(this.onResize.bind(this), 250);
  }

  onPlayersUpdate (result){
    this.updatePlayers({
      challenge_id: result.challenge_id,
      players: result.players
    });
  }

  $onInit() {
    this.tagEventService.sendPageViewEvent({title: 'Open challenges'});
    // mobile view
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    $(window).on('resize', this.onResize);
    // scroll to top
    this.$window.scrollTo(0, 0);
    // order challenges by
    this.orderBy = this.$cookies.get('openChallengesOrder') || '-start_time';
    this.onPlayersUpdateRef = this.onPlayersUpdate.bind(this);
    this.SocketService.socket.on(SocketsEnum.PlayersUpdate, this.onPlayersUpdateRef);

    this.JoinedChallengesPageService.$openChallenges.pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((challenges)=>{
      if (challenges === undefined) { return }
      if (this.firstTimeDataLoaded) {
        this.firstTimeDataLoaded = false;
        this.busy = false;
        // onBoarding
        this.$timeout(() => {
          if (this.SessionConfigService.onBoarding?.challenge) {
            this.onBoarding.next();
          }
        }, 250);
      }
      this.items = challenges;
      setTimeout(() => {
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
      });
    });
    this.JoinedChallengesPageService.getOpenChallenges();
  }

  updatePlayers({challenge_id, players}) {
    const challengeToUpdate = this.JoinedChallengesPageService.openChallengesMap.get(challenge_id);
    if (challengeToUpdate) {
      challengeToUpdate.players = players;
    }
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(window).off('resize', this.onResize);
    this.SocketService.socket.removeListener(SocketsEnum.PlayersUpdate, this.onPlayersUpdateRef);
  }

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  vote(challenge) {
    this.gsModals.open('vote', { challenge: challenge });
  }

  changeOrder(filter) {
    this.eventMixPanelAction(this.filters.find(item => item.value === filter).name.toLowerCase());
    this.$cookies.put('openChallengesOrder', filter);
    this.orderBy = filter;
    this.$timeout(() => {
      this.$rootScope.$emit('lazyImg:refresh');
    });
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  eventMixPanelAction(filterType) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.OPEN_CHALLENGES,
          ui_name: mixpanelEventsEnum.UI_NAME.APPLY_ORDER_BY,
          filter_type: filterType
        }
      }
    );
  }
}
challengesOpenCtrl.$inject = [
  '$cookies',
  '$location',
  '$rootScope',
  '$scope',
  '$stateParams',
  '$timeout',
  '$window',
  'ChallengesManager',
  'gsModals',
  'Modals',
  'onBoarding',
  'tagEventService',
  'mixpanelService',
  'SocketService',
  'JoinedChallengesPageService',
  'SessionConfigService',
  'MediatorService',
];
export default {
  template: template,
  controller: challengesOpenCtrl
};
