import {HTTP_ENUM} from "../../../api/enums/http.enum";

const template = require(`html-loader!./login.html`).default;
import environment from '../../../gsApp.environment';

class loginModalCtrl {
  constructor(
    $mdDialog,
    AuthenticationManager,
    $timeout,
    $element,
    RestManager,
    $rootScope,
    Facebook,
    authService,
    $scope,
    $cookies,
    $http,
    pageData,
    $location,
    NgTokenService,
    MemberService,
  ) {
    'ngInject';
    this.busy = false;
    this.email = '';
    this.password = '';
    this.state = 'form'; // loader, forgot, message

    this.$mdDialog = $mdDialog;
    this.AuthenticationManager = AuthenticationManager;
    this.$timeout = $timeout;
    this.$element = $element;
    this.RestManager = RestManager;
    this.$rootScope = $rootScope;
    this.Facebook = Facebook;
    this.authService = authService;
    this.$scope = $scope;
    this.$cookies = $cookies;
    this.$http = $http;
    this.pageData = pageData;
    this.$location = $location;
    this.NgTokenService = NgTokenService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= 768;
    // state
    if(this.data.state) {
      this.state = this.data.state;
    }
    // hide signIn
    if(this.data.hideSignIn) {
      this.hideSignIn = true;
    }

    this.signUp = this.data.signUp ? this.data.signUp : false;
    this.email = this.data.email ? this.data.email : '';
  }

  /** @return void **/
  async login(form) {
    if (this.busy || !this.validate(form)) return;
    this.busy = true;

    // login / signUp
    let res;
    if (this.signUp) {
      let token;
      let captchaKey = environment.captchaKey;
      if(grecaptcha){
        token = await grecaptcha.execute(captchaKey, {action: 'signUp'});
        console.log(token,'token');
      }
      res = await this.AuthenticationManager.signUp(this.email, this.password, token);
    } else {
      res = await this.AuthenticationManager.signIn(this.email, this.password);
    }

    if (!res.success) {
      this.errorMessage = res.message;
      this.busy = false;
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }

    // save token
    this.NgTokenService.memberToken = res.token;
    this.$http.defaults.headers.common[HTTP_ENUM.HEADER.X_TOKEN] = this.NgTokenService.memberToken;
    // this.NgTokenService.setAccessToken(res.jwt_token);
    if(res.admin_token) {
      this.NgTokenService.adminToken = res.admin_token;
      this.$http.defaults.headers.common[HTTP_ENUM.HEADER.X_ADMIN_TOKEN] = this.NgTokenService.adminToken;
    }
    else {
      this.NgTokenService.resetAdminToken();
    }
    if (res.action === 'signin') {
      this.authService.login();
    } else {
      this.authService.signUp();
    }
    this.close();

    // if already signIn and admin - refresh page
    if (this.member.id && this.member.is_admin) {
      window.location.reload();
    }

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  resetPassword(form) {
    if (this.busy || !this.validate(form)) return;
    this.busy = true;

    this.AuthenticationManager.forgotPassword(this.email).then(res => {
      this.busy = false;
      if (!res.success) {
        // this.message = res.fields.general;
        // this.state = 'message';
        this.errorMessage = res.fields.general;
        return;
      }

      this.message = 'An email has been sent to you';
      this.state = 'message';
    });
  }

  validate(form) {
    // clear form
    form.$submitted = true;
    this.errorMessage = '';
    let noErrors = true;

    form.email.$error.validationError = false;
    if (!this.isValid('user_name', this.email) && !this.isValid('email', this.email)) {
      form.email.$error.validationError = true;
      noErrors = false;
    }

    // validate password if signUp
    if (form.password) {
      form.password.$error.validationError = false;
      if (!this.isValid('password', this.password)) {
        form.password.$error.validationError = true;
        noErrors = false;
      }
    }

    return noErrors;
  }

  isValid(field, value) {
    switch (field) {
      case 'user_name':
        return /^[0-9a-zA-Z_.]+$/.test(value) && value.length >= 3;
      case 'email':
        return /^[a-zA-Z0-9\-_.+]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]+){1,2}$/.test(value);
      case 'password':
        return value && value.length >= 5;
      default:
        return true;
    }
  }

  facebook() {
    this.busy = true;
    this.close();
    this.Facebook.login();
  }

  setPassword(form) {
    if (this.busy) return;

    form.newPassword.$error.validationError = false;
    if (!this.isValid('password', this.newPassword)) {
      form.newPassword.$error.validationError = true;
      return false;
    }

    form.repeatPassword.$error.validationError = false;
    if (this.newPassword !== this.repeatPassword) {
      form.repeatPassword.$error.validationError = true;
      return false;
    }

    this.busy = true;

    this.RestManager.updateMemberSettings({
      password: this.newPassword
    }).then(res => {
      this.busy = false;
      if (!res.success) {
        return false;
      }
      this.state = 'message';
      this.message = 'Password changed';
    });
  }

  close() {
    this.$mdDialog.hide();
  }
}
loginModalCtrl.$inject = [
  '$mdDialog',
  'AuthenticationManager',
  '$timeout',
  '$element',
  'RestManager',
  '$rootScope',
  'Facebook',
  'authService',
  '$scope',
  '$cookies',
  '$http',
  'pageData',
  '$location',
  'NgTokenService',
  'MemberService',
];
export default {
  template: template,
  controller: loginModalCtrl,
  bindings: {
    data: '=data'
  }
};
