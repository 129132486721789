const template = require(`html-loader!./gsHeader.html`).default;

import halloween_1 from "../../../../../assets/animations/halloween_1.json";
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";
import ProtectionModel from "../../api/models/protection.model";

class headerCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {authService} authService
   * @param {eventsHeaderService} eventsHeaderService
   * **/

  isBattleRedDot = false;
  mobileMenu = false;
  constructor(
    $rootScope,
    $state,
    $scope,
    Modals,
    authService,
    eventsHeaderService,
    protectionService,
    mixpanelService,
    brazeService,
    MemberService
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$scope = $scope;
    this.brazeService = brazeService;
    this.Modals = Modals;
    this.authService = authService;
    this.eventHeaderService = eventsHeaderService;
    this.protectionService = protectionService;
    this.mixpanelService = mixpanelService;
    this.mixpanelEventsEnum = mixpanelEventsEnum;
    this.member = MemberService.getCurrentMember();
    this.eventHeader = this.eventHeaderService.getEvent();
    this.onDocument = this.onDocument.bind(this);
    this.onResize = _.debounce(this.onResize.bind(this), 250);

    this.$scope.$on('$destroy', () => {
      $(document).off('click', this.onDocument);
    });

  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= 961;
    $(window).on('resize', this.onResize);
  }

  $onDestroy() {
    $(window).off('resize', this.onResize);
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= 961;
  }

  goProfile() {
    if (window.screen.availWidth < 768) {
      return;
    }

    this.eventMixPanelAction(this.mixpanelEventsEnum.UI_NAME.PROFILE_IMAGE);
  }

  _protection() {
    const signinProtectionModel = new ProtectionModel({
      elementClassName: 'signin--s--',
      eventName: 'click',
      callback: this.signin.bind(this)
    });
    const signupProtectionModel = new ProtectionModel({
      elementClassName: 'c-signup-btn--s--',
      eventName: 'click',
      callback: this.signup.bind(this)
    });

    this.protectionService.initEvent(signinProtectionModel);
    this.protectionService.initEvent(signupProtectionModel);
  }

  signin(event) {
    /* TODO: refactore Modals.open arguments to model */
    this.Modals.open('login', event);
  }

  signup(event) {
    /* TODO: refactore Modals.open arguments to model */
    this.Modals.open('login', event, {signUp: true});
  }

  preventEvent(event) {
    event.preventDefault();
    return false;
  }

  onDocument(event) {
    if ($('#header--s--').find(event.target).length > 0 || !this.mobileMenu) {
      return true;
    }

    this.mobileMenu = false;
    $(document).off('click', this.onDocument);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }

    return true;
  }

  openFbInvite(){
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.PROFILE_MENU_SELECT, mixpanelEventsEnum.MENU_SELECTION.INVITE_FRIENDS)
    this.brazeService.sendShowInviteMessage();
  }

  signOut() {
    this.eventMixPanelAction(
      mixpanelEventsEnum.UI_NAME.PROFILE_MENU_SELECT,
      mixpanelEventsEnum.MENU_SELECTION.SIGN_OUT
    );
    this.authService.signOut();
  }

  runAnimation(anim = halloween_1, loop = false) {
    const container = document.querySelector(".gs-header__event");

    if(!container.childElementCount) {
      setTimeout(() => {
        this.animation = window.bodymovin.loadAnimation({
          container: container,
          renderer: 'svg',
          loop: loop,
          autoplay: true,
          animationData: anim
        });
      });
    }
  }

  eventMixPanelAction(uiName, menuSelection = '', uiAction = mixpanelEventsEnum.UI_ACTION.CLICK) {
    let data = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.TOP_NAV,
      ui_action: mixpanelEventsEnum.UI_ACTION[uiAction.toUpperCase()],
      ui_name: uiName
    };

    if (menuSelection !== '') {
      data.menu_selection = menuSelection;
    }

    this.mixpanelService.track({data});
  }

  getTabState(goToState) {
    return this.$state.includes(goToState) ? '-' : goToState;
  }
}

headerCtrl.$inject = [
  '$rootScope',
  '$state',
  '$scope',
  'Modals',
  'authService',
  'eventsHeaderService',
  'protectionService',
  'mixpanelService',
  'brazeService',
  'MemberService'
];

export default {
  template: template,
  controller: headerCtrl
};
