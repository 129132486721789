import authService from './auth.service';

import environment from '../gsApp.environment';
import NgTokenService from "../../../core/services/ng-token.service";

class Facebook {
  constructor(
    AuthenticationManager,
    notificationAlert,
    authService,
    $rootScope,
    $cookies,
    $http,
    FacebookManager,
    RestManager,
    NgTokenService,
    pageData
  ) {
    'ngInject';

    this.AuthenticationManager = AuthenticationManager;
    this.FacebookManager = FacebookManager;
    this.notificationAlert = notificationAlert;
    this.authService = authService;
    this.$rootScope = $rootScope;
    this.$cookies = $cookies;
    this.$http = $http;
    this.RestManager = RestManager;
    this.NgTokenService = NgTokenService;
    this.pageData = pageData;

    this.authResponseToken = this.authResponseToken.bind(this);
    this.authResponseLink = this.authResponseLink.bind(this);
    this.authResponse = this.authResponse.bind(this);
  }

  init() {
    // get appId
    let appID = environment.facebookAppId;
    // fbq('init', appID);
    // fbq('addPixelId', '302561153449897');
    // if(process.env !== 'production'){
    //   fbq('addPixelId', '1878830078962360');
    // }
    // fbq('track', "PageView");

    // load the SDK asynchronously
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    // init Facebook SDK
    window.fbAsyncInit = function() {
      FB.init({
        appId: appID,
        cookie: true,
        xfbml: true,
        version: 'v3.0',
      });
      // pageView init
      FB.AppEvents.logPageView();
    };

    this.offFacebookInvite = this.$rootScope.$on('FacebookInvite', this.onFacebookInvite.bind(this));
  }

  $onDestroy() {
    this.offFacebookInvite();
  }

  login() {
    FB.login(this.authResponse, { scope: 'email,public_profile,user_friends' });
  }

  logout() {
    FB.logout();
  }

  link() {
    FB.login(this.authResponseLink, { scope: 'email,public_profile,user_friends', auth_type: 'rerequest' });
  }

  // Renew member token
  renewToken() {
    FB.login(this.authResponseToken, { scope: 'email,public_profile,user_friends', auth_type: 'rerequest' });
  }

  onFacebookInvite(){
    this.invite();
    console.log('invite');
  }

  async invite(){
    const res = await this.RestManager.getDeeplinks('invite');
    if(!res.success || !res.deeplink){return}
    FB.ui({
      method: 'send',
      link: res.deeplink,
      redirect_uri: "https://www.facebook.com/dialog/return/close#_=_"
    });
  }

  /** @return void **/
  async authResponse(response) {
    if (response.status !== 'connected') {
      console.log('Facebook not connected');
      return;
    }
    // signUp / signIn
    const res = await this.FacebookManager.facebookConnect(response.authResponse.accessToken);
    if (!res.success) {
      if (res.error_code === 1006) {
        this.notificationAlert.open('This facebook account is already connected with a different user!');
        return;
      }
    }

    // save tokens
    this.NgTokenService.memberToken = res.token;
    // this.NgTokenService.setAccessToken(res.jwt_token);
    if (res.is_signup) {
      this.authService.signUp();
    } else {
      this.authService.login();
    }
  }

  async authResponseLink(response) {
    if (response.status !== 'connected') {
      window.location.reload();
      return;
    }
    const res = await this.FacebookManager.facebookLink(response.authResponse.accessToken);
    console.log(res);
    if (!res.success) {
      this.notificationAlert.open(res.message);
      return;
    }
    window.location.reload();
  }

  async authResponseToken(response) {
    // if connected renew key
    if (response.status !== 'connected') {
      window.location.reload();
      return;
    }
    // renew token
    const res = await this.FacebookManager.facebookRenewToken(response.authResponse.accessToken);
    if (!res.success) {
      this.notificationAlert.open(res.message);
      return;
    }
    window.location.reload();
  }
}
Facebook.$inject = [
  'AuthenticationManager',
  'notificationAlert',
  'authService',
  '$rootScope',
  '$cookies',
  '$http',
  'FacebookManager',
  'RestManager',
  'NgTokenService',
  'pageData'
];
export default Facebook;
