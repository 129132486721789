const template = require(`html-loader!./gsApp.html`).default;
import mainCtrl from './gsApp.ctrl';
import {HTTP_ENUM} from "./api/enums/http.enum";
import configEnum from "./api/enums/config.enum";

import environment from './gsApp.environment';

function config(
  $logProvider,
  $locationProvider,
  $stateProvider,
  $urlRouterProvider,
  $httpProvider,
  $qProvider,
  $mdGestureProvider,
  $compileProvider,
  $urlMatcherFactoryProvider,
  lazyImgConfigProvider,
  apiProvider,
  $sceDelegateProvider
) {
  'ngInject';
  apiProvider.setBaseRoute('https://' + environment.domains.api + '/');
  lazyImgConfigProvider.setOptions({
    offset: 100,
    errorClass: 'error',
    successClass: 'loaded'
  });
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    'https://**.shots.guru/**',
    'https://www.youtube.com/**',
    'https://vimeo.com/**',
    'https://player.vimeo.com/**',
    '**'
  ]);
  $urlMatcherFactoryProvider.strictMode(false);
  $compileProvider.debugInfoEnabled(false);
  $compileProvider.commentDirectivesEnabled(false);
  $compileProvider.cssClassDirectivesEnabled(false);
  $httpProvider.useLegacyPromiseExtensions = false;
  $mdGestureProvider.skipClickHijack();
  $locationProvider.html5Mode(true);
  $httpProvider.defaults.headers.post[HTTP_ENUM.HEADER.CONTENT_TYPE] = 'application/x-www-form-urlencoded';
  $httpProvider.defaults.headers.post[HTTP_ENUM.HEADER.X_REQUESTED_WITH] = 'XMLHttpRequest';
  $httpProvider.defaults.headers.post[HTTP_ENUM.HEADER.CACHE_CONTROL] = 'no-cache';
  $httpProvider.defaults.headers.post[HTTP_ENUM.HEADER.PRAGMA] = 'no-cache';
  $httpProvider.defaults.useXDomain = true;
  $urlRouterProvider.otherwise(() => {
    window.location.href = '/error/page_404';
  });
  let resolveFunc = (AuthenticationManager, $q) =>{
    'ngInject';
    return $q.resolve(null);
    // if(configEnum.GWT_ENABLED){
    //   const token = tokenService.getAccessToken();
    //   if(token){
    //     return $q.resolve(token);
    //   }
    //   return AuthenticationManager.getAccessToken();
    // } else {
    //   return $q.resolve(null);
    // }
  }
  resolveFunc.$inject = ['AuthenticationManager', '$q'];
  $stateProvider.state('gs', {
    url: '',
    abstract: true,
    template: template,
    controller: mainCtrl,
    controllerAs: 'main',
    resolve: {
      token: resolveFunc
    }
  });
}
config.$inject = [
  '$logProvider',
  '$locationProvider',
  '$stateProvider',
  '$urlRouterProvider',
  '$httpProvider',
  '$qProvider',
  '$mdGestureProvider',
  '$compileProvider',
  '$urlMatcherFactoryProvider',
  'lazyImgConfigProvider',
  'apiProvider',
  '$sceDelegateProvider'];
export default config;
