function config($stateProvider, $urlRouterProvider) {
  "ngInject";
  $stateProvider
    .state('gs.sponsors', {
      url: '/sponsors',
      redirectTo: 'gs.sponsors.materials',
      abstract: true
    })
    .state('gs.sponsors.materials', {
      url: '/materials/:formId',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'sponsorsPage'},
        'footer@gs': {component: 'gsFooter'}
      },
      params: {
        formId: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.sponsors.payments', {
      url: '/payments/:formId',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'sponsorsPage'},
        'footer@gs': {component: 'gsFooter'}
      },
      params: {
        formId: {
          value: null,
          squash: true
        }
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
