const template = require(`html-loader!./contentManager.html`).default;

class contentManagerCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Challenges', route: 'gs.gsAdmin.contentManager.challenges'},
      {name: 'Exhibitions', route: 'gs.gsAdmin.contentManager.exhibitions'},
      {name: 'Spotlight', route: 'gs.gsAdmin.contentManager.spotlight'},
      {name: 'Popups', route: 'gs.gsAdmin.contentManager.popups'},
      {name: 'Widget', route: 'gs.gsAdmin.contentManager.widget'},
      // {name: 'Daily Rewards', route: 'gs.gsAdmin.contentManager.dailyRewards'},
      {name: 'Rewarded Video', route: 'gs.gsAdmin.contentManager.rewardedVideo'},
      {name: 'Liveops', route: 'gs.gsAdmin.contentManager.liveops'},
      {name: 'Events', route: 'gs.gsAdmin.contentManager.events'},
      {name: 'Chests', route: 'gs.gsAdmin.contentManager.chests'},
      {name: 'Teams', route: 'gs.gsAdmin.contentManager.teams'}
    ];
  }
}

export default {
  template: template,
  controller: contentManagerCtrl,
  bindings: {}
};
