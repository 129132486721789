const template = require(`html-loader!./teamsPage.html`).default;

import TeamModel from "../../../api/models/team/team.model";
import TeamStateModel from '../../../api/models/team/teamState.model';
import teamEnum from '../../../api/enums/team.enum';

class teamsPageCtrl {

  constructor(pageData,
              $window,
              $scope,
              $timeout,
              RestManager,
              stateService,
              $cookies,
              $location,
              $rootScope,
              teamsService,
              $stateParams,
              $state,
              MemberService,
              NgMetaDataService,
  ) {
    'ngInject';
    this.ready = false;
    this.start = 0;

    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.$cookies = $cookies;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.teamsService = teamsService;
    this.$stateParams = $stateParams;
    this.NgMetaDataService = NgMetaDataService;
    this.$state = $state;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Teams');
    this.initPage();
    if (document.body.clientWidth <= 961 ){
      this.stateService.goToDefaultState();
      return;
    }
  }

  $onDestroy() {
    //enable invite interval
    this.teamsService.setInviteNotificationInterval();
  }

  /** @return void **/
  async initPage() {
    this.pageData.get('teams');

    if(this.member.isRestricted()){
      return;
    }
    if (this.member.logged_in === false) {
    // if (this.member.logged_in === false) {
      this.stateService.goToState('gs.homePage');
      return;
    }
    await this.getTeamsConfig();
    await this.getTeams(this.start, 1);
    this.ready = true;

    // in case of team info/create  dont want to redirect
    if(this.state.status !== teamEnum.STATE.LOCKED && (this.$location.path().indexOf('teams/info') === 1
      || (this.state.status !== teamEnum.STATE.JOINED && this.$location.path().indexOf('teams/create') === 1))
    ){
      if (!this.$scope.$$phase) {this.$scope.$digest()}
      return;
    }

    if(!this.state || this.state.status === -1){
      this.stateService.goToDefaultState();
      return;
    }

    //disable invite interval
    this.teamsService.cancelInviteNotificationInterval(true);

    if (this.state.status === teamEnum.STATE.JOINED) {
      if (['gs.teams', 'gs.teams.join', 'gs.teams.create'].includes(this.$state.current.name)) {
        this.stateService.goToState('gs.teams.home.match', this.$stateParams);
      }

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return;
    }

    if (this.state.status === teamEnum.STATE.UNLOCKED) {
      this.stateService.goToState('gs.teams.join');

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return;
    }

    if (this.state.status === teamEnum.STATE.LOCKED) {
      this.stateService.goToState('gs.teams.lock');

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async getTeams(start, limit){
    const res = await this.teamsService.getTeams({ start, limit});

    if (!res.success) {
      return;
    }

    this.state = new TeamStateModel(res.state);
    this.teams = res.data.teams.map( team => new TeamModel(team));

    if (this.state.status === teamEnum.STATE.JOINED) {
      this.team = this.teams[0];
    }
  }

  async getTeamsConfig(){
    await this.teamsService.getTeamsConfig();
    this.configData = this.teamsService.getTeamConfig();
  }
}

teamsPageCtrl.$inject = [
  'pageData',
  '$window',
  '$scope',
  '$timeout',
  'RestManager',
  'stateService',
  '$cookies',
  '$location',
  '$rootScope',
  'teamsService',
  '$stateParams',
  '$state',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: teamsPageCtrl,
};
