import LocalStorageModel from './models/localStorage.model';
import rankFilters from "../ranking/rankFilters.service";

class LocalStorageService {

  constructor($window, $rootScope, MemberService) {
    'ngInject';
    this.name = 'localStorage';

    this.window = $window;
    this.$rootScope = $rootScope;
    this.member = MemberService.getCurrentMember();
  }

  save({ memberId = this.member.id, value, key }) {
    let result = '',
      localStorageData = this.getData(memberId)
    ;
    if(localStorageData) {
      localStorageData[key] = value;
    } else {
      /* create new model an put values there */
      localStorageData = new LocalStorageModel({
        key,
        value
      });
    }
    result = JSON.stringify(localStorageData);
    this.window.localStorage.setItem(memberId, result);
  }

  getData({memberId = this.member.id}) {
    let data;
    if(memberId) {
      data = this.window.localStorage.getItem(memberId);
    }
    return data ? JSON.parse(data) : !!data;
  }

  getDataByKey({memberId  = this.member.id, key}) {
    let data;
    if(memberId) {
      data =  this.getData(memberId);
    }
    return data ? data[key] : data;
  }
}
LocalStorageService.$inject = [
  '$window', '$rootScope', 'MemberService'
];
export default LocalStorageService;
