import TeamModel from "../api/models/team/team.model";
import TeamStateModel from '../api/models/team/teamState.model';
import teamEnum from '../api/enums/team.enum';
import {autoUpdateTypeEnum} from "./autoUpdate.service.data";
import TeamsConfigModel from "../api/models/team/teamsConfig.model";

class teamsService {

  constructor(
    $rootScope,
    RestManager,
    $timeout,
    socketService,
    $state,
    $document,
    notificationAlert,
    $cookies,
    stateService,
    brazeService,
    Modals,
    autoUpdateService,
    MemberService
  ) {
    'ngInject';
    this.orgFavicon = '/assets/images/favicon.png';
    this.notificationFavicon = '/assets/images/favicon-notification.png';
    this.tabVisible = true;
    this.newMessagesFlag = false;

    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.socketService = socketService;
    this.$state = $state;
    this.$document = $document;
    this.notificationAlert = notificationAlert;
    this.$cookies = $cookies;
    this.stateService = stateService;
    this.brazeService = brazeService;
    this.Modals = Modals;
    this.autoUpdateService = autoUpdateService;
    this.member = MemberService.getCurrentMember();

    this.expired = new Date();
    this.expired.setFullYear(2030);

    document.addEventListener('visibilitychange', this.onTabVisibilityChange.bind(this));
  }

  /** @return void **/
  async initAutoUpdate() {
    await this.getTeams({start:0, limit:1});
    this.setInviteNotificationInterval();
    // this.checkIfHighlight();
  }

  setChatListener(){
    this.socket = this.socketService.getSocket();
    this.socketService.addListener(this.socketService.socketEvents.msgToClient, this.onMsgToClient.bind(this));
  }

  onMsgToClient(msg) {
    if (this.$state.current.name.indexOf('teams') === -1) {
      this.$rootScope.teamsShowTabNotificationIcon = true;
    }

    if (!this.tabVisible) {
      this.newMessagesFlag = true;
      document.getElementById('favicon').href = this.notificationFavicon;
    }

    if (!this.$rootScope.$$phase) {
      this.$rootScope.$digest();
    }
  }

  resetFavicon() {
    document.getElementById('favicon').href = this.orgFavicon;
  }

  onTabVisibilityChange() {
    this.tabVisible = document.visibilityState === 'visible';
    if (this.$state.current.name.indexOf('teams')) {
      this.$rootScope.$emit('onChatTabFocus', {tabVisible:this.tabVisible, newMessagesFlag:this.newMessagesFlag});
    }

    if (this.tabVisible) {
      this.resetFavicon();
      this.newMessagesFlag = false;
    }
  }

  isTabVisible() {
    return this.tabVisible;
  }

  getTeamConfig() {
    return this._teamConfig;
  }

  getChatId(){
    return this._chatId;
  }

  getCachedTeams(){
    return this._teams;
  }

  getTeamState(){
    return this._teamState;
  }

  isJoinedToTeam(){
    return this._teamState && this._teamState.status === teamEnum.STATE.JOINED;
  }

  _setChatId(chatId){
    this._chatId = chatId;
  }

  //api actions

  handleTeamsResponse(res, openModal=true) {
    let resObj = {isValid:false, message:''};

    if (!res.success || res.state.status === teamEnum.STATE.FAILURE) {
      resObj.message = (res.state && res.state.message) ? res.state.message : 'Error';

      if (openModal) {
        this.notificationAlert.open(resObj.message);
      }

      return resObj;
    }

    resObj.isValid = true;

    return resObj;
  }

  async getTeamsConfig(){
    const res = await this.RestManager.getTeamsConfig();
    if (!res.success) {
      return false;
    }
    delete res.success;
    this._teamConfig = new TeamsConfigModel(res);
  }

  async joinTeam(teamId, joinBy) {
    const res = await this.RestManager.joinTeam(teamId, joinBy);
    if(!this.handleTeamsResponse(res).isValid){
      return false;
    }

    this.parseTeams(res);
    this.brazeService.sendJoinTeamEvent();

    return res;
  }

  async createTeam(team) {
    const res = await this.RestManager.createTeam(team);

    if(res.success){
      this.parseTeams(res);
      this.brazeService.sendJoinTeamEvent();
    }

    return res;
  }

  async leaveTeam(team) {
    const adminMemberList = team.members.filter(member => member.isAdmin());

    if (team.members.length > 1 && adminMemberList.length === 1 && team.current_member.id === adminMemberList[0].id) {
      this.notificationAlert.open("Please assign a new Team Leader");

      return false;
    }

    const confirm = await this.Modals.confirm(
      '',
      `Are you sure you want to leave your team?`,
      'Yes, I\'m sure',
      'Cancel'
    );

    if (!confirm) {
      return false;
    }

    const res = await this.RestManager.leaveTeam(team.id);

    if(!this.handleTeamsResponse(res).isValid){
      return false;
    }
    this.resetTeams();
    return res;
  }

  resetTeams(){
    this.resetChatCookie();
    this.resetMemberSuggestFilterCookie();
    this.socketService.closeSocket();
  }

  async getTeams({start, limit, name}) {
    const res = await this.RestManager.getTeams({start, limit, name});

    if (res.success) {
      this._teamState = new TeamStateModel(res.state);
      if (this._teamState.status === teamEnum.STATE.JOINED) {
        this.parseTeams(res);
      }
    }

    return res;
  }

  async assignAdmin({member, assign= true, teamId, admin = false}){
    const confirm = await this.Modals.confirm(
      '',
      `Are you sure you sure you want to ${assign ? 'assign' : 'unassign'} ${member.user_name.toUpperCase()} as Team Leader?`,
      'Yes, I\'m sure',
      'Cancel'
    );
    if (!confirm) return;
    const reqData = {
      team_id: teamId,
      member_id: member.id,
      type: assign ? teamEnum.MEMBER.TYPE.ADMIN : teamEnum.MEMBER.TYPE.USER
    };
    const res = await this.RestManager[admin ? 'teamsAdminAssignLeader' : 'updateMember'](reqData);
    if(!this.handleTeamsResponse(res).isValid){
      return false;
    }
    member.type = reqData.type;
  }

  //helpers

  goToTeamInfo(teamId) {
    this.stateService.goToState('gs.teams.info', { teamId: teamId } );
  }

  parseTeams(res){
    this._teams =  res.data.teams.map(team => new TeamModel(team));
    this._setChatId(this._teams[0].chat_id);
    this.setChatListener();
  }

  setInviteNotificationInterval(){
    if(!this._teamState){
      return;
    }
    if (this._teamState.status === teamEnum.STATE.UNLOCKED &&
      this.$state.current.name.indexOf('teams') === -1
    ) {
      let autoUpdateObject = {
        type : autoUpdateTypeEnum.TEAMS_IS_NEW_INVITATION,
        callback : this.checkIfNewInvitations.bind(this),
        initCallbackOnStart : true,
      };
      this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
    }
  }

  cancelInviteNotificationInterval(reset=false){
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.TEAMS_IS_NEW_INVITATION);
    if(reset && this.$rootScope.newInvitationFlag){
      this.RestManager.resetNewInvitations();
      this.$rootScope.newInvitationFlag = false;
    }
  }

  async checkIfNewInvitations() {
    const res = await this.RestManager.isNewInvitations();

    if (!res.success) {
      return;
    }

    // only update on new value
    if (this.$rootScope.newInvitationFlag !== res.new_invitation) {
      this.$rootScope.newInvitationFlag = res.new_invitation;
      // if have new invite stop interval
      if (this.$rootScope.newInvitationFlag) {
        this.cancelInviteNotificationInterval();
      }

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }
    }
  }

  isTeamSuggestedInviteEnabled(team){
    return team.members.length <= this._teamConfig.max_members_to_show_suggested &&
    (!this._teamConfig.only_admin_can_invite || team.current_member.isAdmin());
  }

  // cookies

  async openTeamIntro(state) {
    if (!this._teamConfig.ftue) {
      return;
    }
    let teamIntroObj = this.$cookies.getObject('gs_t_i') || {};
    // console.log('state', state);
    // console.log('teamIntroObj', teamIntroObj);
    if (!teamIntroObj[state]) {
      const ftueItem = this._teamConfig.ftue[state];
      let res;
      if (state === teamEnum.INTRO_STATES.ACTIVE_MATCH_1) {
        res = await this.notificationAlert.teamIntro(ftueItem);
        this.notificationAlert.teamIntro(this._teamConfig.ftue[teamEnum.INTRO_STATES.ACTIVE_MATCH_2]);
      } else {
        res = await this.notificationAlert.teamIntro(ftueItem);
      }
      if (res.success) {
        teamIntroObj[state] = true;
        this.$cookies.putObject('gs_t_i', teamIntroObj, {expires: this.expired});
      }
    }
  }

  resetCookieByMember(cookieName){
    const memberId = this.member.id;
    let cookie = this.$cookies.getObject(cookieName);
    if(!cookie){
      return;
    }
    if(cookie[memberId]){
      delete cookie[memberId];
    }
    this.$cookies.putObject(cookieName, cookie, {expires: this.expired});
  }

  setChatCookie(messageId, dateTime, origDateTime) {
    //TODO need to check refactor
    const memberId = this.member.id;
    if(!memberId || !messageId || !dateTime){return}

    let obj = this.$cookies.getObject('gs_t_c') || {};
    obj[memberId] =
      {
        messageId: messageId,
        dateTime: dateTime,
        origDateTime: origDateTime,
      };
    // console.log('$cookies add', obj);
    this.$cookies.putObject('gs_t_c', obj, {expires: this.expired});
  }


  getChatCookie() {
    const memberId = this.member.id;
    const cookie = this.$cookies.getObject('gs_t_c');
    // console.log('$cookies get', cookie);
    return cookie &&  cookie[memberId] ? cookie[memberId] : {};
  }

  resetChatCookie(){
    this.resetCookieByMember('gs_t_c');
  }

  // setHighlightCookie() {
  //   const memberId = this.member.id;
  //   let obj = this.$cookies.getObject('gs_t_h') || {};
  //   obj[memberId] =
  //   {
  //     intro: true
  //   };
  //   console.log('setHighlightCookie', obj);
  //   this.$cookies.putObject('gs_t_h', obj, {expires: this.expired});
  // }
  //
  // getHighlightCookie() {
  //   const memberId = this.member.id;
  //   const cookie = this.$cookies.getObject('gs_t_h');
  //   console.log('getHighlightCookie by member', cookie);
  //   return cookie &&  cookie[memberId] ? cookie[memberId] : {};
  // }
  // }

  getMemberSuggestFiltersCookie() {
    const memberId = this.member.id;
    const cookie = this.$cookies.getObject('gs_t_m_s_f');
    console.log('getMemberSuggestFiltersCookie by member', cookie);
    return cookie &&  cookie[memberId] ? cookie[memberId] : {};
  }

  setMemberSuggestFiltersCookie(filters) {
    const memberId = this.member.id;
    let obj = this.$cookies.getObject('gs_t_m_s_f') || {};
    obj[memberId] = {filters};
    console.log('setMemberSuggestFiltersCookie', obj);
    this.$cookies.putObject('gs_t_m_s_f', obj, {expires: this.expired});
  }

  resetMemberSuggestFilterCookie(){
    this.resetCookieByMember('gs_t_m_s_f');
  }

}
teamsService.$inject = [
  '$rootScope',
  'RestManager',
  '$timeout',
  'socketService',
  '$state',
  '$document',
  'notificationAlert',
  '$cookies',
  'stateService',
  'brazeService',
  'Modals',
  'autoUpdateService',
  'MemberService',
];
export default teamsService;
