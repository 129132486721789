const template = require(`html-loader!./deactivatedPage.html`).default;
import {parseFromSecToMilisec} from "../../../helpers/date.helper";

class deactivatedPageCtrl {
  /** @param {authService} authService **/

  constructor(pageData,
              $scope,
              $state,
              authService,
              RestManager,
              MemberService,
  ) {
    'ngInject';
    this.busy = true;

    this.pageData = pageData;
    this.$scope = $scope;
    this.$state = $state;
    this.authService = authService;
    this.RestManager = RestManager;
    this.MemberService = MemberService;
  }

  $onInit() {
    this.initPage();
  }

  /** @return void **/
  async initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('deactivated');

    if (!this.member.isRestricted()) {
      this.$state.go('gs.homePage');
      return;
    }

    // if(this.member.isDeactivatedByAdmin()){
    const res = await this.RestManager.getRemoveDetails(this.member.id);
    if (!res.success || !res.remove_details) {
      if (res.error_code === 1001){
        this.oldMember = true;
      }
      this.error = true;
      this.busy = false;
      // this.$state.go('gs.homePage');
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }
    this.remove_details = res.remove_details;
    this.duration = parseFromSecToMilisec(this.remove_details.date_to_action) - new Date();
    // }
    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  signOut() {
    this.authService.signOut();
  }

  async reactivateMember(){
    let reqData = {
      id: this.member.id,
      send_email: false,
      reason: ['user_reactivated']
    };
    const res = await this.RestManager.reactivateMember(reqData);
    if(!res.success){
      return;
    }
    window.location.href = '/';
  }
}
deactivatedPageCtrl.$inject = [
  'pageData',
  '$scope',
  '$state',
  'authService',
  'RestManager',
  'MemberService'
];
export default {
  template: template,
  controller: deactivatedPageCtrl
};
