const template = require(`html-loader!./adminExhibitionsManager.html`).default;

import ImageModel from '../../../../../api/models/image/image.model';

class adminExhibitionsManager {
  /**
   * @param {ExhibitionsManager} ExhibitionsManager
   * @param {ChallengesManager} ChallengesManager
   * @param {Toasts} Toasts
   * @param {ModalsService} Modals
   * */

  constructor(ExhibitionsManager, ChallengesManager, Toasts, $element, Modals, $scope, $timeout, $rootScope, $window) {
    'ngInject';
    this.items = [];
    this.printItems = [];
    this.challengeId;
    // challengeId = 7384;
    // challengeId = 6779;
    this.start = 0;
    this.limit = 100;
    this.busy = false;
    this.allItemsLoaded = false;
    this.viewTypes = ['Top Photo', 'Top Photographer'];
    this.sortItems = [
      {title: 'Top Photo', value: 'votes'},
      {title: 'Top Photographer', value: 'photographer'},
      {title: 'Print', value: 'print'},
      {title: 'Digital', value: 'digital'},
      {title: 'Country', value: 'country'},
      {title: 'Total Paid', value: 'paid'},
      {title: 'Guru Pick', value: 'guru_pick'},
    ];
    this.sortItem = 'votes';
    this.view = 'Top Photo';

    this.ExhibitionsManager = ExhibitionsManager;
    this.ChallengesManager = ChallengesManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.Modals = Modals;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$window = $window;
  }

  $onInit() {
    // $(document).on('scroll', this.onScroll.bind(this));
    this.offOnExhibitionSelection = this.$rootScope.$on('onExhibitionSelection', this.onExhibitionSelection.bind(this));
  }

  async getChallengeAutoSelectAttributes() {
    const res = await this.ChallengesManager.getChallengeAutoSelectAttributes(this.challengeId);
    if (!res.success) return;

    if (res.auto_select_configuration) {
      this.isAutoSelect = res.auto_select_configuration.auto_select;
      this.limits = res.auto_select_configuration.min_member_num;
      this.winners_selected = res.winners_selected;
      this.challengeLock = res.challenge_lock;
      this.catalogue = res.auto_select_configuration.catalogue;
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async getChallenge() {
    if (this.challengeId === this.lastChallengeId) {
      return;
    }
    this.lastChallengeId = this.challengeId;
    const res = await this.ChallengesManager.getData(
      {
        id: this.challengeId,
        fields: ['id', 'url', 'title', 'image_id', 'type'],
        include: ['sponsors']
      });
    if (!res.success) {
      throw new Error(res.message);
    }
    res.challenge.image = new ImageModel(res.challenge.image);
    this.challenge = res.challenge;
    await this.getChallengeAutoSelectAttributes();
    if (!this.winners_selected) {
      throw new Error("Set selection was not executed");
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async getData(startIndex) {
    if (this.busy || this.allItemsLoaded) {
      return;
    }
    this.busy = true;
    console.log('getData: with start index - ', startIndex);

    const data = {
      challenge_id: this.challengeId,
      sort: this.sortItem,
      start: startIndex,
      limit: this.limit,
    };
    if (this.isTopPhotoView()) {
      data.filter = ['selected_only'];
    }
    const res = await this.ExhibitionsManager.getExhibitionWinners(data);
    // const res = await getExhibitionRank2();

    if (!this.isTopPhotoView()) {
      this.busy = false;
    }

    if (!res.success || !res.items || !res.items.length) {
      this.busy = false;
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }
    this.totals = {
      digital: parseInt(res.challenge.total_digital_selected),
      print: parseInt(res.challenge.total_print_selected)
    };
    this.allItemsLoaded = res.items.length < this.limit;

    const newItems = this.parseItems(res.items);
    this.items = this.items.concat(newItems);
    this.start = this.items.length;
    this.images = this.parseImages(this.items);
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  parseImages(items) {
    return items.map((item) => {
      let image = new ImageModel(item.images[0]);
      image.member = item.member;
      image.total = item.total;
      image.badge = item.badge;
      return image;
    });
  }

  parseItems(items) {
    return items.map((item) => {
      item.badge = {
        rank: item.challenge.ranking_level,
        type: 'badge'
      };
      item.images = item.images.map((image) => {
        image = new ImageModel(image);
        if (image.achievements && image.achievements[0]) {
          switch (Object.values(image.achievements[0])[0]) {
            case 1:
              image.winner = 'icon-top-photographer';
              break;
            case 2:
              image.winner = 'icon-top-photo';
              break;
            case 3:
              image.winner = 'icon-gurus-top-pick';
              break;
          }
        }
        return image;
      });
      return item;
    });
  }

  async search() {
    if (!this.challengeId || !this.sortItem) {
      console.log('no challenge id');
      return;
    }
    if (this.busy) {
      return;
    }
    this.reset();
    try {
      await this.getChallenge();
      if (this.challenge.type !== 'exhibition') {
        this.message('Challenge must be of type \'Exhibition\'', true);
        return;
      }
    } catch (e) {
      this.message(e.message, true);
      return;
    }
    this.getData(this.start);
    if (!this.$scope.$$phase) this.$scope.$digest();
    console.log(`search: id: ${this.challengeId} filter: ${this.sortItem}`);
  }

  onChallengeIdChange() {
    this.lastChallengeId = null;
    this.challenge = null;
    this.reset();
    this.resetAutoSelectConf();
  }

  reset() {
    this.items = [];
    this.images = [];
    this.start = 0;
    this.allItemsLoaded = false;
  }

  resetAutoSelectConf() {
    this.isAutoSelect = false;
    this.limits = null;
    this.winners_selected = false;
    this.challengeLock = false;
  }

  // onScroll() {
  //   const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
  //   const body = document.body,
  //     html = document.documentElement;
  //   const docHeight = Math.max(body.scrollHeight,
  //     body.offsetHeight,
  //     html.clientHeight,
  //     html.scrollHeight,
  //     html.offsetHeight);
  //   const windowBottom = windowHeight + window.pageYOffset;
  //   const getMoreHeight = -docHeight / 2;
  //   if ((windowBottom - docHeight) > getMoreHeight) {
  //     if (this.start === this.items.length && !this.busy) {
  //       // console.log('start', this.start);
  //       // console.log('items.length', this.items.length);
  //       this.getData(this.items.length);
  //     }
  //   }
  //   return true;
  // }

  loadMore() {
    console.log("loadMore");
    if (this.start === this.items.length && !this.busy) {
      this.getData(this.items.length);
    }
  }

  $onDestroy() {
    $(document).off('scroll', this.onScroll);
    console.log('$onDestroy adminExhibitionsManager');
    this.offOnExhibitionSelection();
  }

  onViewChange() {
    if (this.view === 'Top Photographer') {
      this.sortItems.push({title: 'Not Selected', value: 'not_selected'});
    } else {
      this.sortItems.pop();
      if (this.sortItem === 'not_selected') {
        this.sortItem = 'votes';
      }
    }
    this.search();
  }

  isTopPhotoView() {
    return this.view === 'Top Photo';
  }

  onComplete() {
    // console.log('oncomplete');
    this.busy = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  onExhibitionSelection(e, counter) {
    console.log(counter);
    this.totals.digital += counter.digital;
    this.totals.print += counter.print;
  }

  async setSelection() {
    if (!this.challengeId || this.actionBusy) {return}
    const confirm = await this.Modals.confirm(
      'Set Selection',
      `Are you sure you want to set selection`,
      'Ok',
      'Cancel'
    );
    if (!confirm) return;

    this.actionBusy = true;
    const res = await this.ChallengesManager.newAutoSelectDigitalExhibition(this.challengeId);
    this.actionBusy = false;
    if (!res.success) {
      this.message('Set selection failed', true);
      return;
    }
    this.winners_selected = true;
    this.search();
    this.message('Set selection success');

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async resetSelection() {
    if (!this.challengeId || this.actionBusy) {return}
    const confirm = await this.Modals.confirm(
      'Reset selection',
      `Are you sure you want to reset selection`,
      'Ok',
      'Cancel'
    );
    if (!confirm) return;

    this.actionBusy = true;
    const res = await this.ChallengesManager.resetAutoSelectExhibition(this.challengeId);
    this.actionBusy = false;
    if (!res.success) {
      this.message('Reset selection failed', true);
      return;
    }
    this.resetAutoSelectConf();
    this.lastChallengeId = null;
    // this.winners_selected = false;
    this.message('Reset selection success');

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async publishSelection() {
    if (!this.challengeId || this.actionBusy) {return}
    const confirm = await this.Modals.confirm(
      'Publish selection',
      `Are you sure you want to publish selection`,
      'Ok',
      'Cancel'
    );
    if (!confirm) return;

    this.actionBusy = true;
    const res = await this.ChallengesManager.lockExhibitionChallenge(this.challengeId);
    this.actionBusy = false;
    if (!res.success) {
      this.message('Publish selection failed', true);
      return;
    }
    this.challengeLock = true;
    this.message('Publish selection success');

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async sendSelectionNotifications() {
    if (!this.challengeId || this.actionBusy) {return}
    const confirm = await this.Modals.confirm(
      'Send selection notifications',
      `Are you sure you want to send selection notifications`,
      'Ok',
      'Cancel'
    );
    if (!confirm) return;

    this.actionBusy = true;
    const res = await this.ChallengesManager.sendSelectionNotifications(this.challengeId);
    if (!res.success) {
      this.message('Selection notifications failed', true);
      return;
    }
    this.actionBusy = false;
    this.challengeLock = true;
    this.message('Selection notifications success');

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async print(type) {
    if (!this.challengeId || this.actionBusy) {return}
    if (!this.printBusy) {
      this.numOfPrintImageLoaded = 0;
      this.numOfPrintImageError = 0;
      this.printItems = [];
      const data = {
        challenge_id: this.challengeId,
        sort: "votes",
        start: 0,
        limit: -1,
        filter: [type, 'selected_only']
      };
      this.actionBusy = true;
      const res = await this.ExhibitionsManager.getExhibitionWinners(data);
      if (!res.success || !res.items || !res.items.length) {
        this.actionBusy = false;
        if (!this.$scope.$$phase) this.$scope.$digest();
        return;
      }
      this.actionBusy = false;
      this.printItems = this.parseImages(res.items);
      this.printTitle = type === 'digital' ? 'Digital Catalogue' : 'Print Catalogue';
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }


  onPrintImgLoad(event) {
    this.numOfPrintImageLoaded++;
    console.log('numOfPrintImageLoaded', this.numOfPrintImageLoaded);
    this.openPrintConsole();
  }

  onPrintImgError(event) {
    this.numOfPrintImageError++;
    console.log('numOfPrintImageError', this.numOfPrintImageError);
    this.openPrintConsole();

  }

  openPrintConsole(){
    if(this.printItems.length === (this.numOfPrintImageLoaded + this.numOfPrintImageError)){
      console.log('end numOfPrintImageLoaded', this.numOfPrintImageLoaded);
      console.log('end numOfPrintImageError', this.numOfPrintImageError);
      this.$timeout(window.print, 5000);
    }
  }

  message(message, isError) {
    let options = {
      data: {
        message: message,
      },
    };
    if (isError) {
      options.data.class = 'error';
    }
    this.Toasts.open(
      'simpleMessage',
      options,
      this.$element,
      'bottom left'
    );
  }

  scrollTop() {
    console.log('click')
    this.$window.scrollTo(0, 0);
  }
}
adminExhibitionsManager.$inject = [
  'ExhibitionsManager', 'ChallengesManager', 'Toasts', '$element', 'Modals', '$scope', '$timeout', '$rootScope', '$window'
];
export default {
  template: template,
  controller: adminExhibitionsManager,
};
