import {extractContentFromHtml} from "../../helpers/string.helper";

const template = require(`html-loader!./virtualExhibitions.html`).default;
import VirtualExhibitionModel from "../../api/models/exhibition/virtualExhibition.model";

class virtualExhibitionsCtrl {
  /**
   * @param {pageData} pageData
   * @param {stateService} stateService
   * */

  constructor(
    $scope,
    stateService,
    $rootScope,
    RestManager,
    tagEventService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.busy = true;

    this.$scope = $scope;
    this.stateService = stateService;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.tagEventService = tagEventService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.updateMetaTags({
      title: `Explore Stunning Virtual Photography Exhibitions`,
      description: `Discover the best virtual photography exhibitions on GuruShots. Explore stunning collections of photography from talented photographers worldwide, all from the comfort of your home`,
    });

    this.init();
  }

  /** @return void **/
  async init() {
    this.tagEventService.sendPageViewEvent({title: 'Virtual exhibition page'});
    this.loadData();
  }

  /** @return void **/
  async loadData() {
    let res = await this.RestManager.getVirtualExhibitions();
    this.busy = false;
    if (!res.success) {
      this.stateService.goToState('gs.exhibitions');
      return;
    }
    this.virtualExhibitions = res.exhibitions.map( exhibition => new VirtualExhibitionModel(exhibition));
    if (!this.$scope.$$phase) this.$scope.$digest();
  }
}
virtualExhibitionsCtrl.$inject = [
  '$scope',
  'stateService',
  '$rootScope',
  'RestManager',
  'tagEventService',
  'NgMetaDataService',
];
export default {
  template,
  controller: virtualExhibitionsCtrl,
};
