const template = require(`html-loader!./moderationCopyright.html`).default;
import {reportsRes, moderatorAction, moderatorReason} from "../moderationPage.data";
import MemberModel from "../../../api/models/member.model";
import ImageModel from "../../../api/models/image/image.model";

class moderationCopyrightCtrl {
  /**
   * @param {stateService} stateService
   * @param {RestManager} RestManager
   * */

  constructor($scope, stateService, $rootScope, RestManager, notificationAlert, Modals, MemberService) {
    'ngInject';
    this.title = "Copyright reports";
    this.subtitle = "";
    this.reports = [];
    this.allLoaded = false;
    this.limit= 3;
    this.start= 0;

    this.stateService = stateService;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.$scope = $scope;
    this.moderatorAction = moderatorAction;
    this.notificationAlert = notificationAlert;
    this.Modals = Modals;
    this.member = MemberService.getCurrentMember();

  }

  $onInit() {
    this.isAdmin = !!this.member.is_admin;
    this.loadMore();
  }

  /** @return void **/
  async loadMore() {
    if (!this.allLoaded && !this.busy) {
      this.busy = true;
      console.log('loadMore');
      let starting_member_id;
      if(this.reports.length){
        starting_member_id = this.reports[this.reports.length-1].member.id;
      }
      let res;
      if(this.isAdmin){
        res = await this.RestManager.getAdminReports({
          start:this.start,
          limit:this.limit,
          reason_id:moderatorReason.COPYRIGHT,
          starting_member_id
        });
      }else{
        res = await this.RestManager.getCopyrightReports({
          start:this.start,
          limit:this.limit,
          starting_member_id
        });
      }

      // const res = await reportsRes;
      if (!res.success) {
        this.stateService.goToDefaultState();
        return;
      }

      this.busy = false;
      if (!res.reports) {
        this.allLoaded = true;
        if (!this.$scope.$$phase) {this.$scope.$digest()}
        return;
      }

      if (res.reports.length !== this.limit) {
        this.allLoaded = true;
      }else{
        this.start += this.limit;
      }
      let items = res.reports.map((report) => {
        report.images = report.images.map((image) => {
          return new ImageModel(image);
        });
        return report;
      });
      this.reports = [...this.reports, ...items];
      this.details = res.details;
      console.log(this.reports);

      if (!this.$scope.$$phase) {this.$scope.$digest()}
    }
  }

  async report(image, action){
    const func = this.isAdmin ? this.RestManager.adminImageHandle.bind(this.RestManager) :this.RestManager.moderatorHandle.bind(this.RestManager);
    const res = await func({
      member_id: image.member_id,
      c_id: 0,
      reason_id: moderatorReason.COPYRIGHT,
      image_id: image.id,
      action,
    });

    if(!res.success){
      return;
    }
    if(this.isAdmin){
      image.feedbackMassage = action === moderatorAction.DISQUALIFY ? 'deleted' : 'dismissed';
    }
    image.feedbackComplete = true;
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  /** @return void **/
  async suspendMember(member){
    const confirm = await this.Modals.confirm(
      'Suspend member',
      `Are you want to Suspend member?`,
      'Suspend',
      'Cancel'
    );
    if (!confirm) return;
    const res = await this.RestManager.adminMemberHandle({
      member_id: member.id,
      reason_id: moderatorReason.COPYRIGHT,
      action: -1,
    });
    if(!res.success){
      this.notificationAlert.open('ERROR!');
      return;
    }
    let index = this.reports.findIndex((report) =>{
      return report.member.id === member.id;
    });
    this.reports.splice(index, 1);
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }
}
moderationCopyrightCtrl.$inject = [
  '$scope', 'stateService', '$rootScope', 'RestManager', 'notificationAlert', 'Modals', 'MemberService'
];
export default {
  template,
  controller: moderationCopyrightCtrl,
};
