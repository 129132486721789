class articlePageCtrl {

  constructor($scope,
              pageData,
              MemberService) {
    "ngInject";
    this.$scope = $scope;
    this.pageData = pageData;
    this.MemberService = MemberService;

    this.init();
  }

  /** @return void **/
  init() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('article');

    this.done = true;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
articlePageCtrl.$inject = [
  '$scope',
  'pageData',
  'MemberService'
];
export default articlePageCtrl;
