const template = require(`html-loader!./discover.html`).default;
import ImageModel from '../../../api/models/image/image.model';

class discoverComponent {
  /** @param {RestManager} RestManager **/

  images = [
    {
      "id": "1da29a425cbf59d1fbc47bb747540c52",
      "member_id": "64289d3f85261de5cf6648fe63809898",
      "member": {
        "user_name": "squirrel.rct"
      }
    },
    {
      "id": "6e07bfac6243b24023a7ca3822f70b37",
      "member_id": "7737cceb9554de4e38baad18d0cf6801",
      "member": {
        "user_name": "lorijroach"
      }
    },
    {
      "id": "7c9e22eeb15a0276c78d91db5f020b6d",
      "member_id": "4a0728f5e918c8cc5b910bf98510198b",
      "member": {
        "user_name": "justinsullivan01"
      }
    },
    {
      "id": "9b32c6c024a7994c215e1b6ce57eb33d",
      "member_id": "028d30b0b9f962f565430241314311b2",
      "member": {
        "user_name": "ianwhite2010"
      }
    },
    {
      "id": "af47290f06b695d9d643102e4d72b671",
      "member_id": "845894fd6e41b3947b2abeaa6c8f646e",
      "member": {
        "user_name": "stephenmcelligott"
      }
    },
    {
      "id": "b7525bb76c875c0d849d9dfa04db6e10",
      "member_id": "1166a72bc3211d3a539f3589b652da18",
      "member": {
        "user_name": "milnewendy"
      }
    },
    {
      "id": "c774e9764de2d72879bab437051d859b",
      "member_id": "b23e72acc15e17c6a6278819561e0add",
      "member": {
        "user_name": "GuruShots_user139"
      }
    }
  ]

  constructor(
    pageData,
    ExhibitionsManager,
    $scope,
    $state,
    $stateParams,
    $location,
    $transitions,
    RestManager,
    gsScrollTopService,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.busy = true;
    this.searchText;

    this.pageData = pageData;
    this.ExhibitionsManager = ExhibitionsManager;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$transitions = $transitions;
    this.RestManager = RestManager;
    this.gsScrollTopService = gsScrollTopService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.updateMetaTags({
      title:`Discover Amazing Photos`,
      description:` Explore award-winning photographs and discover new photography talent on GuruShots. Find inspiration and connect with photographers from around the globe.`,
      keywords:[ 'discover photography', 'award-winning photos', 'photography talent', 'GuruShots discover', 'inspiring photos' ]
    });

    this.gsScrollTopService.scrollTop();
    this.unsubscribe = this.$transitions.onSuccess({}, this.onTransitionSuccess.bind(this));
    this.searchText = this.$stateParams.search;
    this.init();
  }

  $onDestroy() {
    this.unsubscribe();
  }

  /** @return void **/
  async init() {
    this.member = this.MemberService.getCurrentMember();
    let bgImage = this.images[Math.floor(Math.random()*this.images.length)];
    // bgImage
    this.bgImage = new ImageModel(bgImage);
  }

  search() {
    if (!this.searchText.length) {
      this.$state.go('gs.discover.winners');
    } else {
      this.$state.go('gs.discover.search', { search: this.searchText });
    }
  }

  localSearch(text) {
    return this.RestManager.imageSearchAutocomplete(text).then(res => res.items);
  }

  onTransitionSuccess(transition) {
    // remove search on tab click
    if (transition.to().name.indexOf('gs.discover.') === 0 && transition.to().name !== 'gs.discover.search') {
      this.searchText = this.$location.search().s || '';
    }
    return true;
  }
}
discoverComponent.$inject = [
  'pageData',
  'ExhibitionsManager',
  '$scope',
  '$state',
  '$stateParams',
  '$location',
  '$transitions',
  'RestManager',
  'gsScrollTopService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: discoverComponent,
};
