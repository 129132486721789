/* globals angular, $ */
import {
  ActiveChallengeActionButtonType
} from "../../../../../modules/challenges/components/active-challenge-action-button/active-challenge-action-button.component";

const template = require(`html-loader!./challengesItemMobile.html`).default;

import badgeV2Mode from "../../../api/enums/badgeV2.enum";
import challengeEnum from "../../../api/enums/challenge.enum";

class challengesItemMobileController {

  constructor($element, Modals, $timeout, Toasts, $location, photoSwipe, gsModals, $cookies, $state, $rootScope, challengeService) {
    'ngInject';
    this.levelColors = ['#B3B3B3', '#2c7fa6', '#0A655C', '#853445', '#234F64', '#1C1C1C'];
    this.$element = $element;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.Toasts = Toasts;
    this.$location = $location;
    this.photoSwipe = photoSwipe;
    this.gsModals = gsModals;
    this.$cookies = $cookies;
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.badgeV2Mode = badgeV2Mode;
    this.challengeEnum = challengeEnum;
    this.challengeService = challengeService;
    this.ActiveChallengeActionButtonType = ActiveChallengeActionButtonType;
  }

  $onInit() {
    // share link
    this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/challenge/${
      this.challenge.url
    }/rank/top-photographer/${this.member.user_name}/${this.uString}`;
    // calc degree
    this.challenge.degree = this.challenge.member.ranking.total.exposure
      ? (this.challenge.member.ranking.total.exposure * 180) / 100 - 90
      : -90;

    this.$timeout(() => {
      // show popup
      if (window.screen.width <= 1200 && this.challenge.popup) {
        this.Toasts.open(
          this.challenge.popup.type,
          {
            challenge: this.challenge,
          },
          $(this.$element[0]).find(`.js-actions-${this.challenge.id}`)[0]
        );
      }
    });
  }

  preSubmit(event) {
    this.Modals.open('gsUploader', event, {
      target: 'challenge',
      challenge: this.challenge,
    });
  }

  onChallengeFinished(){
    this.challengeService.onChallengeFinished(this.challenge);
  }
}
challengesItemMobileController.$inject = [
  '$element', 'Modals', '$timeout', 'Toasts', '$location', 'photoSwipe', 'gsModals', '$cookies', '$state', '$rootScope', 'challengeService'
];
export default {
  template: template,
  controller: challengesItemMobileController,
  bindings: {
    challenge: '=',
    challengeIds: '<',
    index: '<',
  },
};
