import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, NgOptimizedImage} from '@angular/common';
import {GsLoader} from "./components/gs-loader/gs-loader";
import {Ng1BadgeV2ComponentWrapper} from "../upgradedComponents/badgeV2.directive";
import {GsTimerPipe} from "./pipes/gs-timer/gs-timer.pipe";
import { NgAvatarComponent } from './components/ng-avatar/ng-avatar.component';
import { NgMemberNameComponent } from './components/ng-member-name/ng-member-name.component';
import { NgImageComponent } from './components/ng-image/ng-image.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import {OrdinalSuffixPipe} from "./pipes/ordinal-suffix.pipe";
import { MuteButtonComponent } from './components/mute-button/mute-button.component';
import {LottieComponent} from "ngx-lottie";
import {ModalHelpButtonComponent} from "./components/modal-help-button/modal-help-button.component";
import {NgCountdownComponent} from "./components/countdown/countdown.component";
import {
  ProgressBarShieldGenericComponent
} from "./components/progress-bar/progress-bar-shield-generic/progress-bar-shield-generic.component";
import {CarouselComponent, CarouselItemElement} from './components/carousel/carousel.component';
import {CarouselItemDirective} from "./directives/carousel-item.directive";
import { NgRewardsComponent } from './components/ng-rewards/ng-rewards.component';
import { NgFollowComponent } from './components/ng-follow/ng-follow.component';
import { WinnerListItemComponent } from './components/winner-list-item/winner-list-item.component';
import { NgPhotoSwipeLinkComponent } from './components/ng-photo-swipe-link/ng-photo-swipe-link.component';
import { NgAdultFilterComponent } from './components/ng-adult-filter/ng-adult-filter.component';
import {NgExposureMeterComponent} from "./components/ng-exposure-meter/ng-exposure-meter.component";
import {UsersLimitComponent} from "./components/users-limit/users-limit.component";
import { ResourceIndicatorComponent } from './components/resource-indicator/resource-indicator.component';
import { PlayersAmountComponent } from './components/players-amount/players-amount.component';
import { NgResourcesComponent } from './components/ng-resources/ng-resources.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import {ToolTipDirective} from "./components/custom-tooltip/tooltip.directive";
import { ClaimButtonComponent } from './components/claim-button/claim-button.component';
import {MatButtonModule} from "@angular/material/button";
import {LocateButtonComponent} from "./components/locate-button/locate-button.component";

@NgModule({
  declarations: [
    GsTimerPipe,
    GsLoader,
    Ng1BadgeV2ComponentWrapper,
    NgAvatarComponent,
    NgMemberNameComponent,
    NgImageComponent,
    OrderByPipe,
    OrdinalSuffixPipe,
    MuteButtonComponent,
    ModalHelpButtonComponent,
    NgCountdownComponent,
    ProgressBarShieldGenericComponent,
    CarouselComponent,
    CarouselItemDirective,
    CarouselItemElement,
    NgRewardsComponent,
    NgFollowComponent,
    WinnerListItemComponent,
    NgPhotoSwipeLinkComponent,
    NgAdultFilterComponent,
    NgExposureMeterComponent,
    UsersLimitComponent,
    ResourceIndicatorComponent,
    PlayersAmountComponent,
    NgResourcesComponent,
    CustomTooltipComponent,
    ToolTipDirective,
    ClaimButtonComponent,
    PlayersAmountComponent,
    LocateButtonComponent
  ],
  imports: [
    CommonModule,
    LottieComponent,
    NgOptimizedImage,
    MatTooltipModule,
    MatButtonModule
  ],
  exports: [
    GsTimerPipe,
    GsLoader,
    Ng1BadgeV2ComponentWrapper,
    NgAvatarComponent,
    NgMemberNameComponent,
    NgImageComponent,
    OrderByPipe,
    OrdinalSuffixPipe,
    MuteButtonComponent,
    ModalHelpButtonComponent,
    NgCountdownComponent,
    CommonModule,
    NgOptimizedImage,
    ProgressBarShieldGenericComponent,
    CarouselComponent,
    CarouselItemDirective,
    NgExposureMeterComponent,
    UsersLimitComponent,
	  NgRewardsComponent,
    ResourceIndicatorComponent,
    ToolTipDirective,
    LocateButtonComponent
  ],
  providers: [
    CurrencyPipe
  ]
})
export class SharedModule { }
