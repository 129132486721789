import moment from 'moment';
const template = require(`html-loader!./adminEventsLeaderBoard.html`).default;
import eventLeaderBoardEnum from "../../../../../api/enums/eventLeaderBoard.enum";

class adminEventsLeaderBoardCtrl {

  constructor(RestManager, $scope, $state) {
    'ngInject';
    this.eventLeaderBoardStatuses = [
      eventLeaderBoardEnum.STATUSES.DRAFT.VALUE,
      eventLeaderBoardEnum.STATUSES.UPCOMING.VALUE,
      eventLeaderBoardEnum.STATUSES.ACTIVE.VALUE,
      eventLeaderBoardEnum.STATUSES.CLOSED.VALUE
    ];
    this.currantStatus = eventLeaderBoardEnum.STATUSES.ACTIVE.VALUE;
    this.busy = false;

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.$state = $state;
  }

  $onInit() {
    this.loadData();
  }

  /** @return void **/
  async loadData() {
    this.events = [];
    let res = await this.RestManager.getLbEvents(this.currantStatus);
    if (!res.success) return;
    this.events = res.events;
    for(let event of this.events){
      event.start_time = new Date(event.start_time * 1000);
      event.close_time = new Date(event.close_time * 1000);
      event.lock_time = new Date(event.lock_time * 1000);
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  editEvent(event) {
    this.$state.go('gs.gsAdmin.contentManager.events.editLbEvent', { event: event });
  }

  statusChanged(){
    this.loadData();
  }

  async duplicate(event){
    if(!this.busy){
      let resData = angular.copy(event);
      delete resData.id;
      resData.status = eventLeaderBoardEnum.STATUSES.DRAFT.VALUE;
      for(let dateName of ['start_time', 'close_time', 'lock_time']){
        if(resData[dateName]){
          resData[dateName] = moment(resData[dateName]).format('X');
        }
      }
      this.busy = true;
      let res = await this.RestManager.setLbEvent(resData);
      this.busy = false;
      if (!res.success) {
        //show error
        return;
      }
      this.currantStatus = eventLeaderBoardEnum.STATUSES.DRAFT.VALUE;
      this.loadData();
    }
  }
}
adminEventsLeaderBoardCtrl.$inject = [
  'RestManager', '$scope', '$state'
];
export default {
  template: template,
  controller: adminEventsLeaderBoardCtrl,
  bindings: {},
};
