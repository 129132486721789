function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  // **********
  // Routes
  // **********
  $stateProvider
    .state('gs.ads', {
      url: '/ads',
      params: {
        cUserName: {
          value: null,
          squash: true
        }
      },
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'adsPage'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.ads.view1', {
      url: '/view1',
      template: `<button ui-sref="gs.ads.view1.view2">go to view2</button><ui-view></ui-view>`,
    })
    .state('gs.ads.view1.view2', {
      url: '/view2',
      template: `test test test`,
    })
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
