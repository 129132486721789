import bgImage from './bgImage';
import gsImg from './gsImg';
import setDataAttr from './setDataAttr';
import like from './like';
import follow from './follow';
import gsShowOnScroll from './gsShowOnScroll';
import gsShare from './gsShare';
import socialShare from './socialShare';
import selectOnClick from './selectOnClick';
import clickControl from './clickControl';
import confirm from './confirm';
import gsUploader from './gsUploader';
import disableRightClick from './disableRightClick';
import hideInvisible from './hideInvisible';
import openModal from './openModal';
import contentEditable from './contentEditable';
import gsTimer from './gsTimer';
import protection from './protection';
import infinityScroll from "./infinityScroll/infinityScroll";
import highlighter from "./highlighter";


let moduleDirectives = angular.module('gsApp.directives', [])
    .directive('bgImage', bgImage)
    .directive('gsImg', gsImg)
    .directive('like', like)
    .directive('follow', follow)
    .directive('gsShowOnScroll', gsShowOnScroll)
    .directive('gsShare', gsShare)
    .directive('socialShare', socialShare)
    .directive('confirm', confirm)
    .directive('selectOnClick', selectOnClick)
    .directive('clickControl', clickControl)
    .directive('setDataAttr', setDataAttr)
    .directive('gsUploader', gsUploader)
    .directive('openModal', openModal)
    .directive('disableRightClick', disableRightClick)
    .directive('hideInvisible', hideInvisible)
    .directive('contenteditable', contentEditable)
    .directive('gsTimer', gsTimer)
    .directive('protection', protection)
    .directive('infinityScroll', infinityScroll)
    .directive('highlighter', highlighter)
  ;
export default moduleDirectives.name;
