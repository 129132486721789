import challengeEnum from "../../api/enums/challenge.enum";
import challengePageEnum from "./challengePage.enum";

class challengePageService {
  _challenge;

  constructor() {
    'ngInject';
  }

  set challenge (challenge){
    this._challenge = challenge;
    // this._challenge.type = challengeEnum.TYPE.BATTLE
  }

  getRankTabs(){
    let tabs = [];
    if(this._challenge.top_photo_enable){
      tabs.push(challengePageEnum.RANK.TAB.TOP_PHOTO)
    }
    if(this._challenge.guru_pick_enable){
      tabs.push(challengePageEnum.RANK.TAB.GURU_TOP_PICK)
    }
    if(this._challenge.top_photographer_enable){
      tabs.unshift(challengePageEnum.RANK.TAB.TOP_PHOTOGRAPHER)
    }
    return tabs;
  }
}
challengePageService.$inject = [];
export default challengePageService;
