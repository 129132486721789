const template = require(`html-loader!./achievementsCategory.html`).default;
import resolutionEnum from "../../../../../api/enums/resolution.enum";

class achievementsCategoryCtrl {

  constructor($stateParams, achievementsService) {
    'ngInject';
    this.stateParams = $stateParams;
    this.showAchievements = false;
    this.achievementsService = achievementsService;
  }

  $onInit() {
    console.log('preOpen', this.preOpen);
    this.isMobile = document.body.clientWidth <= resolutionEnum.WIDTH.MOBILE_ACHIEVEMENTS_CATEGORY;
    if(this.stateParams.achievementUniqueKey &&
      this.achievements.some(achievement => achievement.unique_key === this.stateParams.achievementUniqueKey)){
      this.achievementCategory.showAchievements = true;
    }
  }

  showCategoryAchievements(){
    if(this.achievementCategory.showAchievements){
      this.achievementCategory.showAchievements = false;
    } else {
      this.achievementsService.hideAllCategoryAchievements();
      this.achievementCategory.showAchievements = true;
    }
  }
}
achievementsCategoryCtrl.$inject = [
  '$stateParams', 'achievementsService'
];
export default {
  template: template,
  controller: achievementsCategoryCtrl,
  bindings: {
    achievementCategory: '<',
    achievements: '<',
    preOpen: '@',
  }
};
