import {gsIsMobile} from "../../helpers/gen.helper";
import {gsNotificationEnum} from "./gsNotifications.data";
import config from "../../gsApp.config";

class gsNotificationsService {
  /** @param {ModalsService} Modals **/

  constructor(
    Modals,
    $rootScope,
    stateService
  ) {
    'ngInject';
    this._notificationData;

    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.stateService = stateService;
    this.resetNotificationData();
  }

  get notificationData(){
    return this._notificationData;
  }

  set notificationData(notificationData){
    this._notificationData = notificationData;
  }

  resetNotificationData(){
    this._notificationData = {
      lists: {
        vote: {
          type: gsNotificationEnum.TYPE_VOTE,
          start: 0,
          limit: 50,
          items: [],
          loading: false,
          loadMore: true
        },
        activity: {
          type: gsNotificationEnum.TYPE_ACTIVITY,
          start: 0,
          limit: 50,
          items: [],
          loading: false,
          loadMore: true
        },
      },
      new: null,
      inited: false,
    };
  }

  /** @return boolean **/
  openModal(message, itemClicked = false) {
    const isMobile = gsIsMobile();
    switch (message.event_type) {
      case 'BONUS_GSPOINTS_FOLLOW_USERS':
      case 'BONUS_GSPOINTS_TWITTER':
      case 'BONUS_GSPOINTS_FACEBOOK':
      case 'CHALLENGE_LEVEL_UP':
        return false;
      case 'BROADCAST':
        switch (message.value) {
          case 'status_up':
            if (parseInt(message.link_2) < 2) {
              return false;
            }
            this.Modals.open('statusUp', null, {
              status: parseInt(message.link_2),
              message: message,
            });
            break;
          case 'auto_submit':
            this.Modals.open('autoSubmit', null, {
              message: message,
            });
            break;
          case 'event_lb_closed':
            if(!isMobile){
              this.Modals.open('lbEvent', null, message);
            }
            break;
          case 'closed':
            if(itemClicked){
              const challengeId = message.link_2;
              this.stateService.goToState('gs.challenges.myChallenges.completed',
                {challengeIdToScroll: challengeId}
              );
            }
            break;
          default:
            // parse json
            try {
              let jsonData = JSON.parse(message.link_2);
              message.c_id = jsonData.c_id;
              message.type = jsonData.type;
            } catch (e) {}
            // exhibition
            if (message.type === 'exhibition') {
              this.Modals.open('challengeIntro', null, { message });
              return false;
            }
            // broadcast
            this.Modals.open('broadcast', null, message);
        }
        return false;
      case 'SUCCESSFUL_SWAP':
        this.Modals.open('successfulSwap', null, {
          message: message,
        });
        return false;
      default:
        return true;
    }
  }
}
gsNotificationsService.$inject = [
  'Modals', '$rootScope', 'stateService'
];
export default gsNotificationsService;
