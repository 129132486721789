function config($stateProvider) {
  "ngInject";

  $stateProvider
    .state('gs.dev', {
      url: '/dev',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {template: '<ui-view></ui-view>'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.dev.battles', {
      url: '/battles',
      component: 'devBattlesComponent'
    })
    .state('gs.dev.battles.page', {
      url: '/page',
      /*component: 'appChallengeBattlesContainer'*/
      template: '<p>works!</p>'
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
