const template = require(`html-loader!./editSpotlight.html`).default;
import SpotlightModel from '../../../../../api/models/spotlight.model';

class editSpotlightCtrl {
  /**
   * @param {gsAdminManager} gsAdminManager
   * @param {ModalsService} Modals
   * @param {Toasts} Toasts
   * */

  constructor(gsAdminManager, Modals, $stateParams, $state, Toasts, $element, MemberService) {

    'ngInject';
    this.gsAdminManager = gsAdminManager;
    this.Modals = Modals;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.Toasts = Toasts;
    this.$element = $element;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.spotlight = new SpotlightModel(this.$stateParams.spotlight || {});
  }

  select(event) {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: {
        id: this.member.id
      },
      callback: images => {
        this.spotlight.image = images[0];
      }
    });
  }

  set() {
    let data = {
      method: 'set_spotlight',
      id: this.spotlight.id,
      image_id: this.spotlight.image.id,
      label: this.spotlight.label,
      title: this.spotlight.title,
      text: this.spotlight.text,
      action_text: this.spotlight.action.text,
      action_url: this.spotlight.action.url
    };
    this.gsAdminManager.set(data).then(res => {
      let data = {
        message: res.error_code || 'Done',
        class: !res.success ? 'error' : ''
      };
      this.Toasts.open('simpleMessage', {data}, this.$element);
    });
  }

  del() {
    let data = {
      method: 'del_spotlight',
      id: this.spotlight.id
    };
    this.gsAdminManager.set(data).then(res => {
      let data = {
        message: res.error_code || 'Done',
        class: !res.success ? 'error' : ''
      };
      this.Toasts.open('simpleMessage', {data}, this.$element);

      if (res.success) {
        this.$state.go('gs.gsAdmin.contentManager.spotlight', {}, {reload: true});
      }
    });
  }

  newSpotlight() {
    this.$state.go('gs.gsAdmin.contentManager.spotlightEdit', {spotlight: null}, {reload: true});
  }
}
editSpotlightCtrl.$inject = [
  'gsAdminManager', 'Modals', '$stateParams', '$state', 'Toasts', '$element', 'MemberService'
];
export default {
  template: template,
  controller: editSpotlightCtrl,
  bindings: {}
};
