const template = require(`html-loader!./moderationImages.html`).default;
import ImageModel from "../../../api/models/image/image.model";
import {moderatorReason, adultRes, adultReportType} from "../moderationPage.data";

class moderationImages {
  /**
   * @param {stateService} stateService
   * @param {RestManager} RestManager
   * */

  constructor(stateService, $rootScope, $scope, RestManager, $stateParams, RankingManager, $window) {
    'ngInject';
    this.images = [];
    this.allLoaded = false;
    this.start = 0;
    this.allSelected = false;

    this.stateService = stateService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$stateParams = $stateParams;
    this.RankingManager = RankingManager;
    this.$window = $window;
    this.moderatorReason = moderatorReason;
  }

  $onInit() {
    if(this.reason === moderatorReason.OFF_TOPIC) {
      this.moderationTitle = 'Off Topic';
      if(!this.$stateParams.offtopicData){
        this.stateService.goToDefaultState();
        return;
      }
      this.currentIndex = this.$stateParams.offtopicData.index;
      this.challenges = this.$stateParams.offtopicData.challenges;
      this.setChallenge();
    }

    if(this.reason === moderatorReason.SPAM) {
      this.moderationTitle = 'Spam';
      this.title = "SPAM";
      this.subtitle = "Select photos that are Spam. Spam photos are screenshots, illustrations, etc. Anything that is not a photo. Spam images will be deleted when you press Done.";
    }

    if(this.reason === moderatorReason.ADULT_CONTENT) {
      this.moderationTitle = 'Porn';
      this.title = "PORNOGRAPHY";
      this.subtitle = "Below is a list of images that are potentially poronographic. Selecting an image will delete it from the platform.<br>" +
        "Pornography is defined as showing any of the following:<br>" +
        "Male/female genitalia<br>" +
        "Sexual acts (even if no genitalia present)<br>" +
        "Sexual bodily fluids (even if no genitalia present)";
    }
    this.loadImages();
  }

  setChallenge(){
    this.challenge = this.challenges[this.currentIndex];
  }

  /** @return void */
  async loadImages() {
    if (!this.allLoaded && !this.busy) {
      this.busy = true;
      // const res = await adultRes;
      const res = await this.RestManager.getImageReports({
        reason_id: this.reason,
        c_id: this.reason === moderatorReason.OFF_TOPIC ? this.challenge.id : 0
      });
      if (!res.success) {
        return;
      }
      this.numberOfReports = res.number_of_reports;

      this.images = res.items.map((item) => {
        let img =  new ImageModel(item);
        if(this.reason === moderatorReason.ADULT_CONTENT) {
          img.adultType = adultReportType.APPROVED;
        }else{
          img.selected = false;
        }
        return img;
      });
      this.allSelected = false;
      this.busy = false;
      this.$window.scrollTo(0, 0);
      if (!this.$scope.$$phase) {this.$scope.$digest()}
    }
  }

  async done(){
    if(!this.doneBusy){
      this.doneBusy = true;
      let responseImages = {};
      let res;
      if(this.reason === moderatorReason.ADULT_CONTENT) {
        this.images.forEach( (image) => {
          responseImages[image.id] = {
            member_id:image.member_id,
            image_id:image.id,
            action:image.adultType
          };
        });
        res = await this.RestManager.moderatorHandleBulkAdultPorn({image_reports: responseImages});
      }else{
        this.images.forEach( (image) => {
          responseImages[image.id] = {
            member_id:image.member_id,
            image_id:image.id,
            action:image.selected ? 1 : 0
          };
        });
        res = await this.RestManager.moderatorHandleBulk({
          reason_id: this.reason,
          image_reports: responseImages,
          c_id: this.reason === moderatorReason.OFF_TOPIC ? this.challenge.id : 0
        });
      }

      this.doneBusy = false;
      if (!res.success) {
        return;
      }
      this.loadImages();
    }
  }

  nextChallenge(){
    this.currentIndex = this.currentIndex === this.challenges.length-1 ? 0 : (this.currentIndex + 1);
    this.setChallenge();
    this.loadImages();
  }

  previousChallenge(){
    this.currentIndex = this.currentIndex === 0 ? this.challenges.length-1 : (this.currentIndex - 1);
    this.setChallenge();
    this.loadImages();
  }

  selectAll(){
    this.allSelected = !this.allSelected;
    this.images = this.images.map((img) => {
      img.selected = this.allSelected;
      return img;
    });
  }

  // onComplete() {
  //   // console.log('oncomplete');
  //   this.busy = false;
  //   if (!this.$scope.$$phase) this.$scope.$digest();
  // }
}
moderationImages.$inject = [
  'stateService', '$rootScope', '$scope', 'RestManager', '$stateParams', 'RankingManager', '$window'
];
export default {
  template,
  controller: moderationImages,
  bindings: {
    reason: '<',
  }
};
