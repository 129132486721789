import articlePageCtrl from './article.page.ctrl';

function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  // redirect rules
  $urlRouterProvider
    .when('/articles', '/articles/')
    .when('/article', '/articles/')
  ;

  $stateProvider
    // Articles page
    .state('gs.articles', {
      url: '/articles',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'articles'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    // Article page
    .state('gs.article', {
      url: '/article',
      abstract: true,
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {
          template: `<ui-view class="p-articles__view"
                              ng-if="article.done"></ui-view>`,
          controller: articlePageCtrl,
          controllerAs: 'article'
        },
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.article.block', {
      url: '/:url',
      params: {
        url: {
          squash: true
        },
        article: null,
        articles: null
      },
      template: '<gs-article></gs-article>'
    })
    // Edit
    .state('gs.article.edit', {
      url: '/:url/edit',
      params: {
        url: {
          squash: true
        },
        article: null
      },
      template: '<article-edit></article-edit>'
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
