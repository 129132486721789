const template = require(`html-loader!./memberItem.html`).default;
import gsIconEnum from "../../api/enums/gsIcon.enum";

class memberItemCtrl {
  constructor(
    $scope,
    $rootScope,
    stateService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.stateService = stateService;
    this.gsIconEnum = gsIconEnum;
  }

  onClick() {
    this.stateService.goToState('gs.profile', {cUserName:this.member.user_name});
  }
}

memberItemCtrl.$inject = [
  '$scope',
  '$rootScope',
  'stateService'
];

export default {
  template: template,
  controller: memberItemCtrl,
  bindings: {
    member: '<'
  }
};
