const template = require(`html-loader!./teamsInfo.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';

class teamsInfoCtrl {

  constructor(
    $scope,
    $rootScope,
    RestManager,
    $timeout, stateService,
    $stateParams,
    $location,
    notificationAlert,
    Modals,
    socketService,
    teamsService,
    gsScrollTopService,
    tagEventService,
    MemberService,
  ) {
    'ngInject';
    this.busy = true;

    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.stateService = stateService;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.notificationAlert = notificationAlert;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.socketService = socketService;
    this.teamsService = teamsService;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.init();
    this.gsScrollTopService.scrollTop();
  }

  async init(){
    if (this.$stateParams.teamId) {
      if (!await this.getTeam(this.$stateParams.teamId)) {
        this.stateService.goToDefaultState();
        return;
      }

      this.busy = false;
      this.setDetailItems();
      this.setBackState();

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    } else {
      this.stateService.goToDefaultState();
    }
    this.tagEventService.sendPageViewEvent({title: 'Teams - info'});
    this.joinBy = this.$stateParams.joinBy;
    console.log('joinBy', this.joinBy);
  }

  setBackState(){
    if (this.state.status === teamEnum.STATE.JOINED) {
      if (['gs.teams.home.leaderboard'].includes(this.stateService.previousState)) {
        this.backState = 'gs.teams.home.leaderboard';
        this.backBtnText = 'Leaderboard'; /* TODO: enum */
      } else {
        this.backState = 'gs.teams.home.myteam';
        this.backBtnText = 'My Team'; /* TODO: enum */
      }

      return;
    }

    this.backState = 'gs.teams.join';
    this.backBtnText = 'View Teams List'; /* TODO: enum */
  }

  async getTeam(){
    const res = await this.RestManager.getTeam(this.$stateParams.teamId);
    if (!res.success || !res.data || !res.data.teams || !res.data.teams[0] || !res.state) {
      return false;
    }
    this.team = new TeamModel(res.data.teams[0]);
    return true;
  }

  setDetailItems() {
    this.teamDetailTypes = [
      teamEnum.DETAILS.TYPES.SCORE,
      teamEnum.DETAILS.TYPES.WIN,
      teamEnum.DETAILS.TYPES.MEMBERS,
      teamEnum.DETAILS.TYPES.STATUS,
      teamEnum.DETAILS.TYPES.LANGUAGE,
      teamEnum.DETAILS.TYPES.COUNTRY
    ];
  }

  back(){
    this.stateService.goToState(this.backState, {}, false);
  }
}
teamsInfoCtrl.$inject = [
  '$scope',
  '$rootScope',
  'RestManager',
  '$timeout',
  'stateService',
  '$stateParams',
  '$location',
  'notificationAlert',
  'Modals',
  'socketService',
  'teamsService',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
];

export default {
  template: template,
  controller: teamsInfoCtrl,
  bindings: {
    state: '<',
    myTeam: '<',
    configData: '<',
  }
};
