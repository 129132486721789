import TweenLite from 'gsap/TweenLite';
import 'gsap/CSSPlugin';
const template = require(`html-loader!./vote.html`).default;
import environment from '../../../gsApp.environment';
import ImageModel from '../../../api/models/image/image.model';
import normalizeWheel from '../../../vendors/normalizeWheel/normalizeWheel';
import voteAnimation from '../../../../../../assets/animations/vote.json';
import {MediatorMessageKey} from "../../../../../core/services/mediator.service";
import {Subject} from "rxjs";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";
import _ from "lodash";
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class voteModalCtrl {
  /**
   * @param {VoteManager} VoteManager
   * @param {ModalsService} Modals
   * */

  subject = new Subject();

  constructor(
    $mdDialog,
    VoteManager,
    $element,
    $timeout,
    Modals,
    $scope,
    $rootScope,
    $state,
    deviceDetector,
    tagEventService,
    // AdsService,
    MediatorService,
    mixpanelService,
    MemberService
  ) {
    'ngInject';
    this.busy = true;
    this.loading = true;
    this.full = false;
    this.allLoaded = false;
    this.showVotedImages = true;
    this.challenge = {
      vote_minimum_players: undefined
    };
    this.photos = [];
    this.photosBank = [];
    this.voted = {};
    this.viewed = {};
    this.mission = null;
    this.itemsLimit = 25;
    this.limit = 50;
    this.imgHeight = 400;
    this.state = 'greeting';
    this.battleGreetingText = undefined;
    this.guruPicksCountMax = 100;
    this.wheelSpeed = 160;
    this.maxImages = 300;
    this.fullSizedImageIndex = null;

    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.VoteManager = VoteManager;
    this.$element = $element;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$scope = $scope;
    this.$state = $state;
    this.deviceDetector = deviceDetector;
    this.tagEventService = tagEventService;
    // this.AdsService = AdsService;
    this.mediatorService = MediatorService;
    this.mixpanelService = mixpanelService;
    this.member = MemberService.getCurrentMember();
    this.mixpanelService = mixpanelService;

    this.initGallery = _.throttle(this.initGallery.bind(this), 250);
    this.onKeyDown = _.throttle(this.onKeyDown.bind(this), 100);
    this.onWheel = this.onWheel.bind(this);
    this.goToClosestSlide = this.goToClosestSlide.bind(this);
  }

  $onInit() {
    if (!this.data.challenge) {
      this.close();
      return;
    }
    this.challenge = _.clone(this.data.challenge);
    this.mission = _.clone(this.data.mission);
    // is device mobile and touch
    this.isTouch = $(document.documentElement).hasClass('mobile')
      && $(document.documentElement).hasClass('touch');
    this.isMobile = document.body.clientWidth <= 768;
    this.isIE = this.deviceDetector.browser === 'ie';
    // Photos div
    this.gallery = $(this.$element[0]).find('.modal-vote__photos');
    // get data
    this.getVoteData();
    // this.AdsService.hideAd();
    this.mediatorService.broadcast(MediatorMessageKey.STOP_AUTO_UPDATE_ACTIVE_CHALLENGES);
    this.mediatorService.on(MediatorMessageKey.AI_REPORT).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((data)=> {
      const token = data.data;
      const image = this.photos.find(photo => photo.token === token);
      if (image) {
        image.isAiImage = true;
      }
    });
  }

  hideGreetingMessage(){
    if (!this.votingStartIn) {
      this.state = 'photos';
      this.eventMixPanelAction(
        {
          ui_name:mixpanelEventsEnum.UI_NAME.VOTE_INTRO,
          ui_action:mixpanelEventsEnum.UI_ACTION.CLICK,
          data:{
            ui_state: this.exposure && this.exposure.exposure_factor ?  this.exposure.exposure_factor : undefined  //exposure
          }
        }
      )
    }
  }

  $onDestroy() {
    // this.AdsService.showAd();
    $(window).off('resize', this.initGallery);
    document.removeEventListener(normalizeWheel.getEventType(), this.onWheel);
    document.onkeydown = null;
    if (this.Blazy) {
      this.Blazy.destroy();
    }
    this.subject.next();
    this.subject.complete();
  }

  /** @type void **/
  async getVoteData() {
    this.votingStartIn = false;
    const params = {
      c_id: this.challenge.id,
      mode: this.data.mode,
      url: this.challenge.url,
    };
    const res = await this.VoteManager.getVoteData(params);
    if (!res.success) {
      this.close();
      return;
    }

    if (!res.challenge) {
      if (this.hadImages) {
        this.busy = false;
        return {
          cantContinueVoting: true
        };
      } else {
        this.close();
      }
      return;
    }

    this.challenge = new NgChallengeModel(res.challenge);
    this.voteLimit = res.voting.vote_limit;
    this.mode = res.voting.mode;
    this.event_type = res.voting.event_type;
    this.exposure = res.voting.exposure;
    this.guruPicksCount = res.voting.guru_picks_count || 0;

    // guru Top Pick mode
    if (this.mode === 'guru_top_pick' || !res.images.length) {
      this.allLoaded = true;
    }

    // google vPageView
    this.tagEventService.sendPageViewEvent({title: 'Vote | GuruShots'});

    // calc degree
    if (this.exposure) {
      this.challenge.degree = this.exposure ? (this.exposure.exposure_factor * 180) / 100 - 90 : -90;
      this.initialExposureFactor = _.clone(this.exposure.exposure_factor);
    }

    // voting not started
    if (this.challenge.players < this.challenge.vote_minimum_players) {
      this.votingStartIn = true;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }

    // show
    res.images = res.images.map(image => new ImageModel(image));
    res.images = _.shuffle(res.images);
    this.photos = res.images;
    this.hadImages = true;
    if (environment.env === 'production') {
      this.findDuplicates();
    }
    this.busy = false;
    this.imagesLimit = res.voting.images_limit;

    // init grid gallery
    if (window.screen.availWidth > 768 && !this.full) {
      this.$timeout(() => this.initGallery());
    }

    // lazy load
    this.$timeout(() => {
      this.Blazy = new window.Blazy({
        container: '.modal-vote__photos-wrap',
        success: (ele)=>{
          const token = $(ele).data('gstoken');
          if(token){
            this.viewed[token] = token;
          }
        }
      });
    });

    this.$timeout(() => {
      this.loading = false;
    }, 1000);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  eventMixPanelAction({ui_name, ui_action ,data = {}}) {
    this.mixpanelService.track(
      {
        event: mixpanelEventsEnum.EVENT_NAME.VOTE_SESSION,
        data: {
          ui_action: ui_action,
          ui_name: ui_name,
          challenge_id: this.challenge.id,
          ...data
        }
      }
    );
  }

  findDuplicates() {
    _.difference(this.photos, _.uniqBy(this.photos, 'id')).map(image => {
    });
  }

  countMaxScroll() {
    this.$timeout(() => {
      this.availableHeight = $('.modal-vote__photos-wrap').height();
      this.slideHeight = $('.modal-vote__photo').height();
      this.factor = this.availableHeight / this.slideHeight;
      this.maxScroll = ($('.modal-vote__photos').height() - this.availableHeight) / this.factor;
    });
  }

  initGallery() {
    if (this.full) {
      this.$timeout(() => {
        this.Blazy.revalidate();
      }, 1000);
      return;
    }
    if (this.isMobile) {
      this.$timeout(() => {
        this.Blazy.revalidate();
      }, 1000);
      return;
    }
    $(window).on('resize', this.initGallery);
    this.$timeout(() => {
      this.gallery.collagePlus({
        allowPartialLastRow: true,
        targetHeight: this.imgHeight,
      });
      this.$timeout(() => {
        this.Blazy.revalidate();
      }, 1000);
    });
  }

  initSlider(slide = 0) {
    if (this.isTouch || this.isIE) {
      this.$timeout(() => {
        const elm = document.getElementById('vote-photo-' + slide);
        $('.modal-vote__photos-wrap').scrollTop(elm.offsetTop);
        this.Blazy.revalidate();
      }, 550);
      return;
    }
    this.$timeout(() => {
      // init values
      this.currentSlide = 0;
      this.currentSpin = 0;
      this.availableHeight = $('.modal-vote__photos-wrap').height();
      this.slideHeight = $('.modal-vote__photo').height();
      this.factor = this.availableHeight / this.slideHeight;
      this.maxScroll = ($('.modal-vote__photos').height() - this.availableHeight) / this.factor;
      // on wheel
      document.removeEventListener(normalizeWheel.getEventType(), this.onWheel);
      document.addEventListener(normalizeWheel.getEventType(), this.onWheel,{ passive: false });
      document.onkeydown = this.onKeyDown;
      // go to slide
      this.goTo(slide, 0);
    }, 100);
  }

  destroySlider() {
    this.$timeout(() => {
      document.onkeydown = null;
      document.removeEventListener(normalizeWheel.getEventType(), this.onWheel);
      this.gallery.removeAttr('style');
      this.$timeout(() => this.triggerResize());
    });
  }

  triggerResize() {
    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  }

  goToClosestSlide(diff) {
    this.countMaxScroll();
    let closest = Math.round((this.factor * this.currentSpin) / this.availableHeight);
    if (diff) {
      closest += diff;
    }
    this.goTo(closest);
  }

  goTo(slideNumber, speed = 0.5) {
    if (slideNumber < 0 || this.currentSlide === slideNumber) {
      this.fixLazy(0);
      return;
    }
    // end
    if (slideNumber > this.photos.length - 1) {
      this.fixLazy(this.photos.length - 1);
      return;
    }
    this.fullSizedImageIndex = slideNumber;
    this.currentSlide = slideNumber;
    this.currentSpin = this.slideHeight * slideNumber;
    $('.modal-vote__photos-wrap').scrollTop(this.currentSpin * this.factor);
    this.fixLazy(this.currentSlide);
  }

  fixLazy(index) {
    this.$timeout(() => {
      this.Blazy.load($('#vote-photo-' + index + ' .modal-vote__photo__img--full'), true);
      this.Blazy.load($('#vote-photo-' + (index + 1) + ' .modal-vote__photo__img--full'), true);
      this.Blazy.load($('#vote-photo-' + (index - 1) + ' .modal-vote__photo__img--full'), true);
    });
  }

  onWheel(event) {
    event.preventDefault();
    const norm = normalizeWheel(event);
    this.currentSpin += norm.spinY * this.wheelSpeed;
    // validate
    if (this.currentSpin < 0) {
      this.currentSpin = 0;
    }
    if (this.currentSpin > this.maxScroll) {
      this.currentSpin = this.maxScroll;
    }

    // animate scroll
    TweenLite.to('.modal-vote__photos', 0.4, {
      y: -this.currentSpin * this.factor,
      overflow: 5, // preexisting
      onComplete: this.goToClosestSlide,
    });
  }

  onKeyDown(event) {
    event.preventDefault();
    switch (event.keyCode) {
      case 38: // up
        this.goToClosestSlide(-1);
        return false;
      case 40: // down
        this.goToClosestSlide(+1);
        return false;
      case 86: // "v"
        const index = Math.round((this.factor * this.currentSpin) / this.availableHeight);
        this.vote(this.photos[index]);
        if (!this.$scope.$$phase) this.$scope.$digest();
        return false;
    }
    return true;
  }

  vote(image, animate = true, voteForall = false) {
    if (image.voted) {
      image.voted = false;
      delete this.voted[image.token];
      if (this.exposure) {
        this.exposure.exposure_factor -= this.exposure.vote_ratio;
        ((this.data.challenge.member.ranking ??= {}).total ??= {}).exposure -= this.exposure.vote_ratio;
        this.data.challenge.member.ranking.total.exposure = this.data.challenge.member.ranking.total.exposure < 0 ? 0 : this.data.challenge.member.ranking.total.exposure;
      }
      this.guruPicksCount -= 1;
      image.animation = false;
      if (this.mission && this.mission.completed > this.data.mission.completed) {
        this.mission.completed -= 1;
        this.mission.progress = (this.mission.completed * 100) / this.mission.required;
      }
    } else {
      if (this.mission && this.mission.completed < this.mission.required) {
        this.mission.completed += 1;
        this.mission.progress = (this.mission.completed * 100) / this.mission.required;
      }
      if (Object.keys(this.voted).length >= this.voteLimit) return;
      image.voted = true;
      image.animation = true;
      this.voted[image.token] = image.token;
      if (this.exposure) {
        this.exposure.exposure_factor += this.exposure.vote_ratio;
        if (this.data.challenge.member && this.data.challenge.member.ranking && this.data.challenge.member.total) {
          this.data.challenge.member.ranking.total.exposure += this.exposure.vote_ratio;
          this.data.challenge.member.ranking.total.exposure = this.data.challenge.member.ranking.total.exposure > 100 ? 100 : this.data.challenge.member.ranking.total.exposure;
        }
      }
      this.guruPicksCount += 1;
      // animation
      setTimeout(() => {
        if (!animate) return;
        const element = document.querySelector(`.modal-vote__photo__img__animation--${image.id}`);
        if (!element) return;
        window.bodymovin.loadAnimation({
          container: element,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData: voteAnimation,
          rendererSettings: {
            scaleMode: 'noScale',
            clearCanvas: true,
            progressiveLoad: true,
          },
        });
      });
    }

    if (this.exposure) {
      if (this.exposure.exposure_factor >= 100 && this.initialExposureFactor < 100
        && this.state !== 'message') {
        this.showExposureFullMessage()
      }

      if (this.exposure.exposure_factor < this.initialExposureFactor) {
        this.exposure.exposure_factor = window.angular.copy(this.initialExposureFactor);
      }

      if (this.exposure.exposure_factor < 0) {
        this.exposure.exposure_factor = 0;
      }

      if (this.exposure.exposure_factor > 100) {
        this.exposure.exposure_factor = 100;
      }
    }
    // count length for voted images
    this.votedLength = Object.keys(this.voted).length;
  }

  showExposureFullMessage(){
    this.state = 'message';
    this.eventMixPanelAction(
      {
        ui_name:mixpanelEventsEnum.UI_NAME.VOTE_FULL,
        ui_action: mixpanelEventsEnum.UI_ACTION.APPEAR
      }
    )
  }

  showExposureNotFullMessage(){
    this.state = 'message';
    this.eventMixPanelAction(
      {
        ui_name:mixpanelEventsEnum.UI_NAME.VOTE_EXPOSURE,
        ui_action: mixpanelEventsEnum.UI_ACTION.APPEAR
      }
    )
  }

  exposureNotFullContinueVoting(){
    this.continueVoting();
    this.eventMixPanelAction(
      {
        ui_name:mixpanelEventsEnum.UI_NAME.VOTE_EXPOSURE_KEEP,
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK
      }
    )
  }

  exposureNotFullDoneVoting(){
    this.eventMixPanelAction(
      {
        ui_name:mixpanelEventsEnum.UI_NAME.VOTE_EXPOSURE_DONE,
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK
      }
    )
    // close because already submited
    this.close();
  }
  exposureFullDoneVoting(){
    this.eventMixPanelAction(
      {
        ui_name:mixpanelEventsEnum.UI_NAME.VOTE_FULL_GREAT,
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK
      }
    )
    this.submit();
  }

  submitEnabled() {
    return Object.keys(this.voted).length;
  }

  /** @type void **/
  async submit() {
    if (!Object.keys(this.voted).length) {
      return;
    }

    this.busy = true;

    // if not signIn
    if (!this.member.logged_in) {
      this.Modals.open('login', null, { signUp: true });
      return;
    }

    if (this.full) {
      this.switchView(0);
    }

    const params = {
      c_id: this.challenge.id,
      tokens: Object.keys(this.voted),
      viewed_tokens: Object.keys(this.viewed)
    };
    let captchaKey = environment.captchaKey;
    if(grecaptcha){
      params.c_token = await grecaptcha.execute(captchaKey, {action: 'vote'});
    }

    let res;
    switch (this.mode) {
      case 'guru_pick':
        res = await this.VoteManager.submitGuruPicks(params);
        break;
      case 'guru_top_pick':
        res = await this.VoteManager.submitGuruTopPicks(params);
        break;
      default:
        res = await this.VoteManager.submitVotes(params);
    }

    if (!res.success) {
      return;
    }

    if (res.vote_score) {
      this.voteScore = res.vote_score;
    }

    // update parent scope
    if (this.data.challenge.id === this.challenge.id) {
      switch (this.mode) {
        case 'guru_top_pick':
          const image = new ImageModel({
            token:Object.keys(this.voted)[0]
            // id: Object.keys(this.voted)[0],
            // member_id: Object.values(this.voted)[0],
          });
          this.data.challenge.guru_top_pick = image;
          break;
        default:
          if (this.exposure) {
            ((this.data.challenge.member.ranking ??= {}).total ??= {}).exposure = this.exposure.exposure_factor;
            this.mediatorService.broadcast(MediatorMessageKey.UPDATE_EXPOSURE, this.exposure.exposure_factor);
          }
          this.data.challenge.guru_pick_count = this.guruPicksCount;
      }
    }

    this.voted = {};
    const exposure = (this.exposure && this.exposure.exposure_factor) ? this.exposure.exposure_factor : 0;
    if (this.initialExposureFactor === 100 || exposure >= 100) {
      this.close();
    } else {
      this.showExposureNotFullMessage();
    }

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  continueVoting() {
    this.refresh();
  }

  async refresh() {
    this.photos = [];
    this.photosBank = [];
    this.voted = {};
    this.viewed = {};
    this.full = false;
    this.busy = true;
    this.itemsLimit = 25;
    this.loading = true;
    this.allLoaded = false;
    this.battleGreetingText = undefined;
    $('.modal-vote__photos-wrap').scrollTop(0);
    // get photos
    const data = await this.getVoteData();
    if(data && data.cantContinueVoting){
      this.close();
      // this.state = 'message';
      return;
    }

    this.state = 'photos';
  }

  report(event, image) {
    this.Modals.open('report', event, {
      image: image,
      c_id: this.challenge.id,
      noSimilar: true,
      showOffTopic: this.challenge.enable_off_topic
    });
  }

  close() {
    if (Object.keys(this.voted).length > 0) {
      let confirm = this.$mdDialog
        .confirm()
        .title('Leave Voting?')
        .textContent('Your votes will not be submitted if you leave')
        .ariaLabel('Close')
        .ok('Close')
        .cancel('Back');
      this.$mdDialog.show(confirm).then(() => {
        if (this.tools) {
          this.tools.close();
          this.mediatorService.broadcast(MediatorMessageKey.START_AUTO_UPDATE_ACTIVE_CHALLENGES);
          return;
        }
        this.$state.go('gs.challenges.myChallenges.joined', { force: true }, { reload: true });
      });
      return;
    }

    if (this.tools) {
      this.tools.close();
      this.mediatorService.broadcast(MediatorMessageKey.START_AUTO_UPDATE_ACTIVE_CHALLENGES);
      return;
    }
    this.$state.go('gs.challenges.myChallenges.joined', { force: true }, { reload: true });
  }

  switchView(index) {
    // switch state loader
    if (!this.busy) {
      this.busy = true;
      this.$timeout(() => (this.busy = false), 500);
    }
    this.full = !this.full;
    if (this.full) {
      this.fullSizedImageIndex = index;
    } else {
      this.fullSizedImageIndex = null;
    }
    if (this.full) {
      $('.modal-vote__photos-wrap').scrollTop(0);
      this.$timeout(() => {
        this.initSlider(index);
      });
    } else {
      this.destroySlider();
      this.$timeout(() => {
        const elm = document.getElementById('vote-photo-' + index);
        $('.modal-vote__photos-wrap').scrollTop(elm.offsetTop - 200);
      });
    }
  }

  voteForAll() {
    for (const photo of this.photos) {
      this.vote(photo, false, true);
    }
  }
}

voteModalCtrl.$inject = [
  '$mdDialog',
  'VoteManager',
  '$element',
  '$timeout',
  'Modals',
  '$scope',
  '$rootScope',
  '$state',
  'deviceDetector',
  'tagEventService',
  // 'AdsService',
  'MediatorService',
  'mixpanelService',
  'MemberService'
];

export default {
  template: template,
  controller: voteModalCtrl,
  bindings: {
    data: '=data',
    tools: '<',
  },
};
