function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  // **********
  // Routes
  // **********
  $stateProvider
    .state('gs.profileSettings', {
      url: '/profile_settings',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'profileSettings',},
        'footer@gs': {component: 'gsFooter'}
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
