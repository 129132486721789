import LoaderEnum from "../../../../api/enums/loader.enum";

const template = require(`html-loader!./profileAchievementsChallenges.html`).default;
import restEnum from "../../../../api/enums/rest.enum";
import PostRequest from "../../../../api/models/requests/post.request";
import gsChallengeEnum from "../../../../api/enums/gsChallenge.enum";
import GetMemberAchievementsChallengesHistoryResponse
  from "../../../../api/models/responses/GetMemberAchievementsChallengesHistory.response";

class profileAchievementsChallengesCtrl {

  constructor($scope, $rootScope, RestManager, infinityScrollService, MemberService) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.infinityScrollService = infinityScrollService;
    this.MemberService = MemberService;
    this.gsChallengeEnum = gsChallengeEnum;
    this.LoaderEnum = LoaderEnum;

    this.start = 0;
    this.limit = 20;
  }

  $onInit() {
    this.init();
  }

  async init(){
    this.showLoader = true;
    await this.getData(false);
    this.showLoader = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
    this.infinityScrollService.initWindowScrollEvent();
    this.$rootScope.$on('windowInfinityScroll', this.onLoad.bind(this));
  }

  $onDestroy() {
    this.infinityScrollService.offWindowScrollEvent();
  }

  onLoad(){
    if (
      this.items
      && this.loadMore
      && !this.busy
    ) {
      console.log('load more');
      this.getData();
    }
  }

  async getData(digest = true) {
    this.busy = true;
    const responseData = new GetMemberAchievementsChallengesHistoryResponse(
      await this.RestManager.postRequest(
        new PostRequest({
          endpoint: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_CHALLENGES_HISTORY.NAME,
          data: {
            member_id: this.MemberService.profilePageMember.id,
            start: this.start,
            limit: this.limit
          }
          // ,mockState: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_CHALLENGES_HISTORY.STATES["5"],
        })
      )
    );
    if(responseData.success){
      let newItems = responseData.items;
      this.loadMore = newItems.length === this.limit;
      this.items = this.start === 0 ? newItems : [...this.items, ...newItems];
      this.start += this.limit;
      console.log(this.items);
      this.busy = false;
      if(digest){
        if (!this.$scope.$$phase) this.$scope.$digest();
      }
    }
  }
}
profileAchievementsChallengesCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'infinityScrollService', 'MemberService'
];

export default {
  template: template,
  controller: profileAchievementsChallengesCtrl,
};
