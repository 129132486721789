import LoaderEnum from "../../../../api/enums/loader.enum";

const template = require(`html-loader!./profileAchievementsAchievements.html`).default;
import PostRequest from "../../../../api/models/requests/post.request";
import restEnum from "../../../../api/enums/rest.enum";
import GetMemberAchievementsByLevelResponse
  from "../../../../api/models/responses/GetMemberAchievementsByLevel.response";
import LocalStorageEnum from "../../../../api/enums/localStorage.enum";

class profileAchievementsAchievementsCtrl {

  constructor($scope, $rootScope, RestManager, achievementsService, brazeService, localStorageService, $window, $stateParams, $timeout, MemberService) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.achievementsService = achievementsService;
    this.stateParams = $stateParams;
    this.brazeService = brazeService;
    this.window = $window;
    this.$timeout = $timeout;
    this.localStorageService = localStorageService;
    this.LoaderEnum = LoaderEnum;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();


    this.busy = true;
  }

  $onInit() {
    if (this.member.logged_in) {
      this.sendBrazeEvent();
    }
    this.init();
  }

  async init(){
    await this.getData();
    this.scrollToCategory();
  }

  scrollToCategory(){
    if(this.stateParams.achievementUniqueKey){
      const achievementCategoryToScrollTo = this.achievementsService.achievementCategories.find((achievementCategory) => {
        return achievementCategory.achievements.some(achievement =>
          achievement.unique_key === this.stateParams.achievementUniqueKey);
      });
      if(achievementCategoryToScrollTo){
        this.$timeout(() => {
          const categoryClass = `.${this.stateParams.achievementUniqueKey.replace(/\./g, '_')}`;
          console.log('categoryClass', categoryClass);
          $(window).scrollTo(categoryClass, 1000, {offset: -100});
        }, 250);
      }
    }
  }

  sendBrazeEvent(){
    const isFirstAchievementPageView =
      this.localStorageService.getDataByKey({key:LocalStorageEnum.KEY.FIRST_ACHIEVEMENT_PAGE_VIEW});

    if (!isFirstAchievementPageView){
      this.brazeService.sendFirstAchievementPageViewEvent();
      this.localStorageService.save({
        key: LocalStorageEnum.KEY.FIRST_ACHIEVEMENT_PAGE_VIEW,
        value: true
      });
    }
  }

  async getData() {
    const responseData = new GetMemberAchievementsByLevelResponse(
      await this.RestManager.postRequest(
        new PostRequest({
          endpoint: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_BY_LEVEL.NAME,
          data: {
            member_id: this.MemberService.profilePageMember.id
          }
          // ,mockState: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_BY_LEVEL.STATES["5"],
        })
      )
    );
    this.busy = false;
    if(!responseData.success){return}
    this.achievementsService.achievementCategories = responseData.categories;
    this.achievementsService.achievementsInfoHtml = responseData.info_html;
    this.info_html = responseData.info_html;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }
}
profileAchievementsAchievementsCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'achievementsService', 'brazeService', 'localStorageService', '$window', '$stateParams', '$timeout', 'MemberService'
];
export default {
  template: template,
  controller: profileAchievementsAchievementsCtrl,
};
