import { Injectable } from '@angular/core';
// import {NgLocalStorageEnum} from "../models/enums";
// import {NgLocalStorageService} from "./ng-local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    // private ngLocalStorageService: NgLocalStorageService
  ) { }

  onNewSession(): void {
    // if(!this.ngLocalStorageService.getMemberDataByKey(NgLocalStorageEnum.FIRST_SESSION_PASSED)){
    //   this.ngLocalStorageService.saveMemberDataByKeyValue(NgLocalStorageEnum.FIRST_SESSION_PASSED,true)
    // }
    window.location.reload();
  }
}
