const template = require(`html-loader!./topPhoto.html`).default;
const noItemsDefaultTemplate = require(`html-loader!./noItems/default.html`).default;
const noItemsFollowingTemplate = require(`html-loader!./noItems/following.html`).default;
const noItemsFriendsTemplate = require(`html-loader!./noItems/friends.html`).default;
const noItemsMyPhotosTemplate = require(`html-loader!./noItems/my_photos.html`).default;
const noItemsFriendsLinkTemplate = require(`html-loader!./noItems/friends.link.html`).default;

import ImageModel from '../../../../api/models/image/image.model';

class rankTopPhotoCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {ChallengesManager} ChallengesManager
   * @param {RankingManager} RankingManager
   * @param {Toasts} Toasts
   * */

  constructor(
    $timeout,
    $document,
    rankFilters,
    $stateParams,
    $location,
    Modals,
    Facebook,
    ChallengesManager,
    RankingManager,
    Toasts,
    $element,
    $scope,
    $rootScope,
    tagEventService,
    MemberService,
  ) {
    'ngInject';
    this.templates = {
      default: noItemsDefaultTemplate,
      following: noItemsFollowingTemplate,
      friends: noItemsFriendsTemplate,
      my_photos: noItemsMyPhotosTemplate,
      friendsLink: noItemsFriendsLinkTemplate,
    };
    this.imgHeight = 500;
    this.start = 0;
    this.limit = 50;
    this.items = [];
    this.destroyed = false;
    this.allLoaded = false;
    this.loading = false;
    this.inited = false;
    this.noItems = null;

    this.$timeout = $timeout;
    this.$document = $document;
    this.rankFilters = rankFilters;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.Modals = Modals;
    this.Facebook = Facebook;
    this.ChallengesManager = ChallengesManager;
    this.RankingManager = RankingManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.loggedinMember = MemberService.getCurrentMember();

    this.onJgComplete = this.onJgComplete.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
  }

  $onInit() {
    // page view
    this.tagEventService.sendPageViewEvent({title: 'Challenge Top Photo'});

    // events
    this.offOnFilterChanged = this.$rootScope.$on('onFilterChanged', this.onFilterChanged);
    this.$rootScope.$emit('onChangedRankPage');

    // filter
    this.filter = this.rankFilters.filter;
    if (this.$stateParams.filter) {
      this.rankFilters.setFilter(this.$stateParams.filter);
      return;
    } else if (this.filter !== 'default') {
      this.rankFilters.setFilter('default');
      return;
    }
    this.disableLazy = this.$rootScope.disableLazy;

    this.loadMore();

    // trigger signUp
    if (!this.loggedinMember.logged_in && !this.$rootScope.disableLazy) {
      this.$timeout(() => {
        this.Modals.open('login', null, { signUp: true });
      }, 10000);
    }
  }

  $onDestroy() {
    try {
      $('.top-photo__grid')
        .justifiedGallery('destroy')
        .off('jg.complete', this.onJgComplete);
    } catch (e) {}
    if (this.offOnFilterChanged) {
      this.offOnFilterChanged();
    }
    if (this.timeout) {
      this.$timeout.cancel(this.timeout);
    }
    this.destroyed = true;
  }

  /** @return void **/
  async loadMore() {
    if (this.busy || !this.challenge || !this.member  || this.allLoaded) {
      return;
    }
    this.busy = true;
    // get data
    const res = await this.RankingManager.getTopPhoto({
      c_id: this.challenge.id,
      start: this.start,
      limit: this.limit,
      filter: this.filter,
    });
    if (this.destroyed) return;

    if (!res.success) {
      // renew facebook token
      if (this.filter === 'facebook' && res.error_code === 1018) {
        this.Facebook.renewToken();
        this.noItems = this.templates[this.filter];
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
        return;
      }

      if (this.filter === 'facebook') {
        this.noItems = this.templates['friendsLink'];
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
        return;
      }
    }

    if (!res.items.length) {
      this.allLoaded = true;
      if (this.start === 0) {
        this.noItems = this.templates[this.filter];
      }
      this.noData = true;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }

    this.start += this.limit;

    res.items = res.items.map(image => new ImageModel(image));
    this.items.push(...res.items);

    await this.$timeout();
    if (!this.inited) {
      this.inited = true;
      $('.top-photo__grid')
        .justifiedGallery({
          rowHeight: 300,
          maxRowHeight: 300,
          lastRow: 'nojustify',
          captions: false,
          margins: 5,
          waitThumbnailsLoad: false,
          selector: 'div',
          cssAnimation: false,
          imagesAnimationDuration: 0,
        })
        .on('jg.complete', this.onJgComplete);
    } else {
      $('.top-photo__grid').justifiedGallery('norewind');
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onJgComplete() {
    console.log('onJgComplete');
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  linkFacebook() {
    this.Facebook.login();
  }

  selectExpo(image, type) {
    image.selected_expo = image.selected_expo === type ? 0 : type;
    this.ChallengesManager.selectForExpo(this.challenge.id, image.id, image.member.id, image.selected_expo);
  }

  guruPick(image) {
    let image_ids = [image.id];
    this.ChallengesManager.submitVotes(this.challenge.id, image_ids).then(res => {
      if (!res.success) {
        return true;
      }
      image.guru_pick = true;
    });
  }

  /** @return void */
  async removePhoto(image) {
    const confirm = await this.Modals.confirm('Delete?', 'Remove this photo from challenge?', 'Delete', 'Cancel');
    if (!confirm) {
      return;
    }
    // if admin && filter send reason id
    let reason_id;
    if (this.loggedinMember.is_admin && !!reason_id) {
      reason_id = parseInt(this.filter.split('_')[1]);
    }
    const res = await this.ChallengesManager.removeFromChallenge(this.challenge.id, image.id, reason_id);
    if (!res.success) {
      this.Toasts.open('simpleMessage', { message: 'Not deleted!', class: 'error' }, this.$element);
      return;
    }
    // set status
    image.deleted = true;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  reset() {
    this.start = 0;
    this.limit = 50;
    this.items = [];
    this.destroyed = false;

    this.allLoaded = false;
    this.loading = false;
    this.inited = false;

    this.noItems = null;
    this.busy = false;
    try {
      $('.top-photo__grid')
        .justifiedGallery('destroy')
        .off('jg.complete', this.onJgComplete);
    } catch (e) {}
  }

  onFilterChanged(event, data) {
    console.log('topPhoto: onFilterChanged', data);
    this.filter = data.filter;
    this.reset();
    this.loadMore();
  }

  report(image, event) {
    this.Modals.open('report', event, {
      image: image,
      c_id: this.challenge.id,
      noSimilar: true,
      showOffTopic: true
    });
  }
}
rankTopPhotoCtrl.$inject = [
  '$timeout',
  '$document',
  'rankFilters',
  '$stateParams',
  '$location',
  'Modals',
  'Facebook',
  'ChallengesManager',
  'RankingManager',
  'Toasts',
  '$element',
  '$scope',
  '$rootScope',
  'tagEventService',
  'MemberService',
];
export default {
  template: template,
  controller: rankTopPhotoCtrl,
  bindings: {
    challenge: '<',
    member: '<',
  },
};
