import badgeV2Mode from "../../api/enums/badgeV2.enum";
import challengeEnum from '../../api/enums/challenge.enum';
import memberEnum from '../../api/enums/member.enum';
import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";
import {ResourceType} from "../../../../core/models/enums";
import {extractContentFromHtml} from "../../helpers/string.helper";

class challengePageCtrl {
  /**
   * @param {rankFilters} rankFilters
   * @param {ModalsService} Modals
   * @param {ChallengesManager} ChallengesManager
   * @param {brazeService} brazeService
   * */

  ResourceType = ResourceType;

  constructor(
    $state,
    rankFilters,
    $scope,
    pageData,
    $stateParams,
    Modals,
    Facebook,
    $window,
    gsModals,
    $rootScope,
    ChallengesManager,
    deepLinkService,
    brazeService,
    challengePageService,
    NgChallengesService,
    MemberService,
    challengeService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.isSubmitButtonBlocked = false;
    this.$state = $state;
    this.rankFilters = rankFilters;
    this.$scope = $scope;
    this.pageData = pageData;
    this.$stateParams = $stateParams;
    this.Modals = Modals;
    this.Facebook = Facebook;
    this.$window = $window;
    this.gsModals = gsModals;
    this.$rootScope = $rootScope;
    this.ChallengesManager = ChallengesManager;
    this.deepLinkService = deepLinkService;
    this.challengeEnum = challengeEnum;
    this.memberEnum = memberEnum;
    this.brazeService = brazeService;
    this.badgeV2Mode = badgeV2Mode;
    this.challengePageService = challengePageService;
    this.NgChallengesService = NgChallengesService;
    this.MemberService = MemberService;
    this.challengeService = challengeService;
    this.NgMetaDataService = NgMetaDataService;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onChangedRankPage = this.onChangedRankPage.bind(this);

    // Destroy
    $scope.$on('$destroy', () => {
      this.offFilterChanged();
      this.offChangedRankPage();
      this.rankFilters.setFilter('default');
    });
  }

  $onInit() {
    this.$window.scrollTo(0, 0);

    // filters
    this.filters = this.rankFilters.getList();
    this.filter = this.rankFilters.getFilter();

    // temporary patch for component structure
    this.tools = {
      linkFacebook: this.linkFacebook.bind(this),
    };

    this.offFilterChanged = this.$rootScope.$on('onFilterChanged', this.onFilterChanged);
    this.offChangedRankPage = this.$rootScope.$on('onChangedRankPage', this.onChangedRankPage);

    this.init();
  }

  /** @return void **/
  async init() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('challenge');
    const challengeRes =  await this.NgChallengesService.getChallengeByUrl(this.$stateParams.cUrl);
    if (!challengeRes.success) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return;
    }
    challengeRes.challenge.member = challengeRes.member;
    this.challengePageService.challenge = this.challenge = new NgChallengeModel(challengeRes.challenge);
    if(this.challenge.welcome_message){
      this.challenge.welcome_message = this.challenge.welcome_message.replace(/\n|\r/g, "");
    }

    this.rankTabs = this.challengePageService.getRankTabs();
    if(this.challenge.type === challengeEnum.TYPE.EXHIBITION && this.challenge.status === challengeEnum.STATUSES.CLOSED.VALUE){
      const res = await this.ChallengesManager.getChallengeAutoSelectAttributes(this.challenge.id);
      if(res.success){
        this.challengeLock = res.challenge_lock;
      }
    }

    const ChallengeExtraText = ``
    this.challengeTitle = `${this.challenge.title}${ChallengeExtraText}`
    this.NgMetaDataService.updateMetaTags({
      title: this.challengeTitle,
      description: extractContentFromHtml(this.challenge.welcome_message),
      keywords:[ 'photography challenge']
    });

    this.done = true;
    this.deepLinkService.check({challenge:this.challenge});

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setFilter(filter, event) {
    this.rankFilters.setFilter(filter, event);
  }

  linkFacebook() {
    this.Facebook.login();
  }

  async preSubmit() {
    this.isSubmitButtonBlocked = true;
    await this.challengeService.openPreSubmit(this.challenge);
    this.isSubmitButtonBlocked = false;
  }

  login(event) {
    this.Modals.open('login', event, { signUp: true });
  }

  vote() {
    if (this.member.logged_in && this.challenge.status === challengeEnum.STATUSES.ACTIVE.VALUE) {
      this.gsModals.open('vote', { challenge: this.challenge });
    }
  }

  onFilterChanged(event, data) {
    this.filter = data.filter;
  }

  onChangedRankPage(event, data) {
    this.filter = this.rankFilters.filter;
    this.filters = this.rankFilters.getList();
  }

  duplicate(){
    this.Modals.open('createChallenge', null, {cUrl: this.challenge.url});
  }

  chest(){
    this.brazeService.sendChestEvent(this.challenge.id);
  }
}
challengePageCtrl.$inject = [
  '$state',
  'rankFilters',
  '$scope',
  'pageData',
  '$stateParams',
  'Modals',
  'Facebook',
  '$window',
  'gsModals',
  '$rootScope',
  'ChallengesManager',
  'deepLinkService',
  'brazeService',
  'challengePageService',
  'NgChallengesService',
  'MemberService',
  'challengeService',
  'NgMetaDataService',
];
export default challengePageCtrl;
