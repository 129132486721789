import {interval, Subject, Subscription} from "rxjs";
import {DestroyRef, inject, Injectable} from "@angular/core";
import {NgDateHelper} from "../helpers/ngDateHelper";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

interface IGsTimerStart {
  keepSubscription?: boolean, // default: false
  timesUpHandler?(): void,
  timeTickingHandler?(countDownTimestamp: number): void,
  expireTime: number, // in milliseconds
}

//TODO: Omri what do you think about declaration default values?
export const defaultsGsTimerStart: IGsTimerStart = {
  keepSubscription: false,
} as IGsTimerStart;

/**
 * Description:   GsTimerService is Singleton
 * Declaration:   @Component {..., providers: [GsTimerService]}
 * Usage:         this.gsTimerService.run(IGsTimerStart);
 */
@Injectable()
class GsTimerService {
  interval$!: Subscription;
  countDownTime$: Subject<number> = new Subject();
  timeDelta?: number;
  timeEnded = false;
  destroyRef = inject(DestroyRef);

  constructor() {
  }

  start(params: IGsTimerStart = defaultsGsTimerStart): void {
    // need to protect from multiple init
    this.timeEnded = false;
    if (params.expireTime !== undefined) {
      this.stop();
      this.sendTime(params);
      this.interval$ = interval(1000).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => this.sendTime(params));
    }
  }

  sendTime(params: IGsTimerStart): void {
    // console.log('GsTimerService subscription is in progress'); // debug
    this.timeDelta = NgDateHelper.getEndTimeDeltaInMilisec(params.expireTime);
    // console.log('this.timeDelta ', this.timeDelta); // debug
    if (this.timeDelta > 0) {
      this.countDownTime$.next(this.timeDelta);
      params.timeTickingHandler && params.timeTickingHandler(this.timeDelta);
    } else {
      if(!this.timeEnded){
        this.timeEnded = true;
        this.countDownTime$.next(0);
        params.timesUpHandler && params.timesUpHandler();
        !params.keepSubscription && this.stop();
      }
    }
  }

  stop(): void {
    if(this.interval$ && !this.interval$.closed){
      this.interval$.unsubscribe();
    }
  }
}

export default GsTimerService;
