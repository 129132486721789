import {Component, Input} from '@angular/core';
import {IAchievementPrizePrize} from "../../../modules/challenges/interfaces/challenges.interface";
import {PrizeType} from "../../../modules/challenges/enums/challenges.enum";
import {ResourceType} from "../../../core/models/enums";
import {BankrollService} from "../../../modules/bankroll/bankroll.service";

@Component({
  selector: 'app-ng-rewards',
  templateUrl: './ng-rewards.component.html',
  styleUrl: './ng-rewards.component.scss'
})
export class NgRewardsComponent{
  @Input() prizes: IAchievementPrizePrize[] = [];
  @Input() styleClass: '' | 'rank-header' | 'rank-item' = '';

  prizesMap = new Map<PrizeType,ResourceType>();
  PrizeType = PrizeType

  constructor(
    public bankrollService:BankrollService
  ) {
    this.prizesMap = bankrollService.prizeTypeResourceTypeMap;
  }
}
