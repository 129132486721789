import memberEnum from "../../../api/enums/member.enum";

const template = require(`html-loader!./challengesExhibitionBanner.html`).default;
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class challengesExhibitionBannerComponent {
  /** @param {ModalsService} Modals */

  constructor(
    Modals,
    mixpanelService,
    $rootScope,
    challengeService,
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.mixpanelService = mixpanelService;
    this.challengeService = challengeService;
  }

  preSubmit(event) {
    this.eventMixPanelAction();
    this.challengeService.openPreSubmit(this.challenge);
  }

  eventMixPanelAction() {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.BANNER,
          ui_name: mixpanelEventsEnum.UI_NAME.EXHIBITION_BANNER,
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
challengesExhibitionBannerComponent.$inject = [
  'Modals',
  'mixpanelService',
  '$rootScope',
  'challengeService',
];
export default {
  template: template,
  controller: challengesExhibitionBannerComponent,
  bindings: {
    challenge: '='
  }
};
