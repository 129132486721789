/* globals angular */
import config from './profileSettings.page.config';
import profileSettings from './profileSettings/profileSettings';
import socialItem from './profileSettings/socialItem/socialItem';

let moduleProfileSettingsPage = angular.module('gsApp.profileSettingsPage', [])
    .config(config)
    .component('socialItem', socialItem)
    .component('profileSettings', profileSettings)
  ;

export default moduleProfileSettingsPage.name;
