import {Component, OnInit, AfterViewInit, ElementRef, HostListener} from '@angular/core';
import {UpgradeModule} from '@angular/upgrade/static';
import './gsApp/app/gsApp';

import {environment} from '../environments/environment';
import NgTokenService from "./core/services/ng-token.service";
import {SoundService} from "./core/services/sound.service";
import {MemberService} from "./core/services/member.service";
import {SessionConfigService} from "./core/services/session-config.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit , AfterViewInit {
  title = environment.domains.photos;
  captchaKey = environment.captchaKey;
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if(document.hidden){
      this.soundService.previousMuteFlag = this.soundService.muted;
      this.soundService.muted = true;
    }  else {
      this.soundService.muted = this.soundService.previousMuteFlag;
    }
  }

  constructor(
    private upgrade: UpgradeModule,
    private elementRef: ElementRef,
    private tokenService: NgTokenService,
    private soundService: SoundService,
    private memberService: MemberService,
    private sessionConfigService: SessionConfigService,
  ) {}

  async ngOnInit() {
    this.tokenService.initMemberToken();
    this.tokenService.initAdminToken();
    if(this.tokenService.memberToken){
      await Promise.all([
        this.memberService.getCurrentMemberProfile(),
        this.sessionConfigService.getConfigData()
      ]);
    } else {
      this.memberService.resetMember();
    }

    this.upgrade.bootstrap(document.getElementById('gs-app-main') as HTMLElement, ['gsApp'], {strictDi: false});
  }

  ngAfterViewInit() {
    // TODO: https://www.npmjs.com/package/ng-recaptcha
    this.addScriptTag(`https://www.google.com/recaptcha/api.js?render=${this.captchaKey}`);
  }
  addScriptTag(path: string) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = path;
    this.elementRef.nativeElement.appendChild(s);
  }
}
