const template = require(`html-loader!./challenges.page.html`).default;

function config($stateProvider, $urlRouterProvider) {
  'ngInject';

  //Redirect rules
  $urlRouterProvider
    .when('/challenges', '/challenges/my-challenges/current')
    .when('/challenges/', '/challenges/my-challenges/current')
    .when('/challenges/my-challenges', '/challenges/my-challenges/current')
    .when('/challenges/my-challenges/', '/challenges/my-challenges/current');

  // **********
  // Routes
  // **********
  $stateProvider
    .state('gs.challenges', {
      url: '/challenges',
      abstract: true,
      params: {
        force: false
      },
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {
          template: template,
          controller: 'challengesPageCtrl as csp'
        },
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.challenges.myChallenges', {
      url: '/my-challenges',
      abstract: true,
    })
    .state('gs.challenges.myChallenges.joined', {
      url: '/current',
      template: `<my-challenges-current ng-if="csp.done"></my-challenges-current>`,
      params: {
        activeChallengeIdToAdd:{
          value: null,
          squash: true
        },
        challenge: {
          value: null,
          squash: true
        },
        challengeIdToScroll: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.challenges.myChallenges.joined.ads', {
      url: '/joined-challenge',
    })
    .state('gs.challenges.myChallenges.completed', {
      url: '/completed',
      template: `<my-challenges-completed ng-if="csp.done"></my-challenges-completed>`,
      params: {
        challengeIdToScroll: {
          value: null,
          squash: true
        },
        scrollToLastNewChallenge: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.challenges.myChallenges.manage', {
      url: '/manage',
      template: `<my-challenges-manage ng-if="csp.done"></my-challenges-manage>`
    })
    .state('gs.challenges.myChallenges.editor', {
      url: '/editor/:cUrl',
      template: `<my-challenges-editor ng-if="csp.done"></my-challenges-editor>`,
      params: {
        cUrl: {
          value: null,
          squash: true
        },
        dup: {
          value: null,
          squash: true
        },
        assignMode: {
          value: null,
          squash: true
        },
        imageId: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.challenges.open', {
      url: '/open',
      template: `<challenges-open ng-if="csp.done"></challenges-open>`
    })
    .state('gs.challenges.upcoming', {
      url: '/upcoming',
      template: `<challenges-upcoming ng-if="csp.done"></challenges-upcoming>`
    })
    .state('gs.challenges.closed', {
      url: '/closed',
      template: `<challenges-closed ng-if="csp.done"></challenges-closed>`
    });
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
