const template = require(`html-loader!./memberName.html`).default;

import ChatReplyModel from "../../api/models/chat/chatReply.model";

class memberNameCtrl {
  constructor(
    $rootScope,
    $element,
    stateService,
    Modals
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$element = $element;
    this.stateService = stateService;
    this.Modals = Modals;
    this.Modals = Modals;
  }

  $onInit(){
    this.size = $(this.$element[0]).css('font-size').replace('px','');
  }

  onClick(){
    if(this.clickable === 'false'){return}
    const memberUserName = this.getUserName();
    const state = this.goToAdminMemberManager === 'true' ? 'gs.gsAdmin.memberManager' : 'gs.profile';
    const stateMethod = this.newTab === 'true' ? 'goToStateNewTab' : 'goToState';
    if(memberUserName){
      this.stateService[stateMethod](state, {cUserName:memberUserName});
      this.Modals.close();
    }
  }


  getUserName(){
    return this.member.user_name || this.member.memberUserName;
  }

  getName(){
    if(!this.member){
      return;
    }
    if(this.name){
      return  this.name;
    }
    if(this.member instanceof ChatReplyModel){
      return this.member.member.name;
    }
    return this.member.name || this.member.memberName || this.member.user_name || this.member.memberUserName;
  }
}

memberNameCtrl.$inject = [
  '$rootScope',
  '$element',
  'stateService',
  'Modals'
];

export default {
  template: template,
  controller: memberNameCtrl,
  bindings: {
    member: '<',
    clickable: '@',
    hideBadge: '@',
    name: '@',
    textEllipsis: '@',
    newTab:'@',
    goToAdminMemberManager:'@',
    linkBehavior: '@'
  }
};
