function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state('gs.photo', {
      url: '/photo/:id',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'photoPage'},
        'footer@gs': null
      },
      reloadOnSearch: false,
      params: {
        id: {
          value: null,
          squash: true
        }
      }
    })
  ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];

export default config;
