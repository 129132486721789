import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./boardingPage.html`).default;

import ImageModel from '../../../api/models/image/image.model';
import scannerAnimation from '../../../../../../assets/animations/scanner.json';

class boardingPageComponent {

  constructor(
    pageData,
    $scope,
    $stateParams,
    $state,
    $location,
    $transitions,
    Modals,
    $rootScope,
    PhotosManager,
    ChallengesManager,
    onBoarding,
    $cookies,
    challengeService,
    MemberService
  ) {
    'ngInject';
    this.challenges = [];
    this.loading = false;
    this.busy = true;
    this.slickConfig = {
      accessibility: false,
      arrows: false,
      fade: true,
      speed: 500,
      centerPadding: '0px',
      draggable: false,
      infinite: false,
      swipe: false,
      touchMove: false,
    };

    this.pageData = pageData;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$location = $location;
    this.$transitions = $transitions;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.PhotosManager = PhotosManager;
    this.ChallengesManager = ChallengesManager;
    this.onBoarding = onBoarding;
    this.$cookies = $cookies;
    this.challengeService = challengeService;

    this.afterSlideChange = this.afterSlideChange.bind(this);
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    if (this.$cookies.get('intro_step') !== '1') {
      this.$state.go('gs.challenges.myChallenges.joined');
      return;
    }
    this.transitionsOnBefore = this.$transitions.onBefore({}, this.transitionOnBefore);
    this.initPage();
  }

  $onDestroy() {
    if (this.transitionsOnBefore) this.transitionsOnBefore();
    if (this.slides) this.slides.off('afterChange', this.afterSlideChange);
  }

  /** @return void **/
  async initPage() {
    // page data
    this.pageData.get('boarding');
    // find slides
    this.slides = $('.boardingPage__slides');
    this.slides.slick(this.slickConfig);
    // events
    this.slides.on('afterChange', this.afterSlideChange);
    // get image
    if (this.$stateParams.id) {
      const res = await this.PhotosManager.getImageData(this.$stateParams.id);
      if (res.success) this.image = new ImageModel(res.data);
    }
    // goto slide
    if (this.$stateParams.slide && this.$stateParams.slide > 1) {
      this.slides.slick('slickGoTo', parseInt(this.$stateParams.slide) - 1, true);
      this.slideFlow();
    }
    this.busy = false;

    this.onBoarding.load();

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  afterSlideChange() {
    this.loading = false;
  }

  next(slide) {
    if (this.loading) return;
    this.loading = true;

    let nextSlide;
    if (slide) {
      this.$stateParams.slide = nextSlide = slide;
    } else {
      nextSlide = this.$stateParams.slide = +this.$stateParams.slide + 1;
    }
    let location = `/boarding/${nextSlide}`;
    if (this.image) location += '/' + this.image.id;
    this.$location.path(location).replace();
    if (slide) {
      this.slides.slick('slickGoTo', nextSlide - 1, true);
    } else {
      this.slides.slick('slickNext');
    }
    if (!this.$rootScope.$$phase) this.$scope.$apply();
    this.slideFlow();
  }

  upload(event) {
    this.Modals.open('gsUploader', event, {
      title: '<span>Upload your first photo</span>',
      target: 'getImages',
      submit: 'SUBMIT',
      items_limit: 1,
      myPhotos: false,
      saveToProfile: true,
      autoSubmit: true,
      member: {
        id: this.member.id,
      },
      callback: images => {
        if (!images.length) return;
        this.image = images[0];
        this.next();
      },
    });
  }

  /** @return void **/
  async slideFlow() {
    const slide = +this.$stateParams.slide;
    switch (slide) {
      case 6:
        this.scannerText = 'Analyzing your photo...';
        const element = document.querySelector(`.boardingPage__scanner`);
        if (!element) return;
        const animation = window.bodymovin.loadAnimation({
          container: element,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: scannerAnimation,
          rendererSettings: {
            scaleMode: 'noScale',
            clearCanvas: true,
            progressiveLoad: true,
          },
        });
        if (!this.image || !this.image.id) return;
        const image_ids = [this.image.id];
        await Timeout.set(3000);
        this.scannerText = 'Matching challenges...';
        if (!this.$scope.$$phase) this.$scope.$digest();
        await Timeout.set(3000);
        const res = await this.PhotosManager.getMatchedChallenges(image_ids);
        res.items = res.items.map(item => new NgChallengeModel(item.challenge));
        animation.goToAndStop(animation.totalFrames, true);
        this.challenges.push(...res.items);
        animation.destroy();
        if (!this.$scope.$$phase) this.$scope.$digest();
        await Timeout.set(2000);
        this.next();
        break;
      case 7:
        if (!this.challenges.length) {
          this.next(6);
          return;
        }
        setTimeout(() => {
          // all checked
          this.challenges = this.challenges.map(challenge => {
            challenge.selected = true;
            return challenge;
          });
          if (!this.$scope.$$phase) this.$scope.$digest();
        }, 1000);
        break;
    }
  }

  transitionOnBefore($transition$) {
    // prevent redirect to boarding page
    if ($transition$.$to().name === 'gs.boarding') return false;
  }

  async join() {
    if (this.busy || !_.find(this.challenges, { selected: true })) return;
    this.busy = true;
    for (const challenge of this.challenges) {
      if (!challenge.selected) continue;
      await this.challengeService.submitToChallenge(
        [this.image.id],
        challenge.id,
        challenge.type
      );
    }
    this.transitionsOnBefore();
    $(document).trigger({ type: 'intro_next', step: '1.3' });
  }

  changeImage() {
    this.challenges = [];
    this.image = null;
    this.next(5);
  }
}
boardingPageComponent.$inject = [
  'pageData',
  '$scope',
  '$stateParams',
  '$state',
  '$location',
  '$transitions',
  'Modals',
  '$rootScope',
  'PhotosManager',
  'ChallengesManager',
  'onBoarding',
  '$cookies',
  'challengeService',
  'MemberService',
];
export default {
  controller: boardingPageComponent,
  template,
};
