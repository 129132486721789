export enum ChallengeTurboUnlockType {
  KEY = 'KEY',
  COINS = 'COINS'
}
export enum ChallengeTurboState {
  TIMER='TIMER',
  FREE='FREE',
  LOCKED='LOCKED',
  IN_PROGRESS='IN_PROGRESS',
  WON='WON',
  USED='USED',
}
export enum ChallengeBoostState {
  AVAILABLE='AVAILABLE',
  LOCKED='LOCKED',
  MISSED='MISSED',
  USED='USED',
  AVAILABLE_KEY='AVAILABLE_KEY',
}
export enum ChallengeUnlockType {
  BOOST,
  TURBO
}
export enum ChallengeUnlockUsage {
  JOIN_CHALLENGE = 'JOIN_CHALLENGE',
  EXPOSURE_BOOST = 'EXPOSURE_BOOST',
  TURBO = 'TURBO'
}
export enum ChallengeType {
  DEFAULT= 'default',
  EXHIBITION= 'exhibition',
  SPEED= 'speed',
  FLASH= 'flash',
}
export enum PrizeAward {
  CHALLENGE_TOP_PHOTOGRAPHER = 'CHALLENGE_TOP_PHOTOGRAPHER',
  CHALLENGE_TOP_PHOTO = 'CHALLENGE_TOP_PHOTO',
  GURU_TOP_PICK = 'GURU_TOP_PICK',
}
export enum PrizesLayout {
  EXHIBITION = 'EXHIBITION',
  MAGAZINE = 'MAGAZINE',
  RESOURCES_ONLY = 'RESOURCES_ONLY'
}
export enum WinnersLayout {
  MULTIPLE_PHOTOS = 'MULTIPLE_PHOTOS',
  SINGLE_PHOTO ='SINGLE_PHOTO',
  LIST='LIST'
}
export enum AchievementType {
  MEMBER='MEMBER',
  IMAGE='IMAGE'
}
export enum AchievementPrizeAchievementType {
  CHALLENGE_TOP_PHOTOGRAPHER = 'CHALLENGE_TOP_PHOTOGRAPHER',
  CHALLENGE_TOP_PHOTO = 'CHALLENGE_TOP_PHOTO',
  GURU_TOP_PICK = 'GURU_TOP_PICK',
  TOP_FLASH_WINNER = 'TOP_FLASH_WINNER',
  TOP_FLASH_ACHIEVEMENT = 'TOP_FLASH_ACHIEVEMENT',
  TOP_PHOTOGRAPHER_ACHIEVEMENT = 'TOP_PHOTOGRAPHER_ACHIEVEMENT',
  TOP_PHOTO_ACHIEVEMENT = 'TOP_PHOTO_ACHIEVEMENT',
}
export enum PrizeType {
  PHOTO_SWAP = 'PHOTO_SWAP',
  KEY = 'KEY',
  EXPOSURE_AUTOFILL = 'EXPOSURE_AUTOFILL',
  COINS = 'COINS',
}
export enum ChallengeStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  UPCOMING = 'upcoming',
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  ACTIVE = 'active',
  CLOSED = 'closed',
  CLOSING = 'closing',
}
export enum ChallengeGroup {
  OPEN = 'OPEN',
  UPCOMING = 'UPCOMING',
  CLOSED = 'CLOSED',
  ACTIVE = 'ACTIVE',
  MY_CLOSED = 'MY_CLOSED',
}




