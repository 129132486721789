const challengePageEnum = Object.freeze({
  RANK:{
    TAB:{
      TOP_PHOTOGRAPHER:{
        NAME:'Top Photographer',
        STATE:'gs.challenge.rank.topPhotographer'
      },
      TOP_PHOTO:{
        NAME:'Top Photo',
        STATE:'gs.challenge.rank.topPhoto'
      },
      GURU_TOP_PICK:{
        NAME:`Guru's Top Pick`,
        STATE:'gs.challenge.rank.gurusTopPick'
      },
    }
  }
});

export default challengePageEnum;
