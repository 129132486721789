import moment from 'moment';
const template = require(`html-loader!./adminExhibitionsVirtual.html`).default;
import VirtualExhibitionModel from "../../../../../api/models/exhibition/virtualExhibition.model";

class adminExhibitionsVirtualCtrl {

  constructor(RestManager, $scope, $state) {
    'ngInject';
    this.busy = false;

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.$state = $state;
  }

  $onInit() {
    this.loadData();
  }

  /** @return void **/
  async loadData() {
    this.busy = true;
    let res = await this.RestManager.getVirtualExhibitions();
    this.busy = false;
    if (!res.success) return;
    this.virtualExhibitions = res.exhibitions.map( exhibition => new VirtualExhibitionModel(exhibition));
    console.log(this.virtualExhibitions);
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  editEvent(event) {
    this.$state.go('gs.gsAdmin.contentManager.events.editLbEvent', { event: event });
  }

  async deleteVirtualExhibition(virtualExhibition){
    if(!this.busy){
      this.busy = true;
      let res = await this.RestManager.deleteVirtualExhibition(virtualExhibition.id);
      this.busy = false;
      if (!res.success) return;
      this.loadData();
    }
  }
}
adminExhibitionsVirtualCtrl.$inject = [
  'RestManager', '$scope', '$state'
];
export default {
  template: template,
  controller: adminExhibitionsVirtualCtrl,
  bindings: {},
};
