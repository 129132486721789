const template = require(`html-loader!./teamName.html`).default;

class teamNameCtrl {
  constructor(
    $rootScope,
    $element,
    stateService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$element = $element;
    this.stateService = stateService;
  }
  goToTeamInfoPage(){

  }

  onClick(){
    if (!this.team.id || !this.clickable) {
      return;
    }

    const state = this.goToAdminTeamManager === 'true' ? 'gs.gsAdmin.teamsManager' : 'gs.teams.info';
    const stateMethod = this.newTab === 'true' ? 'goToStateNewTab' : 'goToState';
    this.stateService[stateMethod](state, {teamId: this.team.id});
  }
}

teamNameCtrl.$inject = [
  '$rootScope',
  '$element',
  'stateService'
];

export default {
  template: template,
  controller: teamNameCtrl,
  bindings: {
    team: '<',
    clickable: '@',
    hideIcons: '@',
    goToAdminTeamManager: '@',
    newTab: '@',
  }
};
