import {EventEmitter, Inject, Injectable} from '@angular/core';
import RequestServiceModel from "../../../core/services/request/requestService.model";
import NgRequestEnum from "../../../core/services/request/request.enum";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {map} from "rxjs/operators";
import {NgChallengeModel} from "../models/ng-challenge.model";
import {IGsResponse} from "../../../core/models/gsResponse.types";
import {INgChallengeModel} from "../interfaces/challenges.interface";
import RequestService from "../../../core/services/request/requestService";
import {
  ISocketBoostStateUpdateEventResult,
  ISocketStatusUpdateEventResult,
  SocketService
} from "../../../core/services/socket.service";
import {SocketsEnum} from "../../../core/enum/sockets.enum";
import {ChallengeGroup, ChallengeStatus} from "../enums/challenges.enum";
import {StateService} from "../../../core/services/state.service";
import {ArrayHelper} from "../../../core/helpers/ArrayHelper";

export interface IGetMyActiveChallenges extends IGsResponse{
  challenges: INgChallengeModel[],
  exhibition?: INgChallengeModel,
  suggested_challenges: INgChallengeModel[],
  next_interval: number
}
export interface IGetMyActiveChallenge extends IGsResponse{
  challenge: INgChallengeModel,
}
export interface IGetMyActiveChallengeUpdate extends IGsResponse{
  challenges: INgChallengeModel[],
  next_interval: number
}
export interface IGetMemberChallenges extends IGsResponse{
  items: INgChallengeModel[];
}
export interface IGetClosedChallenges extends IGsResponse{
  closed_challenges: INgChallengeModel[];
}
export interface IGetMyClosedChallenges extends IGsResponse{
  completed_challenges: INgChallengeModel[];
  is_new:boolean;
  showing_last_days:number;
}
export interface IsNewCompletedChallenges extends IGsResponse{
  is_new: boolean;
}
export interface IClosedChallengesCache {
  start:number;
  limit:number;
  challenges:INgChallengeModel[];
  allLoaded:boolean;
}
export interface IMyClosedChallengesCache {
  start:number;
  limit:number;
  challenges:INgChallengeModel[];
  allLoaded:boolean;
  showing_last_days:number;
}

export enum getMemberChallengesFilterType {
  OPEN='open',
  UPCOMING='upcoming',
}

@Injectable({
  providedIn: 'root'
})
export class JoinedChallengesPageService {
  public isFirstTimeFetchMyActiveChallenges = true;
  public updateActiveChallengesInterval?: number;
  public activeChallenges: INgChallengeModel[] = [];
  public activeChallengesMap: Map<number, INgChallengeModel> = new Map();
  public suggestedChallenges: INgChallengeModel[] = [];
  public exhibitionBanner?: INgChallengeModel;

  public closedChallengesCache!:IClosedChallengesCache;
  public myClosedChallengesCache!:IMyClosedChallengesCache;

  public openChallengesMap : Map<number, INgChallengeModel> = new Map();
  public $openChallenges = new BehaviorSubject<INgChallengeModel[] | undefined>(undefined)
  public $upcomingChallenges = new BehaviorSubject<INgChallengeModel[] | undefined>(undefined)
  public $closedChallenges = new BehaviorSubject<IClosedChallengesCache | undefined>(undefined)
  public $myClosedChallenges = new BehaviorSubject<IMyClosedChallengesCache | undefined>(undefined)

  public refreshChallengesFlags : {
    [ChallengeGroup.CLOSED]:boolean;
    [ChallengeGroup.OPEN]:boolean;
    [ChallengeGroup.UPCOMING]:boolean;
    [ChallengeGroup.MY_CLOSED]:boolean;
  } = {CLOSED:true, OPEN:true, UPCOMING:true, MY_CLOSED:true}


  public $isFillAllActive = new BehaviorSubject(false);
  public $boostChanged: EventEmitter<ISocketBoostStateUpdateEventResult> = new EventEmitter();
  constructor(
    private requestService: RequestService,
    private socketService: SocketService,
    private stateService: StateService,
    @Inject('Modals') private Modals: any,
  ) {
    this.resetCloseChallengesCache();
    this.resetMyClosedChallengesCache();
    this.socketService.on(SocketsEnum.StatusUpdate, (result: ISocketStatusUpdateEventResult) => {
      this.handleChallengesStatuses(result);
    });

    this.socketService.on(SocketsEnum.BoostStateUpdate, (result: ISocketBoostStateUpdateEventResult) => {
      let challengeToUpdate = this.activeChallengesMap.get(result.challenge_id);
      if(challengeToUpdate?.member?.boost){
        challengeToUpdate.member.boost.state = result.state;
        challengeToUpdate.member.boost.timeout = result.timeout;
      }
      this.$boostChanged.emit(result);
    });
  }

  resetCloseChallengesCache(){
    this.closedChallengesCache = {start: 0, limit:12, challenges:[], allLoaded:false};
  }
  resetMyClosedChallengesCache(){
    this.myClosedChallengesCache = {start: 0, limit:20, challenges:[], allLoaded:false, showing_last_days:0};
  }

  updateIsFillAllActive(){
    this.$isFillAllActive.next(this.getChallengesIdsWithActiveFill().length !== 0);
  }

  getActiveChallengesIds(){
    return [ ...this.activeChallengesMap.keys() ]
  }

  getChallengesIdsWithActiveFill(){
    const challengesWithActiveFill =
      this.activeChallenges.filter(challenge => challenge.fill_enable && !challenge.fill_locked)
    return challengesWithActiveFill.map(challenge => challenge.id);
  }

  removeActiveChallenge(challengeId:number){
    if(this.activeChallengesMap.get(challengeId)){
      this.activeChallenges = this.activeChallenges.filter( challenge => challenge.id !== challengeId);
      this.activeChallengesMap.delete(challengeId);
      this.updateIsFillAllActive();
    }
  }

  removeSuggestedChallenge(challengeId:number){
    this.suggestedChallenges =
      this.suggestedChallenges.filter(suggested_challenge => suggested_challenge.id !== challengeId);
  }

  getMyActiveChallenges(): Promise<IGetMyActiveChallenges> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MY_ACTIVE_CHALLENGES.NAME,
    });
    return firstValueFrom(this.requestService.request<IGetMyActiveChallenges>(conf).pipe(
      map((res)=>{
        if(res.challenges){
          this.activeChallengesMap.clear();
          this.activeChallenges = res.challenges.map((challenge)=> {
            const c = new NgChallengeModel(challenge);
            this.activeChallengesMap.set(Number(challenge.id), c);
            return c;
          });
        }
        if(res.suggested_challenges){
          this.suggestedChallenges = res.suggested_challenges.map(challenge=> new NgChallengeModel(challenge));
        }
        this.exhibitionBanner = res.exhibition ? new NgChallengeModel(res.exhibition) : undefined;
        this.isFirstTimeFetchMyActiveChallenges = false;
        this.updateActiveChallengesInterval = res.next_interval;
        this.updateIsFillAllActive();
        return res;
      })
    ));
  }

  getMyActiveChallenge(challengeId:number): Promise<IGetMyActiveChallenge> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MY_ACTIVE_CHALLENGE.NAME,
      body:{
        challenge_id:challengeId
      }
    });
    return firstValueFrom(this.requestService.request<IGetMyActiveChallenge>(conf).pipe(
      map((res)=>{
        if (res.success && res.challenge && res.challenge.id) {
          res.challenge = new NgChallengeModel(res.challenge);
          if (this.activeChallenges) {
            const challengeIndex =
              this.activeChallenges.findIndex(challenge => challenge.id === challengeId);
            if (challengeIndex !== -1) { // update existing active challenge
              this.activeChallenges[challengeIndex] = res.challenge;
              this.activeChallengesMap.set(res.challenge.id!, res.challenge);
            } else { // add new active challenge
              this.activeChallenges.unshift(res.challenge);
              this.activeChallengesMap.set(res.challenge.id!, res.challenge);
              console.log('getMyActiveChallenge after unshift', this.activeChallenges);
            }
            this.updateIsFillAllActive();
            if (this.suggestedChallenges && this.suggestedChallenges.length) {
              this.removeSuggestedChallenge(challengeId);
            }
          }
        }
        return res;
      })
    ));
  }

  getMyActiveChallengesUpdate(): Promise<IGetMyActiveChallengeUpdate> {
    if (this.activeChallenges?.length <= 0) {
      // @ts-ignore
      return;
    }
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MY_ACTIVE_CHALLENGES_UPDATE.NAME,
      body:{
        challenge_ids: this.getActiveChallengesIds()
      }
    });
    return firstValueFrom(this.requestService.request<IGetMyActiveChallengeUpdate>(conf).pipe(
      map((res) => {
        if (res.success) {
          this.updateActiveChallengesInterval = res.next_interval;
          res.challenges.forEach((updatedChallenge)=> {
            let challengeToUpdate = this.activeChallengesMap.get(Number(updatedChallenge.id));
            if (challengeToUpdate && challengeToUpdate.member && updatedChallenge.member) {
              if (challengeToUpdate.member.ranking?.total?.rank && updatedChallenge.member?.ranking?.total?.rank) {
                // updatedChallenge.member.ranking.total.rank += this.toAdd++;
                // updatedChallenge.member.ranking.total.exposure = 50;
                Object.assign(challengeToUpdate.member.ranking.total, updatedChallenge.member.ranking.total);
              }
              if (challengeToUpdate.member.ranking?.exposure && updatedChallenge.member?.ranking?.exposure) {
                // updatedChallenge.member.ranking.exposure.vote_ratio = 40;
                Object.assign(challengeToUpdate.member.ranking.exposure, updatedChallenge.member.ranking.exposure);
              }
              if (challengeToUpdate.member.ranking?.entries?.length && updatedChallenge.member.ranking?.entries?.length) {
                const updatedEntriesMap = new Map();
                for (const image of updatedChallenge.member.ranking.entries) {
                  updatedEntriesMap.set(image.id, image);
                }
                for (const image of challengeToUpdate.member.ranking.entries) {
                  // console.log(`before`, image);
                  if(updatedEntriesMap.get(image.id)){
                    Object.assign(image, updatedEntriesMap.get(image.id))
                  }
                }
              }
            }
          })
        }
        return res;
      })
    ));
  }

  isNewCompletedChallenges(): Promise<IsNewCompletedChallenges> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.IS_NEW_COMPLETED_CHALLENGES.NAME,
    });
    return firstValueFrom(this.requestService.request<IsNewCompletedChallenges>(conf));
  }

  getClosedChallenges(forceRefresh:boolean = false): void {
    if(!this.refreshChallengesFlags[ChallengeGroup.CLOSED] && !forceRefresh){
      return;
    }
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_CLOSED_CHALLENGES.NAME,
      body: {
        start:this.closedChallengesCache.start,
        limit:this.closedChallengesCache.limit,
      }
    });
    firstValueFrom(this.requestService.request<IGetClosedChallenges>(conf).pipe(
      map((res)=>{
        res.closed_challenges = res.closed_challenges.map((challenge)=>{
          return new NgChallengeModel(challenge);
        });
        if (res.closed_challenges.length < this.closedChallengesCache.limit) {
          this.closedChallengesCache.allLoaded = true;
        } else {
          this.closedChallengesCache.start += this.closedChallengesCache.limit;
        }
        res.closed_challenges = ArrayHelper.getUniqueIdArray(res.closed_challenges);
        this.closedChallengesCache.challenges = [...this.closedChallengesCache.challenges, ...res.closed_challenges];
        this.$closedChallenges.next(this.closedChallengesCache);
        this.refreshChallengesFlags[ChallengeGroup.CLOSED] = false;
        return res;
      })
    ));
  }
  getMyCompletedChallenges(forceRefresh:boolean = false): Promise<IGetMyClosedChallenges | undefined> {
    if(!this.refreshChallengesFlags[ChallengeGroup.MY_CLOSED] && !forceRefresh){
      return Promise.resolve(undefined);
    }

    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MY_COMPLETED_CHALLENGES.NAME,
      body: {
        start:this.myClosedChallengesCache.start,
        limit:this.myClosedChallengesCache.limit,
      }
    });
    return firstValueFrom(this.requestService.request<IGetMyClosedChallenges>(conf).pipe(
      map((res)=>{
        res.completed_challenges = res.completed_challenges.map((challenge)=>{
          return new NgChallengeModel(challenge);
        });
        if (res.completed_challenges.length < this.myClosedChallengesCache.limit) {
          this.myClosedChallengesCache.allLoaded = true;
        } else {
          this.myClosedChallengesCache.start += this.myClosedChallengesCache.limit;
        }
        this.myClosedChallengesCache.challenges = [...this.myClosedChallengesCache.challenges, ...res.completed_challenges];
        this.myClosedChallengesCache.showing_last_days = res.showing_last_days;
        this.$myClosedChallenges.next(this.myClosedChallengesCache);
        if(res.completed_challenges.length){
          for (const newChallenge of res.completed_challenges) {
            if( newChallenge.id && newChallenge.member?.isNew && newChallenge.member.isNew()){
              this.setWatchedCompletedChallenge(newChallenge.id);
            }
          }
        }
        this.refreshChallengesFlags[ChallengeGroup.MY_CLOSED] = false;
        return res;
      })
    ));
  }

  setWatchedCompletedChallenge(challenge_id:number){
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.SET_WATCHED_COMPLETED_CHALLENGE.NAME,
      body:{
        challenge_id
      }
    });
    return firstValueFrom(this.requestService.request<IsNewCompletedChallenges>(conf));
  }

  getUpcomingChallenges(){
    if(!this.refreshChallengesFlags[ChallengeGroup.UPCOMING]){return  Promise.resolve([])}
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MEMBER_CHALLENGES.NAME,
      body:{filter:getMemberChallengesFilterType.UPCOMING}
    });
    return firstValueFrom(this.requestService.request<IGetMemberChallenges>(conf).pipe(
      map((res) => {
        if (!res.success) {
          if (res.error_code === 1000) {
            this.Modals.open('login');
          }
        } else {
          res.items = res.items.map((challenge)=>{
            return new NgChallengeModel(challenge);
          })
          this.$upcomingChallenges.next(res.items);
          this.refreshChallengesFlags[ChallengeGroup.UPCOMING] = false;
        }
        return res;
      })
    ));
  }
  getOpenChallenges(){
    if(!this.refreshChallengesFlags[ChallengeGroup.OPEN]){return  Promise.resolve([])}
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MEMBER_CHALLENGES.NAME,
      body:{filter:getMemberChallengesFilterType.OPEN}
    });
    return firstValueFrom(this.requestService.request<IGetMemberChallenges>(conf).pipe(
      map((res) => {
        if (!res.success) {
          if (res.error_code === 1000) {
            this.Modals.open('login');
          }
        } else {
          res.items = res.items.map((challenge)=>{
            const c = new NgChallengeModel(challenge);
            this.openChallengesMap.set(c.id!,c);
            return c;
          })
          this.$openChallenges.next(res.items);
          this.refreshChallengesFlags[ChallengeGroup.OPEN] = false;
        }
        return res;
      })
    ));
  }

  handleChallengesStatuses({challenge_id, status}: ISocketStatusUpdateEventResult): void {
    switch (status){
      case ChallengeStatus.CLOSING:
        this.removeSuggestedChallenge(challenge_id);
        const activeChallenge = this.activeChallengesMap.get(challenge_id);
        if (activeChallenge) {
          activeChallenge.status = status;
        } else {
          this.refreshOpenChallenges();
        }
        break;
      case ChallengeStatus.CLOSED:
	      this.removeSuggestedChallenge(challenge_id);
        this.removeActiveChallenge(challenge_id);
        this.resetCloseChallengesCache();
        this.resetMyClosedChallengesCache();
        if(this.stateService.isStateByChallengeGroup(ChallengeGroup.CLOSED)){
          //hard refresh closed challenges
          this.getClosedChallenges(true);
        } else {
          this.refreshChallengesFlags[ChallengeGroup.CLOSED] = true;
        }
        if(this.stateService.isStateByChallengeGroup(ChallengeGroup.MY_CLOSED)){
          //hard refresh my closed challenges
          this.getMyCompletedChallenges(true);
        } else {
          this.refreshChallengesFlags[ChallengeGroup.MY_CLOSED] = true;
        }

        // if not in active challenges -> refresh open challenges
        if(!this.activeChallengesMap.get(challenge_id)){
          this.refreshOpenChallenges();
        }
        break;
      case ChallengeStatus.UPCOMING:
        this.refreshUpcomingChallenges();
        break;
      case ChallengeStatus.ACTIVE:
        // if not in active challenges -> refresh open challenges
        if(!this.activeChallengesMap.get(challenge_id)){
          this.refreshOpenChallenges();
        }
        this.refreshUpcomingChallenges();
        break;
    }
  }

  refreshUpcomingChallenges(){
    this.refreshChallengesFlags[ChallengeGroup.UPCOMING] = true;
    if(this.stateService.isStateByChallengeGroup(ChallengeGroup.UPCOMING)){
      this.getUpcomingChallenges();
    }
  }

  refreshOpenChallenges(){
    this.refreshChallengesFlags[ChallengeGroup.OPEN] = true;
    if(this.stateService.isStateByChallengeGroup(ChallengeGroup.OPEN)){
      this.getOpenChallenges();
    }
  }

  cleanUpJoinedChallenge(id: number) {
    let openedChallenges = this.$openChallenges.getValue() as INgChallengeModel[];
    openedChallenges = (openedChallenges || []).filter((challenge: INgChallengeModel) => challenge.id !== id);
    this.$openChallenges.next(openedChallenges);
  }
}
