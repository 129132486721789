const template = require(`html-loader!./adminDailyRewardsPrizes.html`).default;

class adminDailyRewardsPrizesCtrl {

  constructor(RestManager, $scope, $state) {
    'ngInject';
    this.items = [];
    this.error;

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.$state = $state;
  }

  $onInit() {
    this.init();
  }

  /** @return void **/
  async init() {

    let res  = await this.RestManager.getDailyRewardsPrizes();
    if (!res.success) return;

    this.items = res.prizes;
    this.validate();
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  validate(){
    const presentSum = this.items.reduce(( a, b ) => ({percent: a.percent + b.percent})).percent;
    if ( presentSum !== 100){
      this.error = `The percent sum of all prizes is ${presentSum} and it need to be exactly 100!!!! a fix must be done!!!!`;
    }
  }

  newPrize(){
    this.$state.go('gs.gsAdmin.contentManager.dailyRewards.editDailyPrize',{},{reload:true});
  }

  editPrize(prize){
    // console.log(mission);
    this.$state.go('gs.gsAdmin.contentManager.dailyRewards.editDailyPrize',{prize:prize},{reload:true});
  }

}
adminDailyRewardsPrizesCtrl.$inject = [
  'RestManager', '$scope', '$state'
];
export default {
  template: template,
  controller: adminDailyRewardsPrizesCtrl,
  bindings: {},
};
