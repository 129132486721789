const template = require(`html-loader!./adminDailyRewardsMissions.html`).default;

class adminDailyRewardsMissionsCtrl {

  constructor(RestManager, $scope, $state) {
    'ngInject';
    this.items = [];

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.$state = $state;
  }

  $onInit() {
    this.init();
  }

  /** @return void **/
  async init() {
    let res = await this.RestManager.getDailyMissionsDefinitions();
    if (!res.success) return;
    this.items = res.missions;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  editMission(mission) {
    this.$state.go('gs.gsAdmin.contentManager.dailyRewards.editDailyMission', { mission: mission });
  }
}
adminDailyRewardsMissionsCtrl.$inject = [
  'RestManager', '$scope', '$state'
];
export default {
  template: template,
  controller: adminDailyRewardsMissionsCtrl,
  bindings: {},
};
