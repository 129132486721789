const template = require(`html-loader!./adminChallenges.html`).default;

class adminChallengesCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Create', route: 'gs.challenges.myChallenges.editor', newTab: true, iconClass: "icon-link-external"},
      {name: 'Schedule', route: 'gs.gsAdmin.contentManager.challenges.challengesSchedule'},
      {name: 'Challenge Templates', route: 'gs.gsAdmin.contentManager.challenges.challengeTemplates'},
      {name: 'Bulk Upload', route: 'gs.gsAdmin.contentManager.challenges.bulkUpload'},
      {name: 'Winners', route: 'gs.gsAdmin.contentManager.challenges.challengesWinners'},
      {name: 'Calendar', route: 'gs.gsAdmin.contentManager.challenges.challengesCalendar'},
      {name: 'Auto Submit', route: 'gs.gsAdmin.contentManager.challenges.challengesAutoSubmit'},
      {name: 'Notification Times', route: 'gs.gsAdmin.contentManager.challenges.notificationTimes'},
      {name: 'Challenges Planned Timings', route: 'gs.gsAdmin.contentManager.challenges.challengesPlannedTimings'},
      {name: 'Ranking Levels', route: 'gs.gsAdmin.contentManager.challenges.challengesRankingLevels'},
      {name: 'Sponsors', route: 'gs.gsAdmin.contentManager.challenges.challengesSponsors'},
    ];
  }
}

export default {
  template: template,
  controller: adminChallengesCtrl,
  bindings: {}
};
