import config from './exhibitions.config';
import exhibitions from './exhibitionsPage/exhibitionsPage';
import exhibitionsItem from './exhibitionsItem/exhibitionsItem';

let moduleExhibitions = angular.module('gsApp.exhibitions', [])
    .config(config)
    .component('exhibitions', exhibitions)
    .component('exhibitionsItem', exhibitionsItem)
  ;

export default moduleExhibitions.name;
