const template = require(`html-loader!./achievementChallenges.html`).default;
import GetChallengesByAchievement from "../../../../../api/models/responses/GetChallengesByAchievement.response";
import PostRequest from "../../../../../api/models/requests/post.request";
import restEnum from "../../../../../api/enums/rest.enum";

class achievementChallengesCtrl {

  constructor($scope, $rootScope, RestManager, Modals, mixpanelService, achievementsService, MemberService) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.Modals = Modals;
    this.mixpanelService = mixpanelService;
    this.achievementsService = achievementsService;
    this.MemberService = MemberService;

    // TODO refactor to use infinity scroll directive
    this.loadMore = true;
    this.busy = false;
    this.start = 0;
    this.limit = 5;
    this.items = [];
    this.scrollClass = '.achievement-challenges';
    this.onScrollFuc = this.onScroll.bind(this);
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.init();
    this.targetUser =  this.MemberService.profilePageMember ?
      this.MemberService.profilePageMember.user_name : this.member.user_name;
  }


  async init(){
    if(this.isActive()){
      await this.getData();
      $(this.scrollClass).on('scroll', this.onScroll.bind(this));
    }
    this.$rootScope.$emit('listReady', {isListEmpty:this.items.length === 0});
  }

  isActive(){
    return this.achievement.is_active || this.isImageMode();
  }

  isImageMode(){
    return this.imageId;
  }

  async getData() {
    console.log('getData');
    this.busy = true;
    let data = {
      unique_key: this.achievement.unique_key,
      start: this.start,
      limit: this.limit
    };
    if(this.isImageMode()){
      data.image_id = this.imageId;
    }else {
      data.member_id = this.MemberService.profilePageMember.id;
    }
    const responseData = new GetChallengesByAchievement(
      await this.RestManager.postRequest(
        new PostRequest({
          endpoint: restEnum.END_POINTS.GET_CHALLENGES_BY_ACHIEVEMENT.NAME,
          data: data
          // mockState: restEnum.END_POINTS.GET_CHALLENGES_BY_ACHIEVEMENT.STATES["1"]
        })
      )
    );

    if(!responseData.success){
      return;
    }
    if (this.start === 0) {
      this.items = responseData.items;
    } else {
      this.items = [...this.items, ...responseData.items];
    }
    this.loadMore = responseData.items.length === this.limit;
    this.start += this.limit;
    this.busy = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  onScroll(e) {
    const newScrollLeft = $(e.target).scrollLeft();
    const width = $(e.target).innerWidth();
    const scrollWidth = $(e.target).get(0).scrollWidth;
    const offset = 300;
    if (scrollWidth - newScrollLeft - width <= offset) {
      if(!this.busy && this.items.length && this.loadMore){
        this.getData();
      }
    }
  }
}
achievementChallengesCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'Modals', 'mixpanelService', 'achievementsService', 'MemberService'
];
export default {
  template: template,
  controller: achievementChallengesCtrl,
  bindings: {
    achievement: '<',
    imageId:'@',
  }
};
