import challengeEnum from "../../../api/enums/challenge.enum";
const template = require(`html-loader!./challengesClosed.html`).default;
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";
import {ArrayHelper} from "../../../../../core/helpers/ArrayHelper";
import {Subject} from "rxjs";

const IS_MOBILE = 768;

class challengesClosedCtrl {

  subject = new Subject();
  constructor(
    $timeout,
    $location,
    Modals,
    $scope,
    ChallengesManager,
    tagEventService,
    mixpanelService,
    SocketService,
    NgChallengesService,
    MemberService,
    JoinedChallengesPageService,
    MediatorService,
  ) {
    'ngInject';
    this.busy = true;
    this.allLoaded = false;
    this.items = [];
    this.$timeout = $timeout;

    this.$location = $location;
    this.Modals = Modals;
    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.mixpanelService = mixpanelService;
    this.SocketService = SocketService;
    this.challengesService = NgChallengesService;
    this.MemberService = MemberService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.mediatorService = MediatorService;
    this.start = JoinedChallengesPageService.closedChallengesStartDefaultValue;
    this.onResize = _.debounce(this.onResize.bind(this), 250);
  }

  async $onInit() {

    // mobile view
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    $(window).on('resize', this.onResize);
    // page view
    this.tagEventService.sendPageViewEvent({title: 'Closed challenges'});
    this.JoinedChallengesPageService.$closedChallenges.pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((data)=>{
      if(data === undefined){return}
      this.items = data.challenges;
      this.allLoaded = data.allLoaded;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });
    this.JoinedChallengesPageService.getClosedChallenges();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(window).off('resize', this.onResize);
  }

  more() {
    this.eventMixPanelAction();
    this.JoinedChallengesPageService.getClosedChallenges(true);
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  eventMixPanelAction() {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.CLOSED_CHALLENGES,
          ui_name: mixpanelEventsEnum.UI_NAME.MORE_BUTTON
        }
      }
    );
  }
}
challengesClosedCtrl.$inject = [
  '$timeout',
  '$location',
  'Modals',
  '$scope',
  'ChallengesManager',
  'tagEventService',
  'mixpanelService',
  'SocketService',
  'NgChallengesService',
  'MemberService',
  'JoinedChallengesPageService',
  'MediatorService',
];
export default {
  template: template,
  controller: challengesClosedCtrl
};
