const template = require(`html-loader!./myChallengesManageItemMobile.html`).default;
import badgeV2Mode from "../../../../api/enums/badgeV2.enum";

class myChallengesManageItemMobileController {

  constructor($element, Modals, $location, $timeout, $interval, photoSwipe, gsModals, $cookies, $state, MemberService) {
    'ngInject';
    this.$element = $element;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.$location = $location;
    this.$interval = $interval;
    this.photoSwipe = photoSwipe;
    this.gsModals = gsModals;
    this.$cookies = $cookies;
    this.$state = $state;
    this.badgeV2Mode = badgeV2Mode;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/challenge/${this.challenge.url}/details`;
  }

  vote(mode) {
    this.gsModals.open('vote', { challenge: this.challenge, mode });
  }
}
myChallengesManageItemMobileController.$inject = [
  '$element', 'Modals', '$location', '$timeout', '$interval', 'photoSwipe', 'gsModals', '$cookies', '$state', 'MemberService'
];
export default {
  template: template,
  controller: myChallengesManageItemMobileController,
  bindings: {
    challenge: '<',
    settings: '<'
  }
};
