import MixedPanelEventData from "./mixpanel.data";
import mixpanelEventsEnum from "./mixpanel.enum";
import environment from '../../gsApp.environment';

class mixpanelService {

  /* TODO: merge issues: is this redundant? */

  constructor(
    $rootScope,
    $location,
    stateService,
    gsNotificationsService,
    $state,
    gsBrowserService,
    MemberService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$location = $location;
    this.stateService = stateService;
    this.gsNotificationsService = gsNotificationsService;
    this.$state = $state;
    this.gsbrowserServiceData = gsBrowserService.initBrowserData();
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();

    /**
     * Events:
     *
     * pv - page view
     * */
    this.events = {
      pv: 'pv'
    };
  }

  init() {
    const mixpanelToken = environment.mixpanelToken;
    const mixpanelApiHost = environment.mixpanelHost;

    mixpanel.init(
      mixpanelToken,
      {
        api_host: mixpanelApiHost,
        track_pageview: false,
      }
    );
  }

  track({
    event = mixpanelEventsEnum.EVENT_NAME.WEB_CLIENT_EVENTS,
    data = {}
  }) {
    console.log('*** mixpanel ***');
    console.log('mixpanel:event ', event);
    console.log('mixpanel:data ', new MixedPanelEventData(data));
    // console.log('data ', data);
    const urlParams = this.$location.search();

    /* dynamic */
    data.current_page = data.current_page ? data.current_page : this.$state.current.name;
    data.member_id = this.member.id;
    data.browser_type = this.gsbrowserServiceData.type;
    data.browser_version = this.gsbrowserServiceData.version;

    if (urlParams && Object.keys(urlParams).length > 0) {
      data.url_params = urlParams;
    }



    mixpanel.track(event, new MixedPanelEventData(data));
  }

  trackProfilePageImpression({ui_name}){
    let data = {
      ui_action: mixpanelEventsEnum.UI_ACTION.IMPRESSION,
      ui_category: mixpanelEventsEnum.UI_CATEGORY.PROFILE_PAGE_IMPRESSION,
      ui_name: ui_name,
    };
    if (this.MemberService.profilePageMember) {
      data.member_id = this.MemberService.profilePageMember.id;
    }
    this.track({
      data
    });
  }

  trackViewPage(
    data = {}
  ) {
    this.track({
      data: data
    });
  }

  getTotalAmountNotification() {
    const isNotification = this.gsNotificationsService.notificationData;
    let isNotificationNewAmout = '';

    if (isNotification && isNotification.new) {
      isNotificationNewAmout = isNotification.new.total;
    }

    return isNotificationNewAmout;
  }
}
mixpanelService.$inject = [
  '$rootScope',
  '$location',
  'stateService',
  'gsNotificationsService',
  '$state',
  'gsBrowserService',
  'MemberService',
];
export default mixpanelService;
