function config($stateProvider, $urlRouterProvider) {
  'ngInject';
  $stateProvider
    .state('gs.discover', {
      url: '/discover',
      redirectTo: 'gs.discover.winners',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'discover'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.discover.winners', {
      url: '/winners',
      component: 'discoverPhotos',
      params: {
        type: 'winners'
      }
    })
    .state('gs.discover.trending', {
      url: '/trending',
      component: 'discoverPhotos',
      params: {
        type: 'trending'
      }
    })
    .state('gs.discover.guruPicks', {
      url: '/guru-picks',
      component: 'discoverPhotos',
      params: {
        type: 'guru_picks'
      }
    })
    .state('gs.discover.search', {
      url: '/search/:search',
      component: 'discoverSearch',
      params: {
        search: null
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
