const template = require(`html-loader!./challengesSchedule.html`).default;
import challengeEnum from '../../../../../api/enums/challenge.enum';
import {getOldDate, parseFromMilisecToSec} from "../../../../../helpers/date.helper";
import challengesScheduleData from './challengesSchedule.data';

class challengesScheduleCtrl {
  /** @param {ChallengesManager} ChallengesManager */

  constructor(
    $timeout,
    $element,
    $state,
    ChallengesManager,
    $scope,
    $stateParams,
    challengeService,
    Modals
  ) {
    'ngInject';
    this.$ctrl = this;
    this.start = 0;
    this.limit = 100;
    /* TODO: not used anymore? */
    this.challengeStatusesList = [
      challengeEnum.STATUSES.DRAFT,
      challengeEnum.STATUSES.PENDING,
      challengeEnum.STATUSES.PENDING_FILTERED,
      challengeEnum.STATUSES.UPCOMING,
      challengeEnum.STATUSES.ACTIVE,
      challengeEnum.STATUSES.UNASSIGNED,
      challengeEnum.STATUSES.IN_BID,
      challengeEnum.STATUSES.ASSIGNED,
      challengeEnum.STATUSES.PLANNING
    ];
    this.statusColor = {
      unassigned: 'tomato',
      assigned: 'orange',
      in_bid: 'black',
      upcoming: '#218ccc',
      pending: '#218ccc',
      draft: '#218ccc',
      pending_filtered: '#218ccc',
      active: '#218ccc',
    };
    this.challenges = [];
    this.busy = true;
    this.allLoaded = false;
    this.challengeFilterList = [
      challengeEnum.SORT.START_TIME,
      challengeEnum.SORT.CLOSE_TIME,
      challengeEnum.SORT.LAST_UPDATE_TIME,
      challengeEnum.SORT.VOTES,
      challengeEnum.SORT.ENTRIES,
      challengeEnum.SORT.PLAYERS,
      challengeEnum.SORT.ID
    ];
    this.challengeFilterDefaultEnum = challengesScheduleData;
    this.challengeFilterSelected = null;
    this.challengeFilterStatic = 'Start time';

    this.$timeout = $timeout;
    this.$element = $element;
    this.$state = $state;
    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.challengeEnum = challengeEnum;
    this.challengeService = challengeService;
    this.Modals = Modals;
  }

  $onChanges() {
    this.status = this.$stateParams.status;
    this.start = parseInt(this.$stateParams.start) || this.start;
    this.limit = parseInt(this.$stateParams.limit) || this.limit;
    this.loadMore();
  }

  /** @return void */
  async loadMore() {
    if (this.allLoaded) {
      return;
    }

    const params = {
      status: this.status,
      start: this.start,
      limit: this.limit
    };

    if (this.challengeFilterSelected) {
      Object.assign(params, {sort_field: this.challengeFilterSelected.VALUE, order: challengesScheduleData[this.status.toUpperCase()].order});
    } else {
      Object.assign(params, challengesScheduleData[this.status.toUpperCase()]);
      this.challengeFilterSelected = challengeEnum.SORT[params.sort_field.toUpperCase()];
      this.challengeFilterStatic = this.challengeFilterSelected.NAME;
    }

    const res = await this.ChallengesManager.listChallenges(params);

    if (!res.success) {
      return false;
    }

    if (res.challenges.length < this.limit) {
      this.allLoaded = true;
    }

    this.start += this.limit;
    this.challenges.push(...res.challenges);

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onStatusChange() {
    /* reset */
    this.start = 0;
    this.limit = 100;
    this.challenges = [];
    this.allLoaded = false;
    this.busy = true;
    /* ./reset */

    this.loadMore();
  }

  onDateChange(){}

  changeOrder(challengeSelectedFilter) {
    this.challengeFilterSelected = challengeSelectedFilter;
    this.onStatusChange();
  }

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  async save(challenge, data) {
    const isSuccess = await this.challengeService.saveChallenge(data, challenge);

    if (!isSuccess) {
      return false;
    }

    // reload page
    this.$state.go(this.$state.current, { force: true }, { reload: this.$state.current });
  }

  saveTime(challenge) {
    let data = {
      id: challenge.id,
      start_time: parseFromMilisecToSec(challenge.start_time),
      exposure_decay_minutes: challenge.exposure_decay_minutes,
    };

    if (challenge.is_bid_schedule) {
      data.bid_start_time = parseFromMilisecToSec(challenge.bid_start_time);
      data.is_bid_schedule = challenge.is_bid_schedule;
    } else {
      data.close_time = parseFromMilisecToSec(challenge.close_time);
    }

    this.save(challenge, data);
  }

  async bidNow(challenge) {
    const confirm = await this.Modals.confirm(
      'Bid Now?',
      'Do you want to bid this challenge to Gurus now?',
      'Bid Now',
      'Cancel'
    );

    if (!confirm) {
      return;
    }

    const data = {
      id: challenge.id,
      bid_start_time: parseFromMilisecToSec(getOldDate()),
    };

    this.save(challenge, data);
  }

  reject(event, challenge, status) {
    this.Modals.open('toDraft', event, { challenge: challenge, status: status});
  }

  async submit(challenge) {
    await this.challengeService.setAsPending(challenge, true);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async approve(challenge) {
    await this.challengeService.setAsUpcoming(challenge, true);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async setForBid(challenge) {
    await this.challengeService.setForBid(challenge, true);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async setAsUnassigned(challenge) {
    await this.challengeService.setAsUnassigned(challenge, true);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async setAsAssigned(challenge) {
    await this.challengeService.setAsAssigned(challenge, true);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
challengesScheduleCtrl.$inject = [
  '$timeout',
  '$element',
  '$state',
  'ChallengesManager',
  '$scope',
  '$stateParams',
  'challengeService',
  'Modals'
];
export default {
  template: template,
  controller: challengesScheduleCtrl,
  bindings: {},
};
