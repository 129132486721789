import {autoUpdateTypeEnum} from "../../../services/autoUpdate.service.data";

const template = require(`html-loader!./myChallengesCurrent.html`).default;

import {filters} from './myChallengesCurrent.data';
import {ResourceType} from "../../../../../core/models/enums";
import challengeEnum from "../../../api/enums/challenge.enum";
import resolutionEnum from "../../../api/enums/resolution.enum";
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";
import brazeEnum from "../../../api/enums/braze.enum";
import {MediatorMessageKey} from "../../../../../core/services/mediator.service";
import {Subject, takeUntil} from 'rxjs';
import {
  ChallengeTurboState,
  ChallengeTurboUnlockType,
  ChallengeUnlockUsage
} from "../../../../../modules/challenges/enums/challenges.enum";
import {NgDateHelper} from "../../../../../core/helpers/ngDateHelper";
import {JoinedChallengesPageService} from "../../../../../modules/challenges/services/joined-challenges-page.service";
import {
  ActiveChallengeActionButtonType
} from "../../../../../modules/challenges/components/active-challenge-action-button/active-challenge-action-button.component";
import {BankrollService} from "../../../../../modules/bankroll/bankroll.service";

const IS_MOBILE = resolutionEnum.WIDTH.MOBILE_CHALLENGE_ITEM;

class myChallengesCurrentCtrl {

  subject = new Subject();

  constructor(
    $scope,
    $timeout,
    Modals,
    $stateParams,
    $location,
    Toasts,
    $cookies,
    $document,
    $rootScope,
    $state,
    $window,
    Facebook,
    onBoarding,
    ChallengesManager,
    deepLinkService,
    RestManager,
    brazeService,
    tagEventService,
    challengeService,
    mixpanelService,
    MediatorService,
    NgLocalStorageService,
    autoUpdateService,
    JoinedChallengesPageService,
    SessionConfigService,
    MemberService,
    StoreService,
    BankrollService,
  ) {
    'ngInject';
    this.busy = true;
    this.filters = filters;

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.Toasts = Toasts;
    this.$cookies = $cookies;
    this.$document = $document;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$window = $window;
    this.Facebook = Facebook;
    this.onBoarding = onBoarding;
    this.ChallengesManager = ChallengesManager;
    this.deepLinkService = deepLinkService;
    this.RestManager = RestManager;
    this.brazeService = brazeService;
    this.tagEventService = tagEventService;
    this.challengeEnum = challengeEnum;
    this.challengeService = challengeService;
    this.mixpanelService = mixpanelService;
    this.mediatorService = MediatorService;
    this.NgLocalStorageService = NgLocalStorageService;
    this.autoUpdateService = autoUpdateService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.SessionConfigService = SessionConfigService;
    this.MemberService = MemberService;
    this.StoreService = StoreService;
    this.BankrollService = BankrollService;
    this.brazeEnum = brazeEnum;
    this.ActiveChallengeActionButtonType = ActiveChallengeActionButtonType;
    this.cId = null;
    this.isSubmitDisabled = false;

    this.onRemoveSuggestedChallenge = this.onRemoveSuggestedChallenge.bind(this);
    this.onResize = _.debounce(this.onResize.bind(this), 250);
    this.member = this.MemberService.getCurrentMember();
    this.MemberService.getMemberPath().then((res)=>{
      this.member_path = res.member_path;
    });

    this.mediatorService.on(MediatorMessageKey.DISABLE_SUBMIT_BUTTON).pipe(
      takeUntil(this.subject)
    ).subscribe(event => {
      this.isSubmitDisabled = event.data;
    });
  }

  $onInit() {
    // //TODO need to remove form deep link service
    // if (this.deepLinkService.getParam('cId')){
    //   this.deepLinkService.reset();
    // }
    this.brazeService.getFilteredContentCard({filterConfig: brazeEnum.CONTENT_CARDS.JOINED_CHALLENGES});
    // mobile view
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    $(window).on('resize', this.onResize);
    // page view
    this.tagEventService.sendPageViewEvent({title: 'My challenges'});

    // guru pro
    if (this.member.member_group_id === 2000) {
      this.$state.go('gs.challenges.myChallenges.manage');
      return;
    }

    // scroll to top
    this.$window.scrollTo(0, 0);
    // date
    this.date = new Date().getTime();
    // tracking codes
    this.tc = this.$rootScope.tc;
    this.handleUrlParams();

    // active suggest submit event
    this.offRemoveSuggestedChallenge = this.$rootScope.$on('removeSuggestedChallenge', this.onRemoveSuggestedChallenge);
    // order challenges by
    this._orderBy = this.$cookies.get('myChallengesOrder') || 'time_joined';
    this.init();
  }

  getParamsAsObject() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    urlParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
    return paramsObject;
  }

  async handleUrlParams(){
    const params = this.getParamsAsObject();
    const PARAM_SHOP = 'shop';
    const PARAM_P_ID = 'p_id';
    const PARAM_STORE_ITEM_ID = 'store_item_id';
    const PARAM_BROADCAST = 'broadcast';
    const myParamNames = [PARAM_SHOP, PARAM_P_ID, PARAM_STORE_ITEM_ID,PARAM_BROADCAST];
    let value;
    for (let key of myParamNames){
      value = params[key];
      if(value){
        switch (key){
          case PARAM_SHOP:
            this.challengeService.openStoreKeysSection();
            break;
          case PARAM_P_ID:
            this.$location.search('p_id', null);
            this.challengeService.openStorePayment(Number(value));
            break;
          case PARAM_STORE_ITEM_ID:
            const res = await this.StoreService.payStoreItem(Number(value));
            if(res.success){
              this.BankrollService.getBankroll().subscribe(()=>{});
            }
            this.$location.search('store_item_id', null);
            break;
          case PARAM_BROADCAST:
            this.Modals.open('broadcast', null, {value: value});
            this.$location.search('broadcast', null);
            break;
        }
        break;
      }
    }
  }

  openChallengeJoinModal(challenge){
    if (challenge) {
      this.Modals.open('challengeJoin', null, {challenge: challenge});
    }
  }

  async init(){
    // force if this is the first the page loaded
    if(this.JoinedChallengesPageService.isFirstTimeFetchMyActiveChallenges){
      await this.getMyActiveChallenges();
      this.initAutoUpdate();
    } else {
      if (this.$stateParams.activeChallengeIdToAdd) {
        // get new joined challenge from server and add it to the list
        await this.getMyActiveChallenge(this.$stateParams.activeChallengeIdToAdd);
      }
      this.initAutoUpdate(true);
    }

    const cId = this.deepLinkService.getParam('cId');
    if (cId){
      const challenge = this.JoinedChallengesPageService.activeChallengesMap.get(cId);
      this.deepLinkService.check({challenge});
    }
    this.busy = false;
    // on board
    if (this.SessionConfigService.onBoarding) {
      this.onBoardingRun = true;
      this.$timeout(() => this.onBoarding.next(), 150);
    }
    this.openChallengeJoinModal(this.$stateParams.challenge);

    //handle deeplink not related to challenge
    if(this.deepLinkService.getDl() && this.deepLinkService.getDl().type === 'lb'){
      this.deepLinkService.check();
    }
    if(this.$stateParams.challengeIdToScroll){
      this.scrollToChallenge(this.$stateParams.challengeIdToScroll);
    }
    this.initMediatorEvents();
  }

  initMediatorEvents(){
    this.mediatorService.on(MediatorMessageKey.OPEN_MINI_GAME).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((message)=>{
      this.Modals.open('miniGame', null, {
        challenge: message.data
      });
    });

    this.mediatorService.on(MediatorMessageKey.OPEN_TURBO_RULES).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async (message)=> {
      const lockedType = message.data.lockedType;
      const state = message.data.state;
      const amount = message.data.amount;
      const coinsCurrentAmount = this.challengeService.getCoinsAmount();
      const keyStoreEventParams = {outOfResourceId: 'out_key' + Date.now()};
      const coinsStoreEventParams = {outOfResourceId: 'out_coins' + Date.now()};

      if (message.data && state !== ChallengeTurboState.FREE && this.isNotEnoughResourcesToUnlockTurbo(lockedType, amount)) {

        if (lockedType === ChallengeTurboUnlockType.KEY && this.challengeService.isKeysEmpty()) {
          this.brazeService.logCustomEvent('out_key', keyStoreEventParams);
          await this.brazeService.waitForBrazeMessage(this.openStoreKeysSection.bind(this), keyStoreEventParams.outOfResourceId);
        }

        if (lockedType === ChallengeTurboUnlockType.COINS && amount > coinsCurrentAmount) {
          this.brazeService.logCustomEvent('out_coins', coinsStoreEventParams);
          await this.brazeService.waitForBrazeMessage(this.openStoreCoinsSection.bind(this), coinsStoreEventParams.outOfResourceId);
        }
      } else {
        this.Modals.open('challengeActionLocked', null, message.data);
      }
    });

    this.mediatorService.on(MediatorMessageKey.OPEN_TURBO_ACTIVATION).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((message)=>{
      this.Modals.open('challengeActionActivate', null, message.data);
    });

    this.mediatorService.on(MediatorMessageKey.UPDATE_ACTIVE_CHALLENGES).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async () => {
      this.updateActiveChallenges();
    });

    this.mediatorService.on(MediatorMessageKey.START_AUTO_UPDATE_ACTIVE_CHALLENGES).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async () => {
      this.initAutoUpdate(true);
    });

    this.mediatorService.on(MediatorMessageKey.STOP_AUTO_UPDATE_ACTIVE_CHALLENGES).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async () => {
      this.stopAutoUpdateActiveChallenges();
    });

    this.mediatorService.on(MediatorMessageKey.RELOAD_ACTIVE_CHALLENGES).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async () => {
      this.getMyActiveChallenges();
    });

    this.mediatorService.on(MediatorMessageKey.GET_ACTIVE_CHALLENGE).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async (message) => {
      if(message.data.challengeId){
        await this.getMyActiveChallenge(message.data.challengeId);
      }
    });

    this.mediatorService.on(MediatorMessageKey.REMOVE_ACTIVE_CHALLENGE).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((message) => {
      if(message.data.challengeId){
        this.JoinedChallengesPageService.removeActiveChallenge(message.data.challengeId);
      }
    });

    this.mediatorService.on(MediatorMessageKey.LOCKED).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async (message) => {
      const challenge = message.data;
      if (challenge) { // data is challenge object
        let res;
        if(challenge.member.turbo?.turbo_unlock_type === ChallengeTurboUnlockType.COINS){
          res = await this.challengeService.unlockTurboWithCoins(challenge);
        } else {
          res = await this.challengeService.keyUnlock(challenge, ChallengeUnlockUsage.TURBO);
        }

        this.mediatorService.broadcast(MediatorMessageKey.LOCKED_RESULT, res.success);
      }
    });

    this.mediatorService.on(MediatorMessageKey.ADD_ACTIVE_CHALLENGE).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(async (message) => {
      if(message.data && message.data.challengeId){
        const res = await this.getMyActiveChallenge(message.data.challengeId);
        if(res.challenge){
          this.scrollToChallenge(res.challenge.id, true);
          // exhibition popup
          if (res.challenge.isExhibition()) {
            const openExhibitionShop = await this.Modals.open('challengeIntro', null, {
              challenge: res.challenge,
              closeOnApply: true
            });
            if(openExhibitionShop){
              await this.challengeService.openExhibitionShop(res.challenge);
            }
          }
          if (message.data.openJoinModal) {
            this.openChallengeJoinModal(res.challenge);
          }
        }
      }
    });
  }

  async getMyActiveChallenge(challengeId){
    return await this.JoinedChallengesPageService.getMyActiveChallenge(challengeId);
  }

  initAutoUpdate(initCallbackOnStart = false) {
    let autoUpdateConfig = {
      type: autoUpdateTypeEnum.MY_ACTIVE_CHALLENGES_UPDATE,
      callback: this.updateActiveChallenges.bind(this),
      initCallbackOnStart: initCallbackOnStart,
      delay: this.JoinedChallengesPageService.updateActiveChallengesInterval * NgDateHelper.millisecondsInSecond
      // delay: 4 * NgDateHelper.millisecondsInSecond
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateConfig);
  }

  async updateActiveChallenges(){
   return await this.JoinedChallengesPageService.getMyActiveChallengesUpdate();
  }

  async getMyActiveChallenges() {
    await this.JoinedChallengesPageService.getMyActiveChallenges();
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    this.stopAutoUpdateActiveChallenges();
    this.$document.off('scroll', this.onScroll);
    $(window).off('resize', this.onResize);
    if(this.offRemoveSuggestedChallenge){
      this.offRemoveSuggestedChallenge();
    }
    this.stopAutoUpdateActiveChallenges();
  }

  stopAutoUpdateActiveChallenges(){
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.MY_ACTIVE_CHALLENGES_UPDATE);
  }

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  selectFilter(filter) {
    this.eventMixPanelAction({orderType: filter.name});
    this.orderBy = filter.value;
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  scrollToChallenge(challengeId, highlightChallengeAfterScroll){
    this.challengeService.scrollToChallenge({
      targetClass:`.c-id-${challengeId}`,
      highlightChallengeAfterScroll: highlightChallengeAfterScroll
    });
  }

  onRemoveSuggestedChallenge(event, suggestedChallengeToRemove) {
    this.JoinedChallengesPageService.removeSuggestedChallenge(suggestedChallengeToRemove.id);
  }

  get orderBy() {
    return this._orderBy;
  }

  set orderBy(value) {
    this._orderBy = value;
    this.$cookies.put('myChallengesOrder', value);
  }

  openFbInvite(){
    this.brazeService.sendShowInviteMessage();
    // this.Facebook.invite(res.deeplink);
  }

  eventMixPanelAction({uiName, orderType}) {
    let data = {};

    if (orderType) {
      data.ui_category = mixpanelEventsEnum.UI_CATEGORY.ACTIVE_CHALLENGE;
      data.ui_name = mixpanelEventsEnum.UI_NAME.APPLY_ORDER_BY;
      data.order_type = orderType;
    } else {
      data.ui_category = mixpanelEventsEnum.UI_CATEGORY.STORE_BUTTON;
      data.ui_name = uiName;
      data.balance_swaps = this.challengeService.getSwapsAmount();
      data.balance_keys = this.challengeService.getKeysAmount();
      data.balance_fills = this.challengeService.getFillAmount();
    }

    this.mixpanelService.track({data});
  }

  openStoreKeysSection() {
    this.challengeService.openStoreKeysSection();
  }

  openStoreCoinsSection() {
    this.challengeService.openStoreCoinsSection();
  }

  isNotEnoughResourcesToUnlockTurbo(lockedType, unlockAmount) {
    if (lockedType === ChallengeTurboUnlockType.KEY) {
      return this.challengeService.isKeysEmpty();
    }
    if (lockedType === ChallengeTurboUnlockType.COINS) {
      return this.challengeService.getCoinsAmount() < unlockAmount;
    }
    return false
  }
}
myChallengesCurrentCtrl.$inject = [
  '$scope',
  '$timeout',
  'Modals',
  '$stateParams',
  '$location',
  'Toasts',
  '$cookies',
  '$document',
  '$rootScope',
  '$state',
  '$window',
  'Facebook',
  'onBoarding',
  'ChallengesManager',
  'deepLinkService',
  'RestManager',
  'brazeService',
  'tagEventService',
  'challengeService',
  'mixpanelService',
  'MediatorService',
  'NgLocalStorageService',
  'autoUpdateService',
  'JoinedChallengesPageService',
  'SessionConfigService',
  'MemberService',
  'StoreService',
  'BankrollService',
];
export default {
  template: template,
  controller: myChallengesCurrentCtrl
};
