import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";
import {ClaimStateEnum} from "../../../../../core/enum/claim-state.enum";
import {ResourceNameEnum} from "../../../../../core/enum/resource-name.enum";
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";
import {Subject} from "rxjs";

const template = require(`html-loader!./myChallengesCompleted.html`).default;

class myChallengesCompletedCtrl{

  subject = new Subject();
  challenges;
  allLoaded = false;
  constructor(
    $scope,
    gsScrollTopService,
    tagEventService,
    RestManager,
    $stateParams,
    challengeService,
    BankrollService,
    brazeService,
    mixpanelService,
    JoinedChallengesPageService,
    MediatorService,
  ) {
    'ngInject';
    this.$scope = $scope;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.RestManager = RestManager;
    this.$stateParams = $stateParams;
    this.challengeService = challengeService;
    this.bankrollService = BankrollService;
    this.brazeService = brazeService;
    this.mixpanelService = mixpanelService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.mediatorService = MediatorService;
    this.busy = false;

    this.$scope.$on('claim_reward', async (_, challengeId) => {
      await this.claimReward(challengeId);
    });
  }

  async $onInit() {
    await this.init();
  }

  async init(){
    this.onScroll = this.onScroll.bind(this);
    $(window).on('scroll', this.onScroll);
    this.tagEventService.sendPageViewEvent({title: 'My completed challenges'});

    this.JoinedChallengesPageService.$myClosedChallenges.pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((data)=>{
      if(data === undefined){return}
      this.challenges = data.challenges;
      this.allLoaded = data.allLoaded;
      this.showing_last_days = data.showing_last_days;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });
    await this.getMyCompletedChallenges();
    this.isFirstLoaded = true;
    let challengeIdToScroll = this.getChallengeIdToScroll();
    if(challengeIdToScroll){
      await this.challengeService.scrollToChallenge({
        targetClass:`.my-challenges-completed__challenges__item__${challengeIdToScroll}`,
        highlightChallengeAfterScroll: true
      });
      setTimeout(()=>{
        this.brazeService.showDelayedInAppMessage();
      },5000)
    } else {
      this.gsScrollTopService.scrollTop();
    }
  }

  getChallengeIdToScroll(){
    if(this.$stateParams.scrollToLastNewChallenge){
      const firstNewChallenge = this.challenges.find( challenge => challenge.member.is_new);
      return  firstNewChallenge ? firstNewChallenge.id : undefined;
    } else if(this.$stateParams.challengeIdToScroll){
      return  this.$stateParams.challengeIdToScroll;
    }
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(window).off('scroll', this.onScroll);
  }

  async getMyCompletedChallenges(forceRefresh = false){
    if (!this.busy) {
      this.busy = true;
      await this.JoinedChallengesPageService.getMyCompletedChallenges(forceRefresh);
      this.busy = false;
    }
  }

  async claimReward(challengeId) {
    this.mixpanelService.track({
      event:mixpanelEventsEnum.EVENT_NAME.CHALLENGE_SUMMARY,
      data:{
        ui_name: mixpanelEventsEnum.UI_NAME.CHALLENGE_SUMMARY_CLOSE,
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
        ui_state: 'claim',
      }
    });

    const response = await this.RestManager.claimResources(challengeId);
    if (response.success) {
      const claimedChallenge = this.challenges.find(challenge => challenge.id === challengeId);
      if (claimedChallenge) {
        (claimedChallenge.member.rewards_by_section ??= {}).claim_state = ClaimStateEnum.CLAIMED;
      }

      const resources = claimedChallenge.member.rewards_by_section.sections.find(
        section => section.name === ResourceNameEnum.TOTAL
      )?.resources;

      const bankrollItems = resources.map(resource => ({
        resourceType: this.bankrollService.getResourceTypeByPrizeType(resource.type),
        amountToAddOrReduce: resource.value
      }));

      this.bankrollService.updateBankrollItemAmount(bankrollItems);
    }

  }

  onScroll() {
    let dHight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    if (
      $(window).scrollTop() + $(window).height() > (dHight-300)
      && this.challenges
      && !this.allLoaded
      && !this.busy
    ) {
      this.getMyCompletedChallenges(true);
    }
  }
}
myChallengesCompletedCtrl.$inject = [
  '$scope',
  'gsScrollTopService',
  'tagEventService',
  'RestManager',
  '$stateParams',
  'challengeService',
  'BankrollService',
  'brazeService',
  'mixpanelService',
  'JoinedChallengesPageService',
  'MediatorService',
];
export default {
  template: template,
  controller: myChallengesCompletedCtrl
};
