import tableEnum from "../../../../../api/enums/table.enum";

class challengesSponsorsService {
  constructor(
    RestManager,
    $rootScope,
    Toasts
  ) {
    'ngInject';

    this.RestManager = RestManager;
    this.$rootScope = $rootScope;
    this.toasts = Toasts;

    this.config = {
      pagination: {
        start: 1,
        limit: 10
      },
      gsTable: {
        type: tableEnum.TABLE_TYPE.CHALLENGES_SPONSOR.NAME,
        entities: [],
        filter: true,
        duplicate: this.duplicate.bind(this)
      },
      buttonText: 'sponsor',
      states: {
        'new': 'gs.gsAdmin.contentManager.challenges.challengesSponsors.new',
        edit: 'gs.gsAdmin.contentManager.challenges.challengesSponsors.edit',
        home: 'gs.gsAdmin.contentManager.challenges.challengesSponsors'
      },
      loadMore: {
        loadMoreFn: async (
          {
            start = this.config.pagination.start,
            limit = this.config.pagination.limit
          }
        ) => {
          await this.putSponsorsToConfig({start, limit});
        },
        isMore: true
      }
    };
  }

  //region Pure methods
  getSponsorConfig() {
    return this.config;
  }

  async createSponsor(data) {
    return await this.RestManager.createSponsor(data);
  }

  async getAllSponsors(
    {
      start = this.config.pagination.start,
      limit = this.config.pagination.limit
    }
  ) {
    return await this.RestManager.getAllSponsors({start, limit});
  }

  isLoadmore(flag) {
    this.config.loadMore.isMore = flag;
  }
  //endregion

  async putSponsorsToConfig(
    {
      start = this.config.pagination.start,
      limit = this.config.pagination.limit
    }
  ) {
    const responseSponsors = await this.getAllSponsors({start, limit});

    if (responseSponsors.success) {
      this.isLoadmore(responseSponsors.sponsors.length >= this.config.pagination.limit);
      this.config.gsTable.entities = [...this.config.gsTable.entities, ...responseSponsors.sponsors];
      this.$rootScope.$emit('challengeSponsorsLoadMore');
    }
  }

  async putSponsorsToConfigGetLatest(
    {
      start = this.config.pagination.start,
      limit = this.config.pagination.limit
    }
  ) {
    const responseSponsors = await this.getAllSponsors({start, limit});

    if (responseSponsors.success) {
      let latestItem = responseSponsors.sponsors[0];
      console.log('latestItem',latestItem)
      this.config.gsTable.entities = [latestItem, ...this.config.gsTable.entities];
    }
  }

  updateEntity(entity) {
    let changedEntityIndex = this.config.gsTable.entities.findIndex(entityItem => entityItem.id === entity.id);
    this.config.gsTable.entities[changedEntityIndex] = entity;
  }

  async duplicate(entity) {
    let copyEntity = angular.copy(entity);
    delete copyEntity.id;
    await this.createSponsor(copyEntity);
    this.$rootScope.$emit('challengeSponsorsSave');
  }

  notification(message) {
    this.toasts.message(message, document.querySelector('.gs-admin-sponsors'), false, 'top right');
  }
}

challengesSponsorsService.$inject = [
  'RestManager',
  '$rootScope',
  'Toasts'
];

export default challengesSponsorsService;
