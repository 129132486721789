const millisecondsInSecond = 1000,
  secondsInMinute = 60,
  minutesInHour = 60,
  hoursInDay = 24
;
const millisecondsInMinute =  millisecondsInSecond * secondsInMinute;
const millisecondsInHour =  millisecondsInSecond * secondsInMinute * minutesInHour;

function getUnixTime(){
  return parseFromMilisecToSec(new Date());
}

function parseFromMilisecToSec(milliseconds) {
  return milliseconds ? Math.round(milliseconds / millisecondsInSecond) : milliseconds;
}

function parseFromSecToMilisec(sec) {
  return sec ? (sec * millisecondsInSecond) : sec;
}

function resetSecond(date){
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

function pareDateFromUnixTime(unixtime, monthToAdd, daysToAdd) { // only use it to reset seconds
  if(!unixtime) return unixtime;

  let date = resetSecond(new Date(parseFromSecToMilisec(unixtime)));
  addMonth(date, monthToAdd);
  addDays(date, daysToAdd);
  return date;
}

function getDate ({daysToAdd, monthToAdd, hoursToAdd, initTime, resetSec = true} = {}) {
  let date = initTime ? new Date(initTime) : new Date();
  resetSecond(date);
  /* TODO: move this logic out from getDate method.
   * For example, like resetSec method.
   * OR if we have a multiple actions inside this method, then, we need to change the name.
   * For example, dateWithoutSecondsAddDays.
   * But I think from a business logic there is a better name.
   * For example, createChallenge
   * */
  addMonth(date, monthToAdd);
  addDays(date, daysToAdd);
  addHours(date, hoursToAdd);
  return date;
}

function addDays(date, daysToAdd){
  if(date && daysToAdd){
    date.setDate(date.getDate()+daysToAdd);
  }
  return date;
}

function addMonth(date, mounthToAdd){
  if(date && mounthToAdd) {
    date.setMonth(date.getMonth() + mounthToAdd);
  }
  return date;
}

function addHours(date, hoursToAdd){
  if(date && hoursToAdd) {
    date.setHours(date.getHours() + hoursToAdd);
  }
  return date;
}

function getOldDate(){
  return new Date(2020,0,1);
}

function getDateHours (date) {
  return Math.floor(date/1000/60/60);
}

/**
 * Convert a timestamp delta to amount of days.
 * @param {number} delta - difference between two dates.
 * @returns {number} - amount of days.
 */
function getDaysByDelta (delta) {/* Deprecated. Migrated to Date.helper.ts */
  return Math.floor(delta / (millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay));
}

/**
 * Convert a timestamp delta to amount of hours.
 * @param {number} delta - difference between two dates.
 * @returns {number} - amount of hours. From 0 to 23.
 */
function getHoursByDelta (delta) {/* Deprecated. Migrated to Date.helper.ts */
  return Math.floor((delta % (millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay)) / (millisecondsInSecond * secondsInMinute * minutesInHour));
}

/**
 * Convert a timestamp delta to amount of minutes.
 * @param {number} delta - difference between two dates.
 * @returns {number} - amount of hours. From 0 to 59.
 */
function getMinutesByDelta (delta) {
  return Math.floor((delta % (millisecondsInSecond * secondsInMinute * minutesInHour)) / (millisecondsInSecond * secondsInMinute));
}

/**
 * Convert a timestamp delta to amount of seconds.
 * @param {number} delta - difference between two dates.
 * @returns {number} - amount of seconds. From 0 to 59.
 */
function getSecondsByDelta (delta) {
  return Math.floor((delta % (millisecondsInSecond * secondsInMinute)) / millisecondsInSecond);
}

/**
 * Convert days to hours.
 * @param {number} days - amount of days.
 * @returns {number} - amount of hours.
 */
function daysToHours(days) {
  return days * hoursInDay;
}

export {
  pareDateFromUnixTime,
  getDate,
  getDateHours,
  parseFromMilisecToSec,
  parseFromSecToMilisec,
  getOldDate,
  millisecondsInSecond,
  secondsInMinute,
  minutesInHour,
  hoursInDay,
  millisecondsInMinute,
  millisecondsInHour,
  getDaysByDelta,
  getHoursByDelta,
  getMinutesByDelta,
  getSecondsByDelta,
  daysToHours,
  resetSecond,
  addDays,
  addMonth,
  getUnixTime,
};
