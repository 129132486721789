import { Injectable } from '@angular/core';
import {NgLocalStorageEnum} from "../models/enums";
import {NgLocalStorageService} from "./ng-local-storage.service";
import {Subject} from "rxjs";

@Injectable()
export class BattleFtueService {
  fadeoutBattlesArena$ = new Subject<void>();

  constructor(
    private ngLocalStorageService: NgLocalStorageService,
  ) {}

  get showBattleGuide(): boolean {
    return !this.ngLocalStorageService.getMemberDataByKey(NgLocalStorageEnum.TUTORIALS_FIRST_LOGIN);
  }
}
