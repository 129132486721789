import ChatMessageModel from "../api/models/chat/chatMessage.model";
import {parseFromMilisecToSec} from "../helpers/date.helper";

class chatService {

  constructor(notificationAlert, socketService, teamsService, $rootScope, rfc4122, md5, MemberService) {
    'ngInject';

    this.maxMessageLength = 4000;
    this.notificationAlert = notificationAlert;
    this.socketService = socketService;
    this.teamsService = teamsService;
    this.$rootScope = $rootScope;
    this.rfc4122 = rfc4122;
    this.md5 = md5;
    this.member = MemberService.getCurrentMember();
  }

  sendMessage({textMessage, selectedPhoto, replyMessage, selectedMentions= []}) {
    if (textMessage.length > this.maxMessageLength) {
      this.notificationAlert.open(`Max message length is ${this.maxMessageLength} chars`);
      return;
    }

    const socket = this.socketService.getSocket();
    const member = this.member;

    if (this.socketService.roomCreated && (textMessage.length > 0 || selectedPhoto)) {
      let reply;
      let media;
      if(selectedPhoto){
        selectedPhoto.members = [selectedPhoto.member];
        media = {
          images: [selectedPhoto]
        };
      }
      if(replyMessage){
        const replyMembers = [
          {
            id: replyMessage.memberID,
            user_name: replyMessage.memberUserName,
            name: replyMessage.memberName,
          }
        ];
        reply = {
          id: replyMessage.id,
          dateTime: replyMessage.origDateTime,
          members: replyMembers,
          message: replyMessage.origMessage || replyMessage.message,
          media: replyMessage.media
        };
      }
      const chatMessage = new ChatMessageModel({
        id: this._getMessageId(),
        chatID: this.teamsService.getChatId(),
        message: {
          text: textMessage,
          mentions: {
            members: selectedMentions
              .filter(selectedMention => textMessage.search(selectedMention.displayName) !== -1)
          }
        },
        memberID: member.id,
        memberName: member.name,
        memberUserName: member.user_name,
        avatar: member.avatar,
        dateTime: Math.round(parseFromMilisecToSec(new Date())),
        additional: {
          env: "web"
        },
        external: reply ? {reply} : null,
        media: media ? media : null
      });


      socket.emit(this.socketService.socketEvents.msgToServer, chatMessage);
      console.log(this.socketService.socketEvents.msgToServer, chatMessage);
    }
  }

  _getMessageId() {
    const id = this.md5.createHash(this.rfc4122.v4() + new Date().getTime());
    return id;
  }
}
chatService.$inject = [
  'notificationAlert', 'socketService', 'teamsService', '$rootScope', 'rfc4122', 'md5', 'MemberService'
];
export default chatService;
