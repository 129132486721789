import {gsIsMobile} from "../../helpers/gen.helper";
import {topTabMenuData} from "../../components/topTabsMenu/topTabMenu.data";
import resolutionEnum from "../../api/enums/resolution.enum";
import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";
import {MemberService} from "../../../../core/services/member.service";
import {SessionConfigService} from "../../../../core/services/session-config.service";

class challengesPageCtrl {
  /**
   * @param {onBoarding} onBoarding
   * @param {ModalsService} Modals
   * @param {pageData} pageData
   * @param {RestManager} RestManager
   * */

  constructor(
    $document,
    $state,
    $scope,
    pageData,
    onBoarding,
    $cookies,
    $timeout,
    Modals,
    $rootScope,
    RestManager,
    MemberService,
    SessionConfigService,
    NgMetaDataService
  ) {
    'ngInject';
    this.buttonPressed = false;

    this.$document = $document;
    this.$state = $state;
    this.$scope = $scope;
    this.pageData = pageData;
    this.onBoarding = onBoarding;
    this.$cookies = $cookies;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.MemberService = MemberService;
    this.SessionConfigService = SessionConfigService;
    this.topTabMenuData = topTabMenuData;
    this.NgMetaDataService = NgMetaDataService;

    this.skipIntro = this.skipIntro.bind(this);
    this.openLb = this.openLb.bind(this);

    // events
    $(document).on('skipIntro', this.skipIntro);
    this.offopenLb = this.$rootScope.$on('openLb', this.openLb);

    // destroy
    this.$scope.$on('$destroy', () => {
      if (this.member && this.member.intro) this.intro.destroy();
      $(document).off('skipIntro', this.skipIntro);
      this.offopenLb();
      window.removeEventListener('scroll', this.onScroll);
      console.log('challengesPageCtrl destroy');
    });

    this.init();
  }

  /** @return void **/
  async init() {
    this.NgMetaDataService.updateMetaTags({
      title:`Photography Challenges`,
      description:`Dive into the world of photography challenges on GuruShots. Participate in ongoing competitions, vote for your favorites, and showcase your photography skills.`,
      keywords:[ 'photography challenges', 'photo contests', 'GuruShots challenges', 'compete in photography', 'vote for photos']
    });
    this.member = this.MemberService.getCurrentMember();
    if (this.member.logged_in === false && this.$state.current.name !== 'gs.challenges.closed') {
      this.$state.go('gs.homePage');
      return;
    }
    this.pageData.get('challenges');
    this.done = true;

    // onBoarding
    if (this.SessionConfigService.onBoarding && this.member.logged_in) {
      this.onBoarding.load(this.SessionConfigService.onBoarding);
    }

    this.onResize = this.onResize.bind(this);
    $(window).on('resize', this.onResize);
    this.onResize();

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  $onDestroy() {
    $(window).off('resize', this.onResize);
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= resolutionEnum.WIDTH.MOBILE_HEADER;
    this.tabsType = this.isMobile ? topTabMenuData.TYPE.CHALLENGES_MOBILE_MY_CHALLENGES.NAME: topTabMenuData.TYPE.CHALLENGES.NAME;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setTabsType(type) {
    this.tabsType = type;
  }

  skipIntro() {
    (this.settings ??= {}).show_on_boarding = 0;
    this.$state.go(this.$state.current, { force: true }, { reload: true });
  }

  openLb(event) {
    if(!gsIsMobile()){
      console.log('openLb', event);
      this.buttonPressed = true;
    }
  }
}
challengesPageCtrl.$inject = [
  '$document',
  '$state',
  '$scope',
  'pageData',
  'onBoarding',
  '$cookies',
  '$timeout',
  'Modals',
  '$rootScope',
  'RestManager',
  'MemberService',
  'SessionConfigService',
  'NgMetaDataService',
];
export default challengesPageCtrl;
