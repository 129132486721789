import {Inject, Injectable} from '@angular/core';
import {MediatorService} from "./mediator.service";
import {ChallengeGroup} from "../../modules/challenges/enums/challenges.enum";

export interface IState{
  state?:string
  params?:any
  reload?:boolean
}

@Injectable()
export class StateService {

  static DEFAULT_STATE = 'gs.challenges.myChallenges.joined';
  constructor(
    @Inject('$state') private $state: any,
    mediatorService:MediatorService
  ) {

    mediatorService.gotToState.subscribe(
    (state)=>{
      if(state === 'gs.deactivated'){// hook for deactivated member
        if($state.current.name === 'gs.contact'){
          return;
        }
        if(!document.body.classList.contains("restricted")){
          document.body.classList.add("restricted");
        }
      }
      // @ts-ignore
      if(state){this.goToState({state})}
    });
  }

  goToState({state, params={}, reload=false}:IState){
    this.$state.go(state, params, {reload:reload});
  }

  goToDefaultState({params={}, reload=false}:IState= {}){
    this.goToState(
      {
        state: StateService.DEFAULT_STATE,
        params,
        reload
      }
    );
  }

  getCurrentState():string {
    return this.$state.current.name;
  }

  isStateByChallengeGroup(challengeGroup:ChallengeGroup){
    let isState = false;
    const currentState = this.$state.current.name;
    switch (challengeGroup){
      case ChallengeGroup.OPEN:
        isState =  currentState === 'gs.challenges.open'
        break;
      case ChallengeGroup.UPCOMING:
        isState =  currentState === 'gs.challenges.upcoming'
        break;
      case ChallengeGroup.CLOSED:
        isState =  currentState === 'gs.challenges.closed'
        break;
      case ChallengeGroup.MY_CLOSED:
        isState =  currentState === 'gs.challenges.myChallenges.completed'
        break;
    }
    return isState;
  }
}
