function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $urlRouterProvider.when('/boarding', '/boarding/1');
  $urlRouterProvider.when('/boarding/', '/boarding/1');

  $stateProvider
    .state('gs.boarding', {
      url: '/boarding/:slide/:id',
      views: {
        'header@gs': null,
        'page@gs': {component: 'boardingPage'},
        'footer@gs': null
      },
      params: {
        slide: {
          value: null,
          squash: true
        },
        id: {
          value: null,
          squash: true
        },
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
