<ng-content></ng-content>

<div class="global-bankroll-item gs-c-p"
     (click)="openStore(item)"
     *ngFor="let item of items()">
  <img [src]="item.icon.svg_url" [alt]="item.type">
  <div class="gs-theme-text-headline-sub-bold gs-theme-color-gray_90"
  [style.margin]="resourceStyleMapSignal().get(item.type)">{{item.amount | number}}</div>
  <div class="global-bankroll-item_plus gs-center-flex">
    <i class="icon-plus"></i>
  </div>
</div>
