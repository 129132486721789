const template = require(`html-loader!./photoContest.html`).default;

class photoContestCtrl {
  /** @param {ModalsService} Modals */

  constructor(
    Modals,
    $timeout,
    $scope,
    $state,
    gsScrollTopService,
    tagEventService,
    MemberService,
  ) {
    'ngInject';
    this.busy = true;

    this.$scope = $scope;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.$state = $state;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.tagEventService.sendPageViewEvent({title: 'Landing page - photoContest'});
    this.busy = false;
    if (this.member.logged_in) {
      this.$state.go('gs.challenges.open');
      return;
    }
    this.gsScrollTopService.scrollTop();
  }

  login(event) {
    this.Modals.open('login', event, {signUp: true});
  }

}
photoContestCtrl.$inject = [
  'Modals',
  '$timeout',
  '$scope',
  '$state',
  'gsScrollTopService',
  'tagEventService',
];
export default {
  template: template,
  controller: photoContestCtrl
};
