const template = require(`html-loader!./adminTeams.html`).default;

class adminTeamsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Leagues', route: 'gs.gsAdmin.contentManager.teams.leagues'},
      {name: 'Seasons', route: 'gs.gsAdmin.contentManager.teams.seasons'}
    ];
  }
}

export default {
  template: template,
  controller: adminTeamsCtrl,
  bindings: {}
};
