/* globals angular */
const template = require(`html-loader!./prizes.html`).default;

class challengePrizesCtrl {
  challenge;
  prizes;

  constructor($location, tagEventService, ChallengesManager, NgChallengesService) {
    "ngInject";

    this.$location = $location;
    this.tagEventService = tagEventService;
    this.ChallengesManager = ChallengesManager;
    this.NgChallengesService = NgChallengesService;
  }

  async $onInit() {
    // vPageView
    this.tagEventService.sendPageViewEvent({title: 'Challenge Prizes'});
    const res = await this.NgChallengesService.getChallengePrizes(this.challenge.id);
    if(res.success){
      this.prizes = res.prizes;
    }
    console.log('getChallengePrizes', res);
  }
}

challengePrizesCtrl.$inject = [
  '$location', 'tagEventService', 'ChallengesManager', 'NgChallengesService'
];
export default {
  template: template,
  controller: challengePrizesCtrl,
  bindings: {
    challenge: '<'
  }
};
