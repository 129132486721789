const template = require(`html-loader!./toDraft.html`).default;
import {
  toDraftList,
  toInBidList
} from './toDraft.data';
import challengeEnum from '../../../api/enums/challenge.enum';

class toDraftModalCtrl {

  /** @param {ChallengesManager} ChallengesManager **/
  constructor($mdDialog, $scope, ChallengesManager, $state) {
    'ngInject';
    this.toDraftList = toDraftList;
    this.toInBidList = toInBidList;

    this.$mdDialog = $mdDialog;
    this.$scope = $scope;
    this.ChallengesManager = ChallengesManager;
    this.$state = $state;
  }

  $onInit(){
    this.list = this.data.status === challengeEnum.STATUSES.IN_BID.VALUE ? this.toInBidList : this.toDraftList;
  }

  select(option) {
    const data = {
      id: this.data.challenge.id,
      status: this.data.status,
      reject_title: option.reject_title,
      reject_description: option.reject_description
    };

    this.ChallengesManager.setChallenge(data).then(res => {
      if (!res.success) {
        this.close();

        return;
      }
      this.data.challenge.status = this.data.status;

      // redirect to manage
      if(this.data.redirectState) {
        this.$state.go('gs.challenges.myChallenges.manage');
      }

      this.close();
    });
  }

  custom() {
    const data = {
      id: this.data.challenge.id,
      status: challengeEnum.STATUSES.DRAFT.VALUE,
      reject_title: this.customTitle,
      reject_description: this.customBody
    };

    this.ChallengesManager.setChallenge(data).then(res => {
      if (!res.success) {
        this.close();

        return;
      }

      // redirect to manage
      if(this.data.redirectState){
        this.$state.go('gs.challenges.myChallenges.manage');
      }

      this.close();
    });
  }

  close() {
    this.$mdDialog.hide();
  }
}
toDraftModalCtrl.$inject = [
  '$mdDialog', '$scope', 'ChallengesManager', '$state'
];
export default {
  template: template,
  controller: toDraftModalCtrl,
  bindings: {
    data: '=data'
  }
};
