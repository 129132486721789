/* globals angular */
import config from './ads.page.config';
import adsPage from './ads/ads';

let moduleAdsPage = angular.module('gsApp.ads', [])
    .config(config)
    .component('adsPage', adsPage)
  ;

export default moduleAdsPage.name;
