const template = require(`html-loader!./leaderBoardItem.html`).default;
import {teamItemMode} from "../../teamItem/teamItem.data";
import leaderBoardEnum from "../../../api/enums/leaderBoard.enum";

class leaderBoardItemCtrl {
  constructor(
    $scope,
    $rootScope,
    leaderboardService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.leaderboardService = leaderboardService;
    this.teamItemMode = teamItemMode;
  }

  $onChanges() {
    if(this.item){
      switch (this.type) {
        case leaderBoardEnum.TYPE.INDIVIDUAL:
          this.rank = this.item.league.rank.item_rank;
          break;
        case leaderBoardEnum.TYPE.TEAM_LEAGUES:
          this.rank = this.item.league.rank.item_rank;
          break;
        case leaderBoardEnum.TYPE.TEAM_GLOBAL:
          this.rank = this.item.rank.item_rank;
          this.leagueInternalName = this.item.league.internal_name;
          break;
      }
    }
  }

  isTypeGlobal() {
    return this.leaderboardService.isTypeGlobal(this.type);
  }

  isTypeLeague() {
    return this.leaderboardService.isTypeLeague(this.type);
  }

  isTypeIndividual() {
    return this.leaderboardService.isTypeIndividual(this.type);
  }

  isMyItem() {
    return this.myItem && this.myItem.id === this.item.id;
  }
}

leaderBoardItemCtrl.$inject = [
  '$scope',
  '$rootScope',
  'leaderboardService'
];

export default {
  template: template,
  controller: leaderBoardItemCtrl,
  bindings: {
    type: '@',
    item: '<',
    index: '@',
    isFloating: '@',
    myItem: '<',
  }
};
