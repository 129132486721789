import {Component, DestroyRef, inject, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ChallengeBoostState,
  ChallengeTurboState,
  ChallengeTurboUnlockType,
  ChallengeUnlockType
} from "../../enums/challenges.enum";
import {GsTimerEnum} from "../../../../shared/pipes/gs-timer/gs-timer.enum";
import {INgChallengeModel} from "../../interfaces/challenges.interface";
import gsTimerService from "../../../../core/services/gs-timer.service";
import {parseFromSecToMilisec} from "../../../../gsApp/app/helpers/date.helper";
import {MediatorMessageKey, MediatorService} from "../../../../core/services/mediator.service";
import mixpanelEventsEnum from "../../../../gsApp/app/services/mixpanel/mixpanel.enum";
import {JoinedChallengesPageService} from "../../services/joined-challenges-page.service";
import CookieService from "../../../../core/services/cookie.service";
import {CookieKeyEnum} from "../../../../core/enum/cookies.enum";
import {StateService} from "../../../../core/services/state.service";
import {ResourceType} from "../../../../core/models/enums";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter} from "rxjs";

export enum ActiveChallengeActionButtonType  {
  SWAP = 'SWAP',
  BOOST = 'BOOST',
  FILL = 'FILL',
  EXHIBIT = 'EXHIBIT',
  TURBO = 'TURBO',
  FILL_ALL = 'FILL ALL',
  VOTE = 'VOTE',
}

@Component({
  selector: 'app-active-challenge-action-button',
  templateUrl: './active-challenge-action-button.component.html',
  styleUrls: ['./active-challenge-action-button.component.scss'],
  providers: [gsTimerService],
})

export class ActiveChallengeActionButtonComponent implements OnInit, OnDestroy{
  @Input() type!:ActiveChallengeActionButtonType;
  @Input() challenge!:INgChallengeModel;
  destroyRef = inject(DestroyRef);

  readonly ActiveChallengeActionButtonType = ActiveChallengeActionButtonType;
  readonly ChallengeTurboState = ChallengeTurboState;
  readonly ChallengeBoostState = ChallengeBoostState;
  readonly  GsTimerEnum = GsTimerEnum;

  iconByType = {
    [ActiveChallengeActionButtonType.FILL]: 'icon-flash',
    [ActiveChallengeActionButtonType.FILL_ALL]: 'icon-flash-empty',
    [ActiveChallengeActionButtonType.BOOST]: 'icon-boost',
    [ActiveChallengeActionButtonType.SWAP]: 'icon-swap',
    [ActiveChallengeActionButtonType.EXHIBIT]: 'icon-star',
    [ActiveChallengeActionButtonType.TURBO]: 'icon-challenge-turbo',
    [ActiveChallengeActionButtonType.VOTE]: 'icon-voting',
  }
  isEnable = true;
  countDownTime?:number;
  ResourceType = ResourceType;
  unlockTurboType!:ResourceType;

  constructor(
    private mediatorService: MediatorService,
    private gsTimerService: gsTimerService,
    private joinedChallengesPageService: JoinedChallengesPageService,
    private cookieService: CookieService,
    private stateService: StateService,
    @Inject('mixpanelService')  private mixpanelService: any,
    @Inject('challengeService')  private challengeService: any,
    @Inject('Modals')  private Modals: any,
    @Inject('gsModals')  private gsModals: any,
    @Inject('brazeService') private brazeService: any
  ) {
  }

  ngOnInit(): void {
    //for testing all states
    // if(this.type === ActiveChallengeActionButtonType.BOOST){
    //   if(this.challenge?.member?.boost?.state){
    //     this.challenge.member.boost.state = ChallengeBoostState.AVAILABLE;
    //     this.challenge.member.boost.timeout = 1731333799;
    //     // this.challenge.member.boost.state = ChallengeBoostState.AVAILABLE_KEY;
    //     // this.challenge.member.boost.state = ChallengeBoostState.USED;
    //     // this.challenge.member.boost.state = ChallengeBoostState.MISSED;
    //   }
    // }
    if (this.type === ActiveChallengeActionButtonType.TURBO) {
      if (this.challenge.member?.turbo?.turbo_unlock_type === ChallengeTurboUnlockType.COINS) {
        this.unlockTurboType = ResourceType.COINS;
      } else if(this.challenge.member?.turbo?.turbo_unlock_type === ChallengeTurboUnlockType.KEY) {
        this.unlockTurboType = ResourceType.KEYS;
      }
      // if(this.challenge?.member?.turbo?.state){
        // this.challenge.member.turbo.state = ChallengeTurboState.TIMER;
        // this.challenge.member.turbo.time_to_open = 1712235319
        // this.challenge.member.turbo.state = ChallengeTurboState.WON;
        // this.challenge.member.turbo.state = ChallengeTurboState.USED;
        // this.challenge.member.turbo.state = ChallengeTurboState.LOCKED;
        // this.challenge.member.turbo.state = ChallengeTurboState.IN_PROGRESS;
      // }
    }
    this.joinedChallengesPageService.$isFillAllActive.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(()=> this.type === ActiveChallengeActionButtonType.FILL_ALL)
    ).subscribe((value)=>{
      this.isEnable =  value;
    })
    this.joinedChallengesPageService.$boostChanged.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((value) => {
        return value.challenge_id === this.challenge.id &&
          this.type === ActiveChallengeActionButtonType.BOOST;
      })
    ).subscribe(()=> {
      if(this.isBoostTimerEnabled()){
        this.initBoostTimer();
      }
    });

    //TODO move timer into a component
    switch (this.type){
      case ActiveChallengeActionButtonType.TURBO:
        if (this.challenge.member?.turbo?.state === ChallengeTurboState.TIMER) {
          // this.challenge.member?.turbo.time_to_open =  1702574629;
          this.gsTimerService.start({
            expireTime: parseFromSecToMilisec(this.challenge.member?.turbo?.time_to_open),
            timesUpHandler: ()=> {
              this.challenge.member!.turbo!.state = ChallengeTurboState.FREE;
            },
            timeTickingHandler: (countDownTime)=> {
              this.countDownTime = countDownTime;
            }
          });
        }
        break;
      case ActiveChallengeActionButtonType.BOOST:
        this.initBoostTimer();
        break;
      case ActiveChallengeActionButtonType.SWAP:
        this.isEnable = !this.challenge.swap_locked;
        break;
      case ActiveChallengeActionButtonType.FILL_ALL:
        this.isEnable = this.joinedChallengesPageService.$isFillAllActive.value;
        break;
      case ActiveChallengeActionButtonType.FILL:
        this.isEnable = !this.challenge.fill_locked;
        break;
    }
  }

  initBoostTimer(){
    if (this.isBoostTimerEnabled()) {
      this.gsTimerService.stop();
      this.gsTimerService.start({
        expireTime: parseFromSecToMilisec(this.challenge.member?.boost?.timeout),
        timesUpHandler: ()=> {
          if(this.challenge.member && this.challenge.member.boost){
            this.challenge.member.boost.state = ChallengeBoostState.MISSED;
          }
        },
        timeTickingHandler: (countDownTime)=> {
          this.countDownTime = countDownTime;
        }
      });
    }
  }

  isBoostTimerEnabled(){
    return this.challenge && (this.challenge.member?.boost?.state === ChallengeBoostState.AVAILABLE) && this.challenge.member?.boost?.timeout;
  }

  onButtonClick(){
    switch (this.type){
      case ActiveChallengeActionButtonType.TURBO:
       this.onTurboClick();
      break;
      case ActiveChallengeActionButtonType.SWAP:
       this.onSwapClick();
      break;
      case ActiveChallengeActionButtonType.BOOST:
       this.onBoostClick();
      break;
      case ActiveChallengeActionButtonType.EXHIBIT:
       this.onExhibitClick();
      break;
      case ActiveChallengeActionButtonType.VOTE:
       this.onVoteClick();
      break;
      case ActiveChallengeActionButtonType.FILL:
       this.onFillClick();
      break;
      case ActiveChallengeActionButtonType.FILL_ALL:
       this.onFillClick(true);
      break;
    }
  }
  async onFillClick(all = false) {
    if (all) {
      this.sendVoteFillEvent(mixpanelEventsEnum.UI_NAME.FILL_ALL);
    } else {
      this.sendVoteFillEvent(mixpanelEventsEnum.UI_NAME.FILL, true, true);
    }
    if (this.challengeService.isFillEmpty()) {
      this.isEnable = false;
      const fillsStoreEventParams = {outOfResourceId: 'out_fills' + Date.now()};
      this.brazeService.logCustomEvent('out_fills', fillsStoreEventParams);
      await this.brazeService.waitForBrazeMessage(this.openStoreFillsSection.bind(this), fillsStoreEventParams.outOfResourceId);
      this.isEnable = true;
      return;
    }
    const data:any = {
      availableFills: this.challengeService.getFillAmount(),
      challengeId: all ? {} : this.challenge.id,
      challengeIds: this.joinedChallengesPageService.getChallengesIdsWithActiveFill(),
      exposure: all ? 0 : this.challenge?.member?.ranking?.total?.exposure,
      showSingleFill: !all,
      showAllOption: true
    };
    if (!all) {
      data.challengeId = this.challenge.id;
    }
    this.Modals.open('autoFill', null, data);
  }

  async onVoteClick() {
    this.sendVoteFillEvent(mixpanelEventsEnum.UI_NAME.VOTE, true, true);
    if (this.cookieService.getCookie(CookieKeyEnum.INTRO_STEP)) {
      this.gsModals.open('vote', {
        challenge: this.challenge,
        callback: () => {
          this.stateService.goToDefaultState({params:{ force: true }, reload:true})
        },
      });
    } else {
      this.gsModals.open('vote', { challenge: this.challenge });
    }
  }

  async onExhibitClick() {
    const eventData:any = {};
    if(this.challenge?.member?.ranking?.total?.rankingLevel?.name){
      eventData.challengeLevel = this.challenge.member.ranking.total.rankingLevel.name.toLowerCase();
    }
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.EXHIBIT,eventData);

    const openExhibitionShop = await this.Modals.open('challengeIntro', null, {
      challenge: this.challenge,
      closeOnApply: true
    });
    if(openExhibitionShop){
      const message = await this.challengeService.openExhibitionShop(this.challenge);
      if(message === 'paid'){
        this.mediatorService.broadcast(MediatorMessageKey.GET_ACTIVE_CHALLENGE, {
          challengeId: this.challenge.id
        });
        // location.reload();
      }
    }
  }

  async onBoostClick() {
    switch (this.challenge.member?.boost?.state) {
      case ChallengeBoostState.AVAILABLE_KEY: // after unlock
      case ChallengeBoostState.AVAILABLE: // free boost with timer
        this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.BOOST_UNLOCKED, {});
        if(this.challenge.isOnePhoto()){
          this.challenge.member.boost.state = ChallengeBoostState.USED;
          this.challengeService.boostPhoto(this.challenge.getFirstImage(), this.challenge);
        } else{
          this.Modals.open('challengeActionActivate', null, { challenge: this.challenge });
        }
        break;
      case ChallengeBoostState.LOCKED:
      case ChallengeBoostState.MISSED:
        this.eventMixPanelAction(ChallengeBoostState.LOCKED ? mixpanelEventsEnum.UI_NAME.BOOST_LOCKED : mixpanelEventsEnum.UI_NAME.MISSED_BOOST);
        if (this.challengeService.isKeysEmpty()) {
          this.isEnable = false;
          const keyStoreEventParams = {outOfResourceId: 'out_key' + Date.now()};
          this.brazeService.logCustomEvent('out_key', keyStoreEventParams);
          await this.brazeService.waitForBrazeMessage(this.openStoreKeysSection.bind(this), keyStoreEventParams.outOfResourceId);
          this.isEnable = true;
          return false;
        }
        this.Modals.open('challengeActionLocked', null, { challenge: this.challenge });
        break;
      case ChallengeBoostState.USED:
        break;
      default:
        break;
    }
    return false;
  }

  openStoreKeysSection(): void {
    this.challengeService.openStoreKeysSection();
  }

  openStoreFillsSection(): void {
    this.challengeService.openStoreFillsSection();
  }

  async onSwapClick() {
    this.eventMixPanelAction(
      mixpanelEventsEnum.UI_NAME.SWAP,
      {
        numberOfPhotosSubmitted: this.challenge.member?.ranking?.entries?.length
      });
    this.isEnable = false;
    await this.challengeService.swapAction({
      challenge: this.challenge
    });
    this.isEnable = true;
  }

  onTurboClick(){
    switch (this.challenge.member?.turbo?.state){
      case ChallengeTurboState.FREE:
        this.mediatorService.broadcast(MediatorMessageKey.OPEN_TURBO_RULES, {
          challenge:this.challenge,
          state:this.challenge.member?.turbo.state,
          type:ChallengeUnlockType.TURBO,
          locked:false
        });
        this.sendTurboClickEvent(ChallengeTurboState.FREE.toLowerCase());
        break;
      case ChallengeTurboState.LOCKED:
        const lockedType = this.challenge.member?.turbo.turbo_unlock_type;
        const currentCoinsAmount = this.challengeService.getCoinsAmount();
        this.mediatorService.broadcast(MediatorMessageKey.OPEN_TURBO_RULES, {
          challenge: this.challenge,
          state: this.challenge.member?.turbo.state,
          type: ChallengeUnlockType.TURBO,
          locked: true,
          lockedType: lockedType,
          amount: this.challenge.member?.turbo.turbo_unlock_amount
        });
        if (
          (this.challengeService.isKeysEmpty() && lockedType === ChallengeTurboUnlockType.KEY) ||
          (this.challenge.member?.turbo.turbo_unlock_amount > currentCoinsAmount && lockedType === ChallengeTurboUnlockType.COINS)
        )
        {
          this.isEnable = false;

          setTimeout(() => this.isEnable = true, 2000);
        }
        this.sendTurboClickEvent(ChallengeTurboState.LOCKED.toLowerCase());
        break;
      case ChallengeTurboState.IN_PROGRESS:
        this.mediatorService.broadcast(MediatorMessageKey.OPEN_MINI_GAME, this.challenge);
        this.sendTurboClickEvent(ChallengeTurboState.IN_PROGRESS.toLowerCase());
        break;
      case ChallengeTurboState.WON:
        this.mediatorService.broadcast(MediatorMessageKey.OPEN_TURBO_ACTIVATION, {
          challenge:this.challenge,
          type:ChallengeUnlockType.TURBO,
        });
        this.sendTurboClickEvent(ChallengeTurboState.WON.toLowerCase());
        break;
      case ChallengeTurboState.TIMER:
        this.sendTurboClickEvent(ChallengeTurboState.TIMER.toLowerCase());
        break;
      case ChallengeTurboState.USED:
        this.sendTurboClickEvent(ChallengeTurboState.USED.toLowerCase());
        break;
    }
  }

  //TODO combine all events to one function
  sendTurboClickEvent(state:string){
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.TURBO_CHALLENGES,
      data: {
        ui_name:mixpanelEventsEnum.UI_NAME.TURBO_CHALLENGE_CTA,
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
        challenge_id: this.challenge.id,
        ui_state: state
      }
    });
  }

  eventMixPanelAction(uiName:string, {
    numberOfPhotosSubmitted = 0,
    boostTimeLeft = 0,
    challengeLevel = 0
  }={}) {
    let data = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.ACTIVE_CHALLENGE,
      ui_name: uiName,
      challenge_id: this.challenge.id,
      number_of_photos_submitted: NaN,
      challenge_level:NaN,
      boostTimeLeft:boostTimeLeft
    };

    switch(uiName) {
      case mixpanelEventsEnum.UI_NAME.SWAP:
        data.number_of_photos_submitted = numberOfPhotosSubmitted;
        break;
      case mixpanelEventsEnum.UI_NAME.EXHIBIT:
        data.challenge_level = challengeLevel;
        break;
      case mixpanelEventsEnum.UI_NAME.BOOST_LOCKED:
        data.boostTimeLeft = boostTimeLeft;
        break;
      default:
        break;
    }

    this.mixpanelService.track({data});
  }

  sendVoteFillEvent(uiName:any, meterStatus = false, sendChallengeId = false) {
    let data:any = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.ACTIVE_CHALLENGE,
      ui_name: uiName,
    };

    if (meterStatus) {
      data.meter_status = Math.floor(this.challenge?.member?.ranking?.total?.exposure);
    }

    if (sendChallengeId) {
      data.challenge_id = this.challenge.id;
    }

    this.mixpanelService.track({data});
  }

  isBoostAvailable(){
    // @ts-ignore
    return  this.type === ActiveChallengeActionButtonType.BOOST && [ChallengeBoostState.AVAILABLE, ChallengeBoostState.AVAILABLE_KEY].includes(this.challenge?.member?.boost?.state);
  }

  ngOnDestroy(): void {
    this.gsTimerService.stop();
  }
}
