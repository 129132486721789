import {JoinedChallengesPageService} from "../../../modules/challenges/services/joined-challenges-page.service";

class authService {
  firstInit = true;

  constructor(
    $rootScope,
    $state,
    AuthenticationManager,
    $cookies,
    deepLinkService,
    tagEventService,
    socketService,
    NgTokenService,
    JoinedChallengesPageService,
    brazeService,
    MemberService,
    teamsService,
    mixpanelService,
    SessionConfigService,
    // AdsService,
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.AuthenticationManager = AuthenticationManager;
    this.$cookies = $cookies;
    this.deepLinkService = deepLinkService;
    this.tagEventService = tagEventService;
    this.socketService = socketService;
    this.NgTokenService = NgTokenService;
    // this.AdsService = AdsService;
    this.brazeService = brazeService;
    this.teamsService = teamsService;
    this.mixpanelService = mixpanelService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.SessionConfigService = SessionConfigService;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  afterSessionStarted(){
    if(this.MemberService.isLoggedIn()){
      this.brazeService.init();
      this.brazeService.changeUser(this.member.id);
      this.teamsService.initAutoUpdate();
    }
    if(this.firstInit){
      this.firstInit = false;
      this.mixpanelService.init();
    }
  }

  async login() {
    await this.afterLogin();
    const popupCompletedChallengesFlag = this.SessionConfigService.sessionConfig.popup_completed_challenges;
    const isNewCompletedChallengesRes = await this.JoinedChallengesPageService.isNewCompletedChallenges();
    let stateToRedirect = 'gs.challenges.myChallenges.joined';
    let params = {};
    if (popupCompletedChallengesFlag && isNewCompletedChallengesRes.is_new) {
      stateToRedirect =  'gs.challenges.myChallenges.completed';
      params = {scrollToLastNewChallenge:true};
      this.brazeService.showMessagesLater = true;
    }
    this.go(stateToRedirect, params);
  }

  async afterLogin() {
    await this.MemberService.getCurrentMemberProfile();
    await this.SessionConfigService.getConfigData();
    this.afterSessionStarted();
  }

  // async adsAfterLogin() {
  //  return this.AdsService.afterLogin();
  // }

  async signUp(res = {}, conversionData) {
    // google and facebook
    this.tagEventService.signup(conversionData, res.member_id);
    await this.afterLogin(res.member_id);
    // redirect
    this.go('gs.challenges.open');
  }

  go(state, params = {}){
    let dl = this.deepLinkService.getDl();
    console.log(dl,'auth_service');
    if(dl && dl.dlState){
      this.$state.go(dl.dlState, dl.params);
    }else{
      this.$state.go(state, params);
    }
  }

  signOut() {
    this.NgTokenService.resetAllTokens();
    // this.teamsService.cancelInviteNotificationInterval();
    // this.socketService.closeSocket();
    // this.MemberService.resetMember();
    //TODO rout to home page without reload, need to check...
    window.location.href = '/';
    // this.$state.go('gs.homePage');
  }
}

authService.$inject = [
  '$rootScope',
  '$state',
  'AuthenticationManager',
  '$cookies',
  'deepLinkService',
  'tagEventService',
  'socketService',
  'NgTokenService',
  'JoinedChallengesPageService',
  'brazeService',
  'MemberService',
  'teamsService',
  'mixpanelService',
  'SessionConfigService',
];

export default authService;
