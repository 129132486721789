function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state('gs.mobileNotifications', {
      url: '/mobile_notifications',
      views: {
        'header@gs': null,
        'page@gs': {component: 'mobileNotifications'},
        'footer@gs': null
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
