const template = require(`html-loader!./deactivateDeleteAccount.html`).default;

import {
  deactivateAction,
  deactivateActions,
  memberLeaveModes,
  memberLeaveSteps,
  reasonsType
} from "./deactivateDeleteAccount.data";
import { isFieldValidNew } from '../../../helpers/validate.helper';
import memberEnum from "../../../api/enums/member.enum";
import GetMemberAchievementsByLevelResponse from "../../../api/models/responses/GetMemberAchievementsByLevel.response";
import PostRequest from "../../../api/models/requests/post.request";
import restEnum from "../../../api/enums/rest.enum";
import GetMemberAchievementsDeleteMessage
  from "../../../api/models/responses/GetMemberAchievementsDeleteMessage.response";

class deactivateDeleteAccountCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {FollowersManager} FollowersManager
   * @param {AuthenticationManager} AuthenticationManager
   * */

  constructor($mdDialog, Modals, $scope, $rootScope, FollowersManager, AuthenticationManager, $state, RestManager, MemberService) {
    'ngInject';
    this.busy = false;
    this.achievements = [];
    this.followers = [];
    this.selectedReasons = {};
    this.otherText;
    this.otherDurationText;
    this.adminReason;
    this.adminDuration = 24;
    this.adminDeactivateAction = deactivateAction.ACTIVATE;
    this.dontSendEmail = false;
    this.OTHER = 'other';
    this.memberEnum = memberEnum;

    this.$mdDialog = $mdDialog;
    this.Modals = Modals;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.FollowersManager = FollowersManager;
    this.AuthenticationManager = AuthenticationManager;
    this.$state = $state;
    this.RestManager = RestManager;
    this.memberLeaveModes = memberLeaveModes;
    this.memberLeaveSteps = memberLeaveSteps;
    this.deactivateActions = deactivateActions;
    this.member = MemberService.getCurrentMember();


    this.minFollowers = 2;
  }

  $onInit() {
    // gTag({
    //   event: 'Deactivate',
    //   action: 'click',
    //   label: 'Step 1',
    //   value: 1
    // });
    this.init();
  }

  /** @return void */
  async init() {
    this.memberLeaveMode = this.data.memberLeaveMode || memberLeaveModes.DEACTIVATE;
    this.memberLeaveModeLowerCase = this.memberLeaveMode.toLocaleLowerCase();
    this.memberLeaveStep = this.data.memberLeaveStep || memberLeaveSteps.DEACTIVATE_OR_DELETE;
    if(this.memberLeaveStep === memberLeaveSteps.ADMIN){
      if(this.memberLeaveMode === this.memberLeaveModes.DEACTIVATE){
        await this.getDurationOptions();
      }
      await this.getReasons();
      this.adminReason = this.reasons[0].reason;
    }
  }

  /** @return void */
  async getReasons(){
    this.busy = true;
    const type  = this.memberLeaveMode === memberLeaveModes.DEACTIVATE ? reasonsType.SUSPEND : this.memberLeaveMode;
    const res = await this.RestManager.getReasonOptions(type);
    if(!res.success){this.close()}
    this.reasons = res.reasons;
    this.busy = false;
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  /** @return void */
  async getDurationOptions(){
    this.busy = true;
    const res = await this.RestManager.getDurationOptions();
    if(!res.success){this.close()}
    this.durations = res.durations;
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  /** @return void */
  async getAchievementsAndFollowers(){
    this.busy = true;
    // get achievements
    const achievementsResponseData = new GetMemberAchievementsDeleteMessage(
      await this.RestManager.postRequest(
        new PostRequest({
          endpoint: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_DELETE_MESSAGE.NAME,
          data:{
            member_id: this.member.id
          }
        })
      )
    );
    // get followers
    const followers = await this.FollowersManager.getProfileFollowers(this.member.id, 0, 5, 'getFollowers');

    this.achievements = achievementsResponseData.success ? achievementsResponseData.achievements.splice(0, 6) : [];
    this.followers = followers ? followers : [];

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  close(res = false) {
    this.$mdDialog.hide(res);
  }

  continue(){
    switch (this.memberLeaveStep) {
      case memberLeaveSteps.DEACTIVATE_OR_DELETE:{
        this.memberLeaveModeLowerCase = this.memberLeaveMode.toLocaleLowerCase();
        if(this.memberLeaveMode === memberLeaveModes.DELETE){
          this.memberLeaveStep = memberLeaveSteps.LOSE_PROGRESS;
          this.getAchievementsAndFollowers();
        }
        if(this.memberLeaveMode === memberLeaveModes.DEACTIVATE){
          this.memberLeaveStep = memberLeaveSteps.REASONS;
          this.getReasons();
        }
        break;
      }
      case memberLeaveSteps.LOSE_PROGRESS:{
        this.memberLeaveStep = memberLeaveSteps.REASONS;
        this.getReasons();
        break;
      }
      case memberLeaveSteps.REASONS:{
        console.log('selectedReasons', this.selectedReasons);
        console.log('otherText', this.otherText);
        this.memberLeaveStep = memberLeaveSteps.END;
        break;
      }
      case memberLeaveSteps.END:{
        if(this.memberLeaveMode === memberLeaveModes.DELETE){
          this.deleteMember(this.member.id);
        }
        if(this.memberLeaveMode === memberLeaveModes.DEACTIVATE){
          this.deactivateMember(this.member.id);
        }
        break;
      }
      case memberLeaveSteps.ADMIN:{
        if(this.memberLeaveMode === memberLeaveModes.DELETE){
          this.deleteMember(this.data.member.id, !this.dontSendEmail);
        }
        if(this.memberLeaveMode === memberLeaveModes.DEACTIVATE){
          this.deactivateMember(this.data.member.id, this.adminDeactivateAction, !this.dontSendEmail);
        }
        if(this.memberLeaveMode === memberLeaveModes.REACTIVATE){
          this.reactivateMember(this.data.member.id, !this.dontSendEmail);
        }
        break;
      }
    }
  }

  /** @return void */
  async reactivateMember(id, send_email=false){
    if(this.ajaxOn){ return}
    this.ajaxOn = true;
    let reqData = {
      id,
      send_email,
      reason: this.getSelectedReasons()
    };
    if(reqData.reason.indexOf(this.OTHER) !== -1){
      reqData.other_text = this.otherText;
    }
    console.log('reqData', reqData);
    const res = await this.RestManager.reactivateMember(reqData);
    if(!res.success){
      this.close(false);
      return;
    }
    this.close(true);
  }

  /** @return void */
  async deleteMember(id, send_email=false){
    if(this.ajaxOn){ return}
    this.ajaxOn = true;
    let reqData = {
      id,
      send_email,
      reason: this.getSelectedReasons()
    };
    if(reqData.reason.indexOf(this.OTHER) !== -1){
      reqData.other_text = this.otherText;
    }
    console.log('reqData', reqData);
    const res = await this.RestManager.deleteMember(reqData);
    if(!res.success){
      this.close(false);
      return;
    }
    this.close(true);
  }

  /** @return void */
  async deactivateMember(id, action=deactivateAction.NO_ACTION, send_email=false){
    if(this.ajaxOn){ return}
    this.ajaxOn = true;
    let reqData = {
      id,
      reason: this.getSelectedReasons(),
      duration: this.getSelectedDuration(),
      action,
      send_email
    };
    if(reqData.reason.indexOf(this.OTHER) !== -1){
      reqData.other_text = this.otherText;
    }
    console.log('reqData', reqData);
    const res = await this.RestManager.suspendMember(reqData);
    if(!res.success){
      this.close(false);
      return;
    }
    this.close(true);
  }

  isValid(){
    if(this.memberLeaveStep === memberLeaveSteps.REASONS){
      if(this.selectedReasons[this.OTHER]){
        return this.otherText;
      }
      for (const selectedReason in this.selectedReasons) {
        if(this.selectedReasons[selectedReason]){
          return true;
        }
      }
      return false;
    }
    if(this.memberLeaveStep === memberLeaveSteps.ADMIN){
      if(this.adminDuration === this.OTHER){
        const res = isFieldValidNew(this, {isNumber: true, field:"otherDurationText"});
        return res.valid;
      }
    }
    return true;
  }

  getSelectedReasons(){
    if(this.memberLeaveStep === memberLeaveSteps.ADMIN){
      return [this.adminReason];
    }
    let reasons = [];
    for (const selectedReason in this.selectedReasons) {
      if(this.selectedReasons[selectedReason]){
        reasons.push(selectedReason);
      }
    }
    return reasons;
  }

  getSelectedDuration(){
    if(this.memberLeaveStep === memberLeaveSteps.ADMIN){
      if(this.adminDuration === this.OTHER && this.otherDurationText){
        return this.otherDurationText;
      }
      return this.adminDuration;
    }
    return 0;
  }

  //
  // deactivate() {
  //   gTag({
  //     event: 'Deactivate',
  //     action: 'click',
  //     label: 'Are you Sure Confirm',
  //     value: 1
  //   });
  //   this.Modals.open('enterPassword', null, {
  //     confirmTitle: 'DEACTIVATE',
  //     callback: () => this.deactivateProfile()
  //   });
  // }
  //
  // deactivateProfile() {
  //   gTag({
  //     event: 'Deactivate',
  //     action: 'click',
  //     label: 'Confirm password',
  //     value: 1
  //   });
  //   this.AuthenticationManager.deactivateAccount().then(res => {
  //     if(!res.success) {
  //       return;
  //     }
  //     this.$state.go('gs.deactivated');
  //   });
  // }
  //
  // close() {
  //   gTag({
  //     event: 'Deactivate',
  //     action: 'click',
  //     label: 'Cancel',
  //     value: 1
  //   });
  //   this.$mdDialog.hide();
  // }
}
deactivateDeleteAccountCtrl.$inject = [
  '$mdDialog', 'Modals', '$scope', '$rootScope', 'FollowersManager', 'AuthenticationManager', '$state', 'RestManager', 'MemberService'
];
export default {
  template: template,
  controller: deactivateDeleteAccountCtrl,
  bindings: {
    data: '=data'
  }
};
