const template = require(`html-loader!./challengesAutoSubmit.html`).default;
import moment from 'moment';

class challengesAutoSubmitCtrl {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * */

  constructor(ChallengesManager, $scope, Modals, Toasts, $element) {
    'ngInject';
    this.state = {};

    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.Modals = Modals;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  async submit() {
    if (
      typeof this.state.c_id === undefined ||
      typeof this.state.last_joined_date === undefined ||
      typeof this.state.limit_members === undefined ||
      typeof this.state.max_active_challenges === undefined ||
      typeof this.state.max_joined_challenges === undefined
    ) {
      this.Toasts.open(
        'simpleMessage',
        {
          data: {
            message: 'All fields required!',
            class: 'error'
          }
        },
        this.$element
      );
      return;
    }
    const confirm = await this.Modals.confirm(
      'Submit Auto Submit?',
      `Are you sure? You want to submit auto submit?`,
      'Submit',
      'Cancel'
    );
    if (!confirm) {
      return;
    }
    const res = await this.ChallengesManager.autoSubmitOnGoing({
      c_id: this.state.c_id,
      last_joined_date: moment(this.state.last_joined_date).format('X'),
      limit_members: this.state.limit_members,
      max_active_challenges: this.state.max_active_challenges,
      max_joined_challenges: this.state.max_joined_challenges
    });
    if(res.success) {
      this.Toasts.open(
        'simpleMessage',
        {
          data: {
            message: 'Done!',
          }
        },
        this.$element
      );
    } else {
      this.Toasts.open(
        'simpleMessage',
        {
          data: {
            message: res.message || 'error',
            class: 'error'
          }
        },
        this.$element
      );
    }
  }
}
challengesAutoSubmitCtrl.$inject = [
  'ChallengesManager', '$scope', 'Modals', 'Toasts', '$element'
];
export default {
  template: template,
  controller: challengesAutoSubmitCtrl,
  bindings: {}
};
