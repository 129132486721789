class stateService {

  constructor(
    $state,
    $window,
    SessionConfigService
  ) {
    'ngInject';
    this.previousState = null;
    this.previousStateParams = null;

    this.$state = $state;
    this.$window = $window;
    this.SessionConfigService = SessionConfigService;
  }

  setPreviousState(state, params){
    this.previousState = state;
    this.previousStateParams = params;
  }

  goToState(state, params={}, reload=false){
    this.$state.go(state, params, {reload:reload});
  }

  goToStateNewTab(state, params={}, reload=false){
    const url = this.$state.href(state, params, {reload:reload});
    this.$window.open(url,'_blank');
  }

  goToDiscoverState(params={}, reload=false){
    this.goToState(
      'gs.challenges.open',
      params,
      reload
    );
  }

  goToJoinedChallengeState(params={}, reload=false){
    this.goToState(
      'gs.challenges.myChallenges.joined.ads',
      params,
      reload
    );
  }

  goToDefaultState(params={}, reload=false){
    this.goToState(
      'gs.challenges.myChallenges.joined',
      params,
      reload
    );
  }

  goToHomePage(params={}, reload=false){
    this.goToState(
      'gs.homePage',
      params,
      reload
    );
  }

  goToAchievementsPage(params={}, reload=false){
    this.goToState(
      'gs.profile.achievements.achievements',
      params,
      reload
    );
  }

  goToPreviousState(reload=false){
    this.goToState(
      this.previousState,
      this.previousStateParams,
      reload
    );
  }

  reload(){
    this.$state.reload();
  }

  getCurrentState(){
    return this.$state.current
  }

  getProfileDefaultState(isMyProfile = true){
    const tab = this.SessionConfigService.sessionConfig &&
    this.SessionConfigService.sessionConfig.profile_page_achievements_default_tab && isMyProfile
      ? 'achievements' : 'photos';
    return `gs.profile.${tab}`;
  }

}
stateService.$inject = [
  '$state',
  '$window',
  'SessionConfigService',
];
export default stateService;
