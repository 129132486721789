import config from './challenges.page.config';
import challengesPageCtrl from './challenges.page.ctrl';
// pages
import myChallengesCurrent from './myChallengesCurrent/myChallengesCurrent';
import myChallengesCompleted from './myChallengesCompleted/myChallengesCompleted';
import myChallengesManage from './myChallengesManage/myChallengesManage';
import challengesOpen from './challengesOpen/challengesOpen';
import challengesUpcoming from './challengesUpcoming/challengesUpcoming';
import challengesClosed from './challengesClosed/challengesClosed';
// components
import finalizingResult from './finalizingResult/finalizingResult';
import challengesItem from './challengesItem/challengesItem';
import challengesItemMobile from './challengesItemMobile/challengesItemMobile';
import challengesItemSuggested from './challengesItemSuggested/challengesItemSuggested';
import challengesItemSuggestedMobile from './challengesItemSuggested/challengesItemSuggestedMobile/challengesItemSuggestedMobile';
import challengesExhibitionBanner from './challengesExhibitionBanner/challengesExhibitionBanner';
import challengesItemCompleted from './challengesItemCompleted/challengesItemCompleted';
import blackFridayWidget from './blackFriday/blackFriday';
import myChallengesEditor from './myChallengesEditor/myChallengesEditor';
import myChallengesManageItemSuggested from './myChallengesManage/myChallengesManageItemSuggested/myChallengesManageItemSuggested';
import myChallengesManageItem from './myChallengesManage/myChallengesManageItem/myChallengesManageItem';
import myChallengesManageItemMobile from './myChallengesManage/myChallengesManageItemMobile/myChallengesManageItemMobile';
import myChallengesManageItemMobileDraft from './myChallengesManage/myChallengesManageItemMobileDraft/myChallengesManageItemMobileDraft';
import myChallengesManageDraft from './myChallengesManage/myChallengesManageDraft/myChallengesManageDraft';
import myChallengesSponsors from './myChallengesEditor/myChallengesSponsors/myChallengesSponsors';
import myChallengesRankLevels from './myChallengesEditor/myChallengesRankLevels/myChallengesRankLevels';
import myChallengesAutoSelectWinners from './myChallengesEditor/myChallengesAutoSelectWinners/myChallengesAutoSelectWinners';
import myChallengesMedia from './myChallengesEditor/myChallengesMedia/myChallengesMedia';
import myChallengesAchievementPrize from './myChallengesEditor/myChallengesAchievementPrize/myChallengesAchievementPrize';
//Intro
import onBoarding from '../../services/onBoarding/onBoarding';

import './challenges.page';

let moduleChallengesPage = angular
  .module('gsApp.challengesPage', [])
  .config(config)
  .service('onBoarding', onBoarding)
  .controller('challengesPageCtrl', challengesPageCtrl)
  // pages
  .component('myChallengesCurrent', myChallengesCurrent)
  .component('myChallengesCompleted', myChallengesCompleted)
  .component('myChallengesManage', myChallengesManage)
  .component('challengesOpen', challengesOpen)
  .component('challengesUpcoming', challengesUpcoming)
  .component('challengesClosed', challengesClosed)
  .component('myChallengesEditor', myChallengesEditor)
  // components
  .component('challengesItem', challengesItem)
  .component('myChallengesManageItem', myChallengesManageItem)
  .component('myChallengesManageItemMobile', myChallengesManageItemMobile)
  .component('myChallengesManageItemMobileDraft', myChallengesManageItemMobileDraft)
  .component('myChallengesManageDraft', myChallengesManageDraft)
  .component('challengesItemMobile', challengesItemMobile)
  .component('challengesItemSuggested', challengesItemSuggested)
  .component('challengesItemSuggestedMobile', challengesItemSuggestedMobile)
  .component('challengesExhibitionBanner', challengesExhibitionBanner)
  .component('blackFridayWidget', blackFridayWidget)
  .component('myChallengesSponsors', myChallengesSponsors)
  .component('myChallengesRankLevels', myChallengesRankLevels)
  .component('myChallengesAutoSelectWinners', myChallengesAutoSelectWinners)
  .component('myChallengesManageItemSuggested', myChallengesManageItemSuggested)
  .component('myChallengesMedia', myChallengesMedia)
  .component('finalizingResult', finalizingResult)
  .component('challengesItemCompleted', challengesItemCompleted)
  .component('myChallengesAchievementPrize', myChallengesAchievementPrize)
;

export default moduleChallengesPage.name;
