const template = require(`html-loader!./editDailyMission.html`).default;

class editDailyMissionCtrl {
  /**
   * @param {RestManager} RestManager
   * @param {ModalsService} Modals
   * */

  constructor(RestManager, $stateParams, $state) {
    'ngInject';
    this.types = ['VOTE', 'JOIN_CHALLENGE', 'LIKE', 'UPLOAD_PHOTO', 'CHALLENGE_LEVEL_UP'];
    this.type_params = [];
    this.challenge_type_params = ['random_challenge', 'any_challenge', 'specific_challenge', 'speed', 'exhibition', 'default'];
    this.level_up_type_params = ['1', '2', '3', '4', '5'];
    this.validation_types = [{name: '', value: null}, {
      name: 'active_challenges',
      value: 'active_challenges'
    }, {name: 'profile_photos_count', value: 'profile_photos_count'}];
    this.member_status_types = [];
    this.member_status_all_types = ['below', 'equal', 'above'];
    this.member_statuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 100];
    this.points_options = [1, 5, 10, 25, 100, 300];
    this.fields = ['actions_required', 'challenge_id', 'description', 'member_status', 'member_status_type',
      'title', 'type', 'type_param', 'points', 'validate_max', 'validate_min', 'validation_type', 'weight'];
    this.errors = [];

    this.RestManager = RestManager;
    this.$stateParams = $stateParams;
    this.$state = $state;
  }

  $onInit() {
    this.type_params = angular.copy(this.challenge_type_params);
    this.mission = this.$stateParams.mission || {};
    this.memberStatusChange();
    if (!this.mission.id) {
      this.mission.type = this.types[0];
      this.mission.type_param = this.type_params[0];
      this.mission.member_status = this.member_statuses[0];
      this.mission.points = this.points_options[0];
    }
  }

  async set() {
    if (this.validate()) {
      // let data = {
      //   actions_required: this.mission.actions_required,
      //   challenge_id: this.mission.challenge_id,
      //   description: this.mission.description,
      //   description_fields: this.mission.description_fields,
      //   member_status: this.mission.member_status,
      //   member_status_type: this.mission.member_status_type,
      //   points: this.mission.points,
      //   title: this.mission.title,
      //   title_fields: this.mission.title_fields,
      //   type: this.mission.type,
      //   type_param: this.mission.type_param,
      //   validate_max: this.mission.validate_max,
      //   validate_min: this.mission.validate_min,
      //   validation_type: this.mission.validation_type,
      //   weight: this.mission.weight,
      //   active: this.mission.active,
      // }
      // if (this.mission.id) data.id = this.mission.id;
      this.mission.active = this.mission.active? true : false;
      let res = await this.RestManager.setDailyMissionDefinition(this.mission);
      this.handleResponse(res);
    }

  }

  async del() {
    let res = await this.RestManager.delDailyMissionDefinition(this.mission.id);
    this.handleResponse(res);
  }

  handleResponse(res) {
    if (!res.success) {
      console.log(res);
      return;
    }
    this.$state.go('gs.gsAdmin.contentManager.dailyRewards.missions', {}, {reload: true});
  }

  typeChange() {
    switch (this.mission.type) {
      case 'JOIN_CHALLENGE':
      case 'VOTE':
        this.type_params = angular.copy(this.challenge_type_params);
        this.mission.type_param = this.type_params[0];
        break;
      case 'CHALLENGE_LEVEL_UP':
        this.type_params = angular.copy(this.level_up_type_params);
        this.mission.type_param = this.type_params[0];
        break;
      default:
        this.type_params = [];
        this.mission.type_param = null;
    }
  }

  memberStatusChange() {
    if (this.mission.member_status !== 100) {
      this.member_status_types = angular.copy(this.member_status_all_types);
    }else{
      this.member_status_types = ["equal"];
      this.mission.member_status_type = this.member_status_types[0];
    }
  }

  validate() {
    this.errors = [];
    let required_fields = ['member_status', 'member_status_type', 'points', 'type', 'description', 'title', 'actions_required', 'weight'];
    if (this.mission.type && ["JOIN_CHALLENGE", "VOTE", "CHALLENGE_LEVEL_UP"].includes(this.mission.type)) {
      required_fields.push("type_param");
    }
    if (this.mission.type_param === "specific_challenge" && !this.mission.challenge_id) {
      required_fields.push("challenge_id");
    }
    if (this.mission.validation_type) {
      required_fields.push('validate_max', 'validate_min');
    }

    for (let required_field of required_fields) {
      if (!this.mission[required_field] && this.mission[required_field] !== 0) {
        this.errors.push(`${required_field}  need to be defined`);
        return false;
      }
    }

    if (this.mission.validation_type) {
      if (!this.testNumber(this.mission.validate_max) || !this.testNumber(this.mission.validate_max)) {
        this.errors.push("validate_max/min not valid");
        return false;
      }
    }

    if (!this.testNumber(this.mission.actions_required) || this.mission.actions_required < 0) {
      this.errors.push("actions_required not valid");
      return false;
    }

    if (!this.testNumber(this.mission.weight) || this.mission.weight < 0 || this.mission.weight > 100) {
      this.errors.push("weight not valid");
      return false;
    }
    return true;
  }

  testNumber(value) {
    return Number(value) || Number(value) === 0;
  }

}
editDailyMissionCtrl.$inject = [
  'RestManager', '$stateParams', '$state'
];
export default {
  template: template,
  controller: editDailyMissionCtrl,
  bindings: {},
};
