import teamEnum  from '../../api/enums/team.enum';
import leaderBoardEnum from "../../api/enums/leaderBoard.enum";

function config($stateProvider) {
  "ngInject";

  $stateProvider
    .state('gs.teams', {
      url: '/teams',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'teamsPage'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.teams.lock', {
      template:'<teams-lock state="$ctrl.state"></teams-lock>'
    })
    .state('gs.teams.join', {
      url: '/join',
      template:'<teams-join config-data="$ctrl.configData"></teams-join>'
    })
    .state('gs.teams.home', {
      template:'<team-home team="$ctrl.team" config-data="$ctrl.configData"></team-home>',
      // redirectTo: 'gs.teams.home.myteam',
      // redirectTo: 'gs.teams.home.match',
    })
    .state('gs.teams.home.myteam', {
      template:'<my-team team="$ctrl.team"  config-data="$ctrl.configData"></my-team>',
      url: '/my-team'
    })
    .state('gs.teams.home.leaderboard', {
      template:'<team-leaderboard team="$ctrl.team" config-data="$ctrl.configData"></team-leaderboard>',
      url: '/leaderboard/:type',
      params: {
        type: leaderBoardEnum.TYPE.TEAM_LEAGUES
      }
    })
    .state('gs.teams.home.match', {
      template:'<teams-match team="$ctrl.team" config-data="$ctrl.configData"></teams-match>',
      url: '/match',
      params: {
        matchId: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.teams.create', {
      url: '/create',
      template:'<teams-create config-data="$ctrl.configData" state="$ctrl.state" ></teams-create>'
    })
    .state('gs.teams.edit', {
      url: '/edit',
      template:'<teams-create config-data="$ctrl.configData" state="$ctrl.state" ></teams-create>',
      params: {
        teamId: {
          value: null,
          squash: true
        }
      }
    })
    .state('gs.teams.info', {
      url: '/info/:teamId/:joinBy',
      template:'<teams-info  config-data="$ctrl.configData" my-team="$ctrl.team" state="$ctrl.state"></teams-info>',
      params: {
        teamId: {
          value: null,
          squash: true
        },
        joinBy: {
          value: teamEnum.JOIN_BY.DEFAULT,
          squash: true
        },

      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
