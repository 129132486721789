import gsAdminCtrl from './gsAdmin.page.ctrl';
const template = require(`html-loader!./gsAdmin.page.html`).default;

import challengeEnum from '../../api/enums/challenge.enum';

function config($stateProvider) {
  'ngInject';
  $stateProvider
    .state('gs.gsAdmin', {
      url: '/gs_admin',
      redirectTo: 'gs.gsAdmin.contentManager',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {
          template: template,
          controller: gsAdminCtrl,
          controllerAs: 'gsAdmin',
        },
        'footer@gs': {template: ''},
      },
    })
    .state('gs.gsAdmin.memberManager', {
      url: '/member-manager/:cUserName',
      component: 'memberManager',
      params: {
        cUserName: {
          value: null,
          squash: true,
        },
      },
    })
    .state('gs.gsAdmin.teamsManager', {
      url: '/teams-manager/:teamId',
      component: 'teamsManager',
      params: {
        teamId: {
          value: null,
          squash: true,
        },
      },
    })
    .state('gs.gsAdmin.contentManager', {
      url: '/content-manager',
      component: 'contentManager',
      redirectTo: 'gs.gsAdmin.contentManager.challenges',
    })
    // challenges
    .state('gs.gsAdmin.contentManager.challenges', {
      url: '/challenges',
      component: 'adminChallenges',
      redirectTo: 'gs.gsAdmin.contentManager.challenges.challengesSchedule',
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesSchedule', {
      url: '/challenges-schedule/:status/:start/:limit',
      component: 'challengesSchedule',
      params: {
        status: challengeEnum.STATUSES.UPCOMING.VALUE,
        start: {
          value: null,
          squash: true,
        },
        limit: {
          value: null,
          squash: true,
        }
      },
    })
    .state('gs.gsAdmin.contentManager.challenges.challengeTemplates', {
      url: '/challenge-templates',
      component: 'challengeTemplates',
    })
    .state('gs.gsAdmin.contentManager.challenges.bulkUpload', {
      url: '/bulk-upload',
      component: 'bulkUpload',
    })
    .state('gs.gsAdmin.contentManager.challenges.editChallengeTemplate', {
      url: '/edit-template',
      component: 'editChallengeTemplate',
      params: {
        template: null,
      }
    })
    .state('gs.gsAdmin.contentManager.challenges.newChallengeTemplate', {
      url: '/create-template',
      component: 'editChallengeTemplate'
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesWinners', {
      url: '/challenges-winners',
      component: 'challengesWinners',
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesCalendar', {
      url: '/challenges-calendar',
      component: 'challengesCalendar',
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesAutoSubmit', {
      url: '/challenges-auto-submit',
      component: 'challengesAutoSubmit',
    })
    .state('gs.gsAdmin.contentManager.challenges.notificationTimes', {
      url: '/notification-times',
      component: 'challengesNotificationTimes',
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesRankingLevels', {
      url: '/challenges-ranking-levels',
      component: 'challengesRankingLevels',
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesSponsors', {
      url: '/sponsors',
      component: 'challengesSponsors',
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesSponsors.new', {
      url: '/new',
      component: 'challengesSponsorsEdit'
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesSponsors.edit', {
      url: '/edit/:id',
      component: 'challengesSponsorsEdit',
      params: {
        id: null,
        data: null
      }
    })
    .state('gs.gsAdmin.contentManager.challenges.challengesPlannedTimings', {
      url: '/challenges-planned-timings',
      component: 'challengesPlannedTimings',
    })
    // exhibitions
    .state('gs.gsAdmin.contentManager.exhibitions', {
      url: '/exhibitions',
      component: 'adminExhibitions',
      redirectTo: 'gs.gsAdmin.contentManager.exhibitions.manager',
    })
    .state('gs.gsAdmin.contentManager.exhibitions.manager', {
      url: '/manager/:id',
      component: 'adminExhibitionsManager',
      params: {id: null},
    })
    .state('gs.gsAdmin.contentManager.exhibitions.selected', {
      url: '/selected',
      component: 'adminExhibitionsSelected',
    })
    .state('gs.gsAdmin.contentManager.exhibitions.page', {
      url: '/page',
      component: 'adminExhibitionsPage',
    })
    .state('gs.gsAdmin.contentManager.exhibitions.virtual', {
      url: '/virtual',
      component: 'adminExhibitionsVirtual',
    })
    .state('gs.gsAdmin.contentManager.exhibitions.virtualEdit', {
      url: '/virtual-edit',
      component: 'editVirtualExhibition',
      params: {
        virtualExhibition: null,
      }
    })
    .state('gs.gsAdmin.contentManager.exhibitions.virtualNew', {
      url: '/virtual-new',
      component: 'editVirtualExhibition'
    })
    .state('gs.gsAdmin.contentManager.exhibitions.edit', {
      url: '/edit',
      component: 'adminExhibitionsEdit',
      params: {
        id: null,
        exhibition: null,
      },
    })
    .state('gs.gsAdmin.contentManager.spotlight', {
      url: '/spotlight',
      component: 'adminSpotlight',
    })
    .state('gs.gsAdmin.contentManager.spotlightEdit', {
      url: '/spotlightEdit',
      component: 'editSpotlight',
      params: {
        spotlight: null,
      },
    })
    .state('gs.gsAdmin.contentManager.popups', {
      url: '/popups',
      component: 'adminCustomCard',
    })
    .state('gs.gsAdmin.contentManager.exhibition', {
      url: '/exhibition/{id}',
      component: 'editExhibition',
      params: {
        id: null,
        exhibition: null,
      },
    })
    .state('gs.gsAdmin.contentManager.widget', {
      url: '/widget',
      component: 'adminWidget',
    })
    .state('gs.gsAdmin.contentManager.dailyRewards', {
      url: '/dailyRewards',
      component: 'adminDailyRewards',
      redirectTo: 'gs.gsAdmin.contentManager.dailyRewards.missions'
    })
    .state('gs.gsAdmin.contentManager.dailyRewards.missions', {
      url: '/missions',
      component: 'adminDailyRewardsMissions',
    })
    .state('gs.gsAdmin.contentManager.dailyRewards.editDailyMission', {
      url: '/dailyMissionEdit',
      component: 'editDailyMission',
      params: {
        mission: null,
      }
    })
    .state('gs.gsAdmin.contentManager.dailyRewards.prizes', {
      url: '/prizes',
      component: 'adminDailyRewardsPrizes',
    })
    .state('gs.gsAdmin.contentManager.dailyRewards.editDailyPrize', {
      url: '/dailyPrizeEdit',
      component: 'editDailyPrize',
      params: {
        prize: null,
      }
    })
    .state('gs.gsAdmin.contentManager.rewardedVideo', {
      url: '/rewardedVideo',
      component: 'adminRewardedVideo'
    })
    .state('gs.gsAdmin.contentManager.liveops', {
      url: '/liveops',
      component: 'adminLiveops',
      redirectTo: 'gs.gsAdmin.contentManager.liveops.suggest'
    })
    .state('gs.gsAdmin.contentManager.liveops.suggest', {
      url: '/suggest',
      component: 'adminLiveopsSuggest'
    })
    .state('gs.gsAdmin.contentManager.events', {
      url: '/events',
      component: 'adminEvents',
      redirectTo: 'gs.gsAdmin.contentManager.events.leaderBoard'
    })
    .state('gs.gsAdmin.contentManager.events.leaderBoard', {
      url: '/leaderBoard',
      component: 'adminEventsLeaderBoard',
    })
    .state('gs.gsAdmin.contentManager.events.editLbEvent', {
      url: '/edit',
      component: 'editLbEvent',
      params: {
        event: null,
      }
    })
    .state('gs.gsAdmin.contentManager.events.newLbEvent', {
      url: '/new',
      component: 'editLbEvent'
    })
    .state('gs.gsAdmin.contentManager.chests', {
      url: '/chests',
      component: 'adminChests'
    })
    .state('gs.gsAdmin.contentManager.teams', {
      url: '/teams',
      component: 'adminTeams',
      redirectTo: 'gs.gsAdmin.contentManager.teams.leagues'
    })
    .state('gs.gsAdmin.contentManager.teams.leagues', {
      url: '/leagues',
      component: 'adminLeagues',
      redirectTo: 'gs.gsAdmin.contentManager.teams.leagues.active'
    })
    .state('gs.gsAdmin.contentManager.teams.leagues.active', {
      url: '/edit/:status',
      component: 'adminEditLeagues',
      params: {
        status: 'active'
      },
    })
    .state('gs.gsAdmin.contentManager.teams.leagues.next', {
      url: '/edit/:status',
      component: 'adminEditLeagues',
      params: {
        status: 'next'
      },
    })
    .state('gs.gsAdmin.contentManager.teams.seasons', {
      url: '/seasons',
      component: 'adminSeasons'
    })
  ;
}

config.$inject = [
  '$stateProvider'
];

export default config;
