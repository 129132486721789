const template = require(`html-loader!./adminChests.html`).default;
import {isFieldValid} from "../../../../helpers/validate.helper";

class adminChestCtrl {

  constructor(RestManager, $scope, $state, Toasts, $element) {
    'ngInject';
    this.busy = false;

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.$state = $state;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  $onInit() {
    this.loadData();
  }

  /** @return void **/
  async loadData() {
    let res = await this.RestManager.adminGetChests();
    // let res = await getChestAttributes;
    if (!res.success) return;
    this.chests = res.chests;
    this.currentChest = this.chests[0];
    this.weightChanged();
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  weightChanged(){
    console.log(this.currentChest);
    for (let rewardType in this.currentChest.rewards){
      if(this.currentChest.rewards[rewardType]){
        let sum  = this.currentChest.rewards[rewardType].reduce(this.reducer,0);
        this.currentChest.rewards[rewardType].forEach(rewardRange => rewardRange.percent = rewardRange.weight/sum*100);
      }
    }
  }

  reducer(accumulator, currentValue){
    return accumulator + currentValue.weight;
  }

  async save(){
    console.log(this.chests);
    let rewards = [];
    for (const rewardType in this.currentChest.rewards) {
      rewards = [...rewards, ...this.currentChest.rewards[rewardType]];
    }
    // rewards = rewards.map((reward) => {
    //   delete reward.chest_id;
    //   return reward;
    // });
    if(this.validate(rewards)){
      let res = await this.RestManager.adminSetChestRewards(rewards, this.currentChest.chest_id);
      if (!res.success){
        this.Toasts.message(res.message || 'Error', this.$element, true, 'bottom right');
        return;
      }
      this.Toasts.message('Saved', this.$element, false, 'bottom right');
    }
  }

  validate(rewards){
    let required_fields = ['reward_min', 'reward_max', 'weight'];
    for(let reward of rewards){
      for (let required_field of required_fields) {
        if (!isFieldValid(reward[required_field])) {
          this.Toasts.message(`${required_field}  need to be defined`, this.$element, true, 'bottom right');
          return false;
        }
      }
    }
    return true;
  }

  addRewardRang(rewardRanges, rewardType){
    rewardRanges.push(
      {
        chest_id: this.currentChest.chest_id,
        reward_type: rewardType,
        reward_min: 1,
        reward_max: 1,
        weight: 1
      }
    );
    this.weightChanged();
  }
}
adminChestCtrl.$inject = [
  'RestManager', '$scope', '$state', 'Toasts', '$element'
];
export default {
  template: template,
  controller: adminChestCtrl,
  bindings: {},
};
