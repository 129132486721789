import {Injectable} from '@angular/core';
import {Socket} from "ngx-socket-io";
import NgTokenService from "./ng-token.service";
import {environment} from "../../../environments/environment";
import {IGsResponse} from "../models/gsResponse.types";
import {ChallengeBoostState, ChallengeStatus} from "../../modules/challenges/enums/challenges.enum";

export interface ISocketStatusUpdateEventResult extends IGsResponse{
  status: ChallengeStatus,
  challenge_id:number
}

export interface ISocketBoostStateUpdateEventResult extends IGsResponse{
  state: ChallengeBoostState;
  timeout: any;
  challenge_id:number;
}

@Injectable({providedIn: 'root'})
export class SocketService extends Socket {

  constructor(
    // @ts-ignore
    private tokenService: NgTokenService
  ) {
    super({
      url: `${environment.domains.globalSocket}`,
      options: {
        query: {
          authorization: tokenService.memberToken
        },
        withCredentials: true
      }
    });

    this.on('error', (error: any) => {
      console.error('Error ', error);
    });

    this.on('connect', () => {
      console.log('socket connected')
    });
  }

  get socket(): Socket {
    return this;
  }
}
