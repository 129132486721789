
class pageData {
  constructor(
    $rootScope,
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
  }

  get(pageName) {
    // Update body class
    this.$rootScope.body.classs = pageName;
  }
}
pageData.$inject = [
  '$rootScope',
];
let modulePageData = angular
  .module('gsApp.model.pageData', [])
  .service('pageData', pageData);

export default modulePageData.name;
