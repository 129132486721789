class gsAdminCtrl {

  constructor($state,
              $scope,
              $stateParams,
              $cookies,
              MemberService,
              NgMetaDataService,
              pageData
  ) {
    'ngInject';
    this.done = false;
    this.$state = $state;
    this.$scope = $scope;
    this.pageData = pageData;
    this.$stateParams = $stateParams;
    this.$cookies = $cookies;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.init();
  }

  /** @return void **/
  init() {
    this.pageData.get('gs-admin');
    this.NgMetaDataService.setTitle('Admin');
    this.member = this.MemberService.getCurrentMember();
    if (!this.$cookies.get('gs_at') || !this.member.is_admin) {
      this.$state.go('gs.challenges.myChallenges.joined');
      return;
    }
    this.done = true;
  }
}
gsAdminCtrl.$inject = [
  '$state',
  '$scope',
  '$stateParams',
  '$cookies',
  'MemberService',
  'NgMetaDataService',
  'pageData'
];
export default gsAdminCtrl;
