function config($stateProvider) {
  'ngInject';

  $stateProvider
    .state('gs.virtualExhibitions', {
      url: '/virtual_exhibitions',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'virtualExhibitions'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
