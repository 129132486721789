import {Component, Input, OnChanges} from '@angular/core';
import {MemberService} from "../../../core/services/member.service";
import {INgMemberModel} from "../../../core/models/fromOldApp/models";
import {FollowService} from "../../../core/services/follow.service";
import {MediatorMessageKey, MediatorService} from "../../../core/services/mediator.service";

@Component({
  selector: 'app-ng-follow',
  templateUrl: './ng-follow.component.html',
  styleUrl: './ng-follow.component.scss'
})
export class NgFollowComponent implements OnChanges {
  @Input() public member: INgMemberModel | null = null;

  public following = false;
  public isLoading = false;

  constructor(
    public memberService: MemberService,
    private followService: FollowService,
    private mediatorService: MediatorService,
    ) {}

  public ngOnChanges(): void {
    if (!this.member) return;

    this.following = this.member.following || this.member.is_following;
  }

  public async triggerFollow(event: any): Promise<void> {

    if (!this.memberService.getCurrentMember()?.logged_in) {
      this.goToSignUp(event);
    }

    const rootScope = this.mediatorService.rootScope.getValue();
    this.isLoading = true;

    const result = this.following ?
      await this.followService.unfollow(rootScope.el, rootScope.el_id, this.member?.id) :
      await this.followService.follow(rootScope.el, rootScope.el_id, this.member?.id);

    this.isLoading = false;
    if (!result.success) {
      if (result.error_code === 1000) {
        this.goToSignUp(event);
      }
      return;
    }

    this.following = !this.following;
  }

  private goToSignUp(event: any): void {
    this.mediatorService.broadcast(MediatorMessageKey.OPEN_LOGIN_MODAL, event);
  }

}
