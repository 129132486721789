const template = require(`html-loader!./teamsLock.html`).default;

class teamsPageCtrl {

  constructor(tagEventService) {
    'ngInject';
    this.tagEventService = tagEventService;
  }

  $onInit(){
    this.tagEventService.sendPageViewEvent({title: 'Teams - lock page'});
  }
}
teamsPageCtrl.$inject = [
  'tagEventService',
];
export default {
  template: template,
  controller: teamsPageCtrl,
  bindings: {
    state: '<',
  }
};
