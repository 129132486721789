const template = require(`html-loader!./exhibitionsItem.html`).default;

class exhibitionsItemComponent {

  constructor(Modals) {
    'ngInject';

    this.Modals = Modals;
  }

  openVideo(event) {
    this.Modals.open('player', event, this.exhibition.media);
  }
}
exhibitionsItemComponent.$inject = [
  'Modals',
];
export default {
  template: template,
  controller: exhibitionsItemComponent,
  bindings: {
    exhibition: '<',
    index: '<',
  }
};
