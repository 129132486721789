import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./autoSubmit.html`).default;
import ImageModel from '../../../api/models/image/image.model';
import challengeEnum from '../../../api/enums/challenge.enum';

class autoSubmitModalCtrl {
  /** @param {RestManager} RestManager */

  constructor($mdDialog, $timeout, RestManager, $rootScope, $scope, Modals, $state, NotificationsManager, MemberService) {
    'ngInject';
    this.busy = true;

    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    this.RestManager = RestManager;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.Modals = Modals;
    this.$state = $state;
    this.NotificationsManager = NotificationsManager;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    if (!this.data) {
      this.close();
      return false;
    }
    this.c_id = this.data.message.link_2;
    this.getData();
  }

  /** @return void */
  async getData() {
    console.log(this.c_id, this.member.id);
    if (!this.c_id || !this.member.id) {
      this.close();
      return;
    }

    const res = await this.RestManager.getMemberRanking(this.member.id, this.c_id);
    if (!res.success) {
      this.close();
      return;
    }

    this.challenge = new NgChallengeModel(res.challenge);
    this.images = res.ranking.entries.map(image => new ImageModel(image));

    // challenge closed
    if (this.challenge.status === challengeEnum.STATUSES.CLOSED.VALUE) {
      this.$state.go('gs.challenge.details', {cUrl: this.challenge.url}, {reload: true});
      this.close();

      return;
    }

    // no images -> submit
    if (!this.images.length) {
      this.Modals.open('preSubmit', event, {challenge: this.challenge});
      return;
    }

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  leaveChallenge() {
    this.NotificationsManager.readNotifications(this.data.message.date, 'activity');
    const confirm = this.$mdDialog
      .confirm()
      .title('Remove all entries')
      .textContent('Are you sure you want to remove all your entries to this challenge?')
      .ariaLabel('Remove')
      .ok('Remove')
      .cancel('Cancel');
    this.$mdDialog.show(confirm).then(() => {
      this.RestManager.leaveChallenge(this.challenge.id).then(res => {
        if (res.success) {
          location.reload();
        }
      });
    });
  }

  close() {
    if(this.data.message) {
      this.NotificationsManager.readNotifications(this.data.message.date, 'activity');
    }

    this.$mdDialog.hide();
  }
}
autoSubmitModalCtrl.$inject = [
  '$mdDialog', '$timeout', 'RestManager', '$rootScope', '$scope', 'Modals', '$state', 'NotificationsManager', 'MemberService'
];
export default {
  template: template,
  controller: autoSubmitModalCtrl,
  bindings: {
    data: '=data'
  }
};
