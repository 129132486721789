const template = require(`html-loader!./photoData.html`).default;
import achievementEnum from "../../../api/enums/achievement.enum";

class PhotoDataCtrl {
  /**
   * @param {PhotosManager} PhotosManager
   * @param {popupService} popup
   * @param {ModalsService} Modals
   * */

  constructor($rootScope,
              PhotosManager,
              photoSwipe,
              $timeout,
              popup,
              Modals,
              notificationAlert,
              MemberService,
  ) {
    'ngInject';
    this.$ctrl = this;
    this.owner;
    this.image;
    this.isAdmin = false;
    this.busy = false;

    this.$rootScope = $rootScope;
    this.PhotosManager = PhotosManager;
    this.photoSwipe = photoSwipe;
    this.$timeout = $timeout;
    this.popup = popup;
    this.Modals = Modals;
    this.notificationAlert = notificationAlert;
    this.achievementEnum = achievementEnum;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();

  }

  $onChanges() {
    this.isAdmin = this.member.is_admin;
    this.memberOwner = this.member.isOwner(this.image.member_id);
    this.owner = this.member.isOwner(this.image.member_id) || this.isAdmin;
    this.loggedIn = this.member.logged_in;
    this.h1Text = this.image.labels?.length ? `Photo of ${(this.image.labels.slice(0, 3)).join(', ')}` : undefined;
  }

  $onDestroy() {
    window.removeEventListener('message', this.onMessage, false);
  }

  lockArrows() {
    this.photoSwipe.gallery.options.arrowKeys = false;
  }

  unLockArrows() {
    this.photoSwipe.gallery.options.arrowKeys = true;
  }

  autoSize(event) {
    const el = event.target;
    this.$timeout(() => {
      el.style.cssText = 'height:auto; padding:0';
      el.style.cssText = 'height:' + el.scrollHeight + 2 + 'px';
    });
  }

  click(type) {
    switch (type) {
      case 'votes':
        this.votesList = false;
        break;
      case 'likes':
        this.likesList = false;
        break;
    }
  }

  saveAdult() {
    const data = {
      id: this.image.id,
      adult: this.image.adult ? 1 : 0,
    };
    this.PhotosManager.setPhotoData(data);
  }

  saveData() {
    this.showSave = false;
    const data = {
      id: this.image.id,
      title: this.image.title,
      description: this.image.description,
    };
    this.PhotosManager.setPhotoData(data);
  }

  onLabelUpdate(chip) {
    const isValid = /^([a-zA-Z- ]){2,30}$/.test(chip);
    return isValid ? undefined : null;
  }

  updateLabels(add) {
    this.PhotosManager.setImageData({
      id: this.image.id,
      labels: this.image.labels.length ? this.image.labels : false,
    });
  }

  help() {
    this.notificationAlert.open('No photographer commission is applied when purchasing a print of your own image');
  }

  onAchievementClick(achievement){
    this.showAchievementImageChallenges(achievement);
    if(achievement.showAchievementImageChallenges){
      $('photo-data').scrollTo(`.${achievement.parsed_unique_key}`, 500, {offset: -100,axis:'y'});
    }
  }

  showAchievementImageChallenges(achievement){
    if(achievement.showAchievementImageChallenges){
      achievement.showAchievementImageChallenges = false;
    } else {
      this.hideAchievementImageChallenges();
      achievement.showAchievementImageChallenges = true;
    }
  }

  hideAchievementImageChallenges(){
    this.image.achievements.forEach(achievement => achievement.showAchievementImageChallenges = false);
  }
}
PhotoDataCtrl.$inject = [
  '$rootScope',
  'PhotosManager',
  'photoSwipe',
  '$timeout',
  'popup',
  'Modals',
  'notificationAlert',
  'MemberService',
];
export default {
  template: template,
  controller: PhotoDataCtrl,
  bindings: {
    image: '<',
  },
};
