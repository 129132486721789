import {
  ChallengeTurboUnlockType,
  ChallengeType,
  PrizeAward,
} from "../../../../../modules/challenges/enums/challenges.enum";

const template = require(`html-loader!./myChallengesEditor.html`).default;
import challengeEnum from "../../../api/enums/challenge.enum";
import {
  adminFieldsList,
  challengeFields,
  challengeDefSettings,
  exhibitionPackages,
  unsavedFieldsList,
  maxPhotoSubmitsOptions,
  challengeTypes,
  exhibitionBadges,
  joinMemberStatuses} from './myChallengesEditor.data';
import moment from 'moment';
import * as _ from 'lodash';
import {clearObject, objectDifference} from '../../../helpers/object.helper';
import { assignModes } from "../../../services/Modals/createChallenge/createChallenge.data";
import { isFieldValidNew } from "../../../helpers/validate.helper";
import {ChallengeTemplate} from "../../../api/models/ChallengeTemplate";
import {getDate, millisecondsInHour, millisecondsInSecond, secondsInMinute} from "../../../helpers/date.helper";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

class myChallengesEditorCtrl {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * @param {challengeService} challengeService
   * @param {RestManager} RestManager
   * @param {notificationAlertService} notificationAlert
   * @param {stateService} stateService
   * */

  constructor(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    ChallengesManager,
    Modals,
    PhotosManager,
    RestManager,
    notificationAlert,
    stateService,
    challengeService,
    Toasts,
    $element,
    MemberService,
    SessionConfigService
  ) {
    'ngInject';
    this.busy = true;
    this.challengeTypes = challengeTypes;
    this.ChallengeType = ChallengeType;
    this.imageSuggestTypes = [
      challengeEnum.IMAGE.SUGGEST.TYPE.DISABLED,
      challengeEnum.IMAGE.SUGGEST.TYPE.ESQUERY,
      challengeEnum.IMAGE.SUGGEST.TYPE.MOST_VOTED
    ];
    this.ChallengeTurboUnlockType = ChallengeTurboUnlockType;
    this.memberChanged = false;
    this.dup = false;
    this.tagsSearchText= "";
    this.isBadge = false;

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.ChallengesManager = ChallengesManager;
    this.Modals = Modals;
    this.PhotosManager = PhotosManager;
    this.RestManager = RestManager;
    this.notificationAlert = notificationAlert;
    this.stateService = stateService;
    this.challengeService = challengeService;
    this.SessionConfigService = SessionConfigService;
    this.Toasts = Toasts;
    this.$element = $element;
    this.exhibitionPackages = exhibitionPackages;
    this.challengeEnum = challengeEnum;
    this.maxPhotoSubmitsOptions = maxPhotoSubmitsOptions;
    this.joinMemberStatuses = joinMemberStatuses;
    this.preformattedRecommendedStartTime = [];
    this.preformattedRecommendedEndTime = [];
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.init();
  }

  async init (){
    this.now = new Date();
    this.dup = this.$stateParams.dup;
    this.assignMode = this.$stateParams.assignMode;
    this.suggestedImageId = this.$stateParams.imageId;
    this.isNewChallenge = !this.$stateParams.cUrl;
    this.isSuggestedImageModeAllowed = this.SessionConfigService.permissions.CHALLENGE_BID > 0 && this.suggestedImageId !== null;

    // no permissions before getting challenge data
    if (!this.member.is_admin) {
      if (this.dup ||
        (!this.SessionConfigService.permissions.CREATE_CHALLENGE &&
          !this.isSuggestedImageModeAllowed
        )) {
        this.stateService.goToDefaultState();

        return;
      }
    } else {
      await this.getChallengeTemplates();
    }

    this.getData();
  }

  isSuggestedMode() {
    return (
      this.challenge && this.suggestedImageId &&
      [
        challengeEnum.STATUSES.IN_BID.VALUE,
        challengeEnum.STATUSES.UNASSIGNED.VALUE
      ].includes(this.challenge.status)) || (
        !this.member.is_admin &&
      this.challenge.status === challengeEnum.STATUSES.ASSIGNED.VALUE
    );
  }

  /** @return void */
  async getData() {
    let res;
    if (!this.isNewChallenge) {
      // challenge exist
      const params = {
        url: this.$stateParams.cUrl,
        fields: challengeFields,
        include: ['apply', 'sponsors', 'guru_info', 'media'],
        raw:this.dup
      };

      res = await this.ChallengesManager.getData(params);

      if (!res.success) {
        this.stateService.goToDefaultState();

        return;
      } else {
        if (this.dup) {
          //create dup challenge
          let data = {};

          for (const key of challengeFields) {
            switch (key) {
              // case 'start_time':
              //   data[key] = parseFromMilisecToSec(getDate());
              //   console.log('start_time', data[key]);
              //   break;
              // case 'close_time':
              //   data[key] = parseFromMilisecToSec(getDate({daysToAdd:4}));
              //   console.log('close_time', data[key]);
              //   break;
              case 'as_max_joined_challenges':
              case 'latest_upload_date':
              case 'image_suggest_query':
              case 'description_title':
              case 'badge_left':
              case 'badge_right':
                if (res.challenge[key]) {
                  data[key] = res.challenge[key];
                }
                break;
              case 'achievements_and_prizes':
                if(res.challenge.achievements_and_prizes){
                  data[key] = angular.toJson(res.challenge[key])
                }
                break;
              case 'id':
              case 'url':
              case 'prizes':
              case 'enable_team_match':
                break;
              default:
                data[key] = res.challenge[key];
            }
          }
          data.status = challengeEnum.STATUSES.DRAFT.VALUE;

          switch (this.assignMode) {
            case assignModes.unassigned:
              data.status = challengeEnum.STATUSES.UNASSIGNED.VALUE;
              data.image_id = null;
              break;
            case assignModes.me:
              data.member_id = this.member.id;
              break;
            case assignModes.owner:
              // member already copied from org challenge
              break;
          }

          const dupRes = await this.ChallengesManager.setChallenge(data);

          if (!dupRes.success) {
            this.stateService.goToDefaultState();

            return;
          }

          //save sponsors
          let reqArr = [];

          if (res.challenge.sponsors && res.challenge.sponsors.length > 0) {
            let ids = res.challenge.sponsors.map(sponser => sponser.id);
            reqArr.push(this.ChallengesManager.setChallengeSponsors(dupRes.challenge.id, ids));
          }

          /* TODO: wierd if, did nothing... */
          if (reqArr.length>0) {
            const resArr =  await Promise.all(reqArr);
            const success = resArr.every(r => r.success);

            if (!success) {
              //need to delete challenge
            }
          }

          this.stateService.goToState('gs.challenges.myChallenges.editor',{cUrl: dupRes.challenge.url, dup:false});

          return;
        }

        this.challenge = res.challenge;
        console.log('this.challenge', this.challenge);
        // if guru came from suggested and already uploaded image
        if (this.suggestedImageId) {
          this.challenge.image.id = this.suggestedImageId;
          this.challenge.image.member_id = this.member.id;
        }
      }
    } else {
      // new challenge
      let challenge = new NgChallengeModel({
        member_id: this.member.id,
      });

      this.challenge = challenge;
    }

    // no permissions after getting challenge data
    if (!this.member.is_admin){
      if (
        (this.challenge.member_id && this.challenge.member_id !== this.member.id) && // not the owner of the challenge
        !(this.isSuggestedImageModeAllowed && this.challenge.status ===this.challengeEnum.STATUSES.IN_BID.VALUE) // not in suggested mode
      ) {
        this.stateService.goToDefaultState();
        return;
      }
    } else {
      if(this.challenge.isClosingStatus()){
        this.stateService.goToDefaultState();
        return;
      }
    }

    this.originalChallenge = _.cloneDeep(this.challenge);

    // if new challenge
    if (this.isNewChallenge) {
      this.typeChanged();
      if(this.member.is_admin){
        this.challenge.start_time = getDate({hoursToAdd:2});
        this.challenge.close_time = getDate({hoursToAdd:2, daysToAdd:4});
        this.durationChanged();
      }
    } else {
      //When 'Image suggest type' is Most voted turn 'Set Off enable' to disabled
      this.onImageSuggestTypeChange();
      this.durationChanged();
    }

    this.joinMemberStatusChanged(false);
    this.badgeToggle(false);

    this.busy = false;

    this.$timeout(() => {
      this.activateTextEditor();

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });
  }

  activateTextEditor() {
    this.$timeout(() => {
      new MediumEditor('.welcome_message', {
        elementsContainer: document.querySelector('welcome_message'),
        spellcheck: false,
        targetBlank: true,
        imageDragging: false,
        toolbar: {
          buttons: ['bold', 'italic', 'underline', 'h2', 'h3', 'h4', 'anchor'],
          diffTop: -10,
        },
        paste: {
          cleanPastedHTML: true,
          cleanAttrs: ['style', 'dir'],
          cleanTags: ['label', 'meta'],
        },
      });
    });
  }

  async getChallengeTemplates() {
    this.busy = true;
    const res  = await this.RestManager.getChallengeTemplates();
    this.busy = false;
    if(!res.success){
      return;
    }
    this.templates = res.templates.map((template)=> new ChallengeTemplate(template));
    // if (!this.$scope.$$phase) {
    //   this.$scope.$digest();
    // }
  }

  changeCover(event) {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: {
        id: this.member.id,
      },
      callback: images => {
        this.challenge.image = images[0];
      },
    });
  }

  onImageSuggestTypeChange() {
    if(this.challenge.image_suggest_type === challengeEnum.IMAGE.SUGGEST.TYPE.MOST_VOTED) {
      this.challenge.enable_off_topic = false;
    }
  }

  badgeToggle(saveDefault = true) {
    this.badges = this.challengeService.getBadgesByType(
      this.challenge,
      saveDefault
    );
    this.badgeChanged(saveDefault);
  }



  badgeChanged(saveDefault = true) {
    this.challengeService.badgeChanged(this.challenge, saveDefault);
  }

  typeChanged() {
    _.assignIn(this.challenge, challengeDefSettings[this.challenge.type]);
    this.badgeToggle();
  }

  applyChallengeTemplate(){
    const challengeBeforeTemplate = angular.copy(this.challenge);
    if(!this.selectedChallengeTemplate.params.achievements_and_prizes){
      this.selectedChallengeTemplate.params.achievements_and_prizes = [];
    }
    Object.assign(this.challenge, clearObject(this.selectedChallengeTemplate.params));
    // this.updateDurationByHours(this.selectedChallengeTemplate.params.duration_by_hours);
    this.badgeToggle();
    // this.typeChanged();
    if(this.selectedChallengeTemplate.params.max_photo_submits){
      this.challenge.max_photo_submits = this.selectedChallengeTemplate.params.max_photo_submits;
    }
    if(challengeBeforeTemplate.join_member_status !== this.challenge.join_member_status){
      this.joinMemberStatusChanged(false);
    }
    this.challenge = angular.copy(this.challenge);

    this.preformatRecommendedStartTime();
    this.preformatRecommendedEndTime();
  }

  //region Recommended start time
  /**
   * 100% duplicates Recommended end time
   * */
  preformatRecommendedStartTime() {
    this.preformattedRecommendedStartTime = this.challenge.recommended_start_time.map((item) => {
      let dateItem = new Date(item);
      let hours = dateItem.getHours() < 10 ? `0${dateItem.getHours()}` : dateItem.getHours();
      let minutes = dateItem.getMinutes() < 10 ? `0${dateItem.getMinutes()}` : dateItem.getMinutes();

      return `${hours}:${minutes}`;
    });
  }

  applyRecommendedStartTime(index) {
    let startTime = new Date(this.challenge.start_time);
    let recommendedTime = new Date(this.challenge.recommended_start_time[index]);

    startTime.setHours(recommendedTime.getHours());
    startTime.setMinutes(recommendedTime.getMinutes());

    this.challenge.start_time = startTime;
    this.durationChanged();
  }
  //endregion

  //region Recommended end time
  /**
   * 100% duplicates Recommended start time
   * */
  preformatRecommendedEndTime() {
    console.log(this.preformattedRecommendedEndTime);
    this.preformattedRecommendedEndTime = this.challenge.recommended_end_time.map((item) => {
      let dateItem = new Date(item);
      let hours = dateItem.getHours() < 10 ? `0${dateItem.getHours()}` : dateItem.getHours();
      let minutes = dateItem.getMinutes() < 10 ? `0${dateItem.getMinutes()}` : dateItem.getMinutes();

      return `${hours}:${minutes}`;
    });
  }

  applyRecommendedEndTime(index) {
    let endTime = new Date(this.challenge.close_time);
    let recommendedTime = new Date(this.challenge.recommended_end_time[index]);

    endTime.setHours(recommendedTime.getHours());
    endTime.setMinutes(recommendedTime.getMinutes());

    this.challenge.close_time = endTime;
    this.durationChanged();
  }
  //endregion

  applyRecommendedHours(index) {
    let startTime = new Date(this.challenge.start_time);
    startTime.setHours(startTime.getHours() + this.challenge.recommended_hours[index]);
    this.challenge.close_time = startTime;
    this.durationChanged();
  }

  // updateDurationByHours(hours) {
  //   this.challenge.start_time = getDate();
  //   this.challenge.close_time = getDate({
  //     hoursToAdd: hours
  //   });
  //   this.durationChanged();
  // }

  durationChanged() {
    if (this.challenge.close_time && this.challenge.start_time) {
      this.duration = (this.challenge.close_time - this.challenge.start_time);
      // const challengeHours = Math.floor(this.duration / millisecondsInHour);
      // this.challenge.enable_rank_auto_update = this.challengeService.isRankAutoUpdate(challengeHours);
      if(!this.isNewChallenge &&
        this.challenge.start_time.getTime() !== this.originalChallenge.start_time.getTime()
        && this.challenge.is_bid_schedule){
        this.challenge.setDefaultBidStartTime();
      }
    } else {
      this.duration = 0;
    }
  }

  onMaxPhotoSubmitsChange(){
    this.challenge.top_photographer_enable = !this.challenge.isOnePhoto()
  }

  /** @return boolean */
  isValid(form) {
    // clear form
    // form.$submitted = true;
    // this.errorMessage = '';
    // let noErrors = true;

    // duration min
    if (this.member.is_admin) {
      //achievements_and_prizes validation
      if(!this.isNewChallenge){
        if(this.challenge.achievements_and_prizes && this.challenge.achievements_and_prizes.length){
          for(let achievementPrize of this.challenge.achievements_and_prizes){
            if(!this.challengeService.isAchievementPrizeValid(achievementPrize)){
              return false;
            }
          }
          for(let achievementPrize of this.challenge.achievements_and_prizes){
            achievementPrize.parseBeforeSave();
          }
        }
      }

      const minDuration = millisecondsInSecond * secondsInMinute * 10;
      if (this.duration <= minDuration) {
        this.notificationAlert.open('Duration need to be at least 10 minutes!');
        return false;
      }

      let fieldsToValidate = this.challengeService.getChallengeFieldsToValidate();
      if(!this.challengeService.isFieldsValid(this.challenge, fieldsToValidate)){
        return false;
      }
    }

    if(this.challenge.isOnePhoto()){
      if(this.challenge.boost_enable && this.challenge.turbo_enable){
        this.notificationAlert.open('Cant enable boost and turbo in one photo challenge!');
        return false;
      }
    }

    // validate name
    if (!this.challenge.title || this.challenge.title.length < 5) {
      this.notificationAlert.open(`Title is too short!`);
      return false;
    }

    // validate welcome message
    if (!(this.challenge.welcome_message && this.challenge.welcome_message.length > 20)) {
      this.notificationAlert.open(`BRIEF is too short!`);
      return false;
    }

    // cover selected
    if (!this.challenge.image.id && ![challengeEnum.STATUSES.UNASSIGNED.VALUE, challengeEnum.STATUSES.IN_BID.VALUE].includes(this.originalChallenge.status)) {
      this.notificationAlert.open('Please select cover image!');
      return false;
    }

    return true;
  }

  toggleTip(name) {
    this.showTip = this.showTip === name ? undefined : name;
  }

  /** @return void */
  async save(form, showConfirm = true) {
    if (this.busy) {
      return false;
    }
    if (showConfirm) {
      const confirm = await this.Modals.confirm(
        'Save to draft?',
        'Are you sure you want to save changes to this draft?',
        'Save',
        'Cancel'
      );

      if (!confirm) {
        return false;
      }
    }

    // validate
    if (!this.isValid(form) ||
      (this.member.is_admin &&
        (!this.challengeService.isScheduleBidValid(this.challenge) || !this.challengeService.isValidDates(this.challenge))
      )
    ) {
      this.challenge.status =  this.originalChallenge.status;
      return false;
    }

    // save social
    if (this.memberChanged) {
      this.busy = true;
      await this.RestManager.updateMemberSettings({
        instagram: this.member.instagram,
        facebook: this.member.facebook,
        website: this.member.website,
      });
    }

    const data = { id: this.challenge.id };
    const changedFields = objectDifference(angular.copy(this.challenge), this.originalChallenge);
    // remove fields for admins only
    if (!this.member.is_admin) {
      _.remove(changedFields, key => adminFieldsList.includes(key));
      _.remove(changedFields, key => unsavedFieldsList.includes(key));
    }

    // no changes
    if (!changedFields.length) {
      this.redirect();

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return true;
    }

    if (this.challenge.filter_submit_by_date) {
      if (!changedFields.includes('latest_upload_date')) {
        changedFields.push('latest_upload_date');
      }
    }

    // prepare data
    for (const key of changedFields) {
      switch (key) {
        case 'as_last_joined_date':
        case 'latest_upload_date':
        case 'start_time':
        case 'close_time':
        case 'bid_start_time':
          // date fields
          data[key] = moment(this.challenge[key]).format('X');
          break;
        case 'image':
          data['image_id'] = this.challenge.image.id;
          break;
        case 'achievements_and_prizes':
          if(this.challenge.achievements_and_prizes){
            this.challenge.achievements_and_prizes.forEach((e)=>{
              delete e.selectedAchievement;
            })
            console.log('achievements_and_prizes', this.challenge.achievements_and_prizes);
            data[key] = angular.toJson(this.challenge[key])
          }
          break;
        case 'prizes':
        case 'sponsors':
        case 'media':
        case 'recommended_hours':
        case 'recommended_start_time':
        case 'recommended_end_time':
          // no need to set
          break;
        default:
          data[key] = this.challenge[key];
      }
    }

    // no changes
    if (Object.keys(data).length === 1 && data.id) {

      this.redirect();

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return true;
    }

    this.busy = true;
    const challengeRes = await this.ChallengesManager.setChallenge(data);
    if (!challengeRes.success) {
      this.onResFail();
      return false;
    }

    this.challenge = challengeRes.challenge;
    // redirect to manage
    this.redirect();
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
    return true;
  }

  onResFail(){
    this.busy = false;
    this.challenge.status =  this.originalChallenge.status;
    this.Toasts.message(challengeRes.message || 'Error!', this.$element);
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  redirect() {
    if(this.member.is_admin){
      this.stateService.goToState('gs.challenge.details', {cUrl: this.challenge.url}, true);
    } else {
      this.stateService.goToState('gs.challenges.myChallenges.manage', {}, true);
    }
  }

  reject(event, status) {
    if (this.busy) {
      return false;
    }
    this.Modals.open('toDraft', event, { challenge: this.challenge, status: status, redirectState: 'gs.challenges.myChallenges.manage'});
  }

  async submit(form) {
    if (this.busy) {return false;}

    if (await this.challengeService.setAsPending(this.challenge)) {
      this.save(form, false);
    }
  }

  async approve(form) {
    if (this.busy) {return false;}

    if (await this.challengeService.setAsUpcoming(this.challenge)) {
      this.save(form, false);
    }
  }

  async setForBid(form) {
    if (this.busy) {return false;}

    const res = this.challenge.is_bid_schedule ?
      await this.challengeService.setForScheduleBid(this.challenge) : await this.challengeService.setForBid(this.challenge);

    if (res) {
      this.save(form, false);
    }
  }

  async setAsUnassigned(form) {
    if (this.busy) {return false;}

    if (await this.challengeService.setAsUnassigned(this.challenge)) {
      this.save(form, false);
    }
  }

  openQueryBuilder(event) {
    this.Modals.open('queryBuilder', event, {
      query: this.challenge.image_suggest_query,
      callback: query => (this.challenge.image_suggest_query = query),
    });
  }

  async saveImage() {
    const res = await this.ChallengesManager.setChallenge({
      id: this.challenge.id,
      image_id: this.challenge.image.id
    });
    this.afterSave(res);
  }

  async apply() {
    const res = await this.ChallengesManager.setChallenge({
      id: this.challenge.id,
      image_id: this.challenge.image.id,
      status: challengeEnum.STATUSES.ASSIGNED.VALUE,
      member_id: this.member.id
    });
    this.afterSave(res);
  }

  async afterSave(res) {
    if (!res.success) {
      if (res.message === 'challenge already assigned to member') {
        await this.notificationAlert.open(`
        This challenge is no longer available
        <br><br>
        More information available in our <a href="https://gurushots.freshdesk.com/a/solutions/articles/13000066853-being-a-guru" target="_blank">FAQs</a>
        `);
        this.redirect();
      } else {
        this.Toasts.message(res.message || 'Error!', this.$element);
      }
    } else {
      this.redirect();
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onTagUpdate(chip) {
    return this.challengeService.onChallengeTagUpdate(chip);
  }

  searchTags(text) {
    return this.challengeService.searchTags(text);
  }

  onIsBidScheduleChange() {
    if (this.challenge.is_bid_schedule) {
      this.challenge.setDefaultBidStartTime();
    } else {
      this.challenge.resetBidStartTime();
    }
  }

  joinMemberStatusChanged(resetJoinStatusType){
    this.challengeService.onJoinMemberStatusChanged({
      challenge: this.challenge,
      scope: this,
      resetJoinStatusType: resetJoinStatusType,
    });
  }
}
myChallengesEditorCtrl.$inject = [
  '$rootScope',
  '$scope',
  '$state',
  '$stateParams',
  '$timeout',
  'ChallengesManager',
  'Modals',
  'PhotosManager',
  'RestManager',
  'notificationAlert',
  'stateService',
  'challengeService',
  'Toasts',
  '$element',
  'MemberService',
  'SessionConfigService',
];
export default {
  template: template,
  controller: myChallengesEditorCtrl
};
