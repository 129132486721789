export const filters = [
  {
    name: 'Joined',
    value: 'time_joined',
  },
  {
    name: 'End date',
    value: 'close_time',
  },
  {
    name: 'Start date',
    value: 'start_time',
  },
  {
    name: 'Votes',
    value: '-member.ranking.total.votes',
  },
  {
    name: 'Type',
    value: '-type',
  },
  {
    name: 'Level',
    value: '-member.ranking.total.level',
  },
];
