export enum NgLocalStorageEnum {
  FTUE_BATTLE_VOTE_FIRST_TIME_KEY = 'FTUE_BATTLE_VOTE_FIRST_TIME_KEY',
  LAST_ACTIVE_THEME_TITLE = 'LAST_ACTIVE_THEME_TITLE',
  SELECTED_THEME = 'SELECTED_THEME',
  LAST_PAYED_WITH_PAYPAL = 'LAST_PAYED_WITH_PAYPAL',
  TUTORIALS_FIRST_LOGIN = 'TUTORIALS_FIRST_LOGIN',
  FIRST_EXHIBITION_GALLERY = 'FIRST_EXHIBITION_GALLERY',
  FTUE_FIRST_STARS = 'FTUE_FIRST_STARS',
  FTUE_DRAG_HANG_IMAGE = 'FTUE_DRAG_HANG_IMAGE',
  FTUE_BATTLES_TAB = 'FTUE_BATTLES_TAB',
  SHOW_OOC = 'SHOW_OOC',
  BATTLES_VISITOR_BONUS_ELIGIBLE_AT = 'BATTLES_VISITOR_BONUS_ELIGIBLE_AT',
  BATTLES_MEMBER_FINISHED_TRAINING = 'BATTLES_MEMBER_FINISHED_TRAINING',
  ALL_GALLERIES_COMPLETED = 'ALL_GALLERIES_COMPLETED',
  FIRST_SESSION_PASSED = 'FIRST_SESSION_PASSED',
}
export enum ResourceType {
  COINS = 'COINS',
  FILLS = 'FILLS',
  KEYS = 'KEYS',
  SWAPS = 'SWAPS',
}

export enum TurboType {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum MemberPermission {
  CREATE_CHALLENGE= 'CREATE_CHALLENGE',
  CHALLENGE_BID= 'CHALLENGE_BID',
}

