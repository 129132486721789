import {Injectable} from '@angular/core';
import {INgMemberModel} from "../models/fromOldApp/models";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import RequestServiceModel from "./request/requestService.model";
import NgRequestEnum from "./request/request.enum";
import RequestService from "./request/requestService";
import {IGsResponse} from "../models/gsResponse.types";
import {map} from "rxjs/operators";
import {NgMemberModel} from "../models/fromOldApp/ng-member.model";
import {MediatorService} from "./mediator.service";

export interface IUpdateMemberSettingsBody {
  adult:boolean
}
export interface IGetMemberProfile  extends IGsResponse{
  profile:INgMemberModel,
  is_admin:boolean
}
export interface IGetProfileSettings  extends IGsResponse{
  settings: {
    google_connected:boolean
    facebook_connected:boolean
    t_subscription:{
      id: number
      in_profile_name: any
      subscribed: boolean
    }[]
    countries:{
      ID: string
      code: string
      name: string
      active: boolean
    }[]
  }
}
export interface IGetProfileByUserName  extends IGsResponse{
  counters:{
    following: number
    followers: number
    likes: number
    photos: number
    achievements: number
  },
  country: string;
  is_following:boolean,
  profile:INgMemberModel
}
export interface IGetMemberPath  extends IGsResponse{
  member_path:{
    id: string
    is_pioneer: boolean
    member_status: number
    member_status_name: string
    member_status_progress: number
    points: number
    progress: {
      "status": 1,
      "status_name": "NEWBIE"
      "requirements": [
        {
          required: number
          title: string
          badge: {
            type: string
            param: number
          },
          achieved: number
          percent: number
        }
      ]
    }[];
  }
}

@Injectable()
export class MemberService {

  member$ = new BehaviorSubject<INgMemberModel | undefined>(undefined);
  memberChanged$ = new BehaviorSubject<boolean | undefined>(undefined);
  lastMember:INgMemberModel | undefined = undefined;
  _profilePageMember:INgMemberModel | undefined = undefined;

  constructor(
      private requestService:RequestService,
      private mediatorService:MediatorService
  ) {
  }

  setCurrentMember(member:INgMemberModel){
    this.lastMember = this.getCurrentMember();
    // console.log('member', member);
    if (member !== undefined && member?.id && this.lastMember?.id !== member?.id) {
      // console.log('memberChanged$');
      this.memberChanged$.next(true);
    }
    this.member$.next(member);

    if (member.isRestricted && member.isRestricted() && !member.is_admin) {
      this.mediatorService.gotToState.next('gs.deactivated');// hook for deactivated member
    }
    const appElement = document.getElementById('gs-app-main');
    if(appElement){
      if (this.isLoggedIn()){
        if (!appElement.classList.contains("loggedIn")) {
          appElement.classList.add("loggedIn");
        }
      } else {
        if (appElement.classList.contains("loggedIn")) {
          appElement.classList.remove("loggedIn");
        }
      }
    }
  }

  getCurrentMember():INgMemberModel {
    return this.member$.value!;
  }

  get profilePageMember(){
      return this._profilePageMember;
  }

  get isMyProfilePage(){
      return this._profilePageMember?.id === this.getCurrentMember().id;
  }

  resetMemberProfile(){
    this._profilePageMember = undefined;
  }

  resetMember(){
    this.setCurrentMember(new NgMemberModel());
  }

  isMyMember(id:string){
    return this.getCurrentMember()?.id === id;
  }

  isLoggedIn(){
    return this.getCurrentMember().logged_in;
  }
  updateMemberSettings(body:IUpdateMemberSettingsBody): Promise<IGsResponse> {
    const conf: RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.UPDATE_MEMBER_SETTINGS.NAME,
      body: body,
    });
    return firstValueFrom(this.requestService.request<IGsResponse>(conf));
  }

  getCurrentMemberProfile(): Promise<IGetMemberProfile> {
    const conf: RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_CURRENT_MEMBER_PROFILE.NAME,
    });
    return firstValueFrom(this.requestService.request<IGetMemberProfile>(conf).pipe(
      map((res)=>{
        if(res.success){
          let m = res.profile;
          m.logged_in = !!m.id;
          m.is_admin = res.is_admin;
          this.setCurrentMember(new NgMemberModel(m));//res.member
        }else{
          this.resetMember();
        }
        return res;
      })
    ));
  }

  getProfileByUserName(user_name:string): Promise<IGetProfileByUserName> {
    const conf: RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_PROFILE_BY_USER_NAME.NAME,
      body:{
        user_name
      }
    });
    return firstValueFrom(this.requestService.request<IGetProfileByUserName>(conf).pipe(
      map((res)=>{
        if(res.success){
          this._profilePageMember = res.profile = new NgMemberModel(res.profile);
          this._profilePageMember.is_following =  res.is_following;
          this._profilePageMember.country =  res.country;
        }
        return res;
      })
    ));
  }

  getMemberPath(): Promise<IGetMemberPath> {
    const conf: RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MEMBER_PATH.NAME
    });
    return firstValueFrom(this.requestService.request<IGetMemberPath>(conf));
  }

  getProfileSettings(): Promise<IGetProfileSettings> {
    const conf: RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_PROFILE_SETTINGS.NAME
    });
    return firstValueFrom(this.requestService.request<IGetProfileSettings>(conf));
  }

}
