const template = require(`html-loader!./adminEvents.html`).default;

class adminEventsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Leader Board', route: 'gs.gsAdmin.contentManager.events.leaderBoard'},
    ];
  }
}

export default {
  template: template,
  controller: adminEventsCtrl,
};
