const template = require(`html-loader!./achievementsLevel.html`).default;
import mixpanelEventsEnum from "../../../../../services/mixpanel/mixpanel.enum";

class achievementsLevelCtrl {

  constructor(achievementsService, mixpanelService, $stateParams) {
    'ngInject';
    this.achievement = null;
    this.mixpanelService = mixpanelService;
    this.achievementsService = achievementsService;
    this.stateParams = $stateParams;
  }
  $onInit() {
    this.achievement.showAchievementsDetails = this.achievement.unique_key === this.stateParams.achievementUniqueKey;
  }

  showDetails(){
    if(this.achievement.showAchievementsDetails){
      this.achievement.showAchievementsDetails = false;
    } else {
      this.achievementsService.hideAllAchievementsDetails();
      this.achievement.showAchievementsDetails = true;
      this.eventMixPanelAction();
    }
  }

  eventMixPanelAction() {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.ACHIEVEMENT_LEVELS,
      data: {
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
        ui_name: mixpanelEventsEnum.UI_NAME.ACHIEVEMENT_EXPAND_BUTTON,
        achievement_name: this.achievement.name
      }
    });
  }
}
achievementsLevelCtrl.$inject = [
  'achievementsService', 'mixpanelService', '$stateParams'
];
export default {
  template: template,
  controller: achievementsLevelCtrl,
  bindings: {
    achievement: '<',
  }
};
