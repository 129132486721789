import environment from '../gsApp.environment';
import {gsIsMobile} from "../helpers/gen.helper";
import {autoUpdateTypeEnum} from "./autoUpdate.service.data";
import {PromiseHelper} from "../../../core/helpers/PromiseHelper";
import {SectionType} from "../../../modules/store/models/enums";
import {ResourceType} from "../../../core/models/enums";

class brazeService {
  currentUser;
  constructor(
    $rootScope,
    RestManager,
    $timeout,
    $interval,
    $document,
    autoUpdateService,
    Modals,
    BankrollService
  ) {
    'ngInject';

    this.isInitialized = false;
    this.filteredContentCardData = null;
    this.shownInAppMessages = [];

    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.$document = $document;
    this.autoUpdateService = autoUpdateService;
    this.Modals = Modals;
    this.bankrollService = BankrollService;
  }

  init(){
    if (!this.isInitialized) {
      this.isInitialized = true;
      // const isMobile = document.body.clientWidth <= 768;
      const isMobile = gsIsMobile();
      const appId = isMobile ? environment.brazeAppId.mobile : environment.brazeAppId.desktop;

      appboy.initialize(appId, {
        baseUrl: 'https://sdk.iad-03.braze.com/api/v3',
        // enableLogging: environment.env !== 'production',
        enableLogging: false,
        safariWebsitePushId: 'web.com.gurushots',
        minimumIntervalBetweenTriggerActionsInSeconds: 0,
        enableHtmlInAppMessages: true
      });
      // appboy.display.automaticallyShowNewInAppMessages();
      appboy.subscribeToInAppMessage(this.onInAppMessage.bind(this));
      appboy.openSession();
      this.sendEventFromServer();
      this.$document.on('brazeEvent', this.onBrazeEvent.bind(this));
    }
  }

  async onBrazeEvent(e) {
    let detail = e.detail;
    if (detail.action === 'fb_invite') {
      //invite
      this.$rootScope.$emit('FacebookInvite');
    }
  }

  set showMessagesLater(value){
    this._showMessagesLater = value;
  }

  setServerEventsAutoUpdate(delay){
    let autoUpdateObject = {
      type : autoUpdateTypeEnum.BRAZE_SERVER_EVENTS,
      callback : this.sendEventFromServer.bind(this),
      delay: delay
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
  }

  async sendEventFromServer(){
    const res = await this.RestManager.getBrazeClientEvents();
    if(!res.success){
      return;
    }

    for(let event of res.events){
      let data = {};
      for(let prop of event.properties){
        data[prop.name] = prop.value;
      }
      console.log(event.name, data);
      this.logCustomEvent(event.name, data);
    }
    const delay = res.polling_interval ?  res.polling_interval * 60000 : 180000;

    this.setServerEventsAutoUpdate(delay);
  }

  async onInAppMessage(inAppMessage){
    if (inAppMessage instanceof appboy.InAppMessage) {
      let shouldDisplay = true;
      let classToAdd;

      console.log('subscribeToInAppMessage', inAppMessage);
      let extras = inAppMessage.extras;
      console.log('extras', extras);

      let packages;
      if(extras['type']){
        //this is for custom campaigns
        $("body").addClass('gs-braze-scroll');

        if (extras[key] === 'webModal') {
          classToAdd = 'gs-braze-web-modal';
        }
        if (extras[key] === 'sticky') {
          classToAdd = 'gs-braze-sticky';
        }
      } else if(extras['msgId']){
        const msgId = extras['msgId'];
        // If this is our push primer message
        if (msgId === "push-primer") {
          // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
          // has already granted/blocked permission
          if (!appboy.isPushSupported() || appboy.isPushPermissionGranted() || appboy.isPushBlocked()) {
            shouldDisplay = false;
          }
          if (inAppMessage.buttons[1] != null) {
            // Prompt the user when the first button is clicked
            inAppMessage.buttons[1].subscribeToClickedEvent(function() {
              appboy.registerAppboyPushMessages();
            });
          }
        }
      } else if(extras['required_coins']){
        const requiredCoins = Number(extras['required_coins']);
        const myCoins = this.bankrollService.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.COINS);
        shouldDisplay = myCoins >= requiredCoins;
      } else {
        for (let key in extras){
          if(key === 'product_id' || key === 'original_product_id'){
            if(!packages){
              const res = await this.RestManager.getPackages([extras.original_product_id, extras.product_id]);
              packages = res.packages;
            }
            if(packages && packages[extras[key]]){
              inAppMessage.message = inAppMessage.message.replace(`$${key}$`, `$${packages[extras[key]].price}`);
            }
          } else{
            inAppMessage.message = inAppMessage.message.replace(`$${key}$`, extras[key]);
          }
        }
      }
      // Display the message
      if (shouldDisplay) {
        if(this._showMessagesLater){
          console.log('braze - set messageToShowLater', inAppMessage);
          this.messageToShowLater = inAppMessage;
        } else {
          this.showInAppMessage(inAppMessage);
        }
      }

      if(classToAdd){
        this.$timeout(()=>{
          $('.ab-in-app-message.ab-html-message').addClass(classToAdd);
        });
      }
    }
  }

  showInAppMessage(inAppMessage){
    appboy.display.showInAppMessage(inAppMessage);
    this.shownInAppMessages.push(inAppMessage);
  }

  showDelayedInAppMessage(){
    this.showMessagesLater = false;
    if (this.messageToShowLater) {
      this.showInAppMessage(this.messageToShowLater);
      this.messageToShowLater = null;
    }
  }

  changeUser(memberId){
    if(memberId && this.currentUser !== memberId){
      appboy.changeUser(memberId);
      this.currentUser = memberId;
      this.requestImmediateDataFlush();
    }
  }

  logCustomEvent(eventName, data = {}, flash = true) {
    if (eventName) {
      appboy.logCustomEvent(eventName, data);
      console.log(`braze event - ${eventName} was fired`, data);
      if (flash) {
        this.requestImmediateDataFlush();
      }
    }
  }

  requestImmediateDataFlush() {
    appboy.requestImmediateDataFlush();
  }

  sendFirstAchievementPageViewEvent(){
    this.logCustomEvent('first_achievement_page_view');
  }

  sendJoinChallengeEvent(challenge_id, challenge_type, balance){
    this.logCustomEvent('join_challenge', {challenge_id, challenge_type, key_balance: balance});
  }

  sendShowInviteMessage(){
    this.logCustomEvent('show_invite_message', {});
  }

  sendPhotoSwapEvent(challenge_id, balance){
    this.logCustomEvent('photo_swap', {challenge_id, balance:balance});
  }

  sendExposureBoostEvent(challenge_id, balance){
    this.logCustomEvent('exposure_boost', {challenge_id, key_balance:balance});
  }

  sendChestEvent(challenge_id){
    this.logCustomEvent('claim_eoc_chest', {challenge_id});
  }

  sendJoinTeamEvent() {
    this.logCustomEvent('join_team');
  }

  sendUploadsPhotoEvent(image_id, source) {
    this.logCustomEvent('upload_photo', {image_id, source});
  }

  sendExposureAutofillEvent(challenge_ids, numOfFills, balance){
    for(let challenge_id of challenge_ids){
      if(numOfFills && numOfFills !== 0){
        numOfFills--;
        balance--;
      }else {
        balance--;
      }
      this.logCustomEvent('exposure_autofill', {challenge_id, balance:balance}, false);
    }
    this.requestImmediateDataFlush();
  }

  sendKeyEvent(challenge_id, usage, balance){
    this.logCustomEvent('key', {challenge_id, usage, balance});
  }

  sendPurchaseEvent({product_id, price, method, challenge_id}){
    let extraParams = {
      method,
      challenge_id: challenge_id || 0
    };
    appboy.logPurchase(product_id.toString(), price, "USD", 1, extraParams);
    this.requestImmediateDataFlush();
  }

  getFilteredContentCard({filterConfig}) {
    const feedElement = null;
    let contentCard = null;
    this.filteredContentCardData = null;

    appboy.display.showContentCards(
      feedElement,
      (cards) => {
        contentCard = cards.filter(
          (card) => card.extras[filterConfig.KEY] === filterConfig.VALUE
        )[0];

        contentCard = contentCard ? contentCard : null;
        this.filteredContentCardData = contentCard ? contentCard : null;
      }
    );

    return contentCard;
  }

  async waitForBrazeMessage(callback, outOfResourceId) {
    await PromiseHelper.wait(2000);
    const inAppMessage = this.shownInAppMessages.find(message => message.extras.outOfResourceId === outOfResourceId);
    if (!inAppMessage) {
      callback();
    }
  }
}
brazeService.$inject = [
  '$rootScope',
  'RestManager',
  '$timeout',
  '$interval',
  '$document',
  'autoUpdateService',
  'Modals',
  'BankrollService',
];
export default brazeService;
