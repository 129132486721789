const template = require(`html-loader!./topPhotographerItemMobile.html`).default;
import leagueEnum from "../../../../../api/enums/league.enum";
import memberEnum from "../../../../../api/enums/member.enum";

class topPhotographerItemMobileCtrl {

  constructor($location, $rootScope, MemberService) {
    'ngInject';
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.MemberService = MemberService;
    this.loggedinMember = MemberService.getCurrentMember();
  }

  $onInit() {
    this.uString = Math.floor(Math.random() * 10000000000000001);
    this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/challenge/${
      this.challenge.url
    }/rank/top-photographer/${this.loggedinMember.user_name}/${this.uString}}`;
  }

  $onChanges(changes) {
    //TODO need to move to avatar component
    if (changes.item && changes.item.currentValue) {
      this.showBadge = this.item.member.canShowLeagueBadge();
      if(this.showBadge){
        this.leagueConf = leagueEnum.ID_LEAGUE[this.item.member.member_league_id]();
      }
      this.loaded = false;
    }
  }

  onAvatarError(){
    this.item.member.avatar = memberEnum.AVATAR.PLACEHOLDER;
  }

  openMenu($mdMenu, ev) {
    $mdMenu.open(ev);
  }
}
topPhotographerItemMobileCtrl.$inject = [
  '$location', '$rootScope', 'MemberService'
];
export default {
  template: template,
  controller: topPhotographerItemMobileCtrl,
  bindings: {
    member: '<',
    challenge: '<',
    item: '<',
    tools: '='
  }
};
