const template = require(`html-loader!./adminEditLeagues.html`).default;

import { leagueNoneActiveTeamsActions } from "../../adminTeams.data";
import { isFieldValidNew } from "../../../../../../helpers/validate.helper";
import LeagueModel from '../../../../../../api/models/team/league.model';
import SeasonModel from '../../../../../../api/models/team/season.model';

class adminEditLeaguesCtrl {

  constructor($scope, $stateParams, RestManager, notificationAlert, $timeout, Toasts, $element) {
    'ngInject';
    this.leaguesList = [];
    this.busy = true;

    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.RestManager = RestManager;
    this.leagueNoneActiveTeamsActions = leagueNoneActiveTeamsActions;
    this.notificationAlert = notificationAlert;
    this.$timeout = $timeout;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  $onInit() {
    this.status = this.$stateParams.status;
    this.getData();

    this.$timeout(()=>{
      if(this.status === 'next'){
        $("admin-leagues .admin-sub-menu__link").last().addClass('gs-active');
      }else{
        $("admin-leagues .admin-sub-menu__link").last().removeClass('gs-active');
      }
    });
  }

  async getData(){
    this.leaguesData = await this.RestManager.getLeagueConfig();
    if(!this.leaguesData.success){return false}
    this.leaguesData.next_season_leagues = this.leaguesData.next_season_leagues.length === 0 ? this.leaguesData.leagues : this.leaguesData.next_season_leagues;
    let resLeagues = this.status === 'active' ? this.leaguesData.leagues : this.leaguesData.next_season_leagues;
    this.leagues = resLeagues.map ((league) => {
      league = new LeagueModel(league);
      this.leaguesList.push({title: league.full_name, value: league.id});
      return league;
    });
    this.leaguesList.unshift({title: 'None', value: null});
    this.seasonsData = await this.RestManager.getLeagueSeasonConfigForAdmin();
    if(!this.seasonsData.success){return false}
    this.season = this.status === 'active' ? new SeasonModel(this.seasonsData.active) : new SeasonModel(this.seasonsData.upcoming);
    console.log('leagues', this.leagues);
    console.log('season', this.season);
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async save(){
    if(this.validate()){
      await this.notificationAlert.info({
        message: 'The changes made will only be for the current season. Next season will not be effected by these changes. In order to change next season, save as default',
        title: 'NOTE'
      });
      console.log(JSON.stringify(this.leagues));
      const res = await this.RestManager.setCurrentLeagueConfig(JSON.stringify(this.leagues));
      this.handleResponse(res);
    }
  }

  async saveAsDefault(){
    if(this.validate()){
      await this.notificationAlert.info({
        message: 'The changes made will change the current season league config and will also overwrite next season’s league config if any have been set.',
        title: 'NOTE'
      });
      console.log(this.leagues);
      const res = await this.RestManager.setDefaultLeagueConfig(JSON.stringify(this.leagues));
      this.handleResponse(res);
    }
  }

  async saveForNextSeason(){
    if(this.validate()){
      await this.notificationAlert.info({
        message: 'Warning this will be set as the new season default starting next season.',
        title: 'NOTE'
      });
      console.log(this.leagues);
      const res = await this.RestManager.setUpcomingLeagueConfig(JSON.stringify(this.leagues));
      this.handleResponse(res);
    }
  }

  help(message){
    this.notificationAlert.info({
      message,
      title: 'NOTE'
    });
  }

  handleResponse(res){
    if(!res.success){
      this.Toasts.message('Server error', this.$element, true, 'bottom right');
      return;
    }
    this.Toasts.message(`Saved`, this.$element, false, 'bottom right');
  }

  validate(){
    let required_fields = [
      {field:'bucket_size', title:'Bucket size', isNumber:true},
      {field:'percent_items_drop', title:'Percent items drop', isNumber:true, maxNumber:100},
      {field:'percent_items_promote', title:'Percent items promote', isNumber:true, maxNumber:100},
    ];


    for(let league of this.leagues){
      for (let required_field of required_fields) {
        let validRes = isFieldValidNew(league, required_field);
        if (!validRes.valid) {
          this.Toasts.message(`${league.full_name} - ${validRes.message}`, this.$element, true, 'bottom right');
          return false;
        }
      }
    }
    return true;
  }
}
adminEditLeaguesCtrl.$inject = [
  '$scope', '$stateParams', 'RestManager', 'notificationAlert', '$timeout', 'Toasts', '$element'
];
export default {
  template: template,
  controller: adminEditLeaguesCtrl,
  bindings: {}
};
