const template = require(`html-loader!./adminSpotlight.html`).default;
import SpotlightModel from '../../../../api/models/spotlight.model';

class adminSpotlightCtrl {

  /**
   * @param {gsAdminManager} gsAdminManager
   * @param {RestManager} RestManager
   * */

  constructor(gsAdminManager, RestManager, $state, Toasts, $element) {
    'ngInject';

    this.gsAdminManager = gsAdminManager;
    this.RestManager = RestManager;
    this.$state = $state;
    this.Toasts = Toasts;
    this.$element = $element;

    this.items = [];
  }

  $onInit() {
    this.get_all();
  }

  get_all() {
    this.RestManager.getSpotlight().then((res) => {
      this.items = res.items.map(item => new SpotlightModel(item));
    });
  }

  newSpotlight() {
    this.$state.go('gs.gsAdmin.contentManager.spotlightEdit',{},{reload:true});
  }

  editSpotlight(event, spotlight) {
    event.preventDefault();
    this.$state.go('gs.gsAdmin.contentManager.spotlightEdit',{spotlight: spotlight},{reload:true});
    return false;
  }

  swap(index, forward = true) {
    //Forward or back swap
    index = !!forward ? index : index - 1;
    //Swap items
    this.items.splice(index, 2, this.items[index + 1], this.items[index]);
  }

  saveOrder() {

    let sort_ids = [];
    this.items.map(item => {
      sort_ids.push(item.id);
    });

    let data = {
      method: 'sort_spotlight',
      sort_ids: sort_ids
    };

    this.gsAdminManager.set(data).then(res => {
      const data = {
        message: res.error_code || 'Done',
        class: !res.success ? 'error' : ''
      };
      this.Toasts.open('simpleMessage', {data}, this.$element);
    });
  }
}
adminSpotlightCtrl.$inject = [
  'gsAdminManager', 'RestManager', '$state', 'Toasts', '$element'
];
export default {
  template: template,
  controller: adminSpotlightCtrl,
  bindings: {}
};
