import { Injectable } from '@angular/core';
import GsResponse from "../../gsApp/app/api/models/responses/GsResponse";
import RequestServiceModel from "./request/requestService.model";
import NgRequestEnum from "./request/request.enum";
import {firstValueFrom} from "rxjs";
import RequestService from "./request/requestService";
import {NgChallengeModel} from "../../modules/challenges/models/ng-challenge.model";
import {map} from "rxjs/operators";

export interface IPermissions{
  CREATE_CHALLENGE: boolean,
  CHALLENGE_BID: boolean,
}
export interface ISessionConfig{
  popupCompletedChallengesFlag?:boolean;
  profile_page_achievements_default_tab?:boolean;
  on_boarding?: {
    challenge?:NgChallengeModel
  }
  permissions?: IPermissions | undefined;
  fill_discount_min?: number;
}
interface ISessionConfigResponse extends GsResponse{
  config:ISessionConfig
}

@Injectable({
  providedIn: 'root'
})
export class SessionConfigService {

  _sessionConfig:ISessionConfig = {};
  constructor(
    private requestService:RequestService
  ) { }

  getConfigData(): Promise<ISessionConfigResponse> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_SESSION_CONFIGURATION.NAME,
    });
    return firstValueFrom(this.requestService.request<ISessionConfigResponse>(conf).pipe(
      map((res) => {
        if(res.success){
          if(res.config.on_boarding?.challenge){
            res.config.on_boarding.challenge = new NgChallengeModel(res.config.on_boarding.challenge);
          }
          this._sessionConfig = res.config;
        }
        return res;
      })
    ));
  }

  get onBoarding(){
    return this._sessionConfig.on_boarding
  }

  deleteOnBoarding(){
    if(this._sessionConfig.on_boarding){
      this._sessionConfig.on_boarding = undefined;
    }
  }

  get permissions(): IPermissions | undefined{
    return this._sessionConfig.permissions;
  }

  get fillDiscountMin(): number | undefined{
    return this._sessionConfig.fill_discount_min;
  }

  get sessionConfig(){
    return this._sessionConfig;
  }
}
