function config($stateProvider, $urlRouterProvider) {
  'ngInject';

  $stateProvider.state('gs.vote', {
    url: '/vote/:challengeUrl',
    views: {
      'header@gs': null,
      'page@gs': { component: 'votePage' },
      'footer@gs': null,
    },
    params: {
      challengeUrl: {
        value: null,
        squash: true,
      },
      mission: null,
    },
  });
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
