/* globals angular */
import mediaEnum from '../../../api/enums/media.enum';
const template = require(`html-loader!./details.html`).default;
import { gsIsMobile } from "../../../helpers/gen.helper";
import badgeV2Mode from "../../../api/enums/badgeV2.enum";
import challengeEnum from '../../../api/enums/challenge.enum';

class challengeDetailsCtrl {
  /**
   * @param {FollowersManager} FollowersManager
   * */

  constructor($scope,
              $location,
              $sce,
              Modals,
              $rootScope,
              FollowersManager,
              tagEventService,
              MemberService,
  ) {
    'ngInject';
    this.followChecked = false;
    this.challengeEnum = challengeEnum;

    this.$scope = $scope;
    this.$location = $location;
    this.$sce = $sce;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.FollowersManager = FollowersManager;
    this.badgeV2Mode = badgeV2Mode;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.init();
  }

  /** @return void */
  async checkFollowing() {
    this.isMediaDataReady = false;
    const res = await this.FollowersManager.isFollowing(this.challenge.member_id);
    this.challenge.guru_info.id = this.challenge.member_id;
    this.challenge.guru_info.is_following = res.success ? res.is_following : false;
    this.followChecked = true;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async init() {
    // Google vPageView
    this.tagEventService.sendPageViewEvent({title: 'Challenge Details'});
    await this.checkFollowing();
    this.isMobile = gsIsMobile();
    this.mediaData();
  }

  mediaData() {
    if(this.challenge.media) {
      let youtube = this.challenge.media.filter((item) => item.type === mediaEnum.TYPE.YOUTUBE).map((item) => {
        item.id = item.data.id;
        item.data.url = `https://img.youtube.com/vi/${item.id}/0.jpg`;

        return item;
      });
      let images = this.challenge.media.filter((item) => (item.type === mediaEnum.TYPE.IMAGE) && item.data.id);

      this.media = [...youtube, ...images];

      this.isMediaDataReady = (this.media.length > 0);
    }
  }

  openVideo(event) {
    // Open
    this.Modals.open('player', event, {
      type: 'youtube',
      id: this.challenge.welcome_video
    });
  }
}
challengeDetailsCtrl.$inject = [
  '$scope',
  '$location',
  '$sce',
  'Modals',
  '$rootScope',
  'FollowersManager',
  'tagEventService',
  'MemberService'
];
export default {
  template: template,
  controller: challengeDetailsCtrl,
  bindings: {
    challenge: '<'
  }
};
