import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./successfulSwapModal.html`).default;
import PopupModel from '../../../api/models/popup.model';
import * as _ from 'lodash';

class successfulSwapModalCtrl {
  /** @param {ChallengesManager} ChallengesManager **/

  constructor($mdDialog, $timeout, Modals, ChallengesManager, $scope, challengeService) {
    'ngInject';
    this.challenge;
    this.inited = false;
    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.challengeService = challengeService;
  }

  $onInit() {
    if (!this.data) return;
    this.init();
  }

  /** @return void **/
  async init() {
    // from notifications
    if (this.data.message) {
      this.challenge = new NgChallengeModel({});
      this.challenge.id = this.data.message.link_2;
      let links = this.data.message.link_1.split(',');
      this.data.gspopup = new PopupModel({
        data: {
          new_image_id: links[0],
          old_image_id: links[1],
        },
      });
    } else {
      this.challenge = _.cloneDeep(this.data.challenge);
    }

    const res = await this.ChallengesManager.getSuccessfulSwap({
      c_id: this.challenge.id,
      new_image_id: this.data.gspopup.data.new_image_id,
      old_image_id: this.data.gspopup.data.old_image_id,
    });
    if (!res.success) {
      this.hide();
      return;
    }

    this.oldImage = res.images[0];
    this.newImage = res.images[1];
    this.gained = this.newImage.votes - this.oldImage.votes;
    if (!this.gained) {
      this.hide();
      return;
    }
    this.entries = res.entries;
    this.data.permissions = res.permissions;
    this.challenge = _.merge(this.challenge, res.challenge);
    this.challenge.member.ranking.entries = this.entries;

    this.inited = true;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  swap(event, image) {
    this.challengeService.swapAction({
      challenge: this.challenge
    });
  }

  hide() {
    this.$mdDialog.hide();
  }
}
successfulSwapModalCtrl.$inject = [
  '$mdDialog', '$timeout', 'Modals', 'ChallengesManager', '$scope', 'challengeService'
];
export default {
  template: template,
  controller: successfulSwapModalCtrl,
  bindings: {
    data: '<data',
  },
};
