const template = require(`html-loader!./articlesItem.html`).default;
import {shareList} from "../articles.share.data";

class articlesItemCtrl {
  constructor() {
    "ngInject";

    this.shareList = shareList;
  }

  $onChanges() {
    this.shareInfo = this.article.shareInfo;
  }
}

export default {
  template: template,
  controller: articlesItemCtrl,
  bindings: {
    article: '<'
  }
};
