import {MediatorMessageKey} from "../../../../../core/services/mediator.service";

const template = require(`html-loader!./challengesItemSuggested.html`).default;
import * as _ from 'lodash';
import badgeV2Mode from "../../../api/enums/badgeV2.enum";
import memberEnum from '../../../api/enums/member.enum';
import challengeEnum from '../../../api/enums/challenge.enum';
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";
import {ResourceType} from "../../../../../core/models/enums";

export class challengesItemSuggestedController {
  /**
   * {ChallengesManager} ChallengesManager
   * {ModalsService} Modals
   * {notificationAlert} notificationAlert
   * {challengeService} challengeService
   * **/

  constructor(
    ChallengesManager,
    $rootScope,
    $scope,
    Modals,
    notificationAlert,
    challengeService,
    mixpanelService,
    MediatorService
  ) {
    'ngInject';
    this.images = [];
    this.suggestedImages = [];
    this.maxPhotoSubmits = 4;
    this.$ctrl = this;
    this.challenge;
    this.memberEnum = memberEnum;
    this.ChallengesManager = ChallengesManager;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.Modals = Modals;
    this.notificationAlert = notificationAlert;
    this.challengeService = challengeService;
    this.badgeV2Mode = badgeV2Mode;
    this.mixpanelService = mixpanelService;
    this.mediatorService = MediatorService;
    this.ResourceType = ResourceType;
  }

  $onInit() {
    this.maxPhotoSubmits = this.challenge.max_photo_submits;
    this.suggestedImages = _.clone(this.challenge.member.suggested_images);
    this.images = this.suggestedImages.splice(0, this.maxPhotoSubmits);
  }

  removeImage(index) {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.REMOVE_PHOTO);
    this.suggestedImages.push(...this.images.splice(index, 1));
  }

  swap(index) {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.REPLACE_PHOTO);
    if (!this.suggestedImages.length) return;
    if (typeof index === 'undefined') {
      this.images.push(...this.suggestedImages.splice(0, 1));
      return;
    }
    this.suggestedImages.push(...this.images.splice(index, 1, this.suggestedImages.splice(0, 1)[0]));
  }

  /** @return void **/
  async submit() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.SUBMIT_CHALLENGE);

    if (!this.images.length) return;
    this.mediatorService.broadcast(MediatorMessageKey.DISABLE_SUBMIT_BUTTON, true);
    const canSubmitToChallenge = await this.challengeService.unlockChallengeBeforeJoin(this.challenge);
    if (canSubmitToChallenge) {
      const imageIds = this.images.map(image => image.id);
      const res = await this.challengeService.submitToChallenge(
        imageIds,
        this.challenge.id,
        this.challenge.type
      );

      if (!res.success) {
        return;
      }
      if (res.challenge_id){
        this.challenge.id = res.challenge_id;
      }
      this.mediatorService.broadcast(MediatorMessageKey.ADD_ACTIVE_CHALLENGE,{
        challengeId: this.challenge.id,
        openJoinModal: res.join && res.show_join_message
      });

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
    this.mediatorService.broadcast(MediatorMessageKey.DISABLE_SUBMIT_BUTTON, false);
  }

  openUploader(event) {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.SUBMIT_PHOTO);
    this.Modals.open('gsUploader', event, {
      target: 'challenge',
      state: 'photos',
      source: 'profile',
      member: this.member,
      challenge: this.challenge,
      selected: this.images,
      callback: () => this.$rootScope.$emit('removeSuggestedChallenge', this.challenge),
    });
  }

  help() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.INFO);
    this.notificationAlert.open(`
    GuruShots suggests photos that may fit challenges according to your labels. Upload more photos to get further suggestions!
    <br><br>
    Use the "Replace" icon to get a different photo suggestion for the challenge. You can remove specific photos and select a replacement yourself.
    <br><br>
    * you might get similar images suggested based those in your account
    `);
  }

  remove() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.CLOSE);
    this.ChallengesManager.filterSuggestedChallenge(this.challenge.id);
    this.$rootScope.$emit('removeSuggestedChallenge', this.challenge);
  }

  eventMixPanelAction(eventName) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.CHALLENGE_SUGGESTION,
          ui_name: eventName,
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
challengesItemSuggestedController.$inject = [
  'ChallengesManager',
  '$rootScope',
  '$scope',
  'Modals',
  'notificationAlert',
  'challengeService',
  'mixpanelService',
  'MediatorService'
];
export default {
  template: template,
  controller: challengesItemSuggestedController,
  bindings: {
    challenge: '<',
    isSubmitDisabled: '<'
  },
};
