import {Component, Input, OnInit} from '@angular/core';
import {INgMemberModel} from "../../../core/models/fromOldApp/models";
import {StateService} from "../../../core/services/state.service";

@Component({
  selector: 'app-ng-member-name',
  templateUrl: './ng-member-name.component.html',
  styleUrls: ['./ng-member-name.component.scss']
})
export class NgMemberNameComponent implements OnInit {

  @Input() member?:INgMemberModel;
  @Input() isClickable:boolean = false;

  constructor(
    private stateService: StateService
  ) { }

  ngOnInit(): void {
  }

  isClickEnabled(){
    return this.isClickable && this.member?.user_name;
  }

  onClick() {
    this.stateService.goToState({
      state:'gs.profile',
      params:{cUserName: this.member?.user_name}
    });
  }

}
