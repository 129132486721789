const template = require(`html-loader!./challengesSponsorsEdit.html`).default;
import environment from '../../gsApp.environment';

import memberEnum from "../../api/enums/member.enum";

class challengesSponsorsEdit {
  constructor(
    $state,
    $stateParams,
    challengesSponsorsService,
    notificationAlert,
    RestManager,
    $rootScope
  ) {
    'ngInject';

    this.memberEnum = memberEnum;

    this.sponsorFormDataModel = {
      id: null,
      brand: null,
      name: null,
      description: null,
      url: null,
      image_id: null
    };
    this.sponsorFormData = this.sponsorFormDataModel;

    this.notificationAlert = notificationAlert;

    this.state = $state;
    this.stateParams = $stateParams;
    this.challengesSponsorsService = challengesSponsorsService;
    this.RestManager = RestManager;
    this.rootScope = $rootScope;

    /*this.photoDomain = new Image();*/
    /*this.srcPreview = new Image().src;*/
    /* TODO: create service for Image() to generate links; */
    this.photoDomain = 'https://' + environment.domains.photos;

    this.title = 'none';
    this.sponsorsConfig = this.challengesSponsorsService.getSponsorConfig();
  }

  $onInit() {
    this.title = this.stateParams.id ? `Edit ${this.sponsorsConfig.buttonText} with ID:${this.stateParams.id}` : `Create new ${this.sponsorsConfig.buttonText}`;

    if (this.stateParams.data) {
      this.stateParamsToForm();
    } else {
      this.goToNew();
    }
  }

  stateParamsToForm() {
    Object
      .keys(this.stateParams.data)
      .forEach(stateParamItem => {
        let stateParamValue = this.stateParams.data[stateParamItem];
        stateParamValue = typeof stateParamValue === 'string' ? stateParamValue.trim() : stateParamValue;
        this.sponsorFormData[stateParamItem] = stateParamValue;
      });
  }

  goToHome() {
    this.state.go(this.sponsorsConfig.states.home);
  }

  goToNew() {
    this.state.go(this.sponsorsConfig.states.new);
  }

  async sponsorsSubmit() {
    await this.challengesSponsorsService.createSponsor(this.sponsorFormData);
    if (this.sponsorFormData.id) {
      this.rootScope.$emit('challengeSponsorsChanged', this.sponsorFormData);
    } else {
      this.rootScope.$emit('challengeSponsorsSave');
    }
    this.goToHome();
  }

  help() {
    this.notificationAlert.open(
      `<div class="challenges-sponsors-hint-text">
    URL validation
    <ul>
        <li>
            Valid
            <ul>
                <li>http://www.foufos.gr</li>
                <li>https://www.foufos.gr</li>
                <li>http://foufos.gr</li>
                <li>http://www.foufos.gr/kino</li>
                <li>http://werer.gr</li>
                <li>www.foufos.gr</li>
                <li>www.mp3.com</li>
                <li>www.t.co</li>
                <li>http://t.co</li>
                <li>http://www.t.co</li>
                <li>https://www.t.co</li>
                <li>www.aa.com</li>
                <li>http://aa.com</li>
                <li>http://www.aa.com</li>
                <li>https://www.aa.com</li>
            </ul>
        </li>
        <li>
            Not valid
            <ul>
                <li>www.foufos</li>
                <li>www.foufos-.gr</li>
                <li>www.-foufos.gr</li>
                <li>foufos.gr</li>
                <li>http://www.foufos</li>
                <li>http://foufos</li>
                <li>www.mp3#.com</li>
            </ul>
        </li>
    </ul>
</div>`,
      false
    );
  }
}

challengesSponsorsEdit.$inject = [
  '$state',
  '$stateParams',
  'challengesSponsorsService',
  'notificationAlert',
  'RestManager',
  '$rootScope'
];

export default {
  template: template,
  controller: challengesSponsorsEdit
};
