const template = require(`html-loader!./ads.html`).default;


//test page
class adsPage {
  constructor(pageData, $scope, $location) {
    this.pageData = pageData;
    this.$scope = $scope;
    this.$location = $location;
  }

  $onInit() {
    this.init();
  }

  /** @type void **/
  async init() {}

  addHash() {
    if(this.$location.hash() !== 'offerwall'){
      this.$location.hash('offerwall');
    }
  }
}
adsPage.$inject = [
  'pageData', '$scope', '$location'
];
export default {
  template: template,
  controller: adsPage
};
