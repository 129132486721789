import { Injectable } from '@angular/core';
import CookieService from './cookie.service';
import {CookieKeyEnum} from "../enum/cookies.enum";

@Injectable()
class NgTokenService {
  private _memberToken?: string;
  private _adminToken?: string;

  constructor(private cookieService: CookieService) {}

  initMemberToken(){
    this._memberToken =  this.cookieService.getCookie(CookieKeyEnum.TOKEN);
  }

  get memberToken():string {
    return this._memberToken!;
  }

  set memberToken(token:string) {
    this.cookieService.setCookie(CookieKeyEnum.TOKEN, token);
    this._memberToken = token;
  }

  initAdminToken(){
    this._adminToken =  this.cookieService.getCookie(CookieKeyEnum.ADMIN_TOKEN);
  }

  set adminToken(token:string) {
    this.cookieService.setCookie(CookieKeyEnum.ADMIN_TOKEN, token);
    this._adminToken = token;
  }

  get adminToken():string {
    return this._adminToken!;
  }

  resetAdminToken(){
    this.cookieService.removeCookie(CookieKeyEnum.ADMIN_TOKEN)
    this._adminToken = undefined;
  }

  resetToken(){
    this.cookieService.removeCookie(CookieKeyEnum.TOKEN)
    this._memberToken = undefined;
  }

  resetAllTokens(){
    this.resetToken();
    this.resetAdminToken();
  }
}

export default NgTokenService;
