const template = require(`html-loader!./memberManager.html`).default;

import moment from 'moment';
import { pointsPacks } from './memberManager.data';
import TeamModel from '../../../api/models/team/team.model';
import teamEnum from '../../../api/enums/team.enum';
import {
  memberLeaveModes,
  memberLeaveSteps
} from "../../../services/Modals/deactivateDeleteAccount/deactivateDeleteAccount.data";
import memberEnum from "../../../api/enums/member.enum";
import {parseFromSecToMilisec} from "../../../helpers/date.helper";
import MemberModel from "../../../api/models/member.model";

class memberManagerCtrl {
  /**
   * @param {gsAdminManager} gsAdminManager
   * @param {ModalsService} Modals
   * @param {RestManager} RestManager
   * @param {FacebookManager} FacebookManager
   * */

  constructor(
    gsAdminManager,
    $stateParams,
    Toasts,
    $scope,
    $rootScope,
    Modals,
    $element,
    RestManager,
    FacebookManager,
    MemberService,
  ) {
    'ngInject';

    this.busy = false;
    this.error = false;
    this.paymentsLimit = 10;
    this.search = '';
    this.pointsPacks = pointsPacks;
    this.give = {
      swap: 0,
      exposure_autofill: 0,
      key: 0,
      create_challenge: 0,
      points: 1,
      coins: 1,
    };
    this.createChallengeAllow = false;
    this.challengeBidAllow = false;
    this.team = null;
    this.comments = [];
    this.memberEnum = memberEnum;
    this.OLD_DELETED_MEMBER_GROUP_ID = 10000;

    this.gsAdminManager = gsAdminManager;
    this.$stateParams = $stateParams;
    this.Toasts = Toasts;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.$element = $element;
    this.RestManager = RestManager;
    this.FacebookManager = FacebookManager;
    this.MemberService = MemberService;
    this.member = null;
    /*https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url*/
    this.regexUrl = new RegExp(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    this.timeFormat = 'DD.MM.YYYY HH:mm';
    this.setMemberGroupNames();
  }

  $onInit() {
    this.admin = this.MemberService.getCurrentMember();
    if(this.$stateParams.cUserName){
      this.search = this.$stateParams.cUserName;
      this.findMember();
    }
  }

  setMemberGroupNames(){
    this.memberGroupName = {};
    this.memberGroupName[memberEnum.GROUP.DELETED_BY_ADMIN] = 'Deleted';
    this.memberGroupName[memberEnum.GROUP.DELETED_BY_MEMBER] = 'Deleted';
    this.memberGroupName[memberEnum.GROUP.DEACTIVATE_BY_MEMBER] = 'Deactivated';
    this.memberGroupName[memberEnum.GROUP.DEACTIVATE_BY_ADMIN] = 'Deactivated';
  }

  resetState() {
    this.error = false;
    this.member = null;
    this.permissions = null;
    this.payments = [];
    this.paymentsLimit = 10;
    this.give = {
      swap: 0,
      exposure_autofill: 0,
      key: 0,
      create_challenge: 0,
      coins: 0,
      points: 1,
    };
    this.team = null;
    this.comments = [];
    this.removeDuration = null;
    this.oldDeletedMember = false;
  }

  isActive(){
    return !this.isMemberGroup([memberEnum.GROUP.DEACTIVATE_BY_ADMIN, memberEnum.GROUP.DEACTIVATE_BY_MEMBER,
      memberEnum.GROUP.DELETED_BY_ADMIN, memberEnum.GROUP.DELETED_BY_MEMBER]);
  }

  isMemberGroup(memberGroups){
    return memberGroups.includes(parseInt(this.member.member_group_id));
  }

  isOldDeletedMember(){
    return this.isMemberGroup([this.OLD_DELETED_MEMBER_GROUP_ID]) && this.oldDeletedMember;
  }

  isDeletedMemberWithTime(){
    return this.isMemberGroup([this.memberEnum.GROUP.DELETED_BY_ADMIN, this.memberEnum.GROUP.DELETED_BY_MEMBER]) &&
    this.removeDuration > 0;
  }

  /** @return void */
  async findMember() {
    this.setSearchType();
    this.resetState();
    if (this.busy || !this.search.length) return;
    this.busy = true;

    // get data
    const params = {};
    params[this.searchType] = this.search;
    const res = await this.RestManager.getMemberData(params);

    if (!res.success) {
      this.error = true;
      this.busy = false;
    } else {
      const responses = await Promise.all([
        this.getAdminComments(res.member.id),
        this.getMemberRemoveDetails(res.member.id),
        this.RestManager.getItemHistory({
          type: 'members',
          item_id: res.member.id,
          offset: 0
        })
      ]);

      this.getItemHistoryResponse = responses[2];
      this.member = res.member;
      this.permissions = res.permissions;
      this.createChallengeAllow = this.permissions.CREATE_CHALLENGE >= 1;
      this.challengeBidAllow = this.permissions.CHALLENGE_BID >= 1;
      this.payments = res.payments;
      this.busy = false;

      const getMemberTeamStateResponse = await this.RestManager.getMemberTeamState(res.member.id);
      if(getMemberTeamStateResponse.success) {
        if(getMemberTeamStateResponse.state === teamEnum.MEMBER.STATE.IN_TEAM) {
          if(getMemberTeamStateResponse.team) {
            this.team = new TeamModel(getMemberTeamStateResponse.team);
          }
        }
      }
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async getMemberRemoveDetails(member_id){
    const res = await this.RestManager.getRemoveDetails(member_id);
    if (!res.success || !res.remove_details) {
      if (res.error_code === 1001){
        this.oldDeletedMember = true;
      }
      return;
    }
    this.removeDuration = parseFromSecToMilisec(res.remove_details.date_to_action) - new Date();
  }

  async reactivateOldMember(){
    const confirm = await this.Modals.confirm(
      `reactivate old member?`,
      `Are you sure you want to reactivate old member?`,
      'reactivate',
      'Cancel'
    );
    if (!confirm) return;
    const res = await this.RestManager.reactivateOldMember(this.member.id);
    if (res.success){
      this.findMember();
    }
  }

  async reactivateDeleteMember(){
    const confirm = await this.Modals.confirm(
      `reactivate deleted member?`,
      `Are you sure you want to reactivate deleted member?`,
      'reactivate',
      'Cancel'
    );
    if (!confirm) return;
    const res = await this.RestManager.reactivateDeletedMember({
      id: this.member.id,
      reason: 'deactivated_by_mistake'
    });
    if (res.success){
      this.findMember();
    }
  }

  setSearchType() {
    this.error = false;
    // empty
    if (!this.search.length) {
      delete this.searchType;
      return;
    }
    // id
    if (this.search.length === 32) {
      this.searchType = 'id';
      return;
    }
    // email
    if (this.search.indexOf('@') !== -1) {
      this.searchType = 'email';
      return;
    }
    // user_name (default)
    this.searchType = 'user_name';
  }

  login() {
    this.Modals.open('login', null, { signUp: false, email: this.member.user_name });
  }

  async setGroup(member_group_id) {
    if (!member_group_id) {
      member_group_id = window.prompt('Move member to group:');
      if (!member_group_id) return;
      member_group_id = parseInt(member_group_id);
      if ([memberEnum.GROUP.DEACTIVATE_BY_MEMBER, memberEnum.GROUP.DEACTIVATE_BY_ADMIN].includes(member_group_id)) {
        this.deactivateAccount();
        return;
      }
      if ([memberEnum.GROUP.DELETED_BY_MEMBER, memberEnum.GROUP.DELETED_BY_ADMIN].includes(member_group_id)) {
        this.deleteAccount();
        return;
      }
    }
    const params = {
      el: 'gs_admin',
      el_id: 'gs_admin',
      force: false,
      method: 'set_member_group',
      member_id: this.member.id,
      group_id: member_group_id,
    };
    const res = await this.gsAdminManager.set(params);
    if (!res.success) {
      const data = {
        message: res.message || 'error',
        class: 'error',
      };
      this.Toasts.open('simpleMessage', { data }, this.$element);
      return;
    }
    this.member.member_group_id = parseInt(member_group_id);
    const data = { message: res.message || 'done' };
    this.Toasts.open('simpleMessage', { data }, this.$element);
  }

  async setPassword() {
    let password = window.prompt('Enter new password:');
    if (!password) return;
    const res = await this.RestManager.updateMemberSettings({ password });
    if (!res.success) {
      const data = {
        message: 'ERROR',
        class: 'error',
      };
      this.Toasts.open('simpleMessage', { data }, this.$element);
      return;
    }
    const data = { message: 'DONE' };
    this.Toasts.open('simpleMessage', { data }, this.$element);
  }

  async clearCache(key) {
    const confirm = await this.clearCacheConfirm(key);

    if (confirm) {
      const params = {
        el: 'gs_admin',
        el_id: 'gs_admin',
        force: false,
        method: 'del_cache',
        cache_key: key,
        value: this.member.id,
      };
      const response = await this.gsAdminManager.set(params);
      this.clearCacheResponse(response);
    }
  }

  async clearCacheAchievements(key) {
    const confirm = await this.clearCacheConfirm(key);
    if (confirm) {
      const response = await this.RestManager.resetCacheAchievements(this.member.id);
      this.clearCacheResponse(response);
    }
  }

  async clearCacheConfirm(key) {
    const confirm = await this.Modals.confirm(
      'Are you sure?',
      `Are you want to clear redis key: ${key}`,
      'Clear',
      'Cancel'
    );

    return confirm;
  }

  clearCacheResponse(response) {
    const data = {
      message: response.success ? 'Success!' : 'Error!',
      class: response.success ? '' : 'error',
    };
    this.Toasts.open('simpleMessage', { data }, this.$element);
  }

  deactivateAccount() {
    this.openDeactivateDeleteAccountModal(memberLeaveModes.DEACTIVATE);
  }

  deleteAccount() {
    this.openDeactivateDeleteAccountModal(memberLeaveModes.DELETE);
  }

  reactivateAccount() {
    this.openDeactivateDeleteAccountModal(memberLeaveModes.REACTIVATE);
  }

  /** @return void */
  async openDeactivateDeleteAccountModal(memberLeaveMode){
    const res = await this.Modals.open('deactivateDeleteAccount', event, {
      memberLeaveMode : memberLeaveMode,
      memberLeaveStep : memberLeaveSteps.ADMIN,
      member: this.member
    });
    if(res){
      this.findMember();
      // window.location.reload();
    }
  }

  async setPermission(permission) {
    let amount;
    if(this.permissions[permission] === 0){
      amount = 1;
    }else{
      amount = -this.permissions[permission];
    }
    const params = {
      el: 'gs_admin',
      el_id: 'gs_admin',
      method: 'grant_permission',
      member_id: this.member.id,
      permission: permission.toLowerCase(),
      amount: amount,
    };
    this.busyPermissions = true;
    const res = await this.gsAdminManager.set(params);
    if (!res.success) {
      const data = {
        message: res.message || 'error',
        class: 'error',
      };
      this.Toasts.open('simpleMessage', { data }, this.$element);
      return;
    }
    await this.findMember();
    this.busyPermissions = false;
  }

  async gift(key, validate = true) {
    if (this.busyPermissions) return;
    if (validate) {
      const confirm = await this.Modals.confirm(
        `Add ${this.give[key]} ${key}?`,
        `Are you sure? You want to add ${this.give[key]} ${key}?`,
        'Add',
        'Cancel'
      );
      if (!confirm) return;
    }
    this.busyPermissions = true;

    const params = {
      el: 'gs_admin',
      el_id: 'gs_admin',
      method: 'grant_permission',
      member_id: this.member.id,
      permission: key,
      amount: this.give[key],
    };
    const res = await this.gsAdminManager.set(params);
    this.busyPermissions = false;
    if (!res.success) {
      const data = {
        message: res.message || 'error',
        class: 'error',
      };
      this.Toasts.open('simpleMessage', { data }, this.$element);
      return;
    }

    switch (key) {
      case 'swap':
        this.permissions.PHOTO_SWAP += this.give[key];
        break;
      case 'exposure_autofill':
        this.permissions.EXPOSURE_AUTOFILL += this.give[key];
        break;
      case 'key':
        this.permissions.KEY += this.give[key];
        break;
      case 'coins':
        this.permissions.COINS += this.give[key];
        break;
    }

    this.give[key] = 0;
    const data = { message: res.message || 'DONE' };
    this.Toasts.open('simpleMessage', { data }, this.$element);
  }

  async addPoints() {
    if (this.busyPoints) return;
    const confirm = await this.Modals.confirm(
      `Add ${this.give.points} GS points?`,
      `Are you sure? You want to add ${this.give.points} GS points to account?`,
      'Add',
      'Cancel'
    );
    if (!confirm) return;
    this.busyPoints = true;

    const params = {
      el: 'gs_admin',
      el_id: 'gs_admin',
      force: false,
      method: 'give_extra_points',
      member_id: this.member.id,
      points: this.give.points,
    };
    const res = await this.gsAdminManager.set(params);
    this.busyPoints = false;
    if (!res.success) {
      const data = {
        message: res.message || 'error',
        class: 'error',
      };
      this.Toasts.open('simpleMessage', { data }, this.$element);
      return;
    }

    this.member.points += this.give.points;

    const data = { message: res.message };
    this.Toasts.open('simpleMessage', { data }, this.$element);
  }

  async unlinkFacebook() {
    const confirm = await this.Modals.confirm(
      'Unlink Facebook?',
      'Are you sure you want to unlink Facebook?',
      'Unlink',
      'Cancel'
    );
    if (!confirm) return;
    await this.FacebookManager.facebookUnlink();
    window.location.reload();
  }

  async grantModeration(enabled){
    const res =  await this.gsAdminManager.set({
      member_id: this.member.id,
      enabled: enabled ? 0 : 1,
      el: 'gs_admin',
      el_id: 'gs_admin',
      method: 'grant_moderation',
    });
    if(!res.success){
      this.member.moderator = !this.member.moderator;
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async getAdminComments(member_id) {
    const response = await this.RestManager.getAdminComments(member_id);

    if(response.success && response.comments.length) {
      this.comments = response.comments.map((comment)=>{
        comment.date = moment.unix(comment.date).format(this.timeFormat);
        comment.by_member = new MemberModel(comment.by_member);
        return comment;
      });
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async deleteAdminComment(member_id, id) {
    const response = await this.RestManager.deleteAdminComment({member_id, id});

    if(response.success) {
      const data = { message: 'Comment was successfully removed!' };

      const commentIndex = this.comments.findIndex(comment => comment.id === id);
      this.comments.splice(commentIndex, 1);

      this.Toasts.open('simpleMessage', { data }, this.$element);
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async sendComment() {
    this.textComment = this._createLinkTag(this.textComment);

    if(this.textComment.length) {
      const commentModel = {
          "member_id": this.member.id,
          "by_member_id": this.member.id,
          "text": this.textComment,
          "date": moment().format(this.timeFormat),
          "by_member": {
            "id": this.member.id,
            "name": this.member.name,
            "user_name": this.member.user_name,
            "avatar": this.member.avatar
          }
        },
        setAdminCommentResponse = await this.RestManager.setAdminComment({
          member_id: this.member.id,
          text: this.textComment
        })
      ;

      if(setAdminCommentResponse.success) {
        const toastsData = { message: 'Comment was successfully saved!' };
        commentModel.id = setAdminCommentResponse.id;

        (this.comments && this.comments.length) ? this.comments.unshift(commentModel) : this.comments.push(commentModel);

        this.textComment = '';
        this.Toasts.open('simpleMessage', { toastsData }, this.$element);
      }
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  _createLinkTag(textComment) {
    textComment = textComment.trim();
    const findedLinks = textComment.match(this.regexUrl);

    if (findedLinks) {
      const separator = ' ';
      textComment = textComment.split(separator);

      findedLinks.forEach((match) => {
        if(textComment.includes(match)) {
          let linkIndex = textComment.indexOf(match);
          textComment[linkIndex] = `<a href="${match}" target="_blank">${match}</a>`;
        }
      });

      textComment = textComment.join(separator);
    }

    return textComment;
  }
}
memberManagerCtrl.$inject = [
  'gsAdminManager',
  '$stateParams',
  'Toasts',
  '$scope',
  '$rootScope',
  'Modals',
  '$element',
  'RestManager',
  'FacebookManager',
  'MemberService',
];
export default {
  template: template,
  controller: memberManagerCtrl,
  bindings: {},
};
