import {NgChallengeModel} from "../../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./adminCustomCard.html`).default;
import AdminCardModel from '../../../../api/models/card/adminCard.model';

class adminCustomCardCtrl {

  /** @param {ChallengesManager} ChallengesManager **/
  constructor(Modals, Toasts, $element, $timeout, ChallengesManager, MemberService) {
    'ngInject';
    this.mediaTypes = ['youtube', 'image'];
    this.typesList = [
      { label: 'Suspended images', value: 'suspended_images' },
      { label: 'Successful swap', value: 'successful_swap' },
      { label: 'Try swap', value: 'try_swap' },
      { label: 'Unlock state', value: 'unlock_state' },
      { label: 'Off topic', value: 'off_topic' },
      { label: 'Similar', value: 'similar' },
      { label: 'custom', value: 'custom' },
    ];
    this.showCard = false;

    this.Modals = Modals;
    this.Toasts = Toasts;
    this.$element = $element;
    this.$timeout = $timeout;
    this.ChallengesManager = ChallengesManager;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    // empty custom card
    this.card = new AdminCardModel({
      type: 'custom',
      data: {
        title: '',
        body: '',
        images: [],
        action: { text: '', url: '' },
        media: { id: '', type: 'image', image: { id: '', member_id: this.member.id } },
        popup_type: 1,
      },
    });
    // empty challenge
    this.challenge = new NgChallengeModel({});
    this.showCard = true;
  }

  onTypeChange() {
    this.showCard = false;
    this.$timeout(() => {
      this.showCard = true;
    });
  }

  select(event) {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: {
        id: this.member.id,
      },
      callback: images => {
        this.card.data.media.image.id = this.card.data.media.id = images[0].id;
      },
    });
  }

  async push() {
    const confirm = await this.Modals.confirm('Push popup?', `Are you sure? You want to push popup?`, 'Push', 'Cancel');
    if (!confirm) {
      return;
    }

    let params = {
      c_id: this.card.c_id,
      member_id: this.card.member_id,
      type: this.card.type,
    };
    switch (this.card.type) {
      case 'suspended_images':
      case 'similar':
        params.image_id = this.card.image_id;
        params.reason_text = this.card.reason_text;
        break;
      case 'successful_swap':
        params.old_image_id = this.card.old_image_id;
        params.new_image_id = this.card.new_image_id;
        break;
      case 'try_swap':
        // params.popup_type = this.card.data.popup_type;
        break;
      case 'off_topic':
        params.image_id = this.card.image_id;
        break;
      case 'custom':
        params.join_days = this.card.join_days;
        params.title = this.card.data.title;
        params.body = this.card.data.body;
        params.media_id = this.card.data.media.id;
        params.media_type = this.card.data.media.type;
        params.action_url = this.card.data.action.url;
        params.action_text = this.card.data.action.text;
        break;
    }
    // const res = await this.ChallengesManager.setPopupMessage(params);
    const res = await this.ChallengesManager.setCustomChallengeMessage(params);

    let data = {
      message: res.success ? 'Done' : res.message ? res.message : 'Error',
      class: !res.success ? 'error' : '',
    };
    this.Toasts.open('simpleMessage', { data }, this.$element);
  }
}
adminCustomCardCtrl.$inject = [
  'Modals', 'Toasts', '$element', '$timeout', 'ChallengesManager', 'MemberService'
];
export default {
  template: template,
  controller: adminCustomCardCtrl,
  bindings: {},
};
