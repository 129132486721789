function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state('gs.contact', {
      url: '/contact',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'contactPage'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
