import ImageModel from "../../../api/models/image/image.model";

const template = require(`html-loader!./challengeIntro.html`).default;
import challengeEnum from '../../../api/enums/challenge.enum';
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class challengeIntroModalCtrl {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {NotificationsManager} NotificationsManager
   * @param {challengeService} challengeService
   * **/

  constructor(
    $mdDialog,
    ChallengesManager,
    Modals,
    $state,
    NotificationsManager,
    mixpanelService,
    challengeService
  ) {
    "ngInject";
    this.busy = true;

    this.$mdDialog = $mdDialog;
    this.ChallengesManager = ChallengesManager;
    this.Modals = Modals;
    this.$state = $state;
    this.NotificationsManager = NotificationsManager;
    this.mixpanelService = mixpanelService;
    this.challengeService = challengeService;
  }

  $onDestroy() {
    this.readNotification();
  }

  $onInit() {
    if (!this.data || (!this.data.challenge && !this.data.message)) {
      this.close();
      return;
    }

    // challenge data exist
    if (this.data.challenge) {
      if (this.data.challenge.status !== challengeEnum.STATUSES.ACTIVE.VALUE) {
        this.close();

        return;
      }

      this.challenge = this.data.challenge;
      this.createImageModels();
      this.busy = false;

      return true;
    }

    // get challenge data (c_id from notifications)
    if (!this.data.message) {
      this.close();
      return;
    }
    this.notification = this.data.message;
    this.getData();
  }

  getData() {
    const params = {
      id: this.notification.c_id,
      fields: ['id', 'status', 'type'],
      include: ['apply']
    };
    this.ChallengesManager.getData(params).then(res => {
      if (!res.success) {
        this.close();
        return;
      }

      this.challenge = res.challenge;
      this.createImageModels();
      this.busy = false;
    });
  }

  book(event) {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.APPLY_NOW);
    // this.readNotification();
    // Open shop
    if(this.data.closeOnApply){
      this.$mdDialog.hide('openExhibitionShop');
      return;
    }
    this.challengeService.openExhibitionShop(this.challenge);
  }

  close() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.NO_THANKS);
    // this.readNotification();
    // close
    if (this.notification) {
      this.$mdDialog.hide();
      return;
    }
    // reload page
    this.$state.go(this.$state.current, {force: true}, {reload: this.$state.current});
    this.$mdDialog.hide();
  }

  readNotification() {
    if (this.notification && this.notification.date) {
      this.NotificationsManager.readNotifications(this.notification.date, 'activity');
    }
  }

  createImageModels() {
    this.challenge.apply = {
      ...this.challenge.apply,
      big_image: new ImageModel(this.data.challenge.apply.big_image),
      small_image: new ImageModel(this.data.challenge.apply.small_image)
    }
  }

  eventMixPanelAction(uiName) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.EXHIBITION_POP_UP,
          ui_name: uiName,
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
challengeIntroModalCtrl.$inject = [
  '$mdDialog',
  'ChallengesManager',
  'Modals',
  '$state',
  'NotificationsManager',
  'mixpanelService',
  'challengeService'
];
export default {
  template: template,
  controller: challengeIntroModalCtrl,
  bindings: {
    data: '=data'
  }
};
