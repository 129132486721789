class rankFilters {

  constructor($state, $stateParams, $rootScope, MemberService) {
    'ngInject';
    this.filter = 'default';
    this.filtersList = {
      'gs.challenge.rank.topPhotographer': {
        default: 'All Members',
        following: 'Following',
        friends: 'Friends'
      },
      'gs.challenge.rank.topPhoto': {
        default: 'All Members',
        following: 'Following',
      },
      'gs.challenge.rank.gurusTopPick': {
        default: 'All Members',
        following: 'Following',
        my_photos: 'Me'
      }
    };
    this.filtersListAdmin = {
      'gs.challenge.rank.topPhotographer': {
        adult: 'Adult content',
        off_topic: 'Off Topic',
        copyright: 'Copyrights Infringement',
        winning: 'Previous Winning Photo',
        similar: 'Similar image',
        paid_exhibition: 'Paid members'
      },
      'gs.challenge.rank.topPhoto': {
        paid_members: 'Paid members',
        selected_expo_1: 'Selected Expo Print',
        selected_expo_2: 'Selected Expo Digit'
      },
      'gs.challenge.rank.gurusTopPick': {
        paid_members: 'Paid members'
      }
    };

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$rootScope = $rootScope;
    this.member = MemberService.getCurrentMember();
  }

  getList() {
    let filtersList = _.cloneDeep(this.filtersList);
    // remove friends if not signed in
    if (!this.member.logged_in) {
      delete filtersList["gs.challenge.rank.topPhotographer"].friends;
    }
    return this.member.is_admin
      ? _.assignIn(filtersList[this.$state.current.name], this.filtersListAdmin[this.$state.current.name])
      : filtersList[this.$state.current.name];
  }

  getFilter() {
    if (!this.filtersList[this.$state.current.name]) {
      return false;
    }

    if (!this.filtersList[this.$state.current.name][this.filter]) {
      this.filter = 'default';
    }

    return this.filter;
  }

  setFilter(filter, event) {
    if (event) {
      event.stopPropagation();
    }
    // if same return false
    if (this.filter === filter) {
      return this.filter;
    }
    // set filter
    this.filter = filter ? filter : 'default';
    this.$stateParams.filter = null;
    // update subscribers
    this.$rootScope.$emit('onFilterChanged', {filter: this.filter});
    return this.filter;
  }

  isAdminFilter() {
    return _.includes(Object.keys(this.filtersListAdmin[this.$state.current.name]), this.filter);
  }
}
rankFilters.$inject = [
  '$state', '$stateParams', '$rootScope', 'MemberService'
];
export default rankFilters;
