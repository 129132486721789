import {clearObject} from "../helpers/object.helper";
import {getUnixTime} from "../helpers/date.helper";
import environment from "../gsApp.environment";

class tagEventService {

  constructor($rootScope, $location, mixpanelService, $window, MemberService) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.mixpanelService = mixpanelService;
    this.$window = $window;
    this.member = MemberService.getCurrentMember();
  }

  signup(memberId) {
    // this.setConversionData(conversionData);
    this.gTag({
      event: 'sign_up',
      user_id: memberId,
    });
  }

  swap(firstTime){
    // this.setConversionData();
    // this.sendEvent('Web_Swap');
    if(firstTime){
      this.gTag({event: 'first_swap',});
    }
  }

  gTag(data) {
    this.$window.dataLayer = this.$window.dataLayer || [];
    if(!data.user_id && this.member.logged_in && this.member.id){
      data.user_id = this.member.id;
      data.level_status = this.member.member_status_name;
    }
    if(environment.production){
      dataLayer.push(data);
    }
  }

  storeComplete(pack, firstTime){
    // this.setConversionData();
    const transactionId = getUnixTime();
    const ecommerce = {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id: transactionId,
          revenue: pack.price,
        },
        products: [
          {
            id : pack.id,
            name : pack.description,
            price : pack.price,
            category : pack.type,
            quantity : 1
          }
        ]
      }
    };
    this.gTag({
      event: firstTime ? 'first_purchase' : 'purchase',
      ecommerce: ecommerce
    });
  }

  fill(){
    // this.setConversionData();
  }

  boost(){
    // this.setConversionData();
  }

  setConversionData(data) {
    let conversionData;
    if(data){
      conversionData = data;
    }else{
      conversionData = {
        "email": this.member.email,
        "first_name": this.member.first_name,
        "last_name": this.member.last_name,
        "home_address": {
          "city": this.member.city,
          "state": this.member.state,
          "country": this.member.country,
        }
      };
    }
    clearObject(conversionData);
    window.enhanced_conversion_data = conversionData;
  }

  sendPageViewEvent({title, sendToMixPanel=true}){
    const fullPath = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`
    const data = {
      event: 'virtual_page_view',
      virtualPageURL: this.$location.path(),
      virtualPageTitle: title,
      fullPath: this.$location.absUrl()
    };
    // console.log('data', data);
    this.gTag(data);
    if(sendToMixPanel){
      this.mixpanelService.trackViewPage();
    }
  }

  joinChallenge(challengeCount){
    this.gTag({event: `join_challenge_${challengeCount}`});
  }
}
tagEventService.$inject = [
  '$rootScope', '$location', 'mixpanelService', '$window', 'MemberService'
];
export default tagEventService;
