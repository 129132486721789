import memberEnum from "../../../../api/enums/member.enum";

const template = require(`html-loader!./topPhotographer.html`).default;
const noItemsDefaultTemplate = require(`html-loader!./noItems/default.html`).default;
const noItemsFollowingTemplate = require(`html-loader!./noItems/following.html`).default;
const noItemsFriendsTemplate = require(`html-loader!./noItems/friends.html`).default;
const noItemsFriendsLinkTemplate = require(`html-loader!./noItems/friends.link.html`).default;
import ImageModel from '../../../../api/models/image/image.model';
import { levels, levelNames, filterReasonIds } from './topPhotographer.data';
import MemberModel from "../../../../api/models/member.model";


class rankTopPhotographerCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {RestManager} RestManager
   * @param {ChallengesManager} ChallengesManager
   * @param {RankingManager} RankingManager
   * @param {rankFilters} rankFilters
   * @param {Toasts} Toasts
   * */

  constructor(
    $scope,
    $timeout,
    $stateParams,
    rankFilters,
    Modals,
    $location,
    RestManager,
    Facebook,
    $window,
    ChallengesManager,
    $state,
    RankingManager,
    $element,
    $mdToast,
    $rootScope,
    tagEventService,
    challengeService,
  ) {
    'ngInject';
    this.challenge;
    this.member;
    this.items = [];
    this.currentLevel = 0;
    this.limit = 100;
    this.busy = false;
    this.is_joined = false;
    this.noItems = null;
    this.inited = false;
    this.templates = {
      default: noItemsDefaultTemplate,
      following: noItemsFollowingTemplate,
      friends: noItemsFriendsTemplate,
      friendsLink: noItemsFriendsLinkTemplate,
    };
    this.levels = _.clone(levels);
    this.levelNames = _.clone(levelNames);

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.rankFilters = rankFilters;
    this.Modals = Modals;
    this.$location = $location;
    this.RestManager = RestManager;
    this.Facebook = Facebook;
    this.$window = $window;
    this.ChallengesManager = ChallengesManager;
    this.$state = $state;
    this.RankingManager = RankingManager;
    this.$element = $element;
    this.$mdToast = $mdToast;
    this.$rootScope = $rootScope;
    this.tagEventService = tagEventService;
    this.challengeService = challengeService;

    this.goToMyPos = this.goToMyPos.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.updateNavLevel = _.debounce(this.updateNavLevel, 250);
    this.onScroll = _.debounce(this.onScroll.bind(this), 250);
    this.onResize = _.debounce(this.onResize.bind(this), 250);
    // tools
    this.tools = {
      cancelReport: this.cancelReport.bind(this),
      remove: this.remove.bind(this),
      report: this.report.bind(this),
      preSubmit: this.preSubmit.bind(this),
      guruPick: this.guruPick.bind(this),
    };
  }

  $onInit() {
    this.isNewRankingEnabled = this.challenge.new_ranking_enable;
    if (this.isNewRankingEnabled) {
      return false;
    }
    // redirect to top photo for speed challenge
    if (!this.challenge.top_photographer_enable) {
      this.$state.go('gs.challenge.rank.topPhoto', this.$stateParams, {
        reload: true,
        location: 'replace',
      });
      return;
    }

    // page view
    this.tagEventService.sendPageViewEvent({title: 'Challenge Gurus Top Photographer'});

    // fix for mobile
    if (document.body.clientWidth <= 961) {
      this.onResize();
      $(window).on('resize', this.onResize);
    }

    // events
    $(document).on('go-to-my-position', this.goToMyPos);
    this.offOnFilterChanged = this.$rootScope.$on('onFilterChanged', this.onFilterChanged);
    this.$rootScope.$emit('onChangedRankPage');

    // filter
    this.filter = this.rankFilters.filter;
    if (this.$stateParams.filter) {
      this.rankFilters.setFilter(this.$stateParams.filter);
      return;
    } else if (this.filter !== 'default') {
      this.rankFilters.setFilter('default');
      return;
    }

    this.loadItems();
  }

  $onDestroy() {
    $(window).off('resize', this.onResize);
    $(document).off('go-to-my-position', this.goToMyPos);
    $('.md-virtual-repeat-scroller').off('scroll', this.onScroll);
    if (this.offOnFilterChanged) {
      this.offOnFilterChanged();
    }
    if (this.timeout) {
      this.$timeout.cancel(this.timeout);
    }
    this.destroyed = true;
  }

  /** @return void */
  async loadItems(extraParams = {}) {
    if (this.busy) return;
    this.busy = true;

    // reset
    this.items = [];

    // prepare data
    let params = {
      c_id: this.challenge.id,
      user_name: this.$stateParams.targetMember,
      init: !this.inited,
      filter: this.filter,
      limit_above: 100,
      limit_below: 100,
    };
    // extra params
    _.assignIn(params, extraParams);
    // get data
    const res = await this.RankingManager.getTopPhotographer(params);
    // renew facebook token
    if (!res.success && this.filter === 'facebook' && res.error_code === 1018) {
      this.Facebook.renewToken();
      this.noItems = this.templates[this.filter];
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }

    // not logged in
    if (!this.timeout && !this.member.logged_in && !this.$rootScope.disableLazy) {
      this.timeout = this.$timeout(() => {
        this.Modals.open('login', null, { signUp: true });
      }, 10000);
    }

    // no items
    if (!res.success || !res.items.length) {
      this.noItems = this.templates[this.filter];
      this.busy = false;
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }

    this.startIndex = _.findIndex(res.items, { index: res.start_index });
    for (const [index, item] of res.items.entries()) {
      item.member = new MemberModel(item.member);
      item.entries = item.entries.map(image => new ImageModel(image));
      // add badge
      if (
        this.challenge.levels_rank_enable &&
        (this.startIndex === 0 && index === 0) ||
        (index !== 0 && item.total.level < res.items[index - 1].total.level)
      ) {
        this.items.push({
          type: 'badge',
          value: item.total.level,
          total: {
            level: item.total.level,
          },
        });
      }
      // item.total.prizes = [
      //   {
      //     "type": "PHOTO_SWAP",
      //     "amount": 30
      //   },
      //   {
      //     "type": "EXPOSURE_AUTOFILL",
      //     "amount": 25
      //   },
      //   {
      //     "type": "KEY",
      //     "amount": 20
      //   }
      // ]
      // if(index === 1){
      //   item.member.member_group_id = memberEnum.GROUP.DEACTIVATE_BY_MEMBER;
      // }
      // add item
      this.items.push(item);
    }
    this.items = this.items.filter((item) => {
      return item.member ? !item.member.isRestricted() : true;
    });
    // get index again (fix for badges)
    this.startIndex = _.findIndex(this.items, { index: res.start_index });

    if (!this.inited) {
      this.is_joined = res.init.is_joined;

      // set levels
      for (const level of this.levels) {
        level.count = res.init.levels_count[level.index];
      }

      this.inited = true;
    }
    // scroll to start index
    this.$timeout(() => this.go(this.startIndex), 250);

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  //Go to position
  go(index = 0) {
    // validate
    if (index === false || !this.items) {
      return false;
    }

    // move one down if it first element (patch for badge)
    if (index === 0) {
      index = 1;
    }

    // fix for desktop: Top index - 1 to center element
    this.topIndex = window.screen.availWidth > 768 || index === 1 ? index - 1 : index;

    // find level
    const level = this.items[index].total.level;
    // change active tab
    if (this.currentLevel !== level) {
      this.currentLevel = level;
    }

    this.busy = false;
  }

  goToMyPos() {
    this.loadItems({ member_id: this.member.id });
  }

  virtualRepeatOnInit() {
    // scroll inside changing global scroll
    $('.md-virtual-repeat-scroller')
      .off('scroll', this.onScroll)
      .on('scroll', this.onScroll);
  }

  async remove(imageToRemove, item) {
    if(await this.challengeService.removeImageFromChallenge({
      imageToRemove:imageToRemove,
      challenge: this.challenge,
    })){
      item.entries = item.entries.filter(image => image.id !== imageToRemove.id);
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }

  updateNavLevel() {
    // active menu level
    const items = $('.top-photographer-item:not(.top-photographer-item--badge)');
    const item = items[items.length - 3];
    if (!item) {
      return;
    }
    // get current level
    const level = item.attributes['data-level'].value;
    // set if new
    if (level !== this.currentLevel) {
      this.currentLevel = level;
    }
  }

  cancelReport(item) {
    this.RestManager.handleReports({
      member_id: item.member.id,
      c_id: this.challenge.id,
      reason_id: item.reason_id,
    }).then(res => {
      if (res.success) {
        this.items.splice(this.items.indexOf(item), 1);
      }
    });
  }

  report(event, image) {
    this.$window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.Modals.open('report', event, {
      image: image,
      c_id: this.challenge.id,
      showOffTopic: true
    });
  }

  preSubmit(event) {
    this.Modals.open('gsUploader', event, {
      target: 'challenge',
      challenge: this.challenge,
    });
  }

  guruPick(image) {
    let image_ids = [image.id];
    this.ChallengesManager.submitVotes(this.challenge.id, image_ids).then(res => {
      if (!res.success) {
        return true;
      }
      image.guru_pick = true;
    });
  }

  onScroll(event) {
    // scroll to bottom
    if (window.scrollY + window.innerHeight < document.body.scrollHeight) {
      console.log('scroll to bottom');
      this.$window.scroll({ top: 700, behavior: 'smooth' });
    }
    // validate
    if (this.busy || this.rankFilters.isAdminFilter() || this.items.length <= this.limit) {
      return true;
    }
    let element = event.currentTarget;
    // get above
    if (
      element.scrollTop === 0 &&
      this.startIndex !== 0 &&
      !(this.startIndex === 1 && this.items[0].type === 'badge')
    ) {
      console.log('load index above:' + this.items[0].index);
      this.loadItems({ index: this.items[0].index });
      // track
      // popup
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .parent(this.$element)
          .textContent('Ranking was just updated live! Some of the ranks may have changed while you scrolled.')
          .position('bottom left')
          .hideDelay(4000)
      );
      return true;
    }
    // get below
    if (element.scrollTop >= element.scrollHeight - element.offsetHeight && this.startIndex !== this.items.length - 1) {
      console.log('load index below:' + this.items[this.items.length - 1].index);
      this.loadItems({ index: this.items[this.items.length - 1].index });
      // track
      // popup
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .parent(this.$element)
          .textContent('Ranking was just updated live! Some of the ranks may have changed while you scrolled.')
          .position('bottom left')
          .hideDelay(4000)
      );
      return true;
    }
    this.updateNavLevel();
    return true;
  }

  onFilterChanged(event, data) {
    this.filter = this.rankFilters.filter;
    this.inited = false;
    this.loadItems();
  }

  onResize() {
    this.$timeout(() => {
      this.availHeight = window.innerHeight;
      $('rank-top-photographer').css({
        minHeight: this.availHeight - 40 + 'px',
        maxHeight: this.availHeight - 40 + 'px',
        height: this.availHeight - 40 + 'px',
        overflow: 'hidden',
      });
    }, 500);
  }
}
rankTopPhotographerCtrl.$inject = [
  '$scope',
  '$timeout',
  '$stateParams',
  'rankFilters',
  'Modals',
  '$location',
  'RestManager',
  'Facebook',
  '$window',
  'ChallengesManager',
  '$state',
  'RankingManager',
  '$element',
  '$mdToast',
  '$rootScope',
  'tagEventService',
  'challengeService',
];
export default {
  template: template,
  controller: rankTopPhotographerCtrl,
  bindings: {
    challenge: '<',
    member: '<',
  },
};
