import environment from "../gsApp.environment";

/**
 * Share button manager
 *
 * <tag attr>: gs-share="[fb, twitter, google-plus]" gs-share-code="<integer>"
 *             gs-share-url="<string>" (must be with tracking!)
 *
 * @param {popupService} popup
 * @param {RestManager} RestManager
 * */
function gsShare(
  $document,
  RestManager,
  popup
) {
  'ngInject';

  return {
    restrict: 'A',
    controller: shareBtnCtrl,
    controllerAs: 'shareBtn',
    bindToController: true,
    scope: {
      type: '@gsShare',
      code: '@gsShareCode',
      param: '<gsShareParam',
      url: '<gsShareUrl',
      disabled: '=gsShareDisabled'
    },
    link: function(scope, element, attrs, ctrl) {
      const onClick = async event => {
        if (ctrl.disabled) {
          return;
        }

        // open empty window
        let newWindow = popup.open();
        // get link
        let link;
        if (ctrl.url) {
          ctrl.code = 'url';
          link = ctrl.getLink();
        } else {
          // get tracking codes
          const res = await RestManager.getTc();
          // code exist
          if (!res.items[ctrl.code]) {
            newWindow.close();
            return false;
          }
          // get link
          link = ctrl.getLink(res.items[ctrl.code]);
        }
        if (!link) {
          newWindow.close();
          return;
        }
        // replace popup link
        newWindow.location.replace(link);
      };
      // Event
      element.bind('click', onClick);
    }
  };
}

gsShare.$inject = [
  '$document',
  'RestManager',
  'popup'
];

class shareBtnCtrl {
  constructor(
    $rootScope,
    $state,
    $scope,
    MemberService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$scope = $scope;

    //Facebook app id
    this.appId = environment.facebookAppId;
    //Random string to disable Facebook cache
    this.uString = Math.floor(Math.random() * 10000000000000001);
    this.member = MemberService.getCurrentMember();
  }

  getLink(tc) {
    const redirect_uri = `https://${environment.domains.api}/viral/redirect/${tc}`;
    let link;
    switch (this.type) {
      case 'fb': {
        //Share type
        switch (this.code) {
          //Share member
          case '1000': {
            let facebook_message = {
              main: 'https://www.facebook.com/dialog/share?',
              params: {
                app_id: this.appId,
                display: 'popup',
                href: `${window.location.href}/${this.member.user_name}?tc=${tc}`,
                redirect_uri: redirect_uri
              }
            };
            link = facebook_message.main + $.param(facebook_message.params);
            break;
          }
          //Send challenge URL
          case '1003':
          case '1001': {
            this.$state.go('gs.invite', {}, {reload: true});
            return;
          }
          //Send home url
          case '1006':
          case '1026': {
            this.$state.go('gs.invite', {}, {reload: true});
            return;
          }
          case '1027': {
            let facebook_message = {
              main: 'https://www.facebook.com/dialog/share?',
              params: {
                app_id: this.appId,
                display: 'popup',
                href: `https://${window.location.host}/challenge/${this.param}/rank/top-photographer/${
                  this.member.user_name
                }/${this.uString}?tc=${tc}`,
                redirect_uri: redirect_uri
              }
            };
            link = facebook_message.main + $.param(facebook_message.params);
            break;
          }
          case 'url': {
            let facebook_message = {
              main: 'https://www.facebook.com/dialog/share?',
              params: {
                app_id: this.appId,
                display: 'popup',
                href: this.url,
                redirect_uri: redirect_uri
              }
            };
            link = facebook_message.main + $.param(facebook_message.params);
            break;
          }
          default:
            let facebook_message = {
              main: 'https://www.facebook.com/dialog/share?',
              params: {
                app_id: this.appId,
                display: 'popup',
                href: `${window.location.href}?tc=${tc}`,
                redirect_uri: redirect_uri
              }
            };
            link = facebook_message.main + $.param(facebook_message.params);
        }
        break;
      }
      case 'twitter': {
        switch (this.code) {
          //Share to time line
          case '1000': {
            let text =
              typeof window.location.href.split('/')[7] === 'undefined'
                ? `${window.location.href}/${this.member.user_name}?tc=${tc}`
                : `https://${window.location.host}?tc=${tc}`;

            let twitter_message = {
              main: 'https://twitter.com/intent/tweet?',
              params: {
                hashtags: 'gurushots',
                text: text,
                redirect_uri: redirect_uri
              }
            };
            link = twitter_message.main + $.param(twitter_message.params);
            break;
          }
          case '1027': {
            let text = `https://${window.location.host}/challenge/${this.param}/rank/top-photographer/${
              this.member.user_name
            }/${this.uString}?tc=${tc}`;

            let twitter_message = {
              main: 'https://twitter.com/intent/tweet?',
              params: {
                hashtags: 'gurushots',
                text: text,
                redirect_uri: redirect_uri
              }
            };
            link = twitter_message.main + $.param(twitter_message.params);
            break;
          }
          case 'url': {
            let twitter_message = {
              main: 'https://twitter.com/intent/tweet?',
              params: {
                hashtags: 'gurushots',
                text: this.url,
                redirect_uri: redirect_uri
              }
            };
            link = twitter_message.main + $.param(twitter_message.params);
            break;
          }
          default: {
            let text = `https://${window.location.href}?tc=${tc}`;

            let twitter_message = {
              main: 'https://twitter.com/intent/tweet?',
              params: {
                hashtags: 'gurushots',
                text: text,
                redirect_uri: redirect_uri
              }
            };
            link = twitter_message.main + $.param(twitter_message.params);
          }
        }
        break;
      }
      case 'tumblr':
        let tumblr_message = {
          main: 'http://tumblr.com/widgets/share/tool?',
          params: {
            canonicalUrl: this.url
          }
        };
        link = tumblr_message.main + $.param(tumblr_message.params);
        break;
      default:
        return;
    }

    return link;
  }
}

shareBtnCtrl.$inject = [
  '$rootScope',
  '$state',
  '$scope',
  'MemberService',
];

export default gsShare;
