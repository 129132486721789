import detectDevice from './vendors/detectDevice';
import {HTTP_ENUM} from "./api/enums/http.enum";

import environment from './gsApp.environment';
import NgTokenService from "../../core/services/ng-token.service";

function gsAppRun (
  pageData,
  $http,
  $rootScope,
  NgTokenService,
  authService,
  Facebook,
) {
  'ngInject';

  // set photo domain
  $rootScope.photoDomain =  'https://' + environment.domains.photos;
  // put device class to HTML tag
  detectDevice();
  // REST API settings
  $http.defaults.headers.common[HTTP_ENUM.HEADER.X_API_VERSION] = '13';
  $http.defaults.headers.common[HTTP_ENUM.HEADER.X_ENV] = 'WEB';

  if(NgTokenService.memberToken){
    $http.defaults.headers.common[HTTP_ENUM.HEADER.X_TOKEN] = NgTokenService.memberToken;
    if(NgTokenService.adminToken) {
      $http.defaults.headers.common[HTTP_ENUM.HEADER.X_ADMIN_TOKEN] = NgTokenService.adminToken;
    }
  }

  authService.afterSessionStarted();
  // init facebook
  Facebook.init();
}
gsAppRun.$inject = [
  'pageData',
  '$http',
  '$rootScope',
  'NgTokenService',
  'authService',
  'Facebook',
];
export default gsAppRun;
