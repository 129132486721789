import {getMemberChallengesFilterType} from "../../../../../modules/challenges/services/joined-challenges-page.service";

const template = require(`html-loader!./challengesUpcoming.html`).default;
import {SocketsEnum} from "../../../../../core/enum/sockets.enum";
import {Subject} from "rxjs";
const IS_MOBILE = 768;

class challengesUpcomingCtrl {
  /** @param {ChallengesManager} ChallengesManager **/

  subject = new Subject();
  constructor(
    $timeout,
    $stateParams,
    $location,
    $window,
    Modals,
    ChallengesManager,
    $scope,
    tagEventService,
    JoinedChallengesPageService,
    MediatorService,
  ) {
    'ngInject';
    this.busy = true;

    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$window = $window;
    this.Modals = Modals;
    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.mediatorService = MediatorService;

    this.onResize = _.debounce(this.onResize.bind(this), 250);
  }

  async $onInit() {
    // virtual page view
    this.tagEventService.sendPageViewEvent({title: 'Upcoming challenges'});
    // mobile view
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    $(window).on('resize', this.onResize);
    // scroll to top
    this.$window.scrollTo(0, 0);
    this.JoinedChallengesPageService.$upcomingChallenges.pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe((challenges)=>{
      if(challenges === undefined){return}
      this.items = challenges;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });
    this.JoinedChallengesPageService.getUpcomingChallenges();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(window).off('resize', this.onResize);
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
challengesUpcomingCtrl.$inject = [
  '$timeout',
  '$stateParams',
  '$location',
  '$window',
  'Modals',
  'ChallengesManager',
  '$scope',
  'tagEventService',
  'JoinedChallengesPageService',
  'MediatorService',
];
export default {
  template: template,
  controller: challengesUpcomingCtrl,
};
